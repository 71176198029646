import { useContext } from 'react'
import { AppContext } from '../components/common/context/AppContext'

export const getSteps = (financeType) => {
  if (financeType === 'Finance') {
    return [
      {
        step: 1,
        name: 'Basics',
        nav: 'vehiclebasics',
        ariaLabel: 'Step one of five. Vehicle basics'
      },
      {
        step: 2,
        name: 'Condition',
        nav: 'vehiclecondition',
        ariaLabel: 'Step three of five.  Vehicle condition'
      },
      {
        step: 3,
        name: 'Finance',
        nav: 'financing',
        ariaLabel: 'Step four of five.  Vehicle finance'
      }
    ]
  } else if (financeType === 'Lease') {
    return [
      {
        step: 1,
        name: 'Lease Information',
        nav: 'financing',
        ariaLabel: 'Step one of three.  Lease Information'
      },
      {
        step: 2,
        name: 'Lease Obligation',
        nav: 'confirmbalance',
        ariaLabel: 'Step two of three.  Lease obligation'
      }
    ]
  } else {
    return [
      {
        step: 1,
        name: 'Basics',
        nav: 'vehiclebasics',
        ariaLabel: 'Step one of four. Vehicle basics'
      },
      {
        step: 2,
        name: 'Condition',
        nav: 'vehiclecondition',
        ariaLabel: 'Step three of four.  Vehicle condition'
      }
    ]
  }
}
