export const StepHeaders = {
  financePath: {
    main: 'Vehicle value',
    sub: 'Tell us how you own your vehicle',
    saved: {
      main: 'CHOOSE ONE FROM YOUR CURRENT VEHICLES',
      expired:
        'This appraisal has expired. If you still own this vehicle you can quickly unlock its value by updating your appraisal.'
    }
  },
  landing: {
    main: 'What Vehicle Will You Trade In?',
    sub: 'Provide your VIN (Vehicle Identification Number) or license plate number.',
    recall: {
      main: 'Recall Vehicle Refund Estimator',
      sub: 'You can receive an estimated refund amount for your vehicle by entering some information below'
    }
  },
  vehicleFound: {
    main: "Here's What We Found",
    sub: 'Is this your vehicle?'
  },
  vehicleBasics: {
    main: 'Vehicle Basics',
    sub: "Let's start by entering the details below"
  },
  vehicleCondition: {
    main: 'Vehicle Condition',
    sub: 'Answer the questions below so we can get a better idea of the current condition of your vehicle.'
  },
  financing: {
    Finance: {
      ford: {
        main: 'Enter Your Loan Information',
        sub: 'We’ll look up the status of your loan. Enter what you still owe on your vehicle.',
        manuallySub: ''
      },
      lincoln: {
        main: 'Enter Your Loan Information',
        sub: 'We’ll look up the status of your loan. Simply provide us with your lender and account number.',
        manuallySub: 'Enter what you still owe on your vehicle.'
      }
    },
    Lease: {
      ford: {
        main: 'Please Enter Your Lease Information',
        sub: 'Review the terms of your lease and/or contact your lender to determine the amount you may need to pay to end your lease. This amount may include remaining monthly payments, disposition fees, charges for excess mileage and excess wear and use, and other fees/charges set forth in your lease. Your lease may provide alternative ways to manage early termination that may be less expensive. Please note that by providing your information this way, your Estimated Lease Obligation to Terminate Amount may be less accurate.',
        manuallySub: ''
      },
      lincoln: {
        main: 'Please Enter Your Lease Information',
        sub: 'We’ll look up the status of your lease. Simply provide us with your lender and account number.',
        manuallySub:
          'Review the terms of your lease and/or contact your lender to determine the amount you may need to pay to end your lease. This amount may include remaining monthly payments, disposition fees,charges for excess mileage and excess wear and use, and other fees/charges set forth in your lease. Your lease may provide alternative ways to manage early termination that may be less expensive. Please note that by providing your information this way, your Estimated Lease Obligation to Terminate Amount may be less accurate.'
      }
    }
  },
  confirmBalance: {
    Finance: {
      main: 'Remaining Balance',
      sub: {
        confirm: 'Please confirm this is the correct amount.',
        incorrectBal:
          'Tell us more about why your remaining balance is not correct.'
      }
    },
    Lease: {
      main: 'Your Estimated Lease Obligation to Terminate Amount',
      sub: {
        confirm: 'Please confirm this is the correct amount.',
        incorrectBal:
          'Tell us more about why your remaining balance is not correct.',
        nonFmcc: '',
        fmcc: 'This estimated amount is the sum of your remaining monthly payments including tax, late charges, and extension fees, all of which are reflected as of the date you generated this amount. There may be additional charges such as a disposition fee, excess mileage charges, excess wear and use charges, and associated taxes that are not included in this amount. Your lease may provide alternative ways to manage early termination that may be less expensive. Please see your lease for details and discuss your options with your dealer or Ford Credit.'
      }
    }
  },
  leaseObligation: {
    main: 'Your Estimated Lease Obligation to Terminate Amount',
    sub: 'This estimated amount is the sum of your remaining monthly payments. There may be additional charges such as a disposition fee, excess mileage or excess wear & use and associated taxes that are not included in this amount. Your lease may provide alternative ways to manage early termination that may be less expensive. Please see your lease for details and discuss your options with your dealer or leasing company.'
  },
  sendToDealer: {
    main: 'SEND THIS TO YOUR DEALER',
    sub: "To redeem your vehicle's appraisal value, please fill out the contact information below."
  },
  leaseSummary: {
    main: 'Lease Summary',
    sub: 'Please carefully review your vehicle details'
  },
  summary: {
    main: 'Trade-in Summary',
    sub: 'Please carefully review your vehicle details.'
  },
  appraisal: {
    guaranteedFlow: {
      main: 'Your trade-in appraisal'
    },
    estimatedFlow: {
      main: 'Your estimated trade-in value'
    },
    fusionRecallFlow: {
      main: 'Your Estimated Refund Amount is'
    },
    error: "We're sorry"
  },
  leaseAppraisal: {
    main: 'Your Estimated Lease Obligation to Terminate Amount'
  },
  ownershipStatus: {
    main: 'OWNERSHIP STATUS PAGE'
  }
}

export const ErrorMessage = {
  financing: {
    Finance: {
      errorMessage:
        'We were unable to find your account, please double-check that it was entered correctly.'
    },
    Lease: {
      errorMessage:
        'We were unable to find your account, please double-check it was entered correctly.'
    }
  }
}

export const StepBody = {
  financing: {
    Finance: {
      ford: {
        linkText: 'Enter loan information manually',
        note: 'Note that by providing your remaining balance information this way, your offer will be less accurate.',
        linkText2: 'Want a more accurate offer?',
        note2:
          'By selecting Continue, I certify that I am the account holder for the payoff amount information requested and have verified the accuracy of the search criteria provided. I further consent to access of such account information and payoff information by Ford Motor Company and its affiliates and service providers and the provision of such information to the dealer I have selected.'
      },
      lincoln: {
        linkText: 'Enter loan information manually',
        note: 'Note that by providing your remaining balance information this way, your offer will be less accurate.',
        linkText2: 'Want a more accurate offer?',
        note2:
          'By selecting Next, I certify that I am the account holder for the payoff amount information requested and have verified the accuracy of the search criteria provided. I further consent to access of such account information and payoff information by Ford Motor Company and its affiliates and service providers and the provision of such information to the dealer I have selected.'
      }
    },
    Lease: {
      ford: {
        linkText: 'Enter lease information manually',
        note: 'Note: If you manually enter your Estimated Lease Obligation to Terminate Amount, you are responsible for its accuracy.',
        linkText2: 'Want an estimate directly from your lender?',
        note2:
          'By selecting Next, I certify that I am the account holder for the remaining lease payment information requested and have verified the accuracy of the search criteria provided. I further consent to access of such account information and payoff information by Ford Motor Company and its affiliates and service providers and the provision of such information to the dealer I have selected.'
      },
      lincoln: {
        linkText: 'Enter lease information manually',
        note: 'Note: If you manually enter your Estimated Lease Obligation to Terminate Amount, you are responsible for its accuracy.',
        linkText2: 'Want an estimate directly from your lender?',
        note2:
          'By selecting Next, I certify that I am the account holder for the remaining lease payment information requested and have verified the accuracy of the search criteria provided. I further consent to access of such account information and payoff information by Ford Motor Company and its affiliates and service providers and the provision of such information to the dealer I have selected.'
      }
    }
  },
  selectFinance: {
    ford: {
      owntext: 'My vehicle is paid off.',
      financeText: 'I finance through Ford Credit or another lender.',
      leaseText: 'I lease through Ford Credit or another lender.'
    },
    lincoln: {
      owntext: 'My vehicle is paid off.',
      financeText:
        'I finance through Lincoln Automotive Financial Services, Ford Credit or another lender.',
      leaseText:
        'I have a lease through Lincoln Automotive Financial Services, Ford Credit or another lender.'
    }
  }
}
export const ownershipPageDisclaimer = {
  guaranteedFlow:
    'It appears we have one or more vehicles associated with your account registered in our system. You can opt to choose one of these for trade-in evaluation.',
  estimatedFlow:
    'Trade-in amounts shown are conditional estimates that are not binding. Actual values are dependent on many factors and the dealer is not obligated to accept the amount shown.'
}

export const EstimatedFlowPopupTip = (
  <div>
    The value is conditional. The actual valuation of any used vehicle is
    dependent on many factors and the dealer did not create the estimate and is
    not obligated to accept the estimated trade-in valuation. However, if you
    provided accurate information using the Ford Trade-in Estimator Tool, and
    the information you provided can be verified, Ford will back the amount for
    the next 7 days and ensure it is credited to your transaction, subject to
    any actual remaining balance. For existing lease customers, this amount is
    not applicable to your experience and will always be $0. Please see Trade-In
    Terms and Conditions for more details:{" "}
    <a
      target='_blank'
      href=' https://www.tradein.ford.com/termsandconditions/US/en_us.html'
    >
      https://www.tradein.ford.com/termsandconditions/US/en_us
    </a>{" "}
    If any information was not verified, or the vehicle was not acceptable under
    the Trade-In Terms and Conditions, neither the dealer nor Ford have any
    obligations regarding the estimate or the proposed trade-in vehicle.
  </div>
)

export const TradeInTerms = {
  title: 'Trade-In Program Terms and Conditions',
  introduction:
    'These Trade-In Program Terms and Conditions ("Terms") contain important disclaimers and other provisions that limit our liability to you.',
  applicability:
    'These Terms apply to your access to and use of the Ford eCommerce Trade-In Program module ("Program") which is owned and/or administered by Ford Motor Company, One American Road, Dearborn, Michigan 48126 United States of America (hereinafter “Ford”, “we”, “us”). These Terms do not alter in any way the terms of any other agreement you may have with us. Access to and use of the Program under these Terms is limited to the United States.',
  disagreeTerms:
    'If you do not agree to these Terms or any modification of these Terms, then you must immediately stop using the Program.',
  programFunctionality:
    'The Program functionality depends on whether you are currently leasing or retail financing.',
  sections: [
    {
      title: 'PROGRAM - RETAIL FINANCING.',
      content: [
        'If you are financing your existing vehicle, the Program is tailored specifically so that you are provided with a fair trade-in appraisal ("Trade-In Appraisal") for your vehicle. If your vehicle qualifies, you can apply your trade-in appraisal toward the purchase or lease of a new Ford or Lincoln vehicle subject to the Terms. Trade-In Appraisal is available for any qualified 2009 and later-model-year vehicle.',
        'Trade-In Appraisal is valid for seven days at a participating Ford dealer ("Participating Dealer"). This offer is not binding on dealers in the following states: Florida (FL), North Carolina (NC)',
        'Your Trade-In Appraisal is based on specific information about your vehicle, collected from your online questionnaire and a mandatory inspection at a Participating Dealer.',
        'Your vehicle must pass a mandatory vehicle inspection by a Participating Dealer. If the inspection report differs from your description or online assessment of your vehicle’s condition, the Participating Dealer may adjust the Trade-In Appraisal, which may mean decreasing the appraisal amount or disallowing the vehicle.',
        'Trade-In Appraisal may be combined with other offers. Customer must take delivery of any new previously untitled Ford or Lincoln vehicle or from a Participating Dealer.'
      ],
      subsections: [
        {
          title: 'Retail Financing Requirements',
          items: [
            'You must be 18 years of age or older or the age of majority, as determined by the laws of your state or territory of residency, in order to accept these Terms;',
            'You must be the registered owner of the vehicle in question;',
            'Vehicle cannot be titled in a business, retailer, bank or finance company name;',
            "You must possess a valid driver's license or other form of valid government-issued photo ID;",
            'The personal contact information and the information about your vehicle that you provide in response to our online questionnaire must be accurate, complete, and truthful; and',
            'Within seven (7) days of the issuance of your Trade-In Appraisal, you must deliver your vehicle, with a valid registration and either clear and unencumbered title in your name, or complete documentation regarding any liens on your vehicle (including, for example, contact information for your lender, relevant account information, and the current payoff amount for your vehicle) to a Participating Dealer. Your vehicle must be in the same condition as you have described and with no more than 250 additional miles on the odometer.',
            'The Participating Dealer will inspect your vehicle to confirm its condition and verify the accuracy of the other information about your vehicle that you submitted online. Based on the results of this verification inspection (more information below) reported by the Participating Dealer, your vehicle will be disallowed if your vehicle does not meet the vehicle criteria.'
          ]
        },
        {
          title: 'Restrictions',
          content: [
            'The Program is available only at a participating dealer. Not all vehicles are eligible for the Program. The Program may not be used to sell or to trade in certain categories of vehicles at the discretion of Ford, including, without limitation: dealer inventory; pre-2009 model year vehicles; select exotic vehicles; select commercial vehicles; fleet vehicles, vehicles subject to recall or investigation by a government agency; vehicles with a police or fire history; reconstructed or salvage vehicles; vehicles without a valid Vehicle Identification Number (VIN); vehicles not originally built for the United States market or that were imported illegally or outside of official manufacturer channels (i.e., "gray market" vehicles); vehicles originally sold in a foreign country; vehicles with altered drivelines or bodies or illegal or non-functioning emissions equipment; vehicles registered at an auto auction or offered for sale through any wholesale channel within 45 days; and vehicles that we would value at less than $2,500.'
          ]
        },
        {
          title: 'Vehicle Inspections',
          content: [
            'The Program is based on a vehicle being in safe operating condition and free of existing collision damage which requires panel and/or part(s) replacement.',
            'Participating Dealers will conduct a mandatory vehicle inspection to verify the accuracy of the vehicle description and condition information that you provided to Ford online. This verification inspection may include, for example, paint thickness measurements, a road test, a mechanical inspection, and a review of vehicle history reports and disclosure statements regarding flood, salvage, or odometer discrepancies (including rollbacks), and the like. The Participating Dealer will submit the results of the verification inspection to Ford. All decisions of the Participating Dealer regarding the condition of your vehicle will be final.',
            'Meeting any one of the following criteria will exclude the vehicle from the Program:'
          ]
        },
        {
          title: 'Equity in Your Vehicle',
          content: [
            'Equity in your trade-in vehicle is estimated based on the difference between the estimated Trade-In Appraisal and any amount still owing on your trade-in vehicle. Negative equity generally means that you owe more on your trade-in vehicle than it is currently worth. Positive equity generally means that your trade-in vehicle is worth more than you currently owe.',
            'The Trade-In Appraisal of your vehicle (and therefore, the equity you may have in your trade-in vehicle) is conditional upon confirming your assessment of the vehicle via a physical inspection of the vehicle, which may affect the actual value offered as a trade-in amount. Please see your local dealer for information regarding actual trade-in availability and value.'
          ]
        }
      ]
    },
    {
      title: 'PROGRAM - LEASING',
      content: [
        'If you are leasing your existing vehicle, the Program is tailored specifically so that consumers are provided with an estimated amount that reflects a portion of your existing lease obligations ("Estimated Lease Obligation to Terminate Amount") for your vehicle. If your vehicle qualifies, you can potentially finance this amount when you purchase or lease a new Ford or Lincoln vehicle subject to the Terms.',
        'The Estimated Lease Obligation to Terminate Amount is an estimate provided by you and your lender.'
      ],
      subsections: [
        {
          items: [
            'If your lender is Ford Credit, your Estimated Lease Obligation to Terminate Amount represents: (i) your remaining monthly payments; (ii) any late charges you have incurred; and (iii) any extension fees you have incurred, all of which is reflected as of the date you generate this estimate. It does not include other potential charges, including but not limited to disposition fees, excess mileage charges, excess wear and use charges, and any associated taxes. Please see your lease agreement for details and discuss your options with your dealer or Ford Credit.',
            'If your lender is not Ford Credit, your Estimated Lease Obligation to Terminate Amount is likely to include your remaining monthly payments as of the date you generate this estimate, but may not include any other additional charges, including but not limited to late charges, extension fees, disposition fees, excess mileage charges, excess wear and use charges, and any associated taxes. Please see your lease agreement for details and discuss your options with your dealer or leasing company.'
          ]
        },

        {
          title: 'Additionally',
          content: [
            'Additionally the Program provides you an opportunity to enter your own Estimated Lease Obligation to Terminate Amount. Please note that if you enter your own Estimated Lease Obligation to Terminate Amount, you are responsible for the accuracy of this amount.'
          ]
        },
        {
          title: 'Keep in mind',
          content: [
            'that your lease agreement may provide for additional early termination options that may be less expensive than your Estimated Lease Obligation to Terminate Amount.'
          ]
        }
      ]
    },
    {
      title: 'WAIVER.',
      content: [
        'Any amounts still owing on your trade-in vehicle or reflected in your Estimated Lease Obligation to Terminate Amount are subject to change based on your existing financing and leasing obligations. For purposes of this Program, we use information provided by you and your lender/finance source to reflect the amount you still owe on your vehicle. While we believe this information is reliable, we are not responsible for and do not guarantee the accuracy or reliability of the information. Please see your lender/finance source for information regarding your actual payoff amount.'
      ]
    },
    {
      title: 'INDEMNITY AND HOLD HARMLESS.',
      content: [
        'By participating in the Program you agree that you will defend, indemnify and hold harmless Ford and its related companies and each of their respective directors, officers and employees, agents, licensors, successors or assigns (collectively, "Ford Group") from and against all liabilities, claims, damages and expenses (including reasonable attorneys’ fees and costs) arising out of any use of the Program by or through you including any of its features or services, your breach or alleged breach of these Terms, and any and all claims relating to or arising out of any vehicle sale or lease transaction that you enter into with a Participating Dealer in connection with the Program. In the event that any claims or disputes arise out of such a transaction, you agree to look solely to your Participating Dealer for your remedy and not the Ford Group. Ford reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and, in such case, you shall agree to cooperate with our defense of such claim.'
      ]
    },
    {
      title: 'MODIFICATIONS AND TERMINATION OF PROGRAM.',
      content: [
        "Ford reserves the right at any time, and from time to time to modify, update, suspend or discontinue all or part of the Program for any reason with or without notice to you. All terminations will be made in Ford's sole discretion and you agree that Ford will not be liable for any termination of your use of or access to the Program."
      ]
    },
    {
      title: 'NO ILLEGAL USE.',
      content: [
        "As a condition of your use of and access to the Program, you agree not to use the Program for any unlawful purpose or in any way that violates these Terms. You also agree not to use the Program in any way that could damage, disable, overburden, or impair Ford.com or any Ford server, or the associated networks, or interfere with any other party's use and enjoyment of any Program."
      ]
    }
  ]
}
