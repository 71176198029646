import React from 'react'

const backTarget = 'vehiclefound_EU'
const backTargetEsIt = 'vehiclebasics_es_it'

export const StepHeaders = {
  fr_fr: {
    main: 'Etat du véhicule',
    sub: "Merci de répondre aux questions suivantes sur l'état de votre véhicule."
  },
  en_gb: {
    main: 'Vehicle Condition',
    sub: 'Answer the questions below so we can get a better idea of the current condition of your vehicle.'
  },
  nl_nl: {
    main: 'Staat van de auto',
    sub: 'Beantwoord onderstaande vragen om een beter beeld te krijgen van de staat van je auto.'
  },
  es_es: {
    main: 'Estado del vehículo',
    sub: 'Responde las preguntas a continuación para que podamos hacernos una mejor idea de la condición actual de tu vehículo. Los precios ofertados por el proveedor de este servicio son válidos únicamente para Península y Baleares. Para tasaciones de su vehículo usado en Canarias, Ceuta y Melilla, por favor contacte con su Concesionario. '
  },
  it_it: {
    main: 'Condizioni del veicolo',
    sub: "Rispondi alle domande seguenti in modo che possiamo avere un'idea migliore delle condizioni attuali del tuo veicolo. "
  }
}

export const StepBody = {
  fr_fr: {
    backButton: {
      ariaLabel: "Cliquez ici pour revenir à l'étape précédente.",
      text: 'Retour',
      target: backTarget
    },
    forwardButton: {
      ariaLabel: "Cliquez ici pour passer à l'étape suivante.",
      text: 'Suivant'
    },
    zipcode: {
      label: 'Veuillez indiquer votre code postal',
      placeholder: 'Code Postal',
      ariaLabel: 'Veuillez indiquer votre code postal',
      errorMessage: 'Veuillez entrer la valeur'
    },
    radioObj: {
      errorMessage: 'Cette réponse est nécessaire pour continuer',
      tooltipAriaLabel: "Plus d'information"
    }
  },
  en_gb: {
    backButton: {
      ariaLabel: 'Click here to go back to the previous step of the appraisal',
      text: 'Back',
      target: backTarget
    },
    forwardButton: {
      ariaLabel: 'Click here to go to the next step of the appraisal',
      text: 'Next'
    },
    annualMileage: {
      label: 'Annual Mileage',
      tooltip:
        'Estimated annual mileage to help us provide an accurate future value.'
    }
  },
  nl_nl: {
    backButton: {
      ariaLabel: 'Kies "terug" als je een van je antwoorden wilt veranderen',
      text: 'Terug',
      target: backTarget
    },
    forwardButton: {
      AriaLabel:
        'Kies "volgende" om je indicatieve taxatie van je inruil te zien',
      text: 'Volgende'
    },
    deliveryMileage: {
      initialValue: '',
      label: 'Vul geschatte km-stand in op onderstaande leveringsdatu',
      ariaLabel:
        'Vul je geschatte jaarlijks te rijden aantal kilometers in, zodat we je een juiste toekomstige waarde kunnen geven',
      placeholder: `Bijvoorbeeld 12.001`,
      errorMessage: 'Voer alstublieft een waarde in'
    },
    vehicleValuationObj: {
      errorMessage: 'Dit antwoord is vereist om door te gaan',
      tooltipAriaLabel: 'Meer informatie'
    }
  },
  es_es: {
    backButton: {
      ariaLabel: 'Pincha aquí para volver',
      text: 'Volver',
      target: backTargetEsIt
    },
    forwardButton: {
      ariaLabel:
        'Pincha aquí para continuar al siguiente paso de la valoración',
      text: 'Siguiente'
    },
    zipcode: {
      label: 'Confirma tu código postal',
      placeholder: 'Código Postal',
      ariaLabel: 'Por favor, confirma tu código postal',
      errorMessage: 'Por favor, confirma tu código postal'
    },
    radioObj: {
      errorMessage: 'Esta pregunta es obligatoria',
      tooltipAriaLabel: 'Más información'
    }
  },
  it_it: {
    backButton: {
      ariaLabel: 'Clicca qui per tornare indietro',
      text: 'Indietro',
      target: backTargetEsIt
    },
    forwardButton: {
      ariaLabel: 'Clicca qui per andare al passaggio successivo',
      text: 'Successivo'
    },
    zipcode: {
      label: 'Conferma il CAP',
      placeholder: 'CAP',
      ariaLabel: 'Conferma il CAP',
      errorMessage: 'Veuillez entrer la valeur' // this copy needed for IT
    },
    radioObj: {
      errorMessage: 'Questa risposta è obbligatoria',
      tooltipAriaLabel: 'Maggiori informazioni'
    }
  }
}

export const excellentFr = () => (
  <ul>
    <li>Contrôle technique valable plus de 6 mois à date de cotation</li>
    <li>
      Entretien complet dans le réseau de la marque, à jour et avec factures
      (prochain entretien à prévoir dans plus de 10000 km ou plus de 6 mois à
      date de livraison)
    </li>
    <li>Parfait état mécanique et électronique</li>
    <li>
      Pneus conformes et en bon état, avec minimum 5 mm de profil sur chaque
      roue
    </li>
    <li>Intérieur en état neuf, propre et sans réparation à prévoir</li>
    <li>Carrosserie sans rayures ni défauts</li>
    <li>Jantes sans rayures ni défauts</li>
    <li>Pare-brise sans impact</li>
    <li>Climatisation en bon état, avec entretien récent</li>
  </ul>
)

export const goodFr = () => (
  <ul>
    <li>Contrôle technique valable plus de 6 mois à date de cotation</li>
    <li>
      Entretien complet par professionnel, à jour et avec factures (dernier
      entretien datant de moins de 10000 km ou – 12 mois)
    </li>
    <li>Bon état mécanique et électronique, aucun défaut à signaler</li>
    <li>Freins ne nécessitant pas de réparation</li>
    <li>
      Pneus conformes, en bon état, avec minimum 4 mm de profil sur chaque roue
    </li>
    <li>
      Intérieur propre, avec uniquement quelques défauts mineurs, ne nécessitant
      pas de réparation ou de remplacement d’équipement
    </li>
    <li>
      Carrosserie non-grêlée, avec quelques rayures ou bosses légères sur 1 ou 2
      éléments, mais ne nécessitant pas de peinture ou de remplacement d’élément
    </li>
    <li>
      Jantes avec rayures mineures sur maximum 2 roues, ne nécessitant pas de
      remplacement
    </li>
    <li>
      Pare-brise avec impacts mineurs de moins de 1 cm de long et hors du champ
      de vision
    </li>
    <li>Climatisation en bon état de fonctionnement</li>
  </ul>
)

export const averageFr = () => (
  <ul>
    <li>Contrôle technique valable minimum 3 mois à date de livraison</li>
    <li>
      Entretien irrégulier, dernier entretien avec facture il y a moins de 20000
      km ou – 24 mois
    </li>
    <li>
      Bon état mécanique et électronique, tous les équipements en état de
      fonctionner
    </li>
    <li>Pneus conformes, usés à moins de 50%</li>
    <li>
      Intérieur propre, avec traces d’usure, ne nécessitant pas de remplacement
      d’équipement
    </li>
    <li>
      Carrosserie non-grêlée, avec quelques rayures ou bosses légères sur
      maximum 5 éléments de carrosserie, mais ne nécessitant pas de remplacement
      d’éléments
    </li>
    <li>
      Jantes avec rayures mineures sur jusqu’à 4 roues, ne nécessitant pas de
      remplacement
    </li>
    <li>
      Pare-brise avec impacts mineurs de moins de 1cm de long et hors du champ
      de vision
    </li>
    <li>Climatisation en bon état de fonctionnement</li>
  </ul>
)

export const fairFr = () => (
  <ul>
    <li>Contrôle technique dépassé</li>
    <li>Entretien incomplet, pas de facture d’entretien récent</li>
    <li>Bon état mécanique, aucun défaut à signaler</li>
    <li>
      Electronique en état de fonctionnement, aucun défaut majeur à signaler
    </li>
    <li>Pneus usés à 50% et plus</li>
    <li>
      Intérieur usé, nécessitant quelques réparations, mais pas de remplacement
      d’équipement
    </li>
    <li>
      Carrosserie non grêlée, avec des rayures et bosses sur maximum 5 éléments,
      nécessitant des travaux de peinture, mais ne nécessitant pas de
      remplacement d’éléments
    </li>
    <li>
      Jantes avec rayures et déformations légères, nécessitant des réparations
      mais pas de remplacement
    </li>
    <li>
      Pare-brise avec impacts mineurs hors du champ de vision, ne nécessitant
      pas de remplacement
    </li>
    <li>Climatisation nécessitant un entretien</li>
  </ul>
)

export const poorFr = () => (
  <ul>
    <li>Défauts mécaniques ou électroniques à signaler</li>
    <li>
      Carrosserie endommagée ou rouillée, nécessitant des réparations
      importantes ou des remplacements d’éléments
    </li>
    <li>Véhicule grélé</li>
    <li>Véhicule réparé après un accident majeur (VEI)</li>
  </ul>
)

export const excellentUK = () => (
  <div className='radio-text-list'>
    <p>WHAT IS ‘EXCELLENT’ CONDITION?​</p>
    <p>
      The vehicle will be in excellent condition but may have up to <b>five</b>{' '}
      minor exterior or interior defects.
    </p>
    <p>Examples of minor defects include:</p>
    <ul>
      <li>Fewer than five stone chips</li>
      <li>Small scratches less than 2.5 cm and dents less than 3 cm</li>
      <li>
        Minor interior damage, or minor missing trim items e.g. tow-eye cover
      </li>
    </ul>
  </div>
)

export const goodUK = () => (
  <div className='radio-text-list'>
    <p>WHAT IS ‘GOOD’ CONDITION?​</p>
    <p>
      The vehicle may have multiple minor exterior or interior defects such as
      small scratches, dents, or stone chips, <b>PLUS</b> no more than{' '}
      <b>one</b> body shop repair.
    </p>
    <p>Examples of body shop repairs include:​</p>
    <ul>
      <li>Scratches over 2.5 cm</li>
      <li>Dents over 3 cm, more than five stone chips</li>
      <li>
        Poor paintwork issues, severe interior damage, or any sign of rust
      </li>
    </ul>
  </div>
)

export const averageUK = () => (
  <div className='radio-text-list'>
    <p>WHAT IS ‘AVERAGE’ CONDITION?​</p>
    <p>
      The vehicle may have numerous minor exterior or interior defects such as
      small scratches or stone chips, <b>PLUS</b> up to <b>four</b> body shop
      repairs.
    </p>
    <p>Examples of body shop repairs include:</p>
    <ul>
      <li>Scratches over 2.5 cm</li>
      <li>Dents over 3 cm</li>
      <li>More than five stone chips</li>
      <li>
        Poor paintwork issues, severe interior damage, or any sign of rust.
      </li>
    </ul>
  </div>
)

export const fairUK = () => (
  <div className='radio-text-list'>
    <p>WHAT IS ‘FAIR’ CONDITION?</p>
    <p>
      The vehicle may have numerous minor exterior or interior defects such as
      small scratches, dents, or stone chips.
    </p>
    <p>
      It also may have multiple (over 4) body shop repairs such as scratches
      over 2.5cm, dents over 3cm, more than five stone chips, poor paintwork
      issues, severe interior damage, or any sign of rust, and a maximum of one
      replacement bumper. ​
    </p>
    <p>
      The vehicle could also require one replacement panel<sup>*</sup>, but only
      if there are fewer than three other body shop repairs required.
    </p>
    <p>
      <sup>*</sup> Note: structural panels such as the roof, rear-quarter
      panels, floor, inner wings, or cross members are not included. If a
      replacement to one of these is required due to severe rusting or damage,
      then the vehicle is automatically classified as ‘Poor’.
    </p>
  </div>
)

export const poorUK = () => (
  <div className='radio-text-list'>
    <p>WHAT IS ‘POOR’ CONDITION?​</p>
    <p>
      The vehicle will have severe damage or rusting to the exterior and
      interior that could include numerous minor defects, numerous body shop
      repairs, missing parts and panel replacements.
    </p>
  </div>
)

export const excelllentES = () => (
  <ul>
    <li>
      Certificado ITV válido para más de 6 meses despues de la fecha de
      valoración.
    </li>
    <li>
      Mantenimiento completo realizado en la red oficial, actualizado y con
      facturas (próximo mantenimiento programado dentro de más de 10.000kms o
      más de 6 meses desde la fecha de entrega).
    </li>
    <li>Mecánica y electrónica en perfecto estado.</li>
    <li>
      Neumáticos homologados y en buen estado, con una profundidad del dibujo
      del neumático de al menos 5 mm en cada rueda.
    </li>
    <li>Interior como nuevo, limpio. No necesita reparaciones</li>
    <li>Carrocería sin ningún arañado ni defectos.</li>
    <li>Llantas sin arañazos ni defectos.</li>
    <li>Parabrisas sin ningún impacto.</li>
    <li>Aire acondicionado en buen estado, con revisión reciente.</li>
  </ul>
)

export const goodES = () => (
  <ul>
    <li>
      Certificado ITV válido para más de 6 meses a partir de la fecha de
      valoración.
    </li>
    <li>
      Mantenimiento completo realizado por un taller profesional de automoción,
      al día y con facturas (último mantenimiento fechado en los últimos 10.000
      km o en los últimos 12 meses).
    </li>
    <li>Buen estado mecánico y eléctrico, sin defectos.</li>
    <li>Los frenos no necesitan reparación</li>
    <li>
      Neumáticos homologados y en buen estado, con una profundidad del dibujo
      del neumático de al menos 4 mm en cada rueda.
    </li>
    <li>
      Interior limpio, con solo defectos menores. No se requiere reparación ni
      reemplazo.
    </li>
    <li>
      Carrocería del coche con arañazos leves o abolladuras leves en 1 o 2
      paneles. No se requiere pintura ni reemplazo.
    </li>
    <li>
      Llantas con pequeños arañazo en no más de 2 ruedas. No se requiere
      reemplazo.
    </li>
    <li>
      Parabrisas con impacto menor de menos de 1 cm de ancho y fuera del campo
      de visión principal.
    </li>
    <li>Aire acondicionado en condiciones de funcionamiento.</li>
  </ul>
)

export const fairES = () => (
  <ul>
    <li>
      Certificado ITV válido al menos para 3 meses a partir de la fecha de
      entrega.
    </li>
    <li>
      Mantenimiento incompleto, con último mantenimiento programado facturado
      hace menos de 20.000 km o menos de 24 meses.
    </li>
    <li>
      Buen estado mecánico y eléctrico, todo el equipo en condiciones de
      funcionamiento.
    </li>
    <li>Neumáticos homologados, desgastados menos del 50%.</li>
    <li>Interior limpio, con marcas de desgaste. No se requiere reemplazo.</li>
    <li>
      Carrocería con leves arañazos o abolladuras en un máximo de 5 paneles. No
      se requiere reemplazo.
    </li>
    <li>
      Llantas con pequeños arañazos en no más de 2 ruedas. No se requiere
      reemplazo.
    </li>
    <li>
      Parabrisas con impacto menor de menos de 1 cm de ancho y fuera del campo
      de visión principal.
    </li>
    <li>Aire acondicionado en condiciones de funcionamiento.</li>
  </ul>
)

export const badES = () => (
  <ul>
    <li>Algunos defectos mecánicos o eléctricos.</li>
    <li>
      Carrocería dañada u oxidada. Requiere reparaciones importantes o la
      sustitución de algunos paneles.
    </li>
    <li>Vehículo averiado.</li>
    <li>El vehículo ha sido reparado después de un accidente importante.</li>
  </ul>
)

export const poorES = () => (
  <ul>
    <li>Con certificado ITV caducado</li>
    <li>
      Mantenimiento incompleto, sin factura de mantenimiento reciente
      disponible.
    </li>
    <li>Buen estado mecánico, sin defectos.</li>
    <li>
      Electrónica en funcionamiento, no hay fallos importantes que informar.
    </li>
    <li>Neumáticos gastados a más del 50%.</li>
    <li>
      Interior usado con reparaciones necesarias. No se requiere reemplazo.
    </li>
    <li>
      Carrocería del automóvil con arañazos o abolladuras leves en más de 5
      paneles o que requieran ser repintados. No se requiere reemplazo.
    </li>
    <li>
      Llantas con arañazos o marcas leves, con reparaciones necesarias. No se
      requiere reemplazo.
    </li>
    <li>
      Parabrisas con impacto menor fuera del campo de visión principal. No se
      requiere reemplazo.
    </li>
    <li>Aire acondicionado que requiere mantenimiento.</li>
  </ul>
)

export const excelllentIT = () => (
  <ul>
    <li>Revisione superata e valida per ulteriori 6 mesi.</li>
    <li>
      Manutenzione periodica fatta secondo le scadenze presso la rete ufficiale
      e documentazione disponibile. Il prossimo intervento di manutenzione è
      programmato tra oltre 10.000 km o dopo 6 mesi.
    </li>
    <li>Meccanica e elettronica in perfette condizioni.</li>
    <li>
      Pneumatici in buone condizioni con battistrada di almeno 5 mm su ogni
      pneumatico.
    </li>
    <li>
      Rivestimento degli interni in ottime condizioni. Nessuna riparazione
      necessaria.
    </li>
    <li>Carrozzeria in ottime condizioni, senza graffi o ammaccature. </li>
    <li>Cerchi in lega senza graffi.</li>
    <li>Parabrezza integro.</li>
    <li>Aria condizionata in buone condizioni e con manutenzione regolare. </li>
  </ul>
)

export const goodIT = () => (
  <ul>
    <li>Revisione superata e valida per ulteriori 6 mesi.</li>
    <li>
      Manutenzione periodica fatta secondo le scadenze presso la rete ufficiale
      e documentazione disponibile. Il prossimo intervento di manutenzione è
      programmato tra oltre 10.000 km o dopo 6 mesi.
    </li>
    <li>Meccanica e elettronica in perfette condizioni.</li>
    <li>
      Impianto frenante in buone condizione e senza nessuna necessità di
      intervento.{' '}
    </li>
    <li>
      Pneumatici in buone condizioni con battistrada di almeno 4 mm su ogni
      pneumatico.
    </li>
    <li>
      Rivestimento degli interni pulito e con minimi segni di usura. Nessuan
      riparazione necessaria
    </li>
    <li>
      Carrozzeria con piccoli graffi e 1 o 2 ammaccature. Nessuna verniciatura
      richiesta.
    </li>
    <li>
      Cerchi in lega con piccoli graffi su 1 o 2 ruote. Nessuna sostituzione
      necessaria.
    </li>
    <li>
      Parabrezza con piccoli danni, inferiori ad 1 centimetro e al di fuori del
      campo visivo.
    </li>
    <li>Aria condizionata funzionante.</li>
  </ul>
)

export const averageIT = () => (
  <ul>
    <li>Revisione superata e valida per ulteriori 3 mesi.</li>
    <li>
      Manutenzione non regolare. Ultima manutenzione prevista tra meno di
      20.000.
    </li>
    <li>Meccanica e elettronica in buone condizioni.</li>
    <li>Pneumatici usurati meno del 50%.</li>
    <li>
      Rivestimento degli interni pulito, ma con segni di usura. Nessuna
      sostituzione necessaria.
    </li>
    <li>
      Carrozzeria con piccoli graffi ed ammaccature, fino ad un massimo di 5.
      Nessuna sostituzione necessaria.{' '}
    </li>
    <li>
      Cerchi in lega con piccoli graffi su 1 o 2 ruote. Nessuna sostituzione
      necessaria.
    </li>
    <li>
      Parabrezza con piccoli danni, inferiori ad 1 centimetro e al di fuori del
      campo visivo.
    </li>
    <li>Aria condizionata funzionante.</li>
  </ul>
)

export const fairIT = () => (
  <ul>
    <li>Revisione scaduta.</li>
    <li>Manutenzione non regolare. Nessuna manutenzione recente.</li>
    <li>Meccanica in buone condizioni, senza difetti.</li>
    <li>Elettronica in buone condizioni, senza difetti.</li>
    <li>Pneumatici usurati oltre il 50%.</li>
    <li>
      Rivestimento degli interni molto usurato che necessita di essere
      ripristinato.
    </li>
    <li>
      Carrozzeria con graffi ed ammaccature con necessità di essere
      riverniciata. Nessuna sostituzione necessaria.{' '}
    </li>
    <li>
      Cerchi in lega con graffi che necessitano di essere ripristinati. Nessuna
      sostituzione necessaria.
    </li>
    <li>
      Parabrezza con piccoli danni al di fuori del campo visivo. Nessuna
      sostituzione necessaria.
    </li>
    <li>Aria condizionata che richiedere interventi per il ripristino.</li>
  </ul>
)

export const poorIT = () => (
  <ul>
    <li>Alcuni problemi di meccanica o elettronica.</li>
    <li>
      Carrozzeria danneggiata o arrugginita. Necessita di importanti riparazioni
      o delle sostituzione di alcune parti della carrozzeria.
    </li>
    <li>Veicolo danneggiato.</li>
    <li>Il veicolo deve essere ripristinato dopo un grave danneggiamento.</li>
  </ul>
)
