import React from 'react'

export const overallCondition = () => (
  <ul>
    <li>
      <b>Excellent: </b>Your vehicle's appearance is showroom quality. No
      exterior dings, dents, or scratches. Your interior is pristine and shows
      no signs of wear. This applies to extremely low-mileage vehicles. A very
      small fraction of all used vehicles fall into this category.
    </li>
    <li>
      <b>Good: </b>Your vehicle has been well-maintained with no exterior dents,
      dings, or scratches noticeable outside of arm's reach. The vehicle runs
      well with zero mechanical issues. The interior shows minimal signs of wear
      with no odors or stains present.
    </li>
    <li>
      <b>Average: </b>Your vehicle's exterior shows normal signs of wear,
      including small scratches, dings, and paint chips. There may be small
      dents, less than the size of your driver's license. The interior shows
      signs of wear, such as minor discoloration or worn leather. Your vehicle
      runs without issue, there are no illuminated dashboard warning lights, and
      all electrical components are functioning properly. A majority of vehicles
      fall into this category.{' '}
    </li>
    <li>
      <b>Fair: </b>Your vehicle has seen better days. The exterior shows signs
      of wear, including multiple dents and/or blemishes in the paint. There may
      also be damaged or missing exterior trim. The interior is worn and shows
      prevalent fading and discoloration. There may be issues with the vehicle's
      heating / air conditioning unit. The engine runs and your vehicle drives
      with no issues, but there may be one dashboard warning light illuminated.
      A tune-up, new tires, and/or suspension & brake work may be required.
    </li>
    <li>
      <b>Poor: </b>Admittedly, your vehicle is not in good shape. The engine
      runs and the vehicle is drivable, but there are mechanical issues and/or
      multiple dashboard warning lights present that require attention. Exterior
      damage is noticeable from over 10 feet away and portions of your vehicle's
      trim are damaged or missing. Damage exists on large body panels and/or
      your vehicle's frame. The interior may have stains or odors present that
      require detailing to remove. Issues with the vehicle's heating / air
      conditioning unit or issues with electrical components may be present.
    </li>
  </ul>
)

export const accidents = () => (
  <div>
    Your vehicle has previously been in an accident or has other issues that may
    appear on a Carfax vehicle history report.
  </div>
)

export const maintenance = () => (
  <div>
    All documentation is available for routine maintenance (oil changes,
    transmission fluid, brakes, engine tune-ups, etc.) and any other vehicle
    repairs.
  </div>
)

export const defects = () => (
  <div>
    There are issues with your engine or transmission that require immediate
    attention. Examples include engine knock, leaking oil, transmission slipping
    gears, or inability to use 4x4 mode.
  </div>
)

export const lights = () => (
  <div>
    This may include Check Engine light, Oil Pressure, Tire Pressure Monitoring
    System, Engine Coolant, Airbags, Anti-Lock Brake System, Maintenance
    Required etc.
  </div>
)

export const minor = () => (
  <div>
    Minor damage severity limited to minor or very minor damage reported. These
    definitions typically apply to repairs that are reported as cosmetic and do
    not impact the safe operation of the vehicle.
  </div>
)

export const major = () => (
  <React.Fragment>
    <div>
      Your vehicle has incurred major damage that may be visible on a Carfax
      vehicle history report. Major damage can include being sold through a
      salvage auction, being sold as scrap, having major components removed,
      being housed in an automotive recycler, Severe Damage, Airbag Deployment,
      Total Loss, Structural Damage and Repair, and Branded Damage (i.e. total
      loss, salvage). In many cases vehicles will not be allowed back on the
      road.
    </div>
    <div>
      <strong>Note: </strong>Title branding varies by state resulting in some
      damage categories appearing in both the Damage Brands & Non-Branded Damage
      fields.
    </div>
  </React.Fragment>
)

export const commercial = () => (
  <div>
    Your vehicle has been previously used for commercial purposes, either by you
    or a previous owner. This includes company fleet vehicles, municipal
    vehicles (incl. police), taxi services, or ride sharing services such as
    Uber & Lyft.
  </div>
)

export const HIDHeadlamps = () => (
  <div>
    High Intensity Discharge headlamps. Also known as xenon headlights. Brighter
    light than halogen headlamps
  </div>
)

export const inProgress = () => `<p>Tooltip</p>`

export const legalSuperscript = () => (
  <div>
    This estimated amount was provided by your lender (or you, if you entered
    your own amount) and is intended to reflect the sum of your remaining
    monthly payments as of the date you generated this amount. Depending on your
    lender, this may or may not include other lease fees/charges that you have
    incurred or may incur. Please see your lease for details and discuss your
    options with your dealer or leasing company.
  </div>
)

export const legalSuperscriptString = () => (
  `
    This estimated amount was provided by your lender (or you, if you entered
    your own amount) and is intended to reflect the sum of your remaining
    monthly payments as of the date you generated this amount. Depending on your
    lender, this may or may not include other lease fees/charges that you have
    incurred or may incur. Please see your lease for details and discuss your
    options with your dealer or leasing company.
  `
)


export const netValue = () => (
  <div>
    Difference between the Trade-In Appraisal and any amount still owed on the
    trade-in vehicle. Can be a positive or negative value. Please see Trade-In
    Terms and Conditions for more details.
  </div>
)

