// standalone hostnames
export const devComponentHostnames = new Set([
  'www.tradein-dev.ford.com',
  'tradein-dev.ford.com',
  'tradein-dev-lincoln.ford.com',
  'www.tradein-dev-lincoln.ford.com',
  'tradein.ford.co.uk' // for testing
])

export const qaComponentHostnames = new Set([
  'www.tradein-qa.ford.com',
  'tradein-qa.ford.com',
  'tradein-qa-lincoln.ford.com',
  'www.tradein-qa-lincoln.ford.com'
])

export const prodComponentHostnames = new Set([
  'tradein.ford.com',
  'tradein.ford.co.uk',
  'tradein.ford.nl',
  'tradein.ford.fr',
  'tradein.ford.es',
  'tradein.ford.it',
  'tradein.ford.de',
  'tradein.ford.ch',
  'tradein.ford.no',
  'tradein.ford.be',
  'tradein.ford.fi',
  'tradein.ford.at',
  'tradein.ford.dk',
  'tradein.ford.pt',
  'tradein.ford.pl',
  'tradein.ford.cz',
  'tradein.ford.ie',
  'tradein.ford.hu',
  'tradein.ford.gr',
  'tradein.ford.ro',
  'tradein.lincoln.com'
])

const brand = window.location.hostname.includes('lincoln') ? 'lincoln' : 'ford'
let env = ''
getEnv()
function getEnv() {
  const hostName = window.location.hostname
  if (hostName.includes('dev') || hostName.includes('localhost')) {
    env = 'dev'
  } else if (hostName.includes('qa')) {
    env = 'qa'
  } else {
    env = 'prod'
  }
}

const fordTc =
  env !== 'prod'
    ? `https://www.tradein-${env}.ford.com`
    : `https://www.tradein.ford.com`

const fusionTnc =
  env !== 'prod'
    ? `https://www.tradein-${env}.ford.com/termsandconditions/EN/fusion_recall.html`
    : `https://tradein.ford.com/termsandconditions/EN/fusion_recall.html`
const lincolnTc =
  env !== 'prod'
    ? `https://www.tradein-${env}-lincoln.ford.com`
    : `https://www.tradein.lincoln.com`

// TIQS URLs
export const devTIQSUrl = 'https://tradein-dev.ford.com'
export const qaTIQSUrl = 'https://tradein-qa.ford.com'
export const prodTIQSUrl = 'https://www.tradein.ford.com'

export const devStaticUrl = 'https://tradein-dev.ford.com/assets'
export const qaStaticUrl = 'https://tradein-dev.ford.com/assets'

// Syndicate URLs
export const prodSyndicateFordUrl = 'https://www.ford.com'
export const prodSyndicateLincolnUrl = 'http://www.lincoln.com'
export const qaSyndicateFordUrl = 'https://wwwqa.ford.com'
export const qaSyndicateLincolnUrl = 'https://wwwqa.lincoln.com'

export const getBaseUrl = () => {
  const currentHostname = window.location.hostname
  let staticUrl

  if (
    currentHostname.includes('dev') ||
    currentHostname.includes('localhost')
  ) {
    staticUrl = devStaticUrl
  } else {
    staticUrl = qaStaticUrl
  }
  const tncUrl = brand === 'lincoln' ? lincolnTc : fordTc

  return {
    staticUrl,
    tncUrl
  }
}

export const getTradeInUrl = () => {
  return brand === 'lincoln'
    ? env !== 'prod'
      ? `tradein-${env}.lincoln.com`
      : `tradein.lincoln.com`
    : env !== 'prod'
    ? `tradein-${env}.ford.com`
    : `tradein.ford.com`
}

export const vinModalImg = () => {
  return getBaseUrl().staticUrl + '/vinModalImg.png'
}

export const baseTCUrl = () => {
  return getBaseUrl().tncUrl + '/termsandconditions/'
}

export const getTCUrl = (market, language) => {
  if (market === 'GB') {
    return 'https://www.ford.co.uk/useful-information/terms-and-privacy/online-order'
  } else if (market === 'NL') {
    return getBaseUrl().tncUrl + '/' + 'termsandconditions_NL'
  } else {
    return baseTCUrl() + market + '/' + language + '.html'
  }
}
export const getFusionRecallTCUrl = () => {
  return fusionTnc
}

export const fontUrl = () => {
  return getBaseUrl().staticUrl
}

export const getFTCUrl = () => {
  return getBaseUrl().tncUrl + '/termsandconditions/EN/fusion_recall.html'
}
