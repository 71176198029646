import { createGlobalStyle } from 'styled-components'
import { mediaQueries } from './mediaQueries'
import variables from './variables'

const GlobalStyle = createGlobalStyle`
&& {
    ${variables}

    #foot .disclosures{
        display: none;
    }

    .tradein-app-wrapper-container{
        color: var(--primary);
        font-family: var(--fontPrimary);
        line-height: 24px;
        font-weight: normal;
          
        *{
            box-sizing: border-box;
        }
        button{
            outline: 0;
            border: 0;
        }

        h1, h2, h3, h4{
            font-weight: normal;
            color: var(--primary);
        }
        h2{
            font-size: var( --fz-xxl);
            line-height: 1.29;
            text-transform: uppercase;
        }
        h3{
            font-size: var( --fz-md);
            line-height: 1.93;
        }
        h4{
            font-size: var( --fz-xs);
            line-height: 1.93;
        }
        main {
            padding-bottom: 20px;
        }
        a {
            color: var(--secondary);
            text-decoration: underline;
            font-size: var(--fz-xs);
        }
        a, button{
            cursor: pointer;
        }
    
        .tradein-buttons-container {
            display: flex;
            justify-content: space-evenly;
            margin: 60px auto 0;
            max-width: 430px;
            width: 100%;
            align-items: center;
            @media ${mediaQueries.underM} {
                flex-direction: column-reverse;
                align-items: center;
                margin-top: 40px;
                button {
                    margin: 10px 0;
                }
            }
            .link-button-container {
                margin: 15px 0 0 8px;
            }
        }
        .tradein-error-message {
            font-size: var( --fz-xs);
            line-height: var(--fz-md);
            color: var(--red);
            margin-top: 4px;
            text-align: left;
            vertical-align: middle;
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
                path {
                    fill: var(--red);
                    }
                }
            }

        .tradein-buttons-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 50px;
            button{
                margin: 10px 0;
            }
        }
        /* typography */

        .tradein-main-header {
            font-size: var(--fz-xxl);
            font-family: var(--fontSecondary);
            font-weight: 200;
            line-height: 1.29;
            text-transform: uppercase;
            margin-top: 0;
            @media ${mediaQueries.underM} {
                font-size: var( --fz-xl);
            }
        }

        .tradein-subheader {
            color: var(--secondary);
            letter-spacing: 1px;
            font-size: var( --fz-md);
            font-family: var(--fontSecondary);
            font-weight: 400;
            margin-bottom: 1rem;
        }

        .tradein-subtext {
            font-family: var(--fontPrimary);
            font-size: var( --fz-xxs);
            max-width: 720px;
            margin: 0 auto;
            line-height: 1.93;
            letter-spacing: 1px;
            text-align: center;
            text-transform: none;
            p{
                color: var(--primary);
                font-size: var( --fz-xxs);
            }
        }
        .tradein-loadingWrapper {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 999;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .tradein-currency-title {
            color: var(--secondary);
            font-family: var(--fontSecondary);
            font-weight: bold;
            line-height: 1.2;
            &.tradein-xl {
                font-size: var( --fz-heading);
                letter-spacing: 3px;
                margin-bottom: 10px;
                @media ${mediaQueries.underM} {
                    font-size: var( --fz-xxl);
                }
            }
        }
        .tradein-note {
            color: var(--primary);
            font-family: var(--fontPrimary);
            font-style: italic;
            font-size: var( --fz-xxs);
            line-height: 2;
            letter-spacing: 1px;
            margin-top: 4px;
        }
        &.ford{
       
            .tradein-main-header, .tradein-subtext{
                text-transform: none;
                font-weight: 400;
                color: var(--primary);
                text-align: left;
                font-family: var(--fontPrimary);

            }

            input[type='radio'] {
                outline: 0;
                margin: 0;
                -webkit-appearance: none;
                width: 24px;
                height: 24px;
                border: 1px solid var(--lightModerate);
                border-radius: 50%;
                outline: none;
                margin-right: 16px;

                &:focus-visible + span::before {
                    pointer-events: none;
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    content: '';
                    left: -4px;
                    top: -4px;
                }
                &.error {
                    border: 1px solid var(--red);
                }
            }
                input[type='radio']:before {
                    content: '';
                    display: block;
                    width: 18px;
                    height: 18px;
                    border-radius: 16px;
                }
                input[type='radio']:checked:before {
                    background: #0068d0;
                    margin:2px;
                }
                input[type='radio']:checked {
                    border: 1px solid #066FEF;
                }
                input[type='radio']:focus {
                    border: 2px solid #066FEF;
                }
                input[type='radio']:focus:before {
                    background: #0068d0; 
                    margin: 1px;  
                }
            }
            .tradein-main-header {
                font-size: var(--fz-xxl2);
                line-height: 44px;
                letter-spacing: 0;
                margin-bottom: 10px;
                @media ${mediaQueries.underM} {
                    font-size: var(--fx-mxl2);
                }
                @media ${mediaQueries.underS} {
                    font-size: var(--fx-mxl2);
                    line-height: 38px;
                }
            }
            .tradein-subtext {
                font-size: var(--fz-lg2);
                line-height: 24px;
                text-transform: none;
                letter-spacing: 0;
                @media ${mediaQueries.underS} {
                    font-size: var(--fz-md);
                    line-height: 24px;
                }
                p{
                    color: var(--primary);
                    font-size: var( --fz-xxs);
                }
            }
            .tradein-button-ford-wrapper {
                margin-top: 32px;
                max-width: 100%;
                justify-content: space-between;
                flex-direction: row;
                button {
                  box-shadow: none;
                  .button-text {
                    font-size: var(--fz-sm);
                  }
                }
              }

        }
        &.lincoln {
            /* typography */
            font-family: var(--fontPrimary);
            .tradein-main-header {
              font-size: var( --fz-xl);
              font-weight: normal;
              line-height: 1.33;
              letter-spacing: 2.5px;
              text-align: center;
            }
            .tradein-subtext {
              font-size: var(--fz-lg);
              line-height: 1.65;
              letter-spacing: normal;
              text-align: center;
            }
            input {
              font-size: var(--fz-lg);
            }
            & .react-select__control {
              border-radius: 0px !important;
            }
        }
    }
}
`
export default GlobalStyle
