export const StepHeaders = {
  /*  es_es: {
    main: 'Recibe una oferta por tu vehículo actual',
    sub: 'Descubre el valor de tu vehículo actual, y utilizalo como parte del pago de tu nuevo vehículo. No aplicable en renting de largo plazo.'
  },
  en_gb: {
    main: 'Get an instant offer on your vehicle',
    sub: 'Part Exchange your current car and recieve money off your new Ford vehicle'
  },
  fr_fr: {
    main: 'Estimez votre véhicule',
    sub: "Profitez d'une offre de remise sur votre nouvelle Ford"
  },
  it_it: {
    main: "Ricevi un'offerta per il tuo veicolo",
    sub: "Richiedi la valutazione del veicolo e utilizzalo per l'acquisto della tua nuova Ford. Il servizio non è applicabile per vetture a noleggio a lungo termine."
  },
  */
  nl_nl: {
    main: 'Ontvang een bod op jouw huidige voertuig.',
    sub: 'Wissel je huidige auto in en ontvang geld voor je nieuwe Ford.'
  },
  de_de: {
    main: 'Get an instant offer on your vehicle',
    sub: 'Part Exchange your current car and recieve money off your new Ford vehicle'
  }
}

export const StepBody = {
  /*
  es_es: {
    regPlateText: 'Matrícula',
    regPlateError: 'Por favor introduce la matrícula de tu vehículo',
    mileageText: 'Kilómetros actuales',
    mileageError:
      'Por favor introduce los kilómetros que tiene actualmente tu vehículo',
    submitText: 'Continuar',
    submitAriaLabel: 'Siguiente paso de la valoración del vehículo usado'
  },
  en_gb: {
    regPlateText: 'Registration number',
    regPlateError: 'Please enter the license plate',
    mileageText: 'Current Mileage',
    mileageError: 'Please enter the mileage',
    submitText: 'Continue',
    submitAriaLabel: 'Next step of the used vehicle valuation'
  },
  it_it: {
    regPlateText: 'Targa',
    regPlateError: 'Inserisci la targa',
    mileageText: 'Chilometraggio',
    mileageError: 'Inserisci il chilometraggio',
    submitText: 'Continua',
    submitAriaLabel: 'Valutazione permuta passaggio successivo'
  },

  fr_fr: {
    regPlateText: "Numéro d'immatriculation",
    regPlateError: 'Veuillez entrer la valeur',
    mileageText: 'Kilométrage',
    mileageError: 'Veuillez entrer la valeur',
    submitText: 'Suivant',
    submitAriaLabel: 'Suivant'
  },
  */
  nl_nl: {
    regPlateText: 'Kenteken',
    regPlateError: 'Voer alstublieft een waarde in',
    mileageText: 'Huidige kilometerstand',
    mileageError: 'Voer alstublieft een waarde in',
    submitText: 'Doorgaan',
    submitAriaLabel: 'Doorgaan'
  },
  de_de: {
    regPlateText: 'Targa',
    regPlateError: 'Inserisci la targa',
    mileageText: 'Chilometraggio',
    mileageError: 'Inserisci il chilometraggio',
    submitText: 'Continua',
    submitAriaLabel: 'Valutazione permuta passaggio successivo'
  }
}
