import { css } from 'styled-components'

const variables = css`
  :root {
    --fordBlue: #00095b;
    --fordSlate: #4d4d4d;
    --black: #000;
    --moderateBlack: #333;
    --lightModerate: #808080;
    --lightBlack: #666;
    --borderLightGrey: #dfdfdf;
    --grabber: #1700f4;
    --grabberHover: #e8ecef;
    --fordAccentBlue: #0068d0;
    --lincolnBlue: #324047;
    --lincolnGrey: #919191;
    --fordBlack: #000000;
    --fordLightDefault: #333;
    --fordNeutralBlack: #00142e;
    --fordInputDefault: #808080;
    --fordLightGrey: #333333;
    --fordMedLightGrey: #e5e5e5;
    --fordLightDefault: #1f3047;
    --fordOnLightGrey: #6b7786;

    --fz-xxxs: 8px;
    --fz-xxs: 11px;
    --fz-xs: 12px;
    --fz-xs2: 13px;
    --fz-sm: 14px;
    --fz-sm2: 15px;
    --fz-md: 16px;
    --fz-md2: 17px;
    --fz-lg: 18px;
    --fz-lg2: 20px;
    --fz-xl: 24px;
    --fx-mxl: 30px;
    --fx-mxl2: 32px;
    --fz-xxl: 34px;
    --fz-xxl2: 38px;
    --fz-xxxl: 40px;
    --fz-xxxl2: 46px;
    --fz-heading: 60px;
    --fz-h1: 70px;

    --fordHeadingSize: 38px;

    .ford {
      --primary: var(--moderateBlack);
      --secondary: var(--black);
      --borderGrey: #6e6e6e;
      --borderDarkGrey: #b2b2b2;
      --borderLightGrey: #dfdfdf;
      --textGrey: #75787b;

      --mediumBlue: #003478;
      --lightBlue: #1a3b7d;
      --linkBlue: #2861a4;
      --focusBlue: #0000ff;
      --defaultHighlightBlueGoogle: #025fcc;
      --highlightblue: #0562d2;
      --inputHighlightblue: #066fef;
      --buttonBlue: #066fef;
      --focusBlue: #194bb5;
      --fordModGrey: #808080;

      --red: #bf152c;
      --white: #ffffff;

      --bgLightGrey: #f7f7f7;
      --bgGrey: #f2f2f2;
      --bgNewGrey: #f4f4f4;
      --bgDarkGrey: #eeeeee;
      --bgGreen: #008000;

      --borderRadius: 3px;
      --borderInput: 1px solid var(--borderGrey);
      --borderLight: 1px solid var(--borderLightGrey);

      --buttonPadding: 0 3.2rem;
      --buttonWidth: 133px;
      --buttonRadius: 30px;
      --buttonHeight: 40px;

      --fontPrimary: 'FordF1';
      --fontSecondary: 'FordF1';

      --highlight: var(--lightBlue);
      --secondary: var(--fordLightGrey);
      --fx-mxl: 32px;
      --buttonPadding: 8px 20px;
    }

    .lincoln {
      --primary: var(--lincolnBlue);
      --secondary: var(--lincolnBlue);
      --lincolnOrange: #f26147;
      --borderGrey: #6e6e6e;
      --borderLightGrey: #e0e0e0;
      --inputGrey: var(--lincolnGrey);
      --textGrey: #75787b;
      --fds-color--gray2: #efefef !important;
      --fz-xxl: 34px;

      --mediumBlue: #324047;
      --lightBlue: #1a3b7d;
      --linkBlue: #2861a4;
      --focusBlue: #0000ff;
      --defaultHighlightBlueGoogle: #025fcc;

      --red: #b00020;
      --white: #ffffff;

      --bgLightGrey: #f7f7f7;
      --bgGrey: #f2f2f2;
      --bgDarkGrey: eeeeee;
      --bgGreen: #008000;
      --bgNewGrey: #f6f7f7;

      --borderRadius: 0;
      --borderInput: 1px solid var(--borderGrey);
      --borderLight: 1px solid var(--borderLightGrey);

      --buttonPadding: 15px 20px;
      --buttonWidth: 200px;
      --buttonRadius: 0;
      --buttonHeight: 100%;

      --fontPrimary: 'LincolnProximaNova';
      --fontSecondary: 'LincolnProximaNova';
      --highlight: var(--secondary);
      --defaultHover: var(--lincolnOrange);
    }
  }
`
export default variables
