import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import StepTracker from '../pageElements/StepTracker'

const StepperComponent = ({ currentStep }) => {
  return <StepTracker currentStep={currentStep} />
}

const Stepper = () => {
  const { appState } = useContext(AppContext)
  switch (appState.currentStep) {
    case 'i':
      return <React.Fragment />
    case 'ii':
      return <React.Fragment />
    case 'vehiclebasics':
      return <StepperComponent currentStep={1} />
    case 'vehiclecondition':
      return <StepperComponent currentStep={2} />
    case 'financing':
      return <StepperComponent currentStep={3} />
    case 'confirmbalance':
      return <StepperComponent currentStep={4} />
    case 'tradeinappraisal':
      return <StepperComponent currentStep={5} />
    default:
      return <React.Fragment />
  }
}

export default Stepper
