import React, { useContext, useState, useEffect } from 'react'
import { navigate } from 'hookrouter'

import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import * as regex from '../../../utils/regex'
import focusError from '../../../utils/focusError'
import TextInput from '../../common/pageElements/TextInput'
import SelectInput from '../../common/pageElements/SelectInput'
import TabGroup from '../../common/pageElements/TabGroup'
import ScrollToTop from '../../common/pageElements/ScrollToTop'

import { useInput } from '../../common/customhooks/forms'
import TradeinLink from '../../common/pageElements/TradeinLink'
import TradeinButton from '../../common/pageElements/TradeinButton'
import { stateList } from '../../../constants/forms.js'
import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders } from './Copy'
import PopoverModal from '../../common/pageElements/PopoverModal'
import api from '../../../utils/api'
import { vinModalImg } from '../../../constants/staticUrls'

import Checkbox from '../../common/pageElements/CheckBox'
import { StyledLandingPage } from '../../styled-components/screens/StyledLandingPage'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import { pageLoadTags } from '../../../utils/analytics/index'
import vinModalLincolnImg from '../../../assets/img/VinModalLincolnImg.png'
import * as filters from '../../../utils/filters'

const StyledCheckbox = styled(Checkbox)`
  && {
    display: block;
    .tradein-checkbox-wrapper {
      width: 100%;
      text-align: left;
      label {
        font-size: 14px;
      }
    }
  }
`

const VinPopoverModal = styled(PopoverModal)`
  .tradein-popover-modal {
    .tradein-popover-modal-inner {
      max-width: 1030px;
      z-index: 4;
      top: 15%;
      overflow: scroll;
      height: 75vh;

      @media ${mediaQueries.underL} {
        width: 80%;
        top: 9%;
        height: 85vh;
      }

      @media ${mediaQueries.underM} {
        height: 100%;
      }

      .tradein-popover-modal-body {
        margin: 10px 0;
        padding-bottom: 0;
      }
      .tradein-popover-modal-header {
        border-bottom: var(--borderLight);
      }
    }
  }
`

const StyledVinLink = styled(TradeinLink)`
  && {
    margin-top: -10px;
    &.lincoln {
      span {
        font-size: 13px;
      }
      margin-top: 5px;
      text-decoration: underline;
    }
  }
`

const LandingPage = () => {
  const { appState, appDispatch, resetApp, referralId, brand, application } =
    useContext(AppContext)
  const { dataDispatch, dataState } = useContext(DataContext)
  const { vin, licensePlate, stateName, state, appraisalId, mileage } =
    dataState

  const [activeTab, setActiveTab] = useState(vin ? 'VIN' : 'License Plate')

  const [licenseValid, setLicenseValid] = useState(false)
  const [stateValid, setStateValid] = useState(false)
  const [validMileage, setValidMileage] = useState(false)
  const [legalBoxChecked, setLegalBoxChecked] = useState(false)
  const [validVin, setValidVin] = useState(false)
  const isFusionRecallFlow = application === 'FUSION_RECALL'

  useEffect(() => {
    pageLoadTags.vinOrPlate()
  }, [])

  function toggleTab(e) {
    setActiveTab(e.target.text || e.target.textContent)
  }
  // temporary till we fix the dropdown options
  function getTrimList(options) {
    var data = []
    for (var i = 0; i < options.length; i++) {
      var obj = { name: options[i], label: options[i], value: options[i] }
      data.push(obj)
    }
    return data
  }

  const vinInput = useInput({
    initialValue: vin || '',
    label: 'VIN',
    invalidCharacters: regex.nonAlphanumeric,
    ariaLabel: 'Please enter a valid VIN',
    // placeholder: 'Enter VIN #',
    validation: {
      type: 'vin',
      value: regex.vinRegex,
      errorMessage: 'Please enter a valid VIN'
    },
    maxLength: {
      value: 17
    }
  })

  const licensePlateInput = useInput({
    id: 'License Plate',
    initialValue: licensePlate || '',
    label: 'License Plate',
    // placeholder: 'Enter License Plate',
    invalidCharacters: regex.nonAlphanumeric,
    maxLength: {
      value: 9
    }
  })

  const mileageInput = useInput({
    initialValue: mileage ? filters.numberCommas(mileage) : '',
    label: 'Mileage',
    placeholder: 'Enter Mileage',
    filter: filters.numberCommas,
    id: 'vb_mileage',
    invalidCharacters: regex.nonNumbers,
    maxLength: {
      value: 11
    }
  })

  let initialStateName = stateName

  if (state && !stateName) {
    initialStateName = stateList.find((s) => s.value === state).name
  }

  const usStateInput = useInput({
    id: 'State',
    initialValue: state
      ? { label: stateName || initialStateName, value: state }
      : '',
    label: 'State',
    abbreviation: ''
  })

  function saveVehicle(data, licensePlate, activeTab) {
    const tool = activeTab.toLowerCase()
    dataDispatch({
      type: DataACTIONS.appraisalIdUpdated,
      payload: data.appraisalId
    })
    dataDispatch({
      type: DataACTIONS.trimOptionsUpdated,
      payload: getTrimList(data.trimOptions)
    })
    dataDispatch({
      type: DataACTIONS.vehicleImageUrlUpdated,
      payload: data.vehicleImageUrl
    })
    dataDispatch({
      type: DataACTIONS.vehicleMakeUpdated,
      payload: data.make
    })
    dataDispatch({
      type: DataACTIONS.vehicleModelUpdated,
      payload: data.model
    })
    dataDispatch({
      type: DataACTIONS.vehicleYearUpdated,
      payload: data.year
    })
    dataDispatch({
      type: DataACTIONS.vinUpdated,
      payload: data.vin
    })
    dataDispatch({
      type: DataACTIONS.usStateUpdated,
      payload: usStateInput.value.value
    })
    dataDispatch({
      type: DataACTIONS.usStateNameUpdated,
      payload: usStateInput.value.label
    })
    dataDispatch({
      type: DataACTIONS.licensePlateUpdated,
      payload: licensePlate
    })
    dataDispatch({
      type: DataACTIONS.toolDescriptorUpdated,
      payload: tool
    })
  }
  const [showVehicleNotFound, setShowVehicleNotFound] = useState(false)

  const checkVinValid = (e) => {
    const vinTest = /\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/

    const vinValid = vin ? true : vinTest.test(e.currentTarget.value)
    setValidVin(vinValid)

    vinInput.valid = vinValid
  }

  const licensePlateValid = licensePlate ? true : licenseValid
  const usStateValid = state ? true : stateValid
  const vinValid = vin ? true : validVin
  const mileageValid = mileageInput?.value ? true : validMileage

  const validFields = activeTab === 'VIN' ? vinValid : licensePlateValid
  const pageValid = isFusionRecallFlow
    ? validFields && usStateValid && legalBoxChecked && mileageValid
    : validFields && usStateValid && legalBoxChecked

  function handleSubmit() {
    if (pageValid) {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
      if (isFusionRecallFlow) {
        const mileageValue = mileageInput.value
          ? mileageInput.value.replace(',', '')
          : null

        dataDispatch({
          type: DataACTIONS.mileageUpdated,
          payload: mileageValue
        })
      }
      api
        .vehicleLookup({
          vin: activeTab === 'VIN' ? vinInput.value.toUpperCase() : null,
          plateNumber:
            activeTab === 'License Plate'
              ? licensePlateInput.value.toUpperCase()
              : null,
          stateCode: usStateInput.value.value,
          appraisalId: appraisalId,
          ownershipType: isFusionRecallFlow
            ? 'OWN'
            : appState.financeType.toUpperCase(),
          referralId
        })
        .then((data) => {
          if (!isFusionRecallFlow) dataDispatch({ type: DataACTIONS.resetData })
          if (data.continueValuation) {
            // remove old license plate if search is with VIN
            // for edge case where user changes from license
            // plate search to VIN search
            activeTab === 'VIN'
              ? saveVehicle(data, '', activeTab)
              : saveVehicle(
                  data,
                  licensePlateInput.value.toUpperCase(),
                  activeTab
                )
            appDispatch({
              type: AppACTIONS.jumpToStepUpdated,
              payload: 'vehiclefound'
            })
            dataDispatch({
              type: 'SHOW_LOADING_SPINNER',
              payload: false
            })
            navigate('/vehiclefound')
          } else {
            dataDispatch({
              type: 'SHOW_LOADING_SPINNER',
              payload: false
            })
            dataDispatch({
              type: DataACTIONS.vinUpdated,
              payload: data.vin
            })
            dataDispatch({
              type: DataACTIONS.urlUpdated,
              payload: data.url
            })
            dataDispatch({
              type: DataACTIONS.appraisalIdUpdated,
              payload: data.appraisalId
            })
            dataDispatch({
              type: DataACTIONS.vehicleYearUpdated,
              payload: data.year
            })
            dataDispatch({
              type: DataACTIONS.vehicleMakeUpdated,
              payload: data.make
            })
            dataDispatch({
              type: DataACTIONS.vehicleModelUpdated,
              payload: data.model
            })
            dataDispatch({
              type: DataACTIONS.vehicleImageUrlUpdated,
              payload: data.vehicleImageUrl
            })
            if (brand === 'ford' && !isFusionRecallFlow) {
              navigate('/sorryCannotAppraiseFordUS')
            } else if (isFusionRecallFlow) {
              navigate('/sorryCannotAppraiseFusionRecall')
            } else {
              navigate('/sorryCannotAppraise')
              appDispatch({
                type: AppACTIONS.jumpToStepUpdated,
                payload: 'sorryCannotAppraise'
              })
            }
          }
        })
        .catch((error) => {
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          setShowVehicleNotFound(true)
          console.log(error)
          pageLoadTags.errorPage('vin')
        })
    } else {
      // setPageValid(false)
      const vinOrPlate = activeTab === 'VIN' ? vinInput : licensePlateInput
      const activeElements = [vinOrPlate, usStateInput, legalCheckbox[0]]
      focusError(activeElements)
    }
  }

  function handleBackStep() {
    navigate('/ownershiptype')
  }

  const [showWhatIsVinModal, setShowWhatIsVinModal] = useState(false)

  const whatIsVinLink = () => {
    return (
      <StyledVinLink
        type='primary'
        action={() => setShowWhatIsVinModal(true)}
        text='What is a VIN?'
      />
    )
  }

  const legalCheckbox = [
    {
      name: isFusionRecallFlow
        ? 'I understand and agree that information I provide about my vehicle in order to obtain an estimated refund amount will be shared with a third party to retrieve details about my vehicle, and/or an example vehicle image.'
        : 'I understand and agree that information I provide about my vehicle in order to obtain an estimated trade-in value will be shared with a third party to retrieve my vehicle’s history, and/or an example vehicle image.',
      initialValue: false,
      id: `legal-checkBox-id`,
      value: legalBoxChecked
    }
  ]

  return (
    <StyledLandingPage>
      <ScrollToTop />
      <StepHeader
        mainHead={
          isFusionRecallFlow
            ? StepHeaders.landing.recall.main
            : StepHeaders.landing.main
        }
        subHead={
          isFusionRecallFlow
            ? StepHeaders.landing.recall.sub
            : StepHeaders.landing.sub
        }
        maxWidth={isFusionRecallFlow ? 565 : 430}
      />
      <div className={`tradein-landingpage-wrap ${brand}`}>
        {brand !== 'ford' && (
          <TradeinButton
            type='forward'
            text='Change Ownership Type'
            action={handleBackStep}
          />
        )}
        {showVehicleNotFound && (
          <PopoverModal
            headerText={
              brand !== 'ford'
                ? "We're sorry!"
                : 'Sorry, we couldn’t find a vehicle that matches the information you entered'
            }
            showModal={showVehicleNotFound}
            updatedDesign={brand === 'ford'}
            closeModal={() => setShowVehicleNotFound(false)}
          >
            <div className='tradein-are-you-sure'>
              <div className='tradein-cancel-modal-subtitle'>
                You won’t be able to add this vehicle, but you can still bring
                it to the dealer for an in-person evaluation.
              </div>
            </div>
            <div className='tradein-vehicle-info'>
              {activeTab === 'VIN' ? (
                <div className='tradein-vehicle-info-row'>
                  <span className='tradein-modal-label'>VIN</span>
                  <span className='tradein-modal-value  no-mouseflow'>
                    {vinInput.value}
                  </span>
                </div>
              ) : (
                <div className='tradein-vehicle-info-row'>
                  <span className='tradein-modal-label'>License Plate</span>
                  <span className='tradein-modal-value  no-mouseflow'>
                    {licensePlateInput.value}
                  </span>
                </div>
              )}
              <div className='tradein-vehicle-info-row'>
                <span className='tradein-modal-label'>State</span>
                <span className='tradein-modal-value'>
                  {usStateInput.value.label}
                </span>
              </div>
            </div>
            {brand !== 'ford' && (
              <p className='tradein-subtext'>
                You won't be able to add this vehicle, but you can still bring
                it to the dealer for an in-person evaluation.
              </p>
            )}

            <div
              className={
                brand === 'ford'
                  ? 'tradein-buttons-container'
                  : 'tradein-buttons-column'
              }
            >
              <TradeinButton
                type={brand === 'ford' ? 'USFordPrimary' : 'primary'}
                text='Try Again'
                action={() => setShowVehicleNotFound(false)}
              />
              <TradeinButton
                type={brand === 'ford' ? 'USFordBack' : 'forward'}
                text='Back'
                action={() => resetApp(true)}
              />
            </div>
          </PopoverModal>
        )}
        <TabGroup
          type={['License Plate', 'VIN']}
          action={toggleTab}
          active={activeTab}
          className={brand}
        />
        <div className='tradein-tab-content'>
          {activeTab === 'VIN' ? (
            <div>
              <TextInput
                valid={validVin}
                {...vinInput}
                customOnChange={checkVinValid}
              />
              {/* {!validVin && (
                <span className='tradein-error-message' id={'error-Vin'}>
                  Please enter a valid VIN
                </span>
              )} */}
              {whatIsVinLink()}
            </div>
          ) : (
            <TextInput
              {...licensePlateInput}
              customOnChange={(event) => setLicenseValid(event.target.value)}
            />
          )}
          <SelectInput
            customOnSelect={(event) => setStateValid(event.value)}
            list={stateList}
            {...usStateInput}
            id='stateSelect'
          />
          {isFusionRecallFlow && (
            <TextInput
              {...mileageInput}
              customOnChange={(event) => setValidMileage(event.target.value)}
            />
          )}
          <StyledCheckbox
            isChecked={legalBoxChecked}
            value={legalBoxChecked}
            items={legalCheckbox}
            handleChange={(event) =>
              setLegalBoxChecked(event.currentTarget.checked)
            }
            selectId='legal-checkBox-id'
            required
            errorMessage='Please review and accept the terms'
          />
          {showWhatIsVinModal && (
            <VinPopoverModal
              title='WHAT IS A VIN?'
              headerText='WHAT IS A VIN, AND WHERE CAN I FIND IT?'
              showModal={showWhatIsVinModal}
              closeModal={() => setShowWhatIsVinModal(false)}
              styleName='vinModal'
              required
            >
              <div>
                <img
                  alt='VIN explanation'
                  src={brand === 'ford' ? vinModalImg() : vinModalLincolnImg}
                />
              </div>
              <div className='tradein-modal-description'>
                Your vehicle's vehicle identification number (VIN) is a 17-digit
                code that serves as the car's fingerprint. You may find the VIN
                by looking at the dashboard on the driver's side, while standing
                outside of the vehicle. Additionally, the VIN may be found by
                opening the driver's side door and looking at the door post. If
                you cannot find your VIN, please select the "License Plate" and
                we will find it for you.
              </div>
            </VinPopoverModal>
          )}
        </div>
        {brand === 'ford' ? (
          <div
            className={`tradein-buttons-container tradein-button-ford-wrapper`}
          >
            <TradeinButton
              type='USFordBack'
              text='Back'
              iconType='prevIcon'
              target='ownershiptype'
            />
            <TradeinButton
              type='USFordPrimary'
              text='Continue'
              iconType='nextIcon'
              disabled={!pageValid}
              action={() => handleSubmit()}
            />
          </div>
        ) : (
          <div className='tradein-buttons-container'>
            <TradeinButton
              type='primary'
              text='Next'
              iconType='nextIcon'
              action={() => handleSubmit()}
            />
          </div>
        )}
      </div>
    </StyledLandingPage>
  )
}

export default LandingPage
