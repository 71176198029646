export const footerPrivacy = {
  en_us: {
    ford: {
      ariaLabel: 'Click here to view the Ford Privacy Policy',
      text: 'Ford Privacy Policy',
      link: 'https://www.ford.com/help/privacy/#privacy'
    },
    lincoln: {
      ariaLabel: 'Click here to view the Lincoln Privacy Policy',
      text: 'Lincoln Privacy Policy',
      link: 'https://www.lincoln.com/help/privacy-terms/#privacy'
    }
  },
  en_gb: {
    ariaLabel: 'Click here to view the Ford Privacy Policy',
    text: 'Ford Privacy Policy',
    link: 'https://www.ford.co.uk/useful-information/terms-and-privacy-policy-hub#PrivacyPolicies'
  },
  de_de: {
    ariaLabel: 'Klicken Sie hier, um unsere Datenschutzrichtlinie anzuzeigen',
    text: 'Datenschutzerklärung',
    link: 'https://www.ford.de/footer/uebersicht-datenschutz-rechtliche-hinweise/grundsaetze-zum-datenschutz'
  },
  fr_fr: {
    ariaLabel: 'Cliquez ici pour consulter notre Politique de confidentialité.',
    text: 'Politique de confidentialité',
    link: ' https://www.ford.fr/informations-utiles/politique-de-confidentialite-termes-et-conditions#PrivacyPolicies'
  },
  nb_no: {
    ariaLabel: 'Klikk her for å se mer om personvern',
    text: 'Ford personvern',
    link: 'https://www.ford.no/nyttig-info/personvern'
  },
  nl_nl: {
    ariaLabel: "Klik hier voor Ford's privacyverklaring",
    text: 'Ford privacyverklaring',
    link: 'https://www.ford.nl/belangrijke-informatie/voorwaarden-en-privacyverklaringen#PrivacyPolicies'
  },
  fr_ch: {
    ariaLabel: 'Cliquez ici pour lire notre politique de confidentialité',
    text: 'Directives liées à la protection des données',
    link: 'https://www.fr.ford.ch/informations-utiles/protection-de-donnees-termes-et-conditions#PrivacyPolicies'
  },
  de_ch: {
    ariaLabel: 'Klicken Sie hier, um unsere Datenschutzbestimmungen zu lesen.',
    text: 'Datenschutzrichtlinien',
    link: 'https://www.de.ford.ch/nuetzliche-links/uebersicht#PrivacyPolicies'
  },
  it_ch: {
    ariaLabel:
      'Cliccare qui per visualizzare le nostre norme sulla protezione dei dati',
    text: 'Norme sulla protezione dei dati',
    link: 'https://www.it.ford.ch/informazioni-utili/termini-e-privacy#PrivacyPolicies'
  },
  es_es: {
    ariaLabel: 'Pincha aquí para consultar nuestra política de privacidad',
    text: 'Política de privacidad',
    link: 'https://www.ford.es/informacion-util/terminos-y-condiciones-y-politica-de-privacidad#PrivacyPolicies'
  },
  it_it: {
    ariaLabel: 'Clicca qui per leggere la Policy Privacy',
    text: 'Politica sulla privacy',
    link: 'https://www.ford.it/informazioni-utili/termini-e-privacy#PrivacyPolicies'
  },
  fr_be: {
    ariaLabel: 'Cliquez ici pour lire notre politique de confidentialité',
    text: 'Politique de confidentialité',
    link: 'https://www.fr.ford.be/informations-utiles/conditions-et-vie-privee/vie-privee'
  },
  nl_be: {
    ariaLabel: 'Klik hier om ons privacybeleid te lezen',
    text: 'Privacybeleid',
    link: 'https://www.nl.ford.be/belangrijke-informatie/voorwaarden-en-privacy/algemene-voorwaarden'
  },
  fi_fi: {
    ariaLabel: 'Lue tietosuojakäytäntömme napauttamalla tästä',
    text: 'Yksityisyyden suoja',
    link: 'https://www.ford.fi/lisatiedot/yksityisyydensuoja-ja-kayttoehdot/yksityisyydensuoja'
  },
  de_at: {
    ariaLabel:
      'Klicken Sie hier, um zu unserer Datenschutzerklärung zu gelangen',
    text: 'Datenschutzerklärung',
    link: 'https://www.ford.at/footer/datenschutz-rechtliche-hinweise'
  },
  da_dk: {
    ariaLabel: 'Klik her for at læse vores Privatlivspolitik',
    text: 'Privatlivspolitik',
    link: 'https://www.ford.dk/vigtig-viden/datasikkerhed'
  },
  en_ie: {
    ariaLabel: 'Click here to read our privacy policy',
    text: 'Privacy policy',
    link: 'https://www.ford.ie/useful-information/terms-and-privacy-policy-hub'
  },
  cs_cz: {
    ariaLabel:
      'Klikněte sem, chcete-li si přečíst naše zásady ochrany soukromí',
    text: 'Zásady ochrany soukromí',
    link: 'https://www.ford.cz/uzitecne-informace/ochrana-dat-a-soukromi'
  },
  pl_pl: {
    ariaLabel: 'Kliknij tutaj, aby przeczytać naszą politykę prywatności.',
    text: 'Polityka prywatności',
    link: 'https://www.ford.pl/informacje/polityka-prywatnosci-i-warunki-korzystania'
  },
  pt_pt: {
    ariaLabel: 'Clique aqui para ler a nossa política de privacidade',
    text: 'Política de Privacidade',
    link: 'https://www.ford.pt/informacao-util/termos-e-condicoes-e-politicas-de-privacidade'
  },
  el_gr: {
    ariaLabel: 'Κάνε κλικ εδώ για να διαβάσεις την πολιτική απορρήτου μας',
    text: 'Πολιτική απορρήτου',
    link: 'https://www.ford.gr/useful-information/terms-and-privacy-policy-hub'
  },
  hu_hu: {
    ariaLabel: 'Ide kattintva megtekintheted Adatvédelmi szabályzatunkat',
    text: 'Adatvédelmi szabályzat',
    link: 'https://www.ford.hu/hasznos-informaciok/terms-and-privacy-policy-hub'
  },
  ro_ro: {
    ariaLabel:
      'Faceți clic aici pentru a citi politica noastră de confidențialitate',
    text: 'Politica privind confidențialitatea',
    link: 'https://www.ford.ro/informatii-utile/termeni-si-politica-de-confidentialitate'
  }
}

export const footerTC = {
  en_us: {
    ariaLabel: 'Click here to view the Trade-In Terms & Conditions',
    text: 'Trade-In Terms & Conditions',
    en_us_recall: {
      ariaLabel: 'Click here to view the Terms & Conditions',
      text: 'Terms & Conditions'
    }
  },

  en_gb: {
    ariaLabel: 'Click here to view the Trade-In Terms & Conditions',
    text: 'Trade-In Terms & Conditions'
  },
  de_de: {
    ariaLabel:
      'Hier klicken, um zu den AGB für die Inzahlungnahme von Fahrzeugen zu gelangen',
    text: 'AGB für die Inzahlungnahme von Fahrzeugen'
  },
  fr_fr: {
    ariaLabel: 'Cliquez ici pour consulter les conditions générales de reprise',
    text: 'Conditions générales de reprise'
  },
  nb_no: {
    ariaLabel: 'Klikk her for å se vilkår og betingelser',
    text: 'Vilkår og betingelser'
  },
  nl_nl: {
    ariaLabel: 'Klik hier voor de algemene voorwaarden voor inruilen',
    text: 'Algemene voorwaarden voor inruilen'
  },
  fr_ch: {
    ariaLabel: 'Cliquez ici pour consulter les conditions générales de reprise',
    text: 'Conditions générales de reprise'
  },
  de_ch: {
    ariaLabel:
      'Klicken Sie hier um die Allgemeine Geschäftsbedingungen der Inzahlungnahme anzusehen',
    text: 'Allgemeine Geschäftsbedingungen der Inzahlungnahme eines Eintauschfahrzeuges'
  },
  it_ch: {
    ariaLabel:
      'Cliccare qui per visualizzare le condizioni generali di contratto per la permuta',
    text: 'Condizioni generali di contratto per la permuta'
  },
  es_es: {
    ariaLabel: 'Pincha aquí para consultar nuestros términos y condiciones',
    text: 'Términos y condiciones'
  },
  it_it: {
    ariaLabel: 'Clicca qui per leggere Termini&Condizioni',
    text: 'Termini&Condizioni permuta'
  },
  fr_be: {
    ariaLabel: 'Click here to view the Trade-In Terms & Conditions',
    text: 'Conditions générale Reprise'
  },
  nl_be: {
    ariaLabel: 'Click here to view the Trade-In Terms & Conditions',
    text: 'Algemene voorwaarden waardebepaling'
  },
  fi_fi: {
    ariaLabel: '', // need copy
    text: 'Käyttöehdot'
  },
  de_at: {
    ariaLabel:
      'Klicken Sie hier, um die Allgemeinen Geschäftsbedingungen für die Inzahlungnahme anzuzeigen',
    text: 'Allgemeine Geschäftsbedingungen für Inzahlungnahme'
  },
  da_dk: {
    ariaLabel: '',
    text: 'Inruilvoorwaarden'
  }
}
