import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Tooltip from './Tooltip'
import { AppContext } from '../context/AppContext'

import styled from 'styled-components'

import { mediaQueries } from '../../styled-components/mediaQueries'

const ErrorMessage = styled.span`
  font-size: var(--fz-xs);
  color: var(--red);
  padding: 0 0 8px 5px;
  text-align: left;
  padding-left: 36px;
  svg {
    margin-right: 5px;
    path {
      fill: var(--red);
    }
  }
`

const StyledCheckBox = styled.div`
  width: 100%;
  margin-top: 5px;
  padding: ${(props) => (props.error ? `10px` : '0')};
  margin-top: ${(props) => (props.required ? `15px` : '5px')};
  padding: 10px;
  &.lincoln {
    .tradein-checkbox-wrapper {
      input[type='checkbox'].tradein-checkbox:checked + label::before {
        background: var(--lincolnOrange);
      }
      label.tradein-checkbox-label {
        ::before {
          --secondary: var(--lincolnOrange);
          width: 18px;
          height: 18px;
        }
        ::after {
          width: 60%;
          height: 60%;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 10' fill-rule='none' clip-rule='evenodd' d='M13.7395 1.13308L12.6351 0.0280762L4.90074 7.76183L1.36512 4.2262L0.260742 5.3312L4.90074 9.97183L13.7395 1.13308Z'  fill='#f0f0f0'");
        }
      }
    }
  }
  @media ${mediaQueries.underM} {
    display: flex;
    flex-direction: column;
  }
  .tradein-checkbox-wrapper {
    position: relative;
    display: inline-block;
    height: 26px;
    font-size: var(--fz-md);
    line-height: 26px;
    width: 50%;
    margin-bottom: 10px;
    @media ${mediaQueries.underM} {
      width: 100%;
    }
    input[type='checkbox'].tradein-checkbox {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      outline: 0;
      z-index: -1;
      width: 24px;
      height: 24px;
      &:checked + label:before {
        border-color: var(--buttonBlue);
        background: var(--buttonBlue);
        outline: 5px auto var(--buttonBlue);
      }
      &:checked + label:after {
        opacity: 1;
        transform: scale(1);
        color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7395 1.13308L12.6351 0.0280762L4.90074 7.76183L1.36512 4.2262L0.260742 5.3312L4.90074 9.97183L13.7395 1.13308Z' fill='%23F0F0F0'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
      &:focus {
        + label::before {
          outline: 5px auto var(--buttonBlue);
        }
      }
    }
    label.tradein-checkbox-label {
      padding-left: 36px;
      cursor: pointer;
      font-size: var(--fz-md);
      &:before,
      &:after {
        box-sizing: border-box;
        content: ' ';
      }
      &:before {
        position: absolute;
        left: 0;
        width: 24px;
        height: 24px;
        outline: 1px solid var(--fordModGrey);
        border-radius: 4px;
      }
      &:after {
        position: absolute;
        top: 8px;
        left: 4px;
        width: 100%;
        height: 100%;
      }
    }
  }
`

const Checkbox = (props) => {
  const [showError, setShowError] = useState(false)

  const {
    items,
    handleChange,
    className,
    tabIndex,
    required,
    errorMessage,
    // temporary for focusError to work
    selectId
  } = props
  function handleFocus(e) {
    if (required && !e.currentTarget.checked) {
      setShowError(true)
    }
  }
  function customOnChange(e) {
    // this is only for the checkbox in LandingPage_US
    if (required) {
      setShowError(!e.currentTarget.checked)
    }
    handleChange(e)
  }
  const { brand, market } = useContext(AppContext) || ''
  const radioButtonClass = [className, brand, market].join(' ')

  return (
    <StyledCheckBox
      className={radioButtonClass}
      error={showError}
      required={required}
    >
      {items.map((item) => (
        <div className='tradein-checkbox-wrapper' key={item.name}>
          <input
            className='tradein-checkbox'
            id={selectId || item.name}
            type='checkbox'
            onChange={customOnChange}
            name={item.name}
            checked={item.isChecked}
            aria-label={item.name}
            tabIndex={tabIndex}
            onFocus={handleFocus}
          />
          <label
            htmlFor={selectId || item.name}
            className='tradein-checkbox-label'
            check='true'
          >
            {item.name}
          </label>
          {item.toolTip && (
            <Tooltip
              tooltipBody={item.toolTip}
              id={item.id}
              ariaLabel={item.tooltipAriaLabel}
            />
          )}
          {required &&
            errorMessage &&
            showError &&
            market !== 'US' &&
            brand ===
              'ford'(
                <ErrorMessage
                  id={'error-' + item.name}
                  role='alert'
                  aria-live='assertive'
                >
                  {errorMessage}
                </ErrorMessage>
              )}
        </div>
      ))}
    </StyledCheckBox>
  )
}

Checkbox.propTypes = {
  items: PropTypes.array,
  handleChange: PropTypes.func,
  tabIndex: PropTypes.string
}

Checkbox.defaultProps = {
  items: [
    'default item 1',
    'default item 2',
    'default item 3',
    'default item 4'
  ],
  tabIndex: '0',
  required: false
}

export default Checkbox
