import axios from 'axios'
import {
  qaComponentHostnames,
  prodComponentHostnames,
  devTIQSUrl,
  qaTIQSUrl,
  prodTIQSUrl,
  prodSyndicateFordUrl,
  prodSyndicateLincolnUrl,
  qaSyndicateFordUrl,
  qaSyndicateLincolnUrl
} from '../constants/staticUrls'

import { appMarket } from './appData'

class Api {
  constructor() {
    const currentHostname = window.location.hostname

    this.getBASE_URL = (isTiqsUrl) => {
      let tiqsBaseUrl
      let syndicateFordUrl
      let syndicateLincolnUrl

      const postfix = isTiqsUrl ? 'tiqs-ext' : 'api'
      if (prodComponentHostnames.has(currentHostname.replace('www.', ''))) {
        tiqsBaseUrl = prodTIQSUrl
        syndicateFordUrl = prodSyndicateFordUrl
        syndicateLincolnUrl = prodSyndicateLincolnUrl
      } else if (
        qaComponentHostnames.has(currentHostname) ||
        currentHostname.includes('qa')
      ) {
        tiqsBaseUrl = qaTIQSUrl
        syndicateFordUrl = qaSyndicateFordUrl
        syndicateLincolnUrl = qaSyndicateLincolnUrl
      } else {
        tiqsBaseUrl = devTIQSUrl
        syndicateFordUrl = qaSyndicateFordUrl
        syndicateLincolnUrl = qaSyndicateLincolnUrl
      }
      console.log('tiqsBaseUrl', tiqsBaseUrl)
      // tiqsBaseUrl = `http://localhost:8080/tiqs/v1`
      tiqsBaseUrl = `${tiqsBaseUrl}/${postfix}/v1`
      return { tiqsBaseUrl, syndicateFordUrl, syndicateLincolnUrl }
    }

    this.VEHICLE_LOOKUP_URL = '/vehicle/lookup'
    this.APPRAISE_VEHICLE_URL = '/appraise'
    this.SEND_APPRAISAL_URL = '/send'
    this.FINANCING_LOOKUP_URL = '/financing/lookup'
    this.LEASE_LOOKUP_URL = '/financing/lookup/lease'
    this.ACCEPT_APPRAISAL = '/appraisal/accept'
    this.FUSION_RECALL = '/appraise/fusionRecall'
    this.ACCEPT_APPRAISAL_US = '/appraisal/accept/us'
    this.ACCEPT_APPRAISAL_OFFLINE = '/appraisal/accept/offline'
    this.ACCEPT_APPRAISAL_EU = '/appraisal/accept/'
    this.DELETE_APPRAISAL_URL = '/appraisal/decline/'
    this.UPDATE_APPRAISAL_INFO = '/appraisal/update/information'
    this.GET_CUSTOMER_INFO = '/appraisal/information/customer/prefill/'

    // referral
    this.GET_REFERRAL = `/tradein/referral/`
    this.UPDATE_PREFILL_APPRAISAL = '/tradein/referral/update/prefillAppraisal'

    // showroom estimate
    this.ESTIMATE_APPRAISAL = '/appraise/estimate'

    /*

    this.MANUFACTURERS_UK = '/dropdown/gb/manufacturer'
    this.MODEL_RANGES_UK = '/dropdown/gb/modelrange'
    this.MODELS_UK = '/dropdown/gb/model'
    this.DERIVATIVES_UK = '/dropdown/gb/derivative'

    this.MAKE_FR = '/dropdown/fr/make'
    this.MODEL_FR = '/dropdown/fr/model'
    this.YEAR_FR = '/dropdown/fr/year'
    this.MONTH_FR = '/dropdown/fr/month'
    this.FUEL_FR = '/dropdown/fr/fuel'
    this.GEARS_FR = '/dropdown/fr/gear'
    this.ENGINE_FR = '/dropdown/fr/engine'
    this.TRIM_FR = '/dropdown/fr/versions'

    this.MAKE_ES = '/dropdown/es/make'
    this.MODEL_ES = '/dropdown/es/model'
    this.DILIMITER = '/'
    this.YEAR_ES = '/dropdown/es/year'
    this.MONTH_ES = '/dropdown/es/month'
    this.ENGINE_ES = '/dropdown/es/engine'
    this.TRIM_ES = '/dropdown/es/versions'
    this.FUEL_ES = '/dropdown/es/fuel'
    this.GEAR_ES = '/dropdown/es/gear'
    this.DOORS_ES = '/dropdown/es/doors'
    this.BODY_ES = '/dropdown/es/body'

    this.MAKE_IT = '/dropdown/it/make'
    this.MODEL_IT = '/dropdown/it/model'
    this.YEAR_IT = '/dropdown/it/year'
    this.MONTH_IT = '/dropdown/it/month'
    this.ENGINE_IT = '/dropdown/it/engine'
    this.TRIM_IT = '/dropdown/it/versions'
    this.FUEL_IT = '/dropdown/it/fuel'
    this.GEAR_IT = '/dropdown/it/gear'
    this.DOORS_IT = '/dropdown/it/doors'
    this.BODY_IT = '/dropdown/it/body'
*/
    this.MAKE_DE = '/dropdown/de/make'
    this.MODEL_DE = '/dropdown/de/model'
    this.YEAR_DE = '/dropdown/de/year'
    this.DESIGN_DE = '/dropdown/de/design'
    this.VARIANT_DE = '/dropdown/de/modelVariant'
    this.TYPE_DE = '/dropdown/de/type'
    this.KILO_DE = '/dropdown/de/kilo'

    this.AXIOS = axios.create()

    this.AXIOS.defaults.headers.post['Content-Type'] = 'application/json'
  }

  async vehicleLookup(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.VEHICLE_LOOKUP_URL}`,
      body
    )
    return response.data
  }

  async vehicleLookupEU(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${
        this.VEHICLE_LOOKUP_URL
      }/${appMarket}`,
      body
    )

    return response.data
  }

  async appraiseVehicle(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.APPRAISE_VEHICLE_URL}`,
      body
    )
    return response.data
  }

  async appraiseVehicleEU(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${
        this.APPRAISE_VEHICLE_URL
      }/${appMarket}`,
      body
    )
    return response.data
  }

  async appraiseVehicleDE(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.APPRAISE_VEHICLE_URL}/de`,
      body
    )
    return response.data
  }

  async sendAppraisal(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.SEND_APPRAISAL_URL}`,
      body
    )
    return response.data
  }

  async sendFinancing(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.FINANCING_LOOKUP_URL}`,
      body
    )
    return response.data
  }

  async sendLease(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.LEASE_LOOKUP_URL}`,
      body
    )
    return response.data
  }

  async acceptAppraisalUS(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.ACCEPT_APPRAISAL_US}`,
      body
    )
    return response.data
  }

  async acceptAppraisalOffline(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.ACCEPT_APPRAISAL_OFFLINE}`,
      body
    )
    return response.data
  }

  async acceptAppraisalEU(body) {
    let acceptAppraisalMarket = 'eu'
    let tiqsV1 = false
    if (appMarket === 'de' || appMarket === 'nl') {
      acceptAppraisalMarket = appMarket
      tiqsV1 = true
    }
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(tiqsV1).tiqsBaseUrl}${
        this.ACCEPT_APPRAISAL_EU
      }${acceptAppraisalMarket}`,
      body
    )
    return response.data
  }

  async deleteAppraisal(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${
        this.DELETE_APPRAISAL_URL
      }${appMarket}`,
      body
    )
    return response.data
  }

  async deMake() {
    const response = await this.AXIOS.get(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.MAKE_DE}`
    )
    return response.data
  }

  async deModel(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.MODEL_DE}`,
      body
    )
    return response.data
  }

  async deYear(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.YEAR_DE}`,
      body
    )
    return response.data
  }

  async deDesign(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.DESIGN_DE}`,
      body
    )
    return response.data
  }

  async deVariant(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.VARIANT_DE}`,
      body
    )
    return response.data
  }

  async deType(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.TYPE_DE}`,
      body
    )
    return response.data
  }

  async deKilo() {
    const response = await this.AXIOS.get(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.KILO_DE}`
    )
    return response.data
  }

  async getReferral(refId) {
    const response = await this.AXIOS.get(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.GET_REFERRAL}${refId}`
    )
    return response.data
  }

  async updatePrefillAppraisal(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.UPDATE_PREFILL_APPRAISAL}`,
      body
    )
    return response.data
  }

  async getFordSyndicateData() {
    const response = await this.AXIOS.get(
      `${
        this.getBASE_URL().syndicateFordUrl
      }/aemservices/brand/syndicatedModulesAsHtml.header.footer.json`
    )
    return response.data
  }

  async getLincolnSyndicateData() {
    const response = await this.AXIOS.get(
      `${
        this.getBASE_URL().syndicateLincolnUrl
      }/aemservices/brand/syndicatedModulesAsHtml.set-shoppingtools.header.footer.json`
    )
    return response.data
  }

  async getAppraisalEst(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${
        this.ESTIMATE_APPRAISAL
      }/${appMarket}`,
      body
    )
    return response.data
  }

  async getCustomerInfo(custId) {
    const response = await this.AXIOS.get(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.GET_CUSTOMER_INFO}${custId}`
    )
    return response.data
  }

  async updateAppraisalInfo(body) {
    console.log(body)
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.UPDATE_APPRAISAL_INFO}`,
      body
    )
    return response.data
  }

  async appraisalFusionRecall(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL(true).tiqsBaseUrl}${this.FUSION_RECALL}`,
      body
    )
    return response.data
  }

  /*
  async ukManufacturer() {
    const response = await this.AXIOS.get(
      `${this.getBASE_URL().tiqsBaseUrl}${this.MANUFACTURERS_UK}`
    )
    return response.data
  }

  async ukModelRange(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.MODEL_RANGES_UK}`,
      body
    )
    return response.data
  }

  async ukModel(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.MODELS_UK}`,
      body
    )
    return response.data
  }

  async ukDerivative(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.DERIVATIVES_UK}`,
      body
    )
    return response.data
  }

  async frMakes() {
    const response = await this.AXIOS.get(
      `${this.getBASE_URL().tiqsBaseUrl}${this.MAKE_FR}`
    )
    return response.data
  }

  async frModel(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.MODEL_FR}`,
      body
    )
    return response.data
  }

  async frYear(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.YEAR_FR}`,
      body
    )
    return response.data
  }

  async frMonth(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.MONTH_FR}`,
      body
    )
    return response.data
  }

  async frFuel(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.FUEL_FR}`,
      body
    )
    return response.data
  }

  async frGears(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.GEARS_FR}`,
      body
    )
    return response.data
  }

  async frEngine(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.ENGINE_FR}`,
      body
    )
    return response.data
  }

  async frTrim(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.TRIM_FR}`,
      body
    )
    return response.data
  }

  async esYear(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.YEAR_ES}`,
      body
    )
    return response.data
  }

  async esMonth(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.MONTH_ES}`,
      body
    )
    return response.data
  }

  async esFuel(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.FUEL_ES}`,
      body
    )
    return response.data
  }

  async esBody(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.BODY_ES}`,
      body
    )
    return response.data
  }

  async esGear(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.GEAR_ES}`,
      body
    )
    return response.data
  }

  async esDoors(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.DOORS_ES}`,
      body
    )
    return response.data
  }

  async esEngine(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.ENGINE_ES}`,
      body
    )
    return response.data
  }

  async esTrim(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.TRIM_ES}`,
      body
    )
    return response.data
  }

  async itYear(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.YEAR_IT}`,
      body
    )
    return response.data
  }

  async itMonth(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.MONTH_IT}`,
      body
    )
    return response.data
  }

  async itFuel(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.FUEL_IT}`,
      body
    )
    return response.data
  }

  async itBody(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.BODY_IT}`,
      body
    )
    return response.data
  }

  async itGear(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.GEAR_IT}`,
      body
    )
    return response.data
  }

  async itDoors(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.DOORS_IT}`,
      body
    )
    return response.data
  }

  async itEngine(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.ENGINE_IT}`,
      body
    )
    return response.data
  }

  async itTrim(body) {
    const response = await this.AXIOS.post(
      `${this.getBASE_URL().tiqsBaseUrl}${this.TRIM_IT}`,
      body
    )
    return response.data
  }
  */
}

export default new Api()
