export const sorryCannotAppraiseCopy = {
  en_us: {
    header: "We're sorry!",
    subtext:
      'We can’t provide an appraisal at this time because your vehicle is valued at less than $2,500, older than model year 2006, or rare/exotic (including newer, low-production vehicles with minimal sales data). But don’t worry — you can still add the vehicle details to your cart and bring it to your dealer for an in-person evaluation.',
    button: 'Add to Cart',
    buttonAriaLabel: 'Click here to go back to the cart',
    showroom: {
      subtext:
        'We can’t provide an appraisal at this time because your vehicle is valued at less than $2,500, older than model year 2006, or rare/exotic (including newer, low-production vehicles with minimal sales data).',
      button: 'Back to Showroom',
      buttonAriaLabel: 'Click here to go back to the Showroom'
    }
  },

  en_gb: {
    header: "We're sorry!",
    subtext:
      "Unfortunately, we can't value your vehicle online. But don't worry you can continue your order online and contact your dealer for a valuation.",
    button: 'Add to Cart',
    buttonAriaLabel: 'Click here to go back to the basket'
  },
  fr_fr: {
    header: 'Estimation De Reprise À Effectuer En Concession',
    subtext:
      'Nous ne pouvons malheureusement pas estimer votre véhicule en ligne. Mais ne vous inquiétez pas - vous pouvez poursuivre votre commande en ligne et contacter votre concessionnaire pour une estimation en concession.',
    button: 'Accepter & ajouter au panier',
    buttonAriaLabel: 'Cliquez ici pour accepter et ajouter au panier.'
  },
  es_es: {
    header: 'Lo sentimos',
    subtext:
      'En estos momentos no podemos valorar tu vehículo. No te preocupes, aún puedes continuar con tu compra online y tu concesionario te contactará para informarte sobre la valoración de tu vehículo.',
    button: 'Aceptar y añadir a la cesta',
    buttonAriaLabel: 'Pincha aquí para aceptar y añadir a la cesta'
  },
  it_it: {
    header: 'Ci dispiace',
    subtext:
      'Il tuo veicolo necessità di una valutazione accurata da parte di uno dei nostri consulenti, pertanto ti raccomandiamo di contattare il Ford Partner selezionato. Potrai prendere un appuntamento per la valutazione del tuo veicolo. Puoi continuare il processo di acquisto senza nessun problema.',
    button: 'Ritorna al carrello',
    buttonAriaLabel: 'Clicca qui per accettare ed aggiungere al carrello'
  },
  nl_nl: {
    header: 'Het spijt ons',
    subtext:
      'Helaas kunnen we jouw auto niet online taxeren. Maak je geen zorgen - je kunt jouw bestelling online voortzetten en contact opnemen met jouw dealer voor een persoonlijke taxatie.',
    button: 'Geaccepteerd en toegevoegd aan winkelwagentje',
    buttonAriaLabel: 'Geaccepteerd en toegevoegd aan winkelwagentje'
  },
  de_de: {
    header: 'Es tut uns leid',
    subtext:
      'Leider kann Ihr Fahrzeug nicht online bewertet werden. Sie können Ihre Bestellung jedoch online fortsetzen. Ihr Ford Partner wird Sie kontaktieren, um die Inzahlungnahme mit Ihnen zu besprechen.',
    button: 'an Händler senden',
    buttonAriaLabel:
      'Hier klicken, um zuzustimmen und zum Warenkorb hinzuzufügen'
  }
}

export const errorCopy = {
  en_us: {
    header: 'Sorry to hit the brakes',
    subtext:
      "The page you've requested can't be accessed. Please return to the previous page.",
    back: 'Back'
  },
  en_gb: {
    header: 'Sorry, we’re having trouble loading the page. ',
    subtext:
      'Please click the back button to be taken to the previous page to try again.',
    back: 'Back'
  },
  fr_fr: {
    header:
      'Nous sommes désolés, nous rencontrons des problèmes pour charger la page. ',
    subtext:
      'Veuillez cliquer sur le bouton « Retour » pour accéder à la page précédente et essayer à nouveau.',
    back: 'Retour'
  },
  es_es: {
    header: 'Lo sentimos, estamos teniendo problemas para cargar la página. !',
    subtext:
      'Haz clic en el botón «Volver» para volver a la página anterior e intentarlo de nuevo.',
    back: 'Volver'
  },
  it_it: {
    header: 'Si è verificato un problema durante il caricamento della pagina. ',
    subtext:
      'Fai clic sul pulsante Indietro per tornare alla pagina precedente e riprovare.',
    back: 'Indietro'
  },
  nl_nl: {
    header: 'Sorry, we hebben een probleem met het laden van de pagina. ',
    subtext:
      'Klik op de knop Vorige om naar de vorige pagina te gaan om het opnieuw te proberen.',
    back: 'Vorige'
  },
  de_de: {
    header: 'Leider haben wir gerade Probleme, die Seite zu laden.',
    subtext:
      'Bitte klicken Sie auf „Zurück“, um zur vorherigen Seite zu gelangen, und versuchen Sie es erneut.',
    back: 'Zurück'
  },
  da_dk: {
    header: 'Beklager, vi har problemer med at indlæse siden. ',
    subtext:
      'Klik på knappen "Tilbage" for at gå til den forrige side for at prøve igen.',
    back: 'Tilbage'
  },
  de_ch: {
    header: 'Es tut uns leid, wir haben Probleme beim Laden der Seite. ',
    subtext:
      'Bitte klicken Sie auf das Zurück-Button, um zur vorherigen Seite zu gelangen und es erneut zu versuchen.',
    back: 'Zurück'
  },
  fr_ch: {
    header: 'Désolé, nous rencontrons des difficultés à charger la page. ',
    subtext:
      'Veuillez cliquer sur le bouton de retour pour accéder à la page précédente et essayer à nouveau.',
    back: 'Retour'
  },
  it_ch: {
    header:
      'Siamo spiacenti, si è verificato un problema durante il caricamento della pagina. ',
    subtext:
      'Cliccare sul pulsante Indietro per tornare alla pagina precedente e riprovare.',
    back: 'Indietro'
  },
  nb_no: {
    header: 'Beklager, vi har problemer med å laste inn siden. ',
    subtext:
      'Klikk på tilbake-knappen for å gå til forrige side for å prøve på nytt.',
    back: 'Takaisin'
  },
  fi_fi: {
    header: 'Valitettavasti sivun lataaminen ei onnistu. ',
    subtext:
      'Klikkaa Takaisin-painiketta, niin sinut siirretään edelliselle sivulle, ja yritä sitten uudelleen.',
    back: 'Takaisin'
  },
  de_at: {
    header: 'Es tut uns leid, wir haben Probleme beim Laden der Seite. ',
    subtext:
      'Bitte klicken Sie auf die Schaltfläche Zurück, um zur vorherigen Seite zu gelangen und es erneut zu versuchen.',
    back: 'Zurück'
  },
  fr_be: {
    header:
      'Veuillez nous excuser, nous rencontrons des problèmes relatifs au chargement de la page. ',
    subtext:
      "La page que vous avez demandée n'est pas accessible.Veuillez retourner à la page précédente.",
    back: 'Retour'
  },
  nl_be: {
    header: 'Sorry, we hebben een probleem met het laden van de pagina. ',
    subtext:
      'Klik op de knop Vorige om naar de vorige pagina te gaan en het opnieuw te proberen.',
    back: 'Vorige'
  },
  pl_pl: {
    header: 'Przepraszamy, mamy problemy z wczytaniem strony. ',
    subtext:
      'Kliknij przycisk Wróć, aby przejść do poprzedniej strony i spróbuj ponownie.',
    back: 'Wróć'
  },
  cs_cz: {
    header: 'Je nám líto, ale máme potíže s načtením stránky. ',
    subtext:
      'Kliknutím na tlačítko Zpět se vraťte na předchozí stránku a zkuste to znovu.',
    back: 'Zpět'
  },
  pt_pt: {
    header:
      'Lamentamos, mas estamos com problemas no carregamento da página.  ',
    subtext:
      'Clique no botão Voltar de forma a ser levado para a página anterior e tentar novamente.',
    back: 'Voltar'
  },
  en_ie: {
    header: 'Sorry, we’re having trouble loading the page. ',
    subtext:
      'Please click the back button to be taken to the previous page to try again.',
    back: 'Back'
  },
  hu_hu: {
    header: 'Sajnos probléma merült fel az oldal betöltése során. ',
    subtext:
      'Kérjük, kattintson a Vissza gombra, hogy visszalépjen az előző oldalra, és ismét megpróbálhassa.',
    back: 'Vissza'
  },
  el_gr: {
    header:
      'Λυπούμαστε, αντιμετωπίζουμε προβλήματα με τη φόρτωση της σελίδας. ',
    subtext:
      'Κάντε κλικ στο κουμπί «Πίσω» που θα σας μεταφέρει στην προηγούμενη σελίδα για να προσπαθήσετε ξανά.',
    back: 'Πίσω'
  },
  ro_ro: {
    header: 'Ne pare rău, întâmpinăm dificultăți la încărcarea paginii. ',
    subtext:
      'Vă rugăm să faceți clic pe butonul Înapoi pentru a accesa pagina anterioară și a încerca din nou.',
    back: 'Înapoi'
  }
}

export const vehicleNotFoundCopy = {
  en_us: {
    header: "We're sorry!",
    subtext:
      'We couldn’t find a vehicle that matches the information you entered:',
    regLabel: 'Registration Number',
    mileageLabel: 'Mileage',
    desc: 'You won’t be able to add this vehicle, but you can still bring it to the dealer for an in-person evaluation.',
    button: 'Back',
    buttonAriaLabel: ''
  },
  // needs new copy
  en_gb: {
    header: "We're sorry!",
    subtext:
      'We couldn’t find a vehicle that matches the information you entered:',
    regLabel: 'Registration Number',
    mileageLabel: 'Mileage',
    desc: 'You won’t be able to add this vehicle, but you can still bring it to the dealer for an in-person evaluation.',
    button: 'Back',
    buttonAriaLabel: ''
  },
  fr_fr: {
    header: 'Nous sommes désolés',
    subtext:
      "Nous n'avons pas trouvé de véhicule correspondant aux informations saisies:",
    regLabel: "Numéro d'immatriculation",
    mileageLabel: 'Kilométrage',
    desc: 'Vous ne pouvez pas ajouter ce véhicule à votre panier. Vous pourrez cependant toujours le faire estimer en concession.',
    button: 'Retour au panier',
    buttonAriaLabel: 'Cliquez ici pour revenir au panier'
  },
  es_es: {
    header: 'Lo sentimos',
    subtext:
      'No hemos podido encontrar ningún vehículo que coincida con la información que has facilitado:',
    regLabel: 'Matrícula',
    mileageLabel: 'Kilómetros actuales',
    desc: 'No podrás añadir este vehículo usado a tu cesta, pero puedes llevarlo al concesionario seleccionado para una valoración en persona.',
    button: 'Volver a la cesta',
    buttonAriaLabel: 'Pincha aquí para volver a la cesta'
  },
  it_it: {
    header: 'Ci dispiace',
    subtext:
      'Non abbiamo trovato nessun veicolo con le caratteristiche che hai inserito',
    regLabel: 'Targa',
    mileageLabel: 'Chilometraggio',
    desc: 'Non è possibile aggiungere il valore di questo veicolo nel processo di acquisto. Puoi comunque continuare il tuo processo di acquisto online. Contatta direttamente il Ford Partner per effettuare la valutazione del tuo veicolo.',
    button: 'Indietro',
    buttonAriaLabel: 'Clicca qui per confermare e tornare al carrello'
  },
  nl_nl: {
    header: 'Het spijt ons',
    subtext:
      'We konden geen auto vinden dat overeenkomt met de gegevens die je hebt ingevoerd:',
    regLabel: 'Kenteken',
    mileageLabel: 'Huidige kilometerstand',
    desc: 'Je kan deze auto niet toevoegen aan je winkelwagentje, maar je kan wel naar je dealer gaan voor een persoonlijke taxatie.',
    button: 'Terug naar je winkelwagentje',
    buttonAriaLabel:
      'Klik op deze button om terug te gaan naar je winkelwagentje',
    buttonTryAgain: 'Probeer Opnieuw'
  },
  de_de: {
    header: 'Het spijt ons',
    subtext:
      'We konden geen auto vinden dat overeenkomt met de gegevens die je hebt ingevoerd:',
    regLabel: 'Kenteken',
    mileageLabel: 'Huidige kilometerstand',
    desc: 'Je kan deze auto niet toevoegen aan je winkelwagentje, maar je kan wel naar je dealer gaan voor een persoonlijke taxatie.',
    button: 'Terug naar je winkelwagentje',
    buttonAriaLabel:
      'Klik op deze button om terug te gaan naar je winkelwagentje'
  }
}
