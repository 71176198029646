import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../pageElements/Tooltip'
import styled from 'styled-components'
import CurrencyInput from 'react-currency-input-field'
import { AppContext } from '../context/AppContext'

const StyledTextInput = styled.div`
  margin-bottom: 16px;
  .currency {
    input {
      width: 250px;
    }
  }

  label {
    font-size: var(--fz-sm);
  }
  input {
    border-radius: 8px;
    padding: 8px 16px;
    font-family: var(--fontPrimary);
    background: white;
    color: var(--fordNeutralBlack);
    border: 1px solid var(--fordInputDefault);
    height: 40px;
    text-transform: uppercase;

    &.error-input {
      border: 2px solid var(--red);
    }

    ::placeholder {
      text-transform: none;
      color: var(--fordSlate);
    }
    &:focus {
      box-shadow: none;
      outline: 0;
      border: 2px solid var(--inputHighlightblue);
    }
  }
  label {
    color: var(--fordLightDefault);
    text-align: left;
    margin-bottom: 4px;
  }

  .tradein-text-container {
    display: flex;
    flex-direction: column;
  }
`

const ErrorMessage = styled.div`
  font-size: var(--fz-xs);
  font-family: var(--fontPrimary);
  color: var(--red);
  padding: 0 0 8px 5px;
  text-align: left;
  order: 3;
  svg {
    margin-right: 5px;
    path {
      fill: var(--red);
    }
  }
`

const TextInput = ({
  label,
  value,
  displayValue,
  valid,
  onChange,
  errorMessage,
  tooltip,
  id,
  placeholder,
  customOnBlur,
  customOnChange,
  maxLength,
  ariaLabel,
  tooltipAriaLabel,
  optional,
  currency,
  parentCallback,
  name
}) => {
  const [focused, setFocused] = useState(false)
  const [showError, setShowError] = useState(false)
  const { brand, market } = useContext(AppContext) || ''

  function textInputChange(e) {
    if (!customOnChange) {
      onChange(e)
    } else {
      onChange(e)
      customOnChange(e)
    }
  }

  const fordUS = brand === 'ford' ? true : false

  function handleFocus(e) {
    if (!optional) {
      if (e.relatedTarget) {
        // If it is being focused due to clicking submit and the field being empty, this will be true
        setShowError(true)
      }
    }
    setFocused(true)
  }
  const onCurrencyChange = (value) => {
    parentCallback(value)
  }

  function handleBlur(e) {
    if (!optional) {
      if (customOnBlur) {
        customOnBlur(e)
      }
      if (!value) {
        setShowError(true) // on first blur, display error messages
      }
    }
    setFocused(false)
  }

  function renderUsFordInput() {
    if (currency) {
      return (
        <div class='currency tradein-text-container'>
          <label htmlFor={id || label} className={''} aria-label={ariaLabel}>
            {label}
          </label>
          <CurrencyInput
            id={label}
            onFocus={handleFocus}
            onBlur={handleBlur}
            intlConfig={currency}
            onChange={onChange}
            className={!valid && errorMessage && showError ? 'error-input' : ''}
            maxLength={maxLength ? maxLength.value : ''}
            onValueChange={onCurrencyChange}
            placeholder={(!focused ? label : '', placeholder || label)}
          />
        </div>
      )
    } else {
      return (
        // US ford only
        <div className='tradein-text-container'>
          <label htmlFor={id || label} className={''} aria-label={ariaLabel}>
            {label}
          </label>
          <input
            id={id || label}
            onChange={textInputChange}
            value={displayValue}
            // placeholder={placeholder || label}
            onFocus={handleFocus}
            onBlur={handleBlur}
            maxLength={maxLength ? maxLength.value : ''}
            className={!valid && errorMessage && showError ? 'error-input' : ''}
            type='text'
            name={name}
            aria-describedby={'error-' + label}
          />
        </div>
      )
    }
  }

  return (
    <StyledTextInput className={`${brand}`}>
      <div>
        {renderUsFordInput()}

        {!valid && errorMessage && showError && (
          <ErrorMessage
            id={'error-' + label}
            role='alert'
            className='tradein-error-message'
            aria-live='assertive'
          >
            {errorMessage}
          </ErrorMessage>
        )}
      </div>
    </StyledTextInput>
  )
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valid: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  optionsList: PropTypes.array,
  maxLength: PropTypes.object,
  optional: PropTypes.bool
}

TextInput.defaultProps = {
  errorMessage: 'Please enter a value',
  optional: false
}

export default TextInput
