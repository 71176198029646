import React, { useContext } from 'react'
import { navigate } from 'hookrouter'
import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import { useInput } from '../../common/customhooks/forms'
import TradeinButton from '../../common/pageElements/TradeinButton'
import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders, vehicleFoundCopy } from './copy/VehicleFoundCopy'
import focusError from '../../../utils/focusError'
import api from '../../../utils/api'
import * as filters from '../../../utils/filters'
import Image from '../../common/icons/Image'

import { StyledVehicleFound } from '../../styled-components/screens/StyledVehicleFound'

const VehicleFoundEU = () => {
  const { appDispatch, market, language, application } = useContext(AppContext)
  const { dataState, dataDispatch } = useContext(DataContext)

  const { appraisalId, registrationNumber, mileage, deliveryMileage } =
    dataState

  // creates an object that contains all of the things
  // that change depending on the market property from Cart
  // with the exception of copy

  const marketObj = {
    StepHeaders: StepHeaders[language],
    copy: vehicleFoundCopy[language],
    apiCall: `vehicleLookupEU`,
    regNumValue: registrationNumber
  }

  function handleSubmit() {
    // CART FLOW FOR NON ES/IT MARKETS
    if (application !== 'SHOWROOM' && market !== 'ES' && market !== 'IT') {
      if (market === 'FR') {
        if (trim.value) {
          saveTrimValue(trim.value)
          appDispatch({
            type: AppACTIONS.jumpToStepUpdated,
            payload: 'vehiclecondition_EU'
          })
          navigate('/vehiclecondition_EU')
        } else {
          focusError([trim])
        }
      } else {
        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: 'vehiclecondition_EU'
        })
        navigate('/vehiclecondition_EU')
      }
    }
    // CART & SHOWROOM FLOW FOR ES/IT
    else if (market === 'IT' || market === 'ES') {
      appDispatch({
        type: AppACTIONS.jumpToStepUpdated,
        payload: 'vehiclebasics_es_it'
      })
      navigate('/vehiclebasics_es_it')
    }
    // SHOWROOM FLOW FOR FR & GB
    else {
      let appraisalPayload = ''
      if (market === 'FR') {
        appraisalPayload = {
          appraisalId: appraisalId,
          trim: trim.value.label
        }
      } else if (market === 'GB' || market === 'NL') {
        appraisalPayload = {
          appraisalId
        }
      }

      if (market === 'FR') {
        if (trim.value) {
          appraisalEst(appraisalPayload)
        } else {
          focusError([trim])
        }
      } else {
        appraisalEst(appraisalPayload)
      }
    }
  }

  function appraisalEst(payload) {
    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })
    api
      .getAppraisalEst(payload)
      .then((data) => {
        dataDispatch({
          type: DataACTIONS.currentAppraisalQuoteUpdated,
          payload: data.appraisalEstimate
        })
        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: 'tradeinappraisal_EU'
        })
        navigate('/tradeinappraisal_EU')
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  function saveTrimValue(value) {
    dataDispatch({
      type: DataACTIONS.trimUpdated,
      payload: value
    })
  }

  const trim = useInput({
    ariaLabel: marketObj.copy?.trimInputCopy?.ariaLabel,
    initialValue: marketObj.copy?.trimInputCopy?.initialValue,
    label: marketObj.copy?.trimInputCopy?.label,
    placeholder: marketObj.copy?.trimInputCopy?.placeholder,
    errorMessage: marketObj.copy?.trimInputCopy?.errorMessage
  })

  const mileageValue = mileage || deliveryMileage

  const { year, make, model } = dataState

  return (
    <StyledVehicleFound className='ford'>
      <StepHeader
        mainHead={marketObj.StepHeaders.main}
        subHead={marketObj.StepHeaders.sub}
        maxWidth='420'
      />

      <div class='ford vehiclefound'>
        <div className='tradein-veh-card'>
          <div className='tradein-veh-image-wrapper no-img'>
            <Image img={'placeholderCar'} />
          </div>

          <div className='tradein-veh-content-wrapper'>
            <h2>
              {year} {make} {model}
            </h2>

            <div className='tradein-veh-text-wrapper'>
              <div className='tradein-vehicle-text'>
                {marketObj.copy.vehicleCardCopy.regNum}
                <div className='no-mouseflow tradein-vehicle-value'>
                  {marketObj.regNumValue}
                </div>
              </div>

              <div className='tradein-vehicle-text'>
                {marketObj.copy.vehicleCardCopy.mileageTwo}
                <div className='no-mouseflow tradein-vehicle-value'>
                  {mileageValue == null
                    ? mileageValue
                    : market === 'GB'
                    ? filters.numberCommas(mileageValue)
                    : filters.numberCommasEU(mileageValue)}
                </div>
              </div>
            </div>
            <div className='tradein-buttons-container tradein-button-ford-wrapper'>
              <TradeinButton
                type='USFordBack'
                ariaLabel={marketObj.copy.backButton.ariaLabel}
                target='landingpage_EU'
                text={marketObj.copy.backButton.text}
                iconType='prevIcon'
              />
              <TradeinButton
                ariaLabel={
                  application === 'SHOWROOM'
                    ? marketObj.copy.submitButton.ariaLabel
                    : marketObj.copy.confirmSubmitForwardButton.ariaLabel
                }
                type='USFordPrimary'
                text={
                  application === 'SHOWROOM'
                    ? marketObj.copy.submitButton.text
                    : marketObj.copy.confirmSubmitForwardButton.text
                }
                iconType='nextIcon'
                action={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </StyledVehicleFound>
  )
}

export default VehicleFoundEU
