import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../context/AppContext'

import { mediaQueries } from '../../styled-components/mediaQueries'

const StyledStepHeader = styled.div`
  text-align: center;
  max-width: 650px;
  position: relative;
  z-index: 1;
  margin: auto;
  margin-bottom: 32px;
  @media ${mediaQueries.underS} {
    max-width: 300px;
    margin-bottom: 0;
    &.ford {
      max-width: 100%;
    }
  }
  .tradein-header-note {
    margin: 20px;
    font-family: var(--fontPrimary);
    color: var(--primary);
    font-size: var(--fz-xs);
    letter-spacing: 1px;
  }
`

const StepHeader = (props) => {
  const { brand, market } = useContext(AppContext) || ''
  const stepHeaderClass = props.className
    ? ` ${props.className} ${brand} `
    : `${brand} `

  return (
    <StyledStepHeader
      className={stepHeaderClass}
      style={{ width: props.maxWidth || 650 }}
    >
      <h1 className='tradein-main-header'>{props.mainHead}</h1>
      {props.subHead && (
        <h2
          className='tradein-subtext'
          style={{
            marginBottom: props.marginBottom
          }}
        >
          {props.subHead}
        </h2>
      )}
      {props.note && (
        <h3
          className='tradein-header-note'
          style={{ maxWidth: props.maxWidth }}
        >
          {props.note}
        </h3>
      )}
    </StyledStepHeader>
  )
}

export default StepHeader
