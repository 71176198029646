import React, { useContext } from 'react'
import Icon from '../icons/Icon'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import { AppContext } from '../../common/context/AppContext'
import useLockBodyScroll from '../../common/customhooks/modalScroll'

const StyledPopoverModal = styled.div`
  &&&&& {
    &.updatedDesign {
      @media ${mediaQueries.underM} {
        .tradein-popover-modal {
          height: 400px;
          top: 25%;
          margin: 0 16px;
          width: calc(100% - 32px);
        }
      }

      .tradein-popover-modal-inner {
        padding: 32px;
        height: auto;
        .tradein-popover-modal-body {
          padding: 0;
          display: block;
          margin-top: 32px;
          @media ${mediaQueries.underM} {
            margin-top: 16px;
          }
          .tradein-body-wrap {
            padding: 0;
            display: block;
            .tradein-vehicle-info {
              margin: 32px 0;
              color: #00142e;
              > .tradein-buttons-column {
                margin: 0;
              }
              .tradein-vehicle-info-row {
                font-size: 20px;

                margin-bottom: 16px;
                width: 100%;
                span {
                  font-weight: normal;
                }
              }
            }
            .tradein-cancel-modal-subtitle {
              margin: 0;
              text-align: left;
              max-width: none;
              color: #00142e;
              line-height: 24px;
              font-size: 20px;
            }
            .tradein-buttons-container {
              margin-top: 32px;
              flex-direction: row;
              justify-content: flex-end;
              max-width: none;
              @media ${mediaQueries.underM} {
                margin-top: 16px;
                flex-direction: row;
              }
              button {
                margin: 0;
                &:not(:last-child) {
                  margin-right: 16px;
                }
              }
            }
          }
        }
      }
      .tradein-popover-modal-header {
        padding: 0;
        align-items: flex-start;
        justify-content: space-between;
        button svg {
          width: 16px;
          height: 16px;
        }
      }

      .tradein-popover-modal-header {
        border-bottom: 1px solid #a6a6a6;
        button {
          margin: 0;
        }

        h3.tradein-popover-modal-title {
          text-align: left;
          text-transform: none;
          font-weight: 400;
          font-size: var(--fz-xxl);
          line-height: 40px;
          letter-spacing: 0;
          color: var(--secondary);
          padding-bottom: 32px;
          @media ${mediaQueries.underS} {
            padding-bottom: 16px;
            max-width: 300px;
          }
        }
      }
    }
  }
  .tradein-popover-modal-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    position: fixed;
  }
  .tradein-popover-modal {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: var(--primary);
    align-items: center;
    z-index: 5;
    background-color: transparent;
    text-align: center;
    .tradein-popover-modal-inner {
      background-color: white;
      width: 100%;
      position: absolute;
      top: 20%;
      max-width: 816px;
      z-index: 4;
      @media ${mediaQueries.underM} {
        top: 0 !important;
        width: 100% !important;
        overflow: scroll;
      }
      .tradein-popover-modal-title,
      .tradein-popover-modal-header-text {
        text-transform: uppercase;
        font-family: var(--fontSecondary);
        font-weight: 200;
      }
      .tradein-popover-modal-title {
        font-size: var(--fz-xl);
        line-height: 30px;
        letter-spacing: 3px;
        margin-bottom: 0;
        @media ${mediaQueries.underS} {
          font-size: var(--fz-md);
          letter-spacing: 2px;
        }
      }
      .tradein-popover-modal-header-text {
        font-size: var(--fz-xxl);
        margin-top: 25px;
        max-width: 525px;
        line-height: 1.29;
        @media ${mediaQueries.underS} {
          font-size: var(--fz-md);
          letter-spacing: 2px;
        }
      }
      .tradein-popover-modal-header {
        // border-bottom: var( --borderLight);
        display: flex;
        justify-content: space-between;
        padding: 15px 40px;
        @media ${mediaQueries.underS} {
          padding: 15px 20px;
        }
        .close-icon {
          width: 24px;
          height: 24px;
          @media ${mediaQueries.underS} {
            width: 16px;
            height: 16px;
          }
        }
        button {
          background-color: white;
          &:focus {
            box-shadow: 0px 0px 5px var(--lightBlue);
          }
        }
      }
      .tradein-popover-modal-body {
        padding: 0;
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media ${mediaQueries.underS} {
          padding: 30px;
        }
        .tradein-body-wrap {
          width: 100%;
          max-width: 782px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .tradein-vehicle-info {
            margin: 30px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .tradein-vehicle-info-row {
            font-size: var(--fz-xxs);
            width: 300px;
            display: flex;
            justify-content: space-between;
            letter-spacing: 1px;
            .tradein-modal-value {
              font-weight: bold;
            }
          }
          p.tradein-subtext {
            max-width: 450px;
          }
          img {
            max-width: 268px;
            margin: 20px 0;
          }
          .tradein-modal-description {
            font-family: var(--fontPrimary);
            font-size: var(--fz-xxs);
            letter-spacing: 1px;
            text-align: center;
            margin-bottom: 50px;
          }
        }
        .tradein-cancel-modal-subtitle {
          font-size: var(--fz-md);
          margin-bottom: 30px;
          @media ${mediaQueries.underS} {
            text-align: left;
            margin: 0px 10px 30px 10px;
          }
        }
        .tradein-cancel-modal-buttons-wrapper {
          display: flex;
          @media ${mediaQueries.underS} {
            display: block;
          }
          .tradein-submit-button {
            box-shadow: none;
            flex: 1 1 auto;
            margin-right: 20px;
            @media ${mediaQueries.underS} {
              margin: 0px 0px;
            }
          }
          .tradein-cancel-modal-continue-button-wrapper {
            .tradein-submit-button {
              border: var(--borderInput);
              background-color: white;
              color: var(--primary);
              margin-left: 20px;
              @media ${mediaQueries.underS} {
                margin: 35px 0px 0px 0px;
              }
            }
          }
        }
      }
    }
  }
  &.lincoln {
    .tradein-body-wrap img {
      max-width: 512px !important;
    }
  }
`

const PopoverModal = ({
  showModal,
  className,
  closeModal,
  updatedDesign,
  headerText,
  children,
  style
}) => {
  const { brand } = useContext(AppContext) || ''
  const popoverModalClass = [className, brand].join(' ')
  const updateDesignClass = [popoverModalClass, 'updatedDesign'].join(' ')

  if (showModal) {
    useLockBodyScroll()
  }
  return (
    showModal && (
      <React.Fragment>
        {updatedDesign && brand === 'ford' ? (
          <StyledPopoverModal className={updateDesignClass} style={style}>
            <div className='tradein-popover-modal-overlay' />
            <div className='tradein-popover-modal'>
              <div className='tradein-popover-modal-inner' tabIndex='0'>
                <div className='tradein-popover-modal-header'>
                  <h3 className='tradein-popover-modal-title'>{headerText}</h3>
                  <button onClick={closeModal} tabIndex='0'>
                    <Icon icon='close' title='Close modal' />
                  </button>
                </div>
                <div className='tradein-popover-modal-body'>
                  <div className='tradein-body-wrap'>{children}</div>
                </div>
              </div>
            </div>
          </StyledPopoverModal>
        ) : (
          <StyledPopoverModal className={popoverModalClass} style={style}>
            <div className='tradein-popover-modal-overlay' />
            <div className='tradein-popover-modal'>
              <div className='tradein-popover-modal-inner' tabIndex='0'>
                <div className='tradein-popover-modal-header'>
                  <h3 className='tradein-popover-modal-title'></h3>
                  <button onClick={closeModal} tabIndex='0'>
                    <Icon icon='close' title='Close modal' />
                  </button>
                </div>
                <div className='tradein-popover-modal-body'>
                  <div className='tradein-body-wrap'>
                    <h3 className='tradein-popover-modal-header-text'>
                      {headerText}
                    </h3>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </StyledPopoverModal>
        )}
      </React.Fragment>
    )
  )
}

export default PopoverModal

PopoverModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  headerText: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  descriptionText: PropTypes.string
}
