import React from 'react'
import { TermsAndConditions } from './copy/TermsAndConditionsCopy'
import styled from 'styled-components'

const StyledTerms = styled.div`
  && {
    padding: 10px 40px 20px;
    color: black;
    font-family: var(--fontSecondary);
    margin-top: -20px;
    h1 {
      font-size: 50px;
      font-weight: 200;
      line-height: 1.4;
      letter-spacing: 2px;
      margin-top: 0;
    }
    h2 {
      font-size: 20px;
      font-weight: 400;
      text-decoration: underline;
      text-transform: inherit;
      letter-spacing: 0;
    }
    p {
      color: black;
      padding: 1.6rem 0;
    }
  }
`

const TermsAndConditionsEU = () => {
  const copy = TermsAndConditions['nl_nl']
  return (
    <StyledTerms>
      <h1>{copy.mainTitle}</h1>
      <h2>{copy.subTitle}</h2>

      <div
        dangerouslySetInnerHTML={{
          __html: copy.body
        }}
      ></div>
    </StyledTerms>
  )
}

export default TermsAndConditionsEU
