import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import { AppContext } from '../context/AppContext'

const StyledConditionCard = styled.div`
  .tradein-card-content {
    border: 0;
    margin: 0.5rem;
    background-color: white;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
      0 10px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 250px;
    padding-bottom: 20px;
    @media ${mediaQueries.underS} {
      width: 280px;
    }
  }
  .selected-box {
    border: 2px solid var(--secondary);
  }
  .tradein-card-select {
    text-align: center;
    margin-top: 35px;
  }

  .tradein-card-row {
    padding: 10px 20px;
    background-color: white;
    height: 280px;
    .tradein-card-header {
      font-size: var(--fz-xl);
      color: var(--secondary);
      font-family: var(--fontSecondary);
      font-weight: 600;
      line-height: 2.5;
      letter-spacing: 0.93px;
    }
    .tradein-card-subtitle {
      margin-bottom: 15px;
    }
  }
  .tradein-card-text {
    font-family: var(--fontPrimary);
    font-size: var(--fz-xxs);
    padding-right: 1rem;
    min-height: 3rem;
    line-height: 1.91;
    letter-spacing: 1px;
    div:nth-child(1) {
      font-size: var(--fz-lg);
    }
    div:nth-child(3) {
      margin-top: 10px;
    }
  }
  &.error {
    &:before {
      border: 1px solid var(--red) !important;
    }
  }
`
const StyledVehicleConditionCard = styled.div`
  cursor: pointer;
  .tradein-card-wrapper {
    display: flex;
    padding: 10px 10px 10px 0px;
  }
  &.lincoln {
    .tradein-card-content {
      box-shadow: none;
      border: solid 1px var(--borderLightGrey);
    }
    .tradein-card-row {
      height: 310px;
    }

    color: var(--mediumBlue);
    .tradein-rb-answer {
      font-weight: 600;
    }
    .selected-box {
      border: 2px solid var(--lincolnOrange);
    }
    .tradein-card-header {
      letter-spacing: 3px;
      font-weight: normal;
    }
    .tradein-card-subtitle {
      font-size: var(--fz-lg);
      font-weight: 600;
    }
    .tradein-card-text {
      font-size: var(--fz-md);
      line-height: 24px;
    }
    .tradein-card-select {
      margin-left: 28px;
      text-align: left;
    }
  }
`

const VehicleConditionCard = ({
  answer,
  texts,
  onChange,
  setChecked,
  name,
  ariaLabels,
  id,
  index,
  showErrors,
  value,
  subtitle,
  selectText,
  isoffline
}) => {
  const { brand } = useContext(AppContext)

  function onclickCard(e) {
    e.currentTarget.getElementsByTagName('input')[0].checked = true
    onChange(e.currentTarget.getElementsByTagName('input')[0], true)
  }

  return (
    <StyledVehicleConditionCard className={brand}>
      <div className='tradein-card-wrapper'>
        <StyledConditionCard>
          <div
            className={`tradein-card-content ${
              value === answer ? 'selected-box' : ''
            }`}
            onClick={onclickCard}
          >
            <div className='tradein-card-row'>
              <div className='tradein-card-header'>{answer}</div>
              <div className='tradein-card-subtitle'>{subtitle}</div>
              <div className='tradein-card-text'>{texts}</div>
            </div>
            <div className='tradein-card-select'>
              <label>
                <input
                  onChange={onChange}
                  id={id}
                  type='radio'
                  name='Select'
                  aria-label={ariaLabels}
                  value={isoffline ? index : answer}
                  aria-describedby={'error-' + name}
                  defaultChecked={setChecked(answer)}
                  className={`${!value && showErrors ? 'error ' : ''}`}
                />
                <span htmlFor={id}>{selectText || 'Select'}</span>
              </label>
            </div>
          </div>
        </StyledConditionCard>
      </div>
    </StyledVehicleConditionCard>
  )
}
export default VehicleConditionCard
