import React from 'react'

import { TradeInTerms } from './Copy'
import styled from 'styled-components'
import { mediaQueries, variables } from '../../styled-components/mediaQueries'

const StyledTnC = styled.div`
  & {
    margin-top: -40px;
    h1 {
      font-size: var(--fz-xxxl);
      line-height: 1.3;
    }
    h2 {
      font-size: var(--fz-lg2) !important;
      text-transform: none !important;
      text-decoration: underline;
    }
    p {
      font-size: var(--fz-sm2);
    }
    .header-wrapper {
      padding: 0px 40px 20px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media ${mediaQueries.underL} {
        padding: 40px 40px 20px;
      }
    }
    .header-img {
      width: 100px;
      cursor: pointer;
    }
    .content-wrapper {
      background: #f1f1f1;
      padding: 10px 40px 20px;
      box-sizing: border-box;
      height: auto;
    }
  }
`

const TermsAndConditionsUS = () => {
  return (
    <StyledTnC>
      <div className='header-wrapper'>
        <div>
          <a href='https://www.ford.com/' target='_blank'>
            <img
              src='/assets/ford-logo.png'
              title='Ford'
              alt='Ford Logo'
              id='header-logo'
              className='header-img'
            />
          </a>
        </div>
        <div>TRADE-IN</div>
      </div>
      <div className='content-wrapper'>
        <h1>Trade-In Program Terms and Conditions</h1>
        <h2>
          These Trade-In Program Terms and Conditions (“Terms”) contain
          important disclaimers and other provisions that limit our liability to
          you.
        </h2>
        <p>
          These Terms apply to your access to and use of the Ford eCommerce
          Trade-In Program module (“Program”) which is owned and/or administered
          by Ford Motor Company, One American Road, Dearborn, Michigan 48126
          United States of America (hereinafter “Ford”, “we”, “us”). These Terms
          do not alter in any way the terms of any other agreement you may have
          with us. Access to and use of the Program under these Terms is limited
          to the United States.
        </p>
        <h2>
          If you do not agree to these Terms or any modification of these Terms,
          then you must immediately stop using the Program.
        </h2>
        <p>
          The Program functionality depends on whether you are currently leasing
          or retail financing.
        </p>
        <ol type='1'>
          <li>
            <h2>PROGRAM - RETAIL FINANCING.</h2>
            <p>
              If you are financing your existing vehicle, the Program is
              tailored specifically so that you are provided with a fair
              trade-in appraisal (“Trade-In Appraisal”) for your vehicle. If
              your vehicle qualifies, you can apply your trade-in appraisal
              toward the purchase or lease of a new Ford or Lincoln vehicle
              subject to the Terms. Trade-In Appraisal is available for any
              qualified 2009 and later-model-year vehicle.
            </p>
            <p>
              Trade-In Appraisal is valid for seven days at a participating Ford
              dealer (“Participating Dealer”). This offer is not binding on
              dealers in the following states: Florida (FL), North Carolina (NC)
            </p>
            <p>
              Your Trade-In Appraisal is based on specific information about
              your vehicle, collected from your online questionnaire and a
              mandatory inspection at a Participating Dealer.
            </p>
            <p>
              Your vehicle must pass a mandatory vehicle inspection by a
              Participating Dealer. If the inspection report differs from your
              description or online assessment of your vehicle’s condition, the
              Participating Dealer may adjust the Trade-In Appraisal, which may
              mean decreasing the appraisal amount or disallowing the vehicle.
            </p>
            <p>
              Trade-In Appraisal may be combined with other offers. Customer
              must take delivery of any new previously untitled Ford or Lincoln
              vehicle or from a Participating Dealer.
            </p>
          </li>
          <ol type='I'>
            <li>
              <h3>Retail Financing Requirements.</h3>
            </li>
            <ol type='a'>
              <li>
                You must be 18 years of age or older or the age of majority, as
                determined by the laws of your state or territory of residency,
                in order to accept these Terms;{' '}
              </li>
              <li>
                You must be the registered owner of the vehicle in question;
              </li>
              <li>
                Vehicle cannot be titled in a business, retailer, bank or
                finance company name;
              </li>
              <li>
                You must possess a valid driver's license or other form of valid
                government-issued photo ID;
              </li>
              <li>
                The personal contact information and the information about your
                vehicle that you provide in response to our online questionnaire
                must be accurate, complete, and truthful; and
              </li>
              <li>
                Within seven (7) days of the issuance of your Trade-In
                Appraisal, you must deliver your vehicle, with a valid
                registration and either clear and unencumbered title in your
                name, or complete documentation regarding any liens on your
                vehicle (including, for example, contact information for your
                lender, relevant account information, and the current payoff
                amount for your vehicle) to a Participating Dealer. Your vehicle
                must be in the same condition as you have described and with no
                more than 250 additional miles on the odometer.
              </li>
              <li>
                The Participating Dealer will inspect your vehicle to confirm
                its condition and verify the accuracy of the other information
                about your vehicle that you submitted online. Based on the
                results of this verification inspection (more information below)
                reported by the Participating Dealer, your vehicle will be
                disallowed if your vehicle does not meet the vehicle criteria.{' '}
              </li>
            </ol>
            <li>
              <h3>Restrictions.</h3>
              <p>
                The Program is available only at a participating dealer. Not all
                vehicles are eligible for the Program. The Program may not be
                used to sell or to trade in certain categories of vehicles at
                the discretion of Ford, including, without limitation: dealer
                inventory; pre-2009 model year vehicles; select exotic vehicles;
                select commercial vehicles; fleet vehicles, vehicles subject to
                recall or investigation by a government agency; vehicles with a
                police or fire history; reconstructed or salvage vehicles;
                vehicles without a valid Vehicle Identification Number (VIN);
                vehicles not originally built for the United States market or
                that were imported illegally or outside of official manufacturer
                channels (i.e., &quot;gray market &quot;vehicles); vehicles
                originally sold in a foreign country; vehicles with altered
                drivelines or bodies or illegal or non-functioning emissions
                equipment; vehicles registered at an auto auction or offered for
                sale through any wholesale channel within 45 days; and vehicles
                that we would value at less than $2,500.
              </p>
            </li>
            <li>
              <h3>Vehicle Inspections.</h3>
              <ol type='a'>
                <li>
                  The Program is based on a vehicle being in safe operating
                  condition and free of existing collision damage which requires
                  panel and/or part(s) replacement.
                </li>
                <li>
                  Participating Dealers will conduct a mandatory vehicle
                  inspection to verify the accuracy of the vehicle description
                  and condition information that you provided to Ford online.
                  This verification inspection may include, for example, paint
                  thickness measurements, a road test, a mechanical inspection,
                  and a review of vehicle history reports and disclosure
                  statements regarding flood, salvage, or odometer discrepancies
                  (including rollbacks), and the like. The Participating Dealer
                  will submit the results of the verification inspection to
                  Ford. All decisions of the Participating Dealer regarding the
                  condition of your vehicle will be final.
                </li>
                <li>
                  Meeting any one of the following criteria will exclude the
                  vehicle from the Program:
                </li>
                <ol type='i'>
                  <li>
                    Vehicles that do not have a current registration, inspection
                    or emission sticker, that cannot be legally registered as a
                    passenger vehicle or that will not pass applicable state or
                    federal safety or emissions inspection;
                  </li>
                  <li>
                    Vehicles previously used for racing, emergency vehicle
                    applications or other extreme uses for which the vehicle was
                    not designed;
                  </li>
                  <li>
                    Vehicles where the odometer/speedometer has been replaced,
                    tampered with and/or is currently inoperative, or vehicles
                    with an undocumented odometer change, falsified mileage
                    accumulation, interrupted or limited odometer occurrence, or
                    any vehicle whose true mileage cannot be determined;
                  </li>
                  <li>
                    Vehicles with any condition that would require disclosure at
                    time of auction sale and/or on certificate of title,
                    including but not limited to, frame damage, flood damage, or
                    salvage vehicle;
                  </li>
                  <li>
                    Vehicles with branded titles for &quot;totaled &quot;,
                    &quot;salvage &quot;, &quot;rebuilt &quot;, &quot;Lemon Law
                    &quot;, &quot;flood &quot;, and/or &quot;water damage
                    &quot;regardless of their repaired or service status or
                    vehicles sold with wholesale or retail disclosures;
                  </li>
                  <li>
                    Vehicles that have been modified in such a way as to have
                    taken the vehicle out of compliance with any applicable
                    state or federal safety or emissions requirements;
                  </li>
                  <li>
                    Vehicles that have been abused or neglected resulting in
                    premature wear or damage or vehicles where it is evident
                    that the recommended scheduled maintenance has not been
                    performed as determined by an Authorized Ford Retailer;
                  </li>
                  <li>
                    Vehicles that do not have four (4) undamaged tires with each
                    one having at least 1/8-inch (4/32) tread at its lowest
                    point.
                  </li>
                  <li>
                    Vehicles with engine drivetrain, or other mechanical,
                    safety, or electrical parts that do not operate;
                  </li>
                  <li>
                    Vehicles that have sustained structural or unibody damage as
                    defined by industry standards whether repaired or
                    un-repaired;
                  </li>
                  <li>
                    Vehicles with environmental or applied chemical damage to
                    the finish such as hail, acid rain, etc.; and
                  </li>
                  <li>Vehicles with existing, un-repaired collision damage.</li>
                </ol>
                <li>
                  At the conclusion of your vehicle verification inspection, you
                  can apply your Trade-In Appraisal, subject to any applicable
                  adjustments after the inspection, by delivering your vehicle
                  on the spot or by returning your vehicle to your Participating
                  Dealer's location within seven (7) days in the same condition
                  and with no more than 250 additional miles on the odometer. If
                  your vehicle exceeds the 250 mile allowance, your vehicle no
                  longer qualifies for the appraisal from Ford and must be
                  revaluated through the Program or by your dealer.
                </li>
              </ol>
              <li>
                <h3>Equity in Your Vehicle.</h3>
                <p>
                  Equity in your trade-in vehicle is estimated based on the
                  difference between the estimated Trade-In Appraisal and any
                  amount still owing on your trade-in vehicle. Negative equity
                  generally means that you owe more on your trade-in vehicle
                  than it is currently worth. Positive equity generally means
                  that your trade-in vehicle is worth more than you currently
                  owe.
                </p>
                <p>
                  The Trade-In Appraisal of your vehicle (and therefore, the
                  equity you may have in your trade-in vehicle) is conditional
                  upon confirming your assessment of the vehicle via a physical
                  inspection of the vehicle, which may affect the actual value
                  offered as a trade-in amount. Please see your local dealer for
                  information regarding actual trade-in availability and value.
                </p>
              </li>
            </li>
          </ol>
          <li>
            <h2>PROGRAM – LEASING.</h2>
            <p>
              If you are leasing your existing vehicle, the Program is tailored
              specifically so that consumers are provided with an estimated
              amount that reflects a portion of your existing lease obligations
              (“Estimated Lease Obligation to Terminate Amount”) for your
              vehicle. If your vehicle qualifies, you can potentially finance
              this amount when you purchase or lease a new Ford or Lincoln
              vehicle subject to the Terms.{' '}
            </p>
            <p>
              The Estimated Lease Obligation to Terminate Amount is an estimate
              provided by you and your lender.
            </p>
            <ul>
              <li>
                If your lender is Ford Credit, your Estimated Lease Obligation
                to Terminate Amount represents: (i) your remaining monthly
                payments; (ii) any late charges you have incurred; and (iii) any
                extension fees you have incurred, all of which is reflected as
                of the date you generate this estimate. It does not include
                other potential charges, including but not limited to
                disposition fees, excess mileage charges, excess wear and use
                charges, and any associated taxes. Please see your lease
                agreement for details and discuss your options with your dealer
                or Ford Credit.
              </li>
              <li>
                If your lender is not Ford Credit, your Estimated Lease
                Obligation to Terminate Amount is likely to include your
                remaining monthly payments as of the date you generate this
                estimate, but may not include any other additional charges,
                including but not limited to late charges, extension fees,
                disposition fees, excess mileage charges, excess wear and use
                charges, and any associated taxes. Please see your lease
                agreement for details and discuss your options with your dealer
                or leasing company.
              </li>
            </ul>
            <p>
              Additionally, the Program provides you an opportunity to enter
              your own Estimated Lease Obligation to Terminate Amount. Please
              note that if you enter your own Estimated Lease Obligation to
              Terminate Amount, you are responsible for the accuracy of this
              amount.
            </p>
            <p>
              Keep in mind that your lease agreement may provide for additional
              early termination options that may be less expensive than your
              Estimated Lease Obligation to Terminate Amount.
            </p>
          </li>
          <li>
            <h2>WAIVER.</h2>
            <p>
              Any amounts still owing on your trade-in vehicle or reflected in
              your Estimated Lease Obligation to Terminate Amount are subject to
              change based on your existing financing and leasing obligations.
              For purposes of this Program, we use information provided by you
              and your lender/finance source to reflect the amount you still owe
              on your vehicle. While we believe this information is reliable, we
              are not responsible for and do not guarantee the accuracy or
              reliability of the information. Please see your lender/finance
              source for information regarding your actual payoff amount.
            </p>
          </li>
          <li>
            <h2>INDEMNITY AND HOLD HARMLESS.</h2>
            <p>
              By participating in the Program you agree that you will defend,
              indemnify and hold harmless Ford and its related companies and
              each of their respective directors, officers and employees,
              agents, licensors, successors or assigns (collectively, “Ford
              Group”) from and against all liabilities, claims, damages and
              expenses (including reasonable attorneys’ fees and costs) arising
              out of any use of the Program by or through you including any of
              its features or services, your breach or alleged breach of these
              Terms, and any and all claims relating to or arising out of any
              vehicle sale or lease transaction that you enter into with a
              Participating Dealer in connection with the Program. In the event
              that any claims or disputes arise out of such a transaction, you
              agree to look solely to your Participating Dealer for your remedy
              and not the Ford Group. Ford reserves the right to assume the
              exclusive defense and control of any matter otherwise subject to
              indemnification by you, and, in such case, you shall agree to
              cooperate with our defense of such claim.
            </p>
          </li>
          <li>
            <h2>MODIFICATIONS AND TERMINATION OF PROGRAM.</h2>
            <p>
              Ford reserves the right at any time, and from time to time to
              modify, update, suspend or discontinue all or part of the Program
              for any reason with or without notice to you. All terminations
              will be made in Ford's sole discretion and you agree that Ford
              will not be liable for any termination of your use of or access to
              the Program.
            </p>
          </li>
          <li>
            <h2>NO ILLEGAL USE.</h2>
            <p>
              As a condition of your use of and access to the Program, you agree
              not to use the Program for any unlawful purpose or in any way that
              violates these Terms. You also agree not to use the Program in any
              way that could damage, disable, overburden, or impair Ford.com or
              any Ford server, or the associated networks, or interfere with any
              other party's use and enjoyment of any Program.
            </p>
          </li>
        </ol>
      </div>
    </StyledTnC>
  )
}

export default TermsAndConditionsUS
