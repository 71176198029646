import React from 'react'

import { TradeInTerms } from './Copy'
import styled from 'styled-components'
import { mediaQueries, variables } from '../../styled-components/mediaQueries'

const StyledTnC = styled.div`
  & {
    margin-top: -40px;
    h1 {
      font-size: var(--fz-xxxl);
      line-height: 1.3;
    }
    h2 {
      font-size: var(--fz-lg2) !important;
      text-transform: none !important;
      text-decoration: underline;
    }
    p {
      font-size: var(--fz-sm2);
    }
    .header-wrapper {
      padding: 40px 40px 20px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .header-img {
      width: 100px;
      cursor: pointer;
    }
    .content-wrapper {
      background: #f1f1f1;
      padding: 10px 40px 20px;
      box-sizing: border-box;
      height: auto;
    }
  }
`

const fusionRecallTnC = () => {
  return (
    <StyledTnC>
      <div className='header-wrapper'>
        <div>
          <a href='https://www.ford.com/' target='_blank'>
            <img
              src='/assets/ford-logo.png'
              title='Ford'
              alt='Ford Logo'
              id='header-logo'
              className='header-img'
            />
          </a>
        </div>
        <div>TRADE-IN</div>
      </div>
      <div className='content-wrapper'>
        <h1>Terms and Conditions</h1>
        <h2>
          These Terms and Conditions (“Terms”) contain important disclaimers and
          other provisions.
        </h2>
        <p>
          These Terms apply to your access to and use of this website and Ford’s
          Recall 23S33 Vehicle Refund Estimator (“Tool”), which is owned and/or
          administered by Ford Motor Company, One American Road, Dearborn,
          Michigan 48126 United States of America (hereinafter “Ford”, “we”,
          “us”). These Terms do not alter in any way the terms of any other
          agreement you may have with us. Access to and use of this website and
          the Tool under these Terms is limited to the United States.
        </p>
        <ol type='1'>
          <li>
            <h2>Recall 23S33 Vehicle Refund Estimator (“Tool”)</h2>
            <p>
              The Tool is tailored specifically so that you are provided with an
              estimated refund amount for your vehicle. The “refund amount” is
              an estimate based on fair market value of your vehicle, plus a 15%
              premium. “Fair market value” is based on the original
              Manufacturer’s Suggested Retail Price (“MSRP”), less depreciation
              as determined using regional market data, and does not include the
              cost of any modifications made to the vehicle after the first
              retail sale.
            </p>
            <p>
              Amounts may fluctuate based on various factors, including market
              fluctuation and vehicle mileage. Your official statutory refund
              amount will be based on specific information about your vehicle.
              To lock in your official statutory refund amount, contact the Ford
              Customer Relationship Center at 1-833-807-3673 before July 1,
              2025, when a sufficient quantity of high voltage batteries are
              expected to be available for the final remedy repair.
              Representatives are available Monday through Friday: 8:30 AM –
              5:30 PM (Eastern Time).
            </p>
          </li>
          <ol type='a'>
            <li>
              <h3>Requirements.</h3>
            </li>
            <ol type='i'>
              <li>
                You must be the registered owner of the vehicle in question and
                the vehicle must be an unremedied vehicle included in Recall
                23S33;
              </li>
              <li>
                You must possess a valid driver's license or other form of valid
                government-issued photo ID;
              </li>
              <li>
                You must provide a current vehicle registration showing you as
                the registered owner and either an unencumbered title in your
                name, or complete documentation regarding any liens on your
                vehicle (including, for example, contact information for your
                lender, relevant account information, and the current payoff
                amount for your vehicle);
              </li>
              <li>
                The personal contact information and the information about your
                vehicle that you provide must be accurate, complete, and
                truthful; and
              </li>
              <li>
                Within ninety (90) days of the acceptance of your statutory
                refund offer, you must deliver your vehicle to a Ford or Lincoln
                Dealer to retain your locked in refund amount.
              </li>
              <li>
                The Ford or Lincoln Dealer and Ford will verify the accuracy of
                the information about your vehicle that you submitted.
              </li>
              <li>
                To lock in your official statutory refund amount, contact the
                Ford Customer Relationship Center at 1-833-807-3673 before July
                1, 2025, when a sufficient quantity of high voltage batteries
                are expected to be available for the final remedy repair.
                Representatives are available Monday through Friday: 8:30 AM –
                5:30 PM (Eastern Time).
              </li>
            </ol>
          </ol>
          <li>
            <h2>WAIVER</h2>
            <p>
              For purposes of this Tool, we use information provided by you.
              While we believe this information is reliable, we are not
              responsible for and do not guarantee the accuracy or reliability
              of the information.
            </p>
            <li>
              <h2>MODIFICATIONS AND TERMINATION OF TOOL</h2>
              <p>
                Ford reserves the right at any time, and from time to time to
                modify, update, suspend or discontinue all or part of the Tool
                for any reason with or without notice to you. All terminations
                will be made at Ford's sole discretion and you agree that Ford
                will not be liable for any termination of your use of or access
                to the Tool.
              </p>
              <li>
                <h2>NO ILLEGAL USE</h2>
                <p>
                  As a condition of your use of and access to the Tool, you
                  agree not to use the Tool for any unlawful purpose or in any
                  way that violates these Terms. You also agree not to use the
                  Tool in any way that could damage, disable, overburden, or
                  impair Ford.com or any Ford server, or the associated
                  networks, or interfere with any other party's use and
                  enjoyment of any Tool.
                </p>
              </li>
            </li>
          </li>
        </ol>
      </div>
    </StyledTnC>
  )
}

export default fusionRecallTnC
