import React from 'react'
export const StepHeaders = {
  es_es: {
    main: 'Tu valoración del vehículo usado',
    sub: 'Valor estimado del vehículo en la fecha de entrega de tu nuevo vehículo'
  },
  en_gb: {
    main: 'Your trade-in appraisal:',
    sub: 'The projected value of your current car at the estimated delivery date of your new vehicle'
  },
  fr_fr: {
    main: 'Votre estimation de reprise:',
    sub: 'Valeur estimative à la date de livraison de votre nouveau véhicule'
  },
  it_it: {
    main: 'ECCO LA VALUTAZIONE DEL TUO VEICOLO',
    sub: 'Valutazione del tuo veicolo alla data di consegna del veicolo nuovo:'
  },
  nl_nl: {
    main: 'Indicatieve Taxatiewaarde:',
    sub: 'Indicatieve waarde als jouw auto wordt geleverd'
  },
  de_de: {
    main: 'Geschätzter Inzahlungnahme-Wert',
    sub: 'Zur Zeit beträgt der geschätzte Wert Ihres Fahrzeugs:'
  }
}
export const TradeinAppraisalCopy = {
  en_gb: {
    valuationText: 'The current valuation is',
    validUntilText: (filter, date, tcUrl) => (
      <p>
        Trade-In Appraisal valid until {filter(date)} – see Trade-In Program{' '}
        <a href={tcUrl} rel='noopener noreferrer' target='_blank'>
          Terms and Conditions
        </a>{' '}
        for additional details and restrictions.
      </p>
    ),
    toolTipText: `If your current vehicle is on finance, you are responsible for settling the finance 
                  in full before you part exchange the vehicle.`,
    remainingBalRadioText: {
      ariaLabels: [
        'Select if you still owe a balance on your vehicle',
        'Select if you do not owe a balance on your vehicle'
      ],
      label: 'Do you still owe a balance on your vehicle?',
      options: ['Yes', 'No']
    },
    remainingBalanceInputLabel: 'Remaining Balance',
    applyFinanceRadioText: {
      ariaLabels: [
        'Select if you would like to apply this value to your finance',
        'Select if you would like to receive this value as a cash payout'
      ],
      label:
        'Would you like to apply this value to your purchase or receive a cash payout?',
      options: ['Apply to finance', 'Receive cash payout'],
      error: 'This answer is required to move forward'
    },
    financeAdvisoryText:
      'If your current vehicle is on finance, you are responsible for settling the finance in full before you part exchange the vehicle',
    appraisalLegalText:
      'Please make sure the figure you provide us in relation to your existing finance is accurate as it can affect your Ford Options quote.',
    appraisalValueText: 'Your Appraisal Value',
    balanceOwedText: 'Balance Owed on Vehicle',
    netValueText: 'Net value',
    netValueToolTip: 'This is the value of your trade-in less any finance owed',
    overbalanceError:
      "The amount outstanding on your finance agreement exceeds the trade-in value of your vehicle. Click 'Continue without trade-in' to continue with your finance application.",
    continueButtonText: 'Continue without trade-in',
    continueButtonAriaLabel:
      'Click here to continue to the basket without your trade-in',
    showroomButtonText: 'Continue',
    forwardButtonText: 'Accept & Add to Cart',
    forwardButtonAriaLabel:
      'Click here to accept this valuation and add it to your basket',
    declineButtonText: 'Decline & Close',
    declineButtonAriaLabel:
      'Click here to decline this valuation and close the appraisal'
  },
  fr_fr: {
    valuationText: 'Aujourd’hui, votre estimation est:',
    validUntilText: (filter, date, tcUrl) => (
      <p>
        Estimation de reprise valable jusqu'au {filter(date)} – Veuillez
        consulter les{' '}
        <a href={tcUrl} rel='noopener noreferrer' target='_blank'>
          Conditions générales d'utilisation
        </a>{' '}
        pour plus de détails.
      </p>
    ),
    toolTipText: `La valorisation de votre véhicule sera confirmée lors de sa contre-expertise en 
                 concession lors de la livraison du véhicule neuf. N’hésitez pas à contacter des à 
                  présent votre concessionnaire pour de plus amples informations.`,
    remainingBalTooltipAriaLabel: "Plus d'information",
    remainingBalRadioText: {
      ariaLabels: [
        "Sélectionnez 'Oui' si vous avez un financement en cours sur ce véhicule.",
        "Sélectionnez 'Non' si vous n'avez pas de financement en cours sur ce véhicule."
      ],
      label: 'Avez-vous un financement en cours sur ce véhicule ?',
      options: ['Oui', 'Non'],
      errorMessage: 'Cette réponse est nécessaire pour continuer'
    },
    remainingBalanceInputLabel: 'Restant dû',
    remainingBalanceInputError: 'Veuillez entrer la valeur',
    applyFinanceRadioText: {
      ariaLabels: [
        'Sélectionnez cette option pour utiliser ce montant pour votre demande de financement ',
        'Sélectionnez cette option pour recevoir un paiement en espèces.'
      ],
      label:
        'Souhaitez-vous utiliser cette somme pour réduire le montant à payer de votre nouveau véhicule ? ​Ce montant est une estimation et fera l’objet d’une confirmation après contre-expertise de votre véhicule en concession.​',
      options: [
        'Utiliser comme apport du financement',
        'Déduire du montant de la commande'
      ],
      error: 'Cette réponse est nécessaire pour continuer'
    },
    financeAdvisoryText:
      'Si vous avez un financement en cours sur ce véhicule, vous devez le solder. Le véhicule à reprendre devant être libre de tout gage.',
    appraisalLegalText:
      'Si vous avez un financement en cours sur ce véhicule, vous devez le solder. Le véhicule à reprendre devant être libre de tout gage.',
    appraisalValueText: 'Valeur de reprise',
    balanceOwedText: 'Restant dû',
    netValueText: 'Plus-value générée',
    netValueToolTip:
      "Il s'agit de la valeur de reprise de votre véhicule minoré de tout restant dû à payer.",
    netValueToolTipAriaLabel: "Plus d'information",
    overbalanceError:
      'Le montant restant dû sur votre ancien contrat de financement dépasse la valeur de reprise de votre véhicule. Cliquez sur "Retour au panier" pour poursuivre votre demande de financement.',
    continueButtonText: 'Retour au panier',
    continueButtonAriaLabel:
      'Cliquez ici pour continuer vers votre panier sans effectuer de reprise',
    showroomButtonText: 'Continue',
    forwardButtonText: 'Accepter & ajouter au panier',
    forwardButtonAriaLabel: 'Cliquez ici pour accepter et ajouter au panier.',
    declineButtonText: 'Retour',
    declineButtonAriaLabel: 'Cliquez ici pour revenir en arrière.'
  },
  es_es: {
    valuationText: 'Hoy el valor estimado de tu vehículo es:',
    validUntilText: (filter, date, tcUrl) => (
      <p>
        Valor estimado válido hasta {filter(date)}. Por favor consulta nuestro{' '}
        <a href={tcUrl} rel='noopener noreferrer' target='_blank'>
          Términos y Conditiones
        </a>{' '}
        para más información.
      </p>
    ),
    toolTipText: `El valor de su vehículo usado se confirmará después de una evaluación presencial durante la entrega de su nuevo vehículo. Por favor, póngase en contacto con su concesionario si necesita más información.`,
    remainingBalTooltipAriaLabel: 'Más información',
    remainingBalRadioText: {
      ariaLabels: [
        'Seleccione si tiene un contrato de financiación pediente de liquidar sobre su vehículo',
        'Seleccione si  no tiene un contrato de financiación pediente de liquidar sobre su vehículo'
      ],
      label: '¿Tu vehiculo actual está financiado?',
      options: ['Sí', 'No'],
      errorMessage: 'Cette réponse est nécessaire pour continuer'
    },
    remainingBalanceInputLabel: 'Saldo restante',
    remainingBalanceInputPlaceholder: 'Por ejemplo: 3.000 Eur',
    remainingBalanceInputError: 'Por favor, confirme la cantidad',
    applyFinanceRadioText: {
      ariaLabels: [
        'Seleccione este botón si desea utilizar el valor canje contra el saldo de su nuevo vehículo',
        'Seleccione este botón si desea recibir un pago en efectivo por el valor de su vehículo usado'
      ],
      label:
        '¿Te gustaría descontar este importe de tu compra o prefieres recibir el pago en efectivo?',
      options: ['Descontar de la financiación ', 'Recibir pago en efectivo'],
      error: 'Cette réponse est nécessaire pour continuer'
    },
    financeAdvisoryText:
      'Si tu vehículo actual está financiado, debes liquidar la financiación en su totalidad antes de realizar la entrega de tu vehículo a cambio.',
    appraisalLegalText:
      'Si tu vehículo actual está financiado, debes liquidar la financiación en su totalidad antes de realizar la entrega de tu vehículo a cambio.',
    appraisalValueText: 'Valor del vehículo usado',
    balanceOwedText: 'Saldo pendiente de pago',
    netValueText: 'Valor neto',
    netValueToolTip:
      'Este es el valor neto a pagar por su vehículo, despues de deducir el valor su vehículo usado',
    netValueToolTipAriaLabel: 'Más información',
    overbalanceError:
      'El importe de su saldo pendiente es mayor que el valor de canje de su vehículo. Haga clic en "Volver a la cesta" para continuar su compra sin su vehículo usado',
    continueButtonText: 'Volver a la cesta',
    continueButtonAriaLabel:
      'Cliquez ici pour continuer vers votre panier sans effectuer de reprise',
    showroomButtonText: 'Continue',
    forwardButtonText: 'Aceptar y añadir a la compra',
    forwardButtonAriaLabel: 'Pincha aquí para aceptar y añadir a la cesta',
    declineButtonText: 'Rechazar y cerrar',
    declineButtonAriaLabel: 'Cliquez ici pour revenir en arrière.'
  },
  it_it: {
    valuationText: 'La valutazione del tuo veicolo oggi:',
    validUntilText: (filter, date, tcUrl) => (
      <p>
        La valutazione è valida fino al {filter(date)} – Consulta{' '}
        <a href={tcUrl} rel='noopener noreferrer' target='_blank'>
          Termini & Condizioni
        </a>{' '}
        per avere maggiori informazioni.
      </p>
    ),
    toolTipText: `La valutazione del tuo veicolo verrà confermata dal Ford Partner dopo aver verificato lo stato d'uso. Per maggiori informazioni puoi contattare il Ford Partner.`,
    taxDisclaimerText: (
      <React.Fragment>
        <p>
          La presente valutazione è una stima ottenuta tramite un sistema
          automatico, che assegna un valore medio calcolato sull’analisi delle
          quotazioni di veicoli uguali e/o simili presenti nel mercato italiano.
          Ti invitiamo a contattare il tuo Ford Partner per fissare un
          appuntamento e per ricevere una valutazione più precisa e dettagliata
          del tuo veicolo.
        </p>
        <p>
          La presente valutazione esclude il costo del passaggio di proprietà in
          favore del Ford Partner del valore di € 150 che ti verrà addebitato al
          completamento dell’operazione.
        </p>
      </React.Fragment>
    ),
    remainingBalRadioText: {
      ariaLabels: [
        'Seleziona se hai un finanziamento in corso sul veicolo.',
        'Seleziona se non hai un finanziamento in corso sul veicolo.'
      ],
      label: 'E’ presente un finanziamento attivo sul tuo veicolo?',
      options: ['Si', 'No'],
      errorMessage: 'Cette réponse est nécessaire pour continuer'
    },
    remainingBalanceInputLabel: 'Es. € 3.000',
    remainingBalanceInputError: "Confermare l'importo",
    applyFinanceRadioText: {
      ariaLabels: [
        'Seleziona questa opzione se vuoi utilizza il valore della tua permuta come anticipo per la nuova Ford ',
        'Seleziona questa opzione se vuoi ricevere il pagamento del tuo veicolo usato'
      ],
      label:
        "Desidera utilizzare il valore dell'usato come anticipo per il suo finanziamento?",
      options: ['Si', 'No'],
      error: 'Cette réponse est nécessaire pour continuer'
    },
    financeAdvisoryText:
      'Devi saldare qualsiasi finanziamento in sospeso su questo veicolo. Il veicolo che desideria dare in permute non deve essere soggetto a vincoli finanziari.',
    appraisalLegalText:
      'Devi saldare qualsiasi finanziamento in sospeso su questo veicolo. Il veicolo che desideria dare in permute non deve essere soggetto a vincoli finanziari.',
    appraisalValueText: 'Valutazione permuta',
    balanceOwedText: 'Estinzione finanziamento',
    netValueText: 'Valore netto',
    netValueToolTip:
      "Questo è l'effettivo importo da pagare per la tua nuova Ford, al netto del valore della tua permuta.",
    netValueToolTipAriaLabel: 'Maggiori informazioni',
    overbalanceError:
      'L\'importo del saldo in sospeso è superiore al valore della permuta del tuo veicolo. Fai clic su "Torna al carrello" per continuare l\'acquisto senza la permuta.',
    continueButtonText: 'Torna al carrello',
    continueButtonAriaLabel: 'Continua senza valutazione della permutao',
    showroomButtonText: 'Continue',
    forwardButtonText: 'Accetta e aggiungi al carrello',
    forwardButtonAriaLabel:
      'Clicca qui per accettare ed aggiungere al carrello',
    declineButtonText: 'Indietro',
    declineButtonAriaLabel: 'Clicca qui per tornare indietro'
  },
  nl_nl: {
    valuationText: 'De huidige waarde is',
    validUntilText: (filter, date, tcUrl) => (
      <p>
        Taxatiewaarde geldig tot {filter(date)} – zie{' '}
        <a href={tcUrl} rel='noopener noreferrer' target='_blank'>
          Algemene voorwaarden
        </a>{' '}
        voor additionele details over het Inruilvoertuig.
      </p>
    ),
    toolTipText: `Als jouw huidige auto gefinancierd wordt, ben je verantwoordelijk voor de financiële 
                  afwikkeling, voordat je je auto gaat inruilen.`,
    remainingBalTooltipAriaLabel: 'Meer informatie',
    remainingBalRadioText: {
      ariaLabels: [
        'Kies "ja" als er nog een bedrag verschuldigd is op jouw auto',
        'Kies "nee" als er geen bedrag verschuldigd is op jouw auto'
      ],
      label: 'Is er nog een bedrag verschuldigd op jouw auto?',
      options: ['Ja', 'Nee'],
      errorMessage: 'Dit antwoord is vereist om door te gaan'
    },
    remainingBalanceInputLabel: 'Openstaand bedrag',
    remainingBalanceInputPlaceholder: 'Bijvoorbeeld € 2.800,-',
    remainingBalanceInputError: 'Voer alstublieft een waarde in',
    applyFinanceRadioText: {
      label:
        'Wilt u dit bedrag gebruiken als aanbetaling in uw financieringsaanvraag?',
      options: ['Ja', 'Nee'],
      error: 'Dit antwoord is vereist om door te gaan'
    },
    financeAdvisoryText:
      'Als jouw huidige auto gefinancierd is, ben jij verantwoordelijk voor de volledige afwikkeling van de financiering voordat je auto wordt ingeruild.',
    appraisalLegalText:
      'Zorg ervoor dat het te nemen saldo dat jij ons verstrekt met betrekking tot jouw bestaande financiering correct is, aangezien dit van invloed kan zijn op jouw offerte.',
    appraisalValueText: 'De waarde van jouw taxatie',
    balanceOwedText: 'Verschuldigd bedrag op jouw auto',
    netValueText: 'Nettowaarde',
    netValueToolTip:
      'Dit is de waarde van je auto verminderd met de waarde wat je nog verschuldigd bent',
    netValueToolTipAriaLabel: 'Meer informatie',
    overbalanceError:
      'Het resterende bedrag op uw oude financieringscontract is hoger dan de inruilwaarde van uw voertuig. Klik op "Doorgaan zonder inruilwaarde" om verder te gaan met je bestelling.',
    continueButtonText: 'Doorgaan zonder inruilwaarde',
    continueButtonAriaLabel:
      'Klik op deze button als je door wilt gaan zonder een inruilwaarde toe te voegen aan je overeenkomst',
    showroomButtonText: 'Doorgaan',
    forwardButtonText: 'Accepteer en voeg toe aan winkelwagen',
    forwardButtonAriaLabel: '',
    declineButtonText: 'Weigeren en sluiten',
    declineButtonAriaLabel: ''
  },
  de_de: {
    valuationText: 'Zur Zeit beträgt der geschätzte Wert Ihres Fahrzeugs:',
    validUntilText: (filter, date, tcUrl) => (
      <p>
        Die Fahrzeugbewertung ist gültig bis {filter(date)} – Für weitere
        Informationen lesen Sie bitte{' '}
        <a href={tcUrl} rel='noopener noreferrer' target='_blank'>
          unsere Allgemeinen Geschäftsbedingungen
        </a>{' '}
        für die Inzahlungnahme von Fahrzeugen.
      </p>
    ),
    toolTipText: `Der Wert der Inzahlungnahme Ihres Fahrzeugs wird nach einer Vor-Ort-Bewertung bei der Auslieferung Ihres neuen Fahrzeugs bestätigt. Bitte setzen Sie sich mit Ihrem Ford Partner in Verbindung, wenn Sie weitere Informationen benötigen.`,
    remainingBalTooltipAriaLabel: 'Mehr Informationen',
    remainingBalRadioText: {
      ariaLabels: [
        'Dieses Feld auswählen, falls Sie eine laufende Finanzierungsvereinbarung für dieses Fahrzeug haben',
        'Dieses Feld auswählen, falls Sie keine laufende Finanzierungsvereinbarung für dieses Fahrzeug haben'
      ],
      label:
        'Haben Sie eine laufende Finanzierungsvereinbarung für dieses Fahrzeug?',
      options: ['Ja', 'Nein'],
      errorMessage: 'Dit antwoord is vereist om door te gaan'
    },
    remainingBalanceInputLabel: 'Verbleibender Betrag',
    remainingBalanceInputPlaceholder: 'bsp. € 3.000,00',
    remainingBalanceInputError:
      'Diese Antwort ist erforderlich, um fortzufahren',
    applyFinanceRadioText: {
      ariaLabels: [
        'Dieses Feld auswählen, wenn Sie wünschen, dass der Wert Ihrer Gebrauchtwagens mit dem Kaufpreis Ihres neuen Fahrzeugs verrechnet wird',
        'Dieses Feld auswählen, wenn Sie eine Auszahlung des Inzahlungnahmebetrages wünschen'
      ],
      label:
        'Möchten Sie, dass der Wert Ihrer Inzahlungnahme mit dem Kaufpreis Ihres neuen Fahrzeugs verrechnet wird? Der gezeigte Betrag ist eine Schätzung und wird im Rahmen einer vor-Ort-Bewertung Ihres Fahrzeugs bestätigt.',
      options: [
        'Inzahlungnahme mit dem Kaufpreis Ihres Neuwagens verrechnen',
        'Inzahlungnahme-Wert auszahlen lassen'
      ],
      error: 'Dit antwoord is vereist om door te gaan'
    },
    financeAdvisoryText:
      'Es muss sichergestellt sein, dass alle offenen Finanzierungen für dieses Fahrzeug beglichen sind. Um das Fahrzeug in Zahlung zu geben, muss dieses frei von jeglichen Finanzierungsforderungen sein.',
    appraisalLegalText:
      'Zorg ervoor dat het te nemen saldo dat jij ons verstrekt met betrekking tot jouw bestaande financiering correct is, aangezien dit van invloed kan zijn op jouw offerte.',
    appraisalValueText: 'Inzahlungnahme-Wert',
    balanceOwedText: 'Verbleibender Betrag',
    netValueText: 'Restbetrag',
    netValueToolTip:
      'Dies ist der zu zahlende Restbetrag für Ihren Neuwagen nach Abzug eines eventuellen Inzahlungnahme-Werts für Ihren Gebrauchtwagen.',
    netValueToolTipAriaLabel: 'Mehr Informationen',
    overbalanceError:
      'Der verbleibende Betrag ist höher als der Inzahlungnahmewert Ihres Fahrzeugs. Klicken Sie auf "Zurück zum Warenkorb", um den Kauf ohne Inzahlungnahme fortzusetzen.',
    continueButtonText: 'Zurück zum Warenkorb',
    continueButtonAriaLabel:
      'Hier klicken, um zuzustimmen und zum Warenkorb hinzuzufügen',
    showroomButtonText: 'Fortsetzen',
    forwardButtonText: 'an Händler senden',
    forwardButtonAriaLabel:
      'Hier klicken, um zuzustimmen und zum Warenkorb hinzuzufügen',
    declineButtonText: 'Zurück',
    declineButtonAriaLabel: 'Hier klicken, um zurück zur Übersicht zu gelangen',
    disclaimerHeader: 'INZAHLUNGNAHME GEBRAUCHTWAGEN',
    disclaimerText:
      'Falls Sie erwägen, einen Gebrauchtwagen in Zahlung zu geben, können Sie über den Ford Online Store unter Angabe der Eckdaten des Fahrzeugs beim von Ihnen ausgewählten Händler einen Vor-Ort-Termin anfragen, in dessen Rahmen dann eine verbindliche Gebrauchtwagenbegutachtung vorgenommen werden kann.'
  }
}
