import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from '../context/AppContext'
import ReactSelect, { components } from 'react-select'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tooltip from '../pageElements/Tooltip'

const SelectInputWrap = styled.div`
  height: 100px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  outline-offset: var(outline-offset);
  &.ford {
    .tradein-selectInput-label {
      font-size: var(--fz-sm);
      color: var(--fordLightDefault) !important;
      margin-bottom: 4px;
    }
    .react-select__control {
      border-radius: 8px;
      border: 1px solid var(--fordInputDefault);
      svg {
        fill: var(--fordLightDefault);
      }
      &.react-select__control--menu-is-open,
      &.react-select--is-focused {
        border: 2px solid var(--inputHighlightblue);
      }
      
    .react-select__control--is-focused {
      border: 2px solid var(--inputHighlightblue);
    }
    .react-select__menu {
      top: 45px;
      margin-top: 0;
      .react-select__menu-list {
        border: 2px solid var(--focusBlue);
        padding-top: 8px;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .tradein-selectInput-label,
    .react-select__value-container {
    }
    .css-1dimb5e-singleValue {
      color: var(--fordNeutralBlack);
    }
  }

  .select-wrapper {
    display: flex;
  }
  &:focus {
    border: 1px solid var(--primary);
  }
`

const SelectInputLabel = styled.label`
  font-family: var(--fontPrimary);
  font-size: var(--fz-xs);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  &.errorlabel {
    color: var(--red);
  }
`
const SelectElement = styled(ReactSelect)`
  color: var(--secondary);
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-size: var(--fz-md);
  text-align: left;

  & .react-select__control {
    min-height: 40px;
  }

  & .react-selectInput-label {
    font-family: var(--fontPrimary);
    font-size: var(--fz-xs);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
  }
  & .react-select__menu-list {
    z-index: 4;
    border-radius: 8px;
    color: var(--secondary);
    max-height: 260px;
    .react-select__option--is-focused,
    .react-select__option--is-selected {
      color: white;
      background: var(--borderGrey);
    }
  }

  & .react-select__placeholder {
    padding-top: 0px;
    color: var(--primary);
  }

  & .react-select__dropdown-indicator {
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 4px;
    & svg {
      color: var(--textGrey);
      height: 30px;
      width: 30px;
    }
  }
`

const ErrorMessage = styled.span`
  font-size: var(--fz-xs);
  font-family: var(--fontPrimary);
  color: var(--red);
  padding: 0 0 8px 5px;
  text-align: left;
  svg {
    margin-right: 5px;
    path {
      fill: var(--red);
    }
  }
`

const SelectInput = ({
  label,
  labelReader,
  list,
  isDisabled,
  value,
  onSelect,
  placeholder,
  errorMessage,
  valid,
  errorId,
  initialValue,
  customOnSelect,
  selectId,
  tooltip
}) => {
  const [showError, setShowError] = useState(false)
  const inputId = selectId || label || placeholder
  const parentId = inputId + '_parent_element'
  const { brand, market } = useContext(AppContext) || ''

  function handleFocus(e) {
    const borderElem = document.getElementById(parentId).parentNode.parentNode
    if (borderElem) {
      if (brand === 'lincoln') {
        // on focus, add border highlight for accessibility
        borderElem.style.outline = 'var(--fds-outline)'
      } else {
        e.target.closest('.tradein-selectInput-wrap').children[0].style.color =
          'var(--focusBlue)'
      }
    }

    if (typeof initialValue === 'object') {
      if (e.relatedTarget && !initialValue.value) {
        setShowError(true)
      }
    } else if (e.relatedTarget && !initialValue) {
      setShowError(true)
    }
  }

  function handleBlur(e) {
    const borderElem = document.getElementById(parentId).parentNode.parentNode
    if (borderElem) {
      // on end of focus, remove border highlight
      if (brand === 'lincoln') {
        borderElem.style.outline = ''
      } else {
        e.target.closest('.tradein-selectInput-wrap').children[0].style.color =
          'inherit'
      }
    }
    if (typeof initialValue === 'object') {
      if (initialValue.value && initialValue.label) {
        setShowError(false)
      }
    } else {
      if (!initialValue) {
        setShowError(true) // on first blur, display error messages
      }
    }
  }


  function handleSelect(e) {
    if (customOnSelect) {
      customOnSelect(e)
      onSelect(e)
      setShowError(true)
    }
    onSelect(e)
  }

  useEffect(() => {
    const selectElements = document.querySelectorAll('.react-select__input')
    selectElements.forEach((ele) => {
      if (ele.firstChild) {
        ele.firstChild.setAttribute(
          'aria-describedby',
          'error-' + ele.firstChild.id
        )
        ele.firstChild.setAttribute('aria-required', 'true')
      }
    })
  })

  const fordUS = brand === 'ford' ? true : false

  const customMenuStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: '1px dotted pink',
      color: state.selectProps.menuColor,
      padding: 20
    })
  }
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M12 16.8L19.2 9.6L18.36 8.76L12 15.12L5.63999 8.76L4.79999 9.6L12 16.8Z'
            fill='#1F3047'
          />
        </svg>
      </components.DropdownIndicator>
    )
  }

  return (
    <SelectInputWrap className={`tradein-selectInput-wrap ${brand} ${market}`}>
      <SelectInputLabel
        className={`tradein-selectInput-label ${
          !valid && showError && !fordUS ? 'errorlabel' : ''
        } `}
        aria-label={labelReader || label || placeholder}
        htmlFor={inputId}
      >
        {label}
      </SelectInputLabel>
      <div className='select-wrapper'>
        <SelectElement
          aria-label={`${label}id`}
          id={parentId}
          classNamePrefix='react-select'
          className='tradein-select-inputControl'
          isDisabled={isDisabled}
          options={list}
          value={value}
          defaultValue={initialValue}
          onChange={handleSelect}
          placeholder={placeholder}
          isSearchable
          menuPlacement='auto'
          inputId={inputId}
          onFocus={handleFocus}
          onBlur={handleBlur}
          styles={{
            control: (base) => ({
              ...base,
              borderColor: `var(--primary)`,
              boxShadow: 'none',
              '&:hover': {
                border: '1px solid black'
              }
            }),
            dropdownIndicator: (base, state) => ({
              ...base,
              transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
            })
          }}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator
          }}
        />
        {tooltip && <Tooltip tooltipBody={tooltip} className='tradein-iball' />}
      </div>

      {!valid && errorMessage && showError && !fordUS && (
        <ErrorMessage
          id={'error-' + inputId}
          role='alert'
          aria-live='assertive'
          aria-label={inputId}
          className='tradein-error-message'
        >
          {errorMessage}
        </ErrorMessage>
      )}
    </SelectInputWrap>
  )
}

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func
}

SelectInput.defaultProps = {
  label: 'State',
  isDisabled: false,
  errorMessage: 'Please select an option',
  placeholder: ''
}

export default SelectInput
