import { DataACTIONS } from '../../common/context/DataContext'
import * as tooltips from '../../../constants/tooltips'
import { onClick } from '../../../utils/analytics/index'

const radioButtonId = '-radio-group'

export const vehicleCondition = [
  {
    id: `overallCondition${radioButtonId}`,
    dataAction: DataACTIONS.overallConditionUpdated,
    label: 'What is the overall condition of your vehicle?',
    options: ['Excellent', 'Good', 'Average', 'Fair', 'Poor'],
    subtitle: [
      '5% of trade-in vehicles',
      '35% of trade-in vehicles',
      '43% of trade-in vehicles',
      '15% of trade-in vehicles',
      '2% of  of trade-in vehicles'
    ],
    text: [
      'Your vehicle is almost like new. It has very low mileage with no exterior scratches or dings and a spotless interior. ',
      'Your vehicle has been well-maintained. It does not have any mechanical issues and there is minimal wear and tear to its interior or exterior. ',
      'Your vehicle has no operational issues. There is normal wear to its interior and some minor scratches and chips to the exterior. Most vehicles will fall into this category. ',
      'Your vehicle is drivable but it needs maintenance. Its exterior shows visible signs of wear and/or damage and the interior may be worn and discolored.',
      'Your vehicle’s engine does run but it has significant mechanical and/or electrical issues. It also has noticeable damage to its interior and exterior.'
    ],
    analyticsEvent: onClick.vehicleCondition,
    apiField: 'overallCondition'
  },
  {
    id: `accidents${radioButtonId}`,
    dataAction: DataACTIONS.accidentsUpdated,
    label: 'Has your vehicle been in an accident?',
    options: ['Yes', 'No'],
    tooltip: tooltips.accidents(),
    analyticsEvent: onClick.vehicleCondition,
    ariaLabels: [
      'Select if your vehicle has been in an accident',
      'Select if your vehicle has not been in an accident'
    ],
    apiField: 'hasAccidents'
  },
  {
    id: `odors${radioButtonId}`,
    dataAction: DataACTIONS.odorsUpdated,
    label:
      'Does your vehicle have any odors? (Potentially from smoking, pets, or mold)',
    options: ['Yes', 'No'],
    ariaLabels: [
      'Select if your vehicle has odors',
      'Select if your vehicle does not have odors'
    ],
    apiField: 'hasOdors'
  },
  {
    id: `mechanicalDefects${radioButtonId}`,
    dataAction: DataACTIONS.mechanicalDefectsUpdated,
    label: 'Does your vehicle have mechanical defects?',
    options: ['Yes', 'No'],
    tooltip: tooltips.defects(),
    analyticsEvent: onClick.vehicleCondition,
    ariaLabels: [
      'Select if your vehicle has mechanical defects',
      'Select if your vehicle does not have mechanical defects'
    ],
    apiField: 'hasMechanicalDefects'
  },
  {
    id: `warningLights${radioButtonId}`,
    dataAction: DataACTIONS.warningLightsUpdated,
    label: 'Are there any illuminated warning lights on your dashboard?',
    options: ['Yes', 'No'],
    tooltip: tooltips.lights(),
    analyticsEvent: onClick.vehicleCondition,
    ariaLabels: [
      'Select if your vehicle has illuminated warning lights',
      'Select if your vehicle does not have illuminated warning lights'
    ],
    apiField: 'hasIlluminatedWarningLights'
  },
  {
    id: `minorDamage${radioButtonId}`,
    dataAction: DataACTIONS.minorDamageUpdated,
    label: 'Does your vehicle currently have any minor damage?',
    options: ['Yes', 'No'],
    tooltip: tooltips.minor(),
    analyticsEvent: onClick.vehicleCondition,
    ariaLabels: [
      'Select if your vehicle has minor damage',
      'Select if your vehicle does not have minor damage'
    ],
    apiField: 'hasMinorDamage'
  },
  {
    id: `majorDamage${radioButtonId}`,
    dataAction: DataACTIONS.majorDamageUpdated,
    label: 'Does your vehicle currently have any major damage?',
    options: ['Yes', 'No'],
    tooltip: tooltips.major(),
    analyticsEvent: onClick.vehicleCondition,
    ariaLabels: [
      'Select if your vehicle has major damage',
      'Select if your vehicle does not have major damage'
    ],
    apiField: 'hasMajorDamage'
  }
]
