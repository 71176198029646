import React, { useContext, useState, useEffect } from 'react'
import { DataContext } from '../../common/context/DataContext'
import { AppContext, AppACTIONS } from '../../common/context/AppContext'

import * as filters from '../../../utils/filters'
import TradeinButton from '../../common/pageElements/TradeinButton'
import ScrollToTop from '../../common/pageElements/ScrollToTop'
import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders, EstimatedFlowPopupTip } from './Copy'
import Tooltip from '../../common/pageElements/Tooltip'
import { sorryCannotAppraiseCopy } from '../../common/messages/MessageCopy'

import { AreYouSureModal } from '../../common/messages/Messages'

import * as tooltips from '../../../constants/tooltips'
import { StyledTradeinAppraisal } from '../../styled-components/screens/StyledTradeinAppraisal'

import Image from '../../common/icons/Image'
import api from '../../../utils/api'
import { getTCUrl, getFTCUrl } from '../../../constants/staticUrls'
import { pageLoadTags, onClick } from '../../../utils/analytics/index'
import { getTradeInUrl } from '../../../constants/staticUrls'
import PopUpTip from 'components/common/pageElements/PopUpTip'

const TradeInAppraisal = () => {
  const { dataState, dataDispatch } = useContext(DataContext)
  const [bgHeight, setBgHeight] = useState('')
  const [modalType, setModalType] = useState(false)

  const {
    vehicleImageUrl,
    appraisalQuote,
    appraisalMax,
    appraisalMin,
    year,
    make,
    model,
    remainingBalance,
    actualRemainingBalance,
    financeSource,
    enterFinanceManually,
    enteredObligation,
    remainingBalanceNoFees,
    appraisalId
  } = dataState

  const {
    application,
    market,
    language,
    appState,
    appDispatch,
    brand,
    exitUrl,
    buildShowroomUrl,
    referralId,
    closeAppAndAddToCart,
    isAuthenticated
  } = useContext(AppContext)

  const { isGuaranteedFlow } = appState

  const tcUrl = getTCUrl(market, language)
  const fTCUrl = getFTCUrl()
  const finalRemainingBal = actualRemainingBalance || remainingBalance || 0
  const leaseRemainingBal = enteredObligation || remainingBalance || 0
  const fmcc =
    financeSource.name === 'Ford Credit' || financeSource.name === 'Lincoln AFS'
  const fmccNotManual = fmcc && !enterFinanceManually
  let manualBalance = null
  if (
    enteredObligation &&
    enterFinanceManually &&
    appState.financeType === 'Lease'
  ) {
    manualBalance = enteredObligation
  }
  const isFusionRecallFlow = application === 'FUSION_RECALL'
  const link = isFusionRecallFlow ? fTCUrl : tcUrl
  const valueFlow = isGuaranteedFlow
    ? 'guaranteedFlow'
    : isFusionRecallFlow
    ? 'fusionRecallFlow'
    : 'estimatedFlow'

    const offerPremium = (15/100) * dataState.privateOffer
    const refundQuote = dataState.privateOffer + offerPremium

  useEffect(() => {
    pageLoadTags.tradeInAppraisal()
    if (brand === 'lincoln') {
      bgStyle()
      window.addEventListener('resize', function (event) {
        setTimeout(() => {
          bgStyle()
        }, 1000)
      })
    }
  }, [])

  function bgStyle() {
    const appraisalHeader = document.querySelector('.tradein-appraisal-header')
    const carImg = document.querySelector('.appraisalCarImg')
    const defaultImg = document.querySelector('.defaultImg')
    if (appraisalHeader && carImg) {
      setTimeout(() => {
        // 80 = margin, 150 = availableImg's margin-top: -150px
        const calcBgHeight =
          appraisalHeader.clientHeight + carImg.clientHeight / 2 + 80 - 150
        setBgHeight(calcBgHeight)
      }, 500)
    } else if (appraisalHeader && defaultImg) {
      setTimeout(() => {
        const calcBgHeight =
          appraisalHeader.clientHeight + defaultImg.clientHeight / 2 + 80 - 100
        setBgHeight(calcBgHeight)
      }, 500)
    }
  }

  function setStorage(id) {
    window.sessionStorage.setItem('apprasialId', id)
  }

  function handleAcceptClose() {
    onClick.tradeInAppraisal.addToCart(appState.financeType)
    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })
    api
      .acceptAppraisalUS({
        appraisalId: dataState.appraisalId,
        addToFinance: true,
        // only send netValue for finance path
        netValue:
          appState.financeType === 'Finance'
            ? (appraisalQuote - finalRemainingBal).toFixed(2)
            : null,
        enteredObligation: manualBalance,
        tradeInUrl: getTradeInUrl(),
        referralId
      })
      .then((data) => {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        // reset local UI values
        application === 'SHOWROOM'
          ? window.location.assign(buildShowroomUrl(exitUrl, appraisalId))
          : window.location.assign(exitUrl)
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  function appraiseDiffVeh(exit) {
    if (exit === 'decline') {
      onClick.tradeInAppraisal.declineBackToCart(appState.financeType)
    } else {
      onClick.tradeInAppraisal.appraiseDifferentVehicle(appState.financeType)
      pageLoadTags.appraiseDiffVeh()
    }

    setModalType(exit)
    appDispatch({
      type: AppACTIONS.showAreYouSureModalUpdated,
      payload: true
    })
  }

  return (
    <StyledTradeinAppraisal className={brand}>
      <ScrollToTop />

      {!appraisalQuote &&
      brand === 'ford' &&
      appState.financeType !== 'Lease' ? (
        <div className={`tradein-appraisal ${brand}`}>
          <StepHeader
            mainHead={StepHeaders.appraisal[valueFlow].main}
            maxWidth='680px'
          />
          <div className='tradein-subtext'>
            {market === 'US' && application !== 'CART'
              ? sorryCannotAppraiseCopy['en_us'].showroom.subtext
              : sorryCannotAppraiseCopy['en_us'].subtext}
          </div>

          {vehicleImageUrl ? (
            <div className='tradein-appraisalCar availableImg'>
              <img
                className='appraisalCarImg'
                alt='Vehicle'
                src={vehicleImageUrl}
              />
              <div className='appraisalCar-subtext'>
                {brand === 'lincoln' && '*'}Vehicle image is only an example
              </div>
            </div>
          ) : (
            <div className='tradein-appraisalCar defaultImg'>
              <Image
                img={
                  brand === 'ford' ? 'placeholderCar' : 'lincolnPlaceholderCar'
                }
              />
              <div className='appraisalCar-subtext'>*No Image Available</div>
            </div>
          )}

          <div className='tradein-buttons-column'>
            <TradeinButton
              type='USFordPrimary'
              ariaLabel={
                market === 'US' && application !== 'CART'
                  ? sorryCannotAppraiseCopy['en_us'].showroom.buttonAriaLabel
                  : sorryCannotAppraiseCopy['en_us'].buttonAriaLabel
              }
              text={
                market === 'US' && application !== 'CART'
                  ? sorryCannotAppraiseCopy['en_us'].showroom.button
                  : sorryCannotAppraiseCopy['en_us'].button
              }
              action={() => closeAppAndAddToCart()}
              iconType='nextIcon'
            />

            <TradeinButton
              text='Appraise Different Vehicle'
              type='USFordBack'
              iconType='nextIcon'
              action={() => appraiseDiffVeh(false)}
            />

            <AreYouSureModal exit={false} />
          </div>
        </div>
      ) : (
        <div
          className={`tradein-appraisal ${brand} ${market}`}
          style={{
            background: `linear-gradient(#f2f2f2 ${bgHeight}px, white 0%) repeat`
          }}
        >
          {/* NOT Lease path */}
          {appState.financeType !== 'Lease' ? (
            <div className='tradein-appraisal-header'>
              <StepHeader
                mainHead={StepHeaders.appraisal[valueFlow].main}
                maxWidth='680px'
              />
              <div className='tradein-appraisal-content'>
                <h2 className='tradein-xl tradein-currency-title'>
                  {/* no decimal for APPRAISAL value */}
                  {isFusionRecallFlow
                    ? filters.currencyUSD(refundQuote).replace('.00', '')
                    : filters.currencyUSD(appraisalQuote).replace('.00', '')}
                </h2>
              </div>
              {isGuaranteedFlow || isFusionRecallFlow ? (
                <div className='tradein-subtext'>
                  Amounts shown are estimates that are not binding.
                </div>
              ) : (
                <div className='tradein-subtext'>
                  Trade-in amounts shown are conditional estimates that are not
                  binding. Actual values are dependent on many factors and the
                  dealer is not obligated to accept the amount shown.
                  <PopUpTip
                    popuptipBody={EstimatedFlowPopupTip}
                    className='tradein-iball'
                  />
                </div>
              )}
            </div>
          ) : (
            <div className='tradein-appraisal-header'>
              {/* Lease path */}
              <StepHeader
                mainHead={StepHeaders.leaseAppraisal.main}
                maxWidth='680px'
              />

              <div className='tradein-appraisal-content'>
                <h2 className='tradein-xl tradein-currency-title'>
                  {filters.currencyUSD(leaseRemainingBal)}
                </h2>
                <div className='tradein-subtext'>
                  {brand === 'ford'
                    ? 'You may request to add this amount into the financing of your new Ford vehicle, subject to credit approval.'
                    : 'You may request to add this amount into the financing of your new Lincoln vehicle, subject to credit approval.'}
                </div>
              </div>
            </div>
          )}
          {vehicleImageUrl ? (
            <div className='tradein-appraisalCar availableImg'>
              <img
                className='appraisalCarImg'
                alt='Vehicle'
                src={vehicleImageUrl}
              />
              <div className='appraisalCar-subtext'>
                {brand === 'lincoln' && '*'}Vehicle image is only an example
              </div>
            </div>
          ) : (
            <div className='tradein-appraisalCar defaultImg'>
              <Image
                img={
                  brand === 'ford' ? 'placeholderCar' : 'lincolnPlaceholderCar'
                }
              />
              <div className='appraisalCar-subtext'>*No Image Available</div>
            </div>
          )}

          {appState.financeType === 'Own' ? (
            <h2 className='tradein-m tradein-currency-title'>
              {year} {make} {model}
            </h2>
          ) : (
            <h2 className='tradein-l tradein-currency-title'>
              {year} {make} {model}
            </h2>
          )}
          {isAuthenticated && brand === 'lincoln' && (
            <div className='appraisal-saved'>
              <Image img='checkIcon' />
              Vehicle saved to account
            </div>
          )}

          {appState.financeType === 'Own' && brand === 'lincoln' && (
            <div className='tradein-divider' />
          )}

          {isFusionRecallFlow && (
            <div className='tradein-appraisal-cal'>
              <div className='tradein-appraisal-cal-row'>
                <div className='tradein-appraisal-cal-label'>
                  Private Sale Market Value
                </div>
                <div className='tradein-appraisal-cal-value'>
                  {filters
                    .currencyUSD(dataState.privateOffer)
                    .replace('.00', '')}
                </div>
              </div>
              <div className='tradein-appraisal-cal-row'>
                <div className='tradein-appraisal-cal-label'>+15% Premium</div>
                <div className='tradein-appraisal-cal-value'>
                  +{offerPremium}
                </div>
              </div>
              <div className='tradein-appraisal-cal-row'>
                <div className='tradein-appraisal-cal-row tradein-appraisal-cal-total'>
                  <div className='tradein-appraisal-cal-label'>
                    Estimated Statutory Refund Amount
                  </div>
                  <div className='tradein-appraisal-cal-value'>
                    {filters.currencyUSD(refundQuote).replace('.00', '')}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Finance path */}
          {appState.financeType === 'Finance' && (
            <div className='tradein-appraisal-cal'>
              <div className='tradein-appraisal-cal-row'>
                <div className='tradein-appraisal-cal-label'>
                  Your Trade-In appraisal
                </div>
                <div className='tradein-appraisal-cal-value'>
                  {filters.currencyUSD(appraisalQuote)}
                </div>
              </div>
              <div className='tradein-appraisal-cal-row'>
                <div className='tradein-appraisal-cal-label'>
                  Remaining Balance
                </div>
                <div className='tradein-appraisal-cal-value'>
                  -{filters.currencyUSD(finalRemainingBal)}
                </div>
              </div>
              <div className='tradein-appraisal-cal-row'>
                <div className='tradein-appraisal-cal-row tradein-appraisal-cal-total'>
                  <div className='tradein-appraisal-cal-label'>
                    Net Trade-In Equity
                    {brand === 'lincoln' && (
                      <Tooltip
                        tooltipBody={tooltips.netValue()}
                        id='netValueTT'
                      />
                    )}
                  </div>
                  <div className='tradein-appraisal-cal-value'>
                    {filters.currencyUSD(appraisalQuote - finalRemainingBal)}
                  </div>
                </div>
              </div>
            </div>
          )}
          {appState.financeType === 'Lease' && (
            <div className='tradein-appraisal-cal'>
              {/* Ford or Lincoln && NOT entered manually */}
              {fmccNotManual ? (
                <div>
                  <div className='tradein-appraisal-cal-row'>
                    <div className='tradein-appraisal-cal-label'>
                      Remaining Lease Payments Including Tax
                    </div>
                    <div className='tradein-appraisal-cal-value'>
                      {filters.currencyUSD(remainingBalanceNoFees)}
                    </div>
                  </div>
                  <div className='tradein-appraisal-cal-row'>
                    <div className='tradein-appraisal-cal-label'>
                      Late Charges
                    </div>
                    <div className='tradein-appraisal-cal-value'>
                      {filters.currencyUSD(dataState.lateFees)}
                    </div>
                  </div>
                  <div className='tradein-appraisal-cal-row'>
                    <div className='tradein-appraisal-cal-label'>
                      Fees (Extensions)
                    </div>
                    <div className='tradein-appraisal-cal-value'>
                      {filters.currencyUSD(dataState.otherFees)}
                    </div>
                  </div>
                  <div className='tradein-appraisal-cal-row tradein-appraisal-cal-total'>
                    <div className='tradein-appraisal-cal-label'>
                      Estimated Lease Obligation to Terminate Amount
                      {brand === 'lincoln' && (
                        <Tooltip
                          tooltipBody={tooltips.legalSuperscript()}
                          id='superscript'
                          ariaLabel={tooltips.legalSuperscriptString()}
                        />
                      )}
                    </div>
                    <div className='tradein-appraisal-cal-value'>
                      {filters.currencyUSD(leaseRemainingBal)}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='tradein-appraisal-cal-row'>
                  <div className='tradein-appraisal-cal-label'>
                    Estimated Lease Obligation to Terminate Amount
                    {brand === 'lincoln' && (
                      <Tooltip
                        tooltipBody={tooltips.legalSuperscript()}
                        id='superscript'
                        ariaLabel={tooltips.legalSuperscriptString()}
                      />
                    )}
                  </div>
                  <div className='tradein-appraisal-cal-value'>
                    {/* Manually entered balance or totalOutstandingBalanceAmount from API */}
                    {filters.currencyUSD(manualBalance || remainingBalance)}
                  </div>
                </div>
              )}
            </div>
          )}

          {brand === 'ford' && !isGuaranteedFlow && !isFusionRecallFlow && (
            <>
              <div className='estimatedTradeinVal'>
                <div>Your estimated trade-in value*</div>
                <div>
                  {appState.financeType !== 'Lease'
                    ? filters.currencyUSD(appraisalQuote).replace('.00', '')
                    : filters.currencyUSD(leaseRemainingBal)}
                </div>
              </div>
              <div className='appraisalRangeGrid'>
                <div>
                  low Value:
                  <span className='rangeVal'>
                    {filters.currencyUSD(appraisalMin)}
                  </span>
                </div>
                <div className='caretReverse'></div>
                <div>
                  high value:
                  <span className='rangeVal'>
                    {filters.currencyUSD(appraisalMax)}
                  </span>
                </div>
              </div>
              <div className='rangeNote'>
                This estimated range of values are provided to help you
                understand how your trade-in vehicle will be evaluated by your
                dealer. To proceed, you agree to use the "Estimated trade-in
                value" to place an order online.
              </div>
            </>
          )}
          {brand === 'lincoln' ? (
            <div className='tradein-buttons-column'>
              <TradeinButton
                action={handleAcceptClose}
                type='primary'
                text={
                  application !== 'SHOWROOM' ? 'Accept & Add to Cart' : 'Accept'
                }
                iconType={brand === 'ford' ? 'addIcon' : ''}
                lincolnPlus={brand === 'lincoln'}
              />
              <TradeinButton
                ariaLabel='Click here to decline this valuation and close the appraisal'
                type='secondary'
                text={
                  application !== 'SHOWROOM'
                    ? 'Decline & Back to Cart'
                    : 'Decline'
                }
                action={() => appraiseDiffVeh('decline')}
              />
              <TradeinButton
                type='forward'
                text='Appraise Different Vehicle'
                action={() => appraiseDiffVeh('different')}
              />
            </div>
          ) : (
            !isFusionRecallFlow && (
              <div className='tradein-buttons-column'>
                <TradeinButton
                  type='USFordPrimary'
                  text={'Accept & Add to Cart'}
                  action={() => handleAcceptClose()}
                  iconType='nextIcon'
                />
                <TradeinButton
                  type='USFordBack'
                  text={'Decline & Return to Cart'}
                  iconType='removeIcon'
                  action={() => appraiseDiffVeh('decline')}
                />
              </div>
            )
          )}
          <AreYouSureModal exit={false} isDecline={true} />
          {isFusionRecallFlow && (
            <div className='recall-card'>
              <div className='recall-content'>
                <p className='title'>What happens next?</p>
                <p>
                  <strong>Contact the Ford Customer Relationship Center</strong>{' '}
                  at
                  <a style={{paddingLeft: 5}} href="tel:1-833-807-3673">1-833-807-3673</a> to lock in
                  your offical statutory refund amount. Please have this
                  information ready when you make the call:
                </p>
                <div className='number-circle-row'>
                  <p>
                    <span className='number-circle'>1</span>Reference recall
                    number 23S33
                  </p>
                </div>
                <div className='number-circle-row'>
                  <p>
                    <span className='number-circle'>2</span>Vehicle
                    Identification Number (VIN)
                  </p>
                </div>
                <div className='number-circle-row'>
                  <p>
                    <span className='number-circle'>3</span>Current vehicle
                    mileage
                  </p>
                </div>
                <p>
                  Representatives are available Monday through Friday:
                  8:30AM-5:30PM (Eastern Time)
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </StyledTradeinAppraisal>
  )
}

export default TradeInAppraisal
