import React, { useContext } from 'react'
import { AppContext, AppACTIONS } from '../context/AppContext'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'
import styled from 'styled-components'
import { navigate } from 'hookrouter'

const BasicButton = styled.button`
  && {
    font-size: var(--fz-sm);
    font-weight: bold;
    font-family: var(--fontSecondary);
    padding: var(--buttonPadding);
    height: var(--buttonHeight);
    min-width: var(--buttonWidth);
    object-fit: contain;
    border-radius: var(--buttonRadius);
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: var(--buttonBlue);
    padding-top: 10px;

    &.ford {
      &.next {
        svg {
          margin-top: -2px;
          margin-left: 5px;
          path {
            fill: #ffffff;
          }
        }
      }
      &:not(.disabled) {
        &:hover {
          background: #0559bf;
        }
        &:focus {
          outline: 2px solid var(--buttonBlue);
          border: 2px solid white;
        }
      }
    }
    &.back {
      background: white;
      color: var(--buttonBlue);
      border: 1px solid var(--buttonBlue);
      min-width: 85px;
      position: relative;
      .button-text {
        padding-right: 0 !important;
      }

      svg {
        + .button-text {
          margin-left: 5px;
          margin-right: 5px;
        }
        margin-top: -3px;
        margin-left: -5px;

        color: var(--buttonBlue);
        g,
        path {
          fill: currentColor;
        }
      }
      &:hover {
        color: #0559bf;
        border-color: #0559bf;
      }
      &:focus {
        // outline: 2px solid var(--buttonBlue);
        // border: 2px solid white;
        &:after {
          border-radius: var(--buttonRadius);

          content: '';
          position: absolute;
          width: calc(100% + 5px);
          height: calc(100% + 5px);
          outline: 2px solid var(--buttonBlue);
          border: 2px solid white;
        }
      }
    }
    &.disabled {
      background-color: #e5e5e5;
      span {
        color: var(--fordModGrey);
        font-weight: bold;
      }
      svg {
        g {
          fill: var(--fordModGrey);
        }
        margin-top: -2px;
      }
    }
  }
`

const StyledButton = styled(BasicButton)`
  && {   
    background: ${(props) => (props.primary ? 'var(--secondary)' : 'white')};
    color: ${(props) => (props.primary ? 'var(--white)' : 'var(--secondary)')};
    font-family: var(--fontPrimary);
    font-weight: normal;
    box-shadow: ${(props) =>
      props.primary
        ? '0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.1);'
        : 'none'};
    svg {
      color: white;
      path {
        fill: currentColor;
      }
      vertical-align: middle;
      margin-left: 16px;
      font-size: 1.6rem;
    }

    &:focus span::before {
      position: absolute;
      width: calc(100% + 45px);
      height: calc(100% + 15px);
      border: 1px solid black;
      content: '';
      left: -21px;
      top: -7px;
      }
      &:hover, &:focus{
        --bg-color-hover: ${(props) =>
          props.secondary ? ' var(--grabberHover);' : ''};
        --border-color: ${(props) =>
          props.secondary ? ' var(--grabber);' : ''};
        color: ${(props) => (props.secondary ? 'var(--grabber)' : '')};

      }
    }
  
  &.fmc-button{
    z-index: 0 !important;
    position: relative;
  }
  &.lincoln {
        box-shadow: none;
        &:hover {
          box-shadow: inset 0 -3px 0 var(--defaultHover);
        }
      svg {
        margin-left: 8px;
        path {
          fill: var(--white);
        }
      }
    }
  }
`

const StyledIconButton = styled.button`
  && {
    letter-spacing: 1px;
    font-family: var(--fontSecondary);
    display: inline-block;
    font-size: var(--fz-md);
  }
`

const TradeinButton = ({
  lincolnPlus,
  type,
  text,
  action,
  ariaLabel,
  target,
  className,
  link,
  tertiary,
  id,
  iconType,
  invalidRef,
  disabled
}) => {
  const { appDispatch, market, brand } = useContext(AppContext)
  switch (type) {
    case 'primary':
      return (
        <StyledButton
          className='fmc-button'
          aria-label={ariaLabel}
          onClick={action}
          primary
          id={id}
        >
          <span className='button-text'> {text} </span>
          {iconType && <Icon icon={iconType} />}
          {lincolnPlus && (
            <span>
              <Icon icon='lincolnPlus' />
            </span>
          )}
        </StyledButton>
      )
    case 'secondary':
      return (
        <StyledButton
          className='fmc-button fmc-button--outlined'
          aria-label={ariaLabel}
          onClick={action}
          secondary
        >
          <span className='button-text'> {text} </span>
          {iconType && <Icon icon={iconType} />}
        </StyledButton>
      )
    case 'forward':
      return (
        <StyledIconButton
          className='fmc-text-button fmc-text-button--chevron-right'
          aria-label={ariaLabel}
          onClick={action}
          forward
        >
          {link ? (
            <a target='_blank' rel='noopener noreferrer' href={link}>
              {text}
            </a>
          ) : (
            <span>{text}</span>
          )}
        </StyledIconButton>
      )
    case 'back':
      return (
        <StyledIconButton
          aria-label={ariaLabel}
          className='fmc-text-button fmc-text-button--chevron-left'
          back
          onClick={() => {
            if (target) {
              appDispatch({
                type: AppACTIONS.jumpToStepUpdated,
                payload: target
              })
              navigate('/' + target)
            } else {
              action()
            }
          }}
        >
          <span>{market === 'US' && !invalidRef ? 'Back' : text}</span>
        </StyledIconButton>
      )

    case 'USFordPrimary':
      return (
        <BasicButton
          aria-label={ariaLabel}
          onClick={action}
          id={id}
          disabled={disabled}
          className={disabled ? 'disabled ford' : 'ford next'}
        >
          <span className='button-text'> {text} </span>
          {iconType && <Icon icon={iconType} />}
        </BasicButton>
      )
    case 'USFordBack':
      return (
        <BasicButton
          className={`back`}
          aria-label={ariaLabel}
          onClick={() => {
            if (target) {
              appDispatch({
                type: AppACTIONS.jumpToStepUpdated,
                payload: target
              })
              navigate('/' + target)
            } else {
              action()
            }
          }}
          id={id}
        >
          {iconType && <Icon icon={iconType} />}

          <span className='button-text'> {text} </span>
        </BasicButton>
      )
    case 'USFordBackSecondry':
      return (
        <BasicButton
          aria-label={ariaLabel}
          onClick={action}
          id={id}
          className={disabled ? 'disabled ford' : 'ford'}
        >
          {iconType && <Icon icon={iconType} />}
          <span className='button-text'> {text} </span>
        </BasicButton>
      )
    case 'FordSecondary':
      return (
        <StyledButton
          className='fmc-button fmc-button--outlined'
          aria-label={ariaLabel}
          onClick={action}
          secondary
        >
          <span className='button-text'> {text} </span>
        </StyledButton>
      )

    // default primary button
    default:
      return (
        <StyledButton
          className={className}
          aria-label={ariaLabel}
          onClick={action}
          primary
          disabled={diabled}
        >
          <span className='button-text'> {text} </span>
        </StyledButton>
      )
  }
}

TradeinButton.propTypes = {
  ariaLabel: PropTypes.string,
  action: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.string.isRequired
}

TradeinButton.defaultProps = {
  text: 'Submit',
  type: 'submit'
}

export default TradeinButton
