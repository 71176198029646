// FRANCE VEHICLE FOUND COPY

export const StepHeaders = {
  es_es: {
    main: 'Detalles del vehículo',
    sub: ''
  },
  it_it: {
    main: 'Dettagli veicolo',
    sub: ''
  },
  de_de: {
    main: 'Fahrzeugbewertung',
    sub: 'Bitte füllen Sie die folgenden Felder aus, damit Ihr Fahrzeug bewertet werden kann.'
  }
}

export const vehicleBasicsCopy = {
  es_es: {
    incorrectDate: {
      text: 'Modificar fecha de primera matriculación',
      ariaLabel: 'Pincha aquí para modificar la fecha de primera matriculación'
    },

    trimInputCopy: {
      ariaLabel: 'Por favor, indica cual es la versión del modelo',
      label: 'Versión',
      placeholder: 'Versión',
      errorMessage: 'Selecciona una de las opciones'
    },
    engineInputCopy: {
      ariaLabel: 'Por favor, indica la potencia del vehículo',
      label: 'Potencia del vehículo',
      placeholder: 'Potencia del vehículo',
      errorMessage: 'Selecciona una de las opciones'
    },
    fuelInputCopy: {
      ariaLabel:
        'Por favor, selecciona el tipo de combustible de tu vehículo usado',
      label: 'Combustible',
      placeholder: 'Combustible',
      errorMessage: 'Selecciona una de las opciones'
    },
    bodyInputCopy: {
      ariaLabel: 'Por favor, selecciona la carrocería de tu vehículo usado',
      label: 'Carrocería',
      placeholder: 'Carrocería',
      errorMessage: 'Selecciona una de las opciones'
    },
    gearInputCopy: {
      ariaLabel: 'PPor favor, selección la tracción de tu vehículo usado',
      label: 'Tracción',
      placeholder: 'Tracción',
      errorMessage: 'Selecciona una de las opciones'
    },
    doorInputCopy: {
      ariaLabel:
        'Por favor, selección el número de puertas de tu vehículo usado',
      label: 'Número de puertas',
      placeholder: 'Número de puertas',
      errorMessage: 'Selecciona una de las opciones'
    },
    forwardButton: {
      ariaLabel:
        'Pincha aquí para continuar al siguiente paso de la valoración',
      text: 'Siguiente'
    },
    submitButton: {
      ariaLabel: 'Submit',
      text: 'Submit'
    },
    backButton: {
      ariaLabel: 'Pincha aquí para volver',
      text: 'Volver'
    },
    registrationDate: {
      ariaLabel: 'Por favor, introduce el mes de compra del vehículo',
      text: 'Fecha de compra',
      month: {
        text: 'MM',
        placeholder: 'MM',
        ariaLabel: 'Por favor, introduce el mes de compra del vehículo',
        errorMessage: 'Por favor, introduce la fecha de compra del vehículo'
      },
      year: {
        text: 'AAAA',
        placeholder: 'AAAA',
        ariaLabel: 'Por favor, introduce el año de compra del vehículo',
        errorMessage: 'Por favor, introduce la fecha de compra del vehículo'
      },
      date: {
        text: 'DD',
        placeholder: 'DD',
        ariaLabel: 'Inserisci il mese di acquisto',
        errorMessage:
          'Por favor, verifica la fecha en que tu vehículo fue matriculado por primera vez'
      }
    },
    releaseDate: {
      ariaLabel:
        'Por favor introduce el mes en que fue matrículado el vehículo',
      text: 'Fecha de primera matriculación',
      month: {
        text: 'MM',
        placeholder: 'MM',
        ariaLabel:
          'Por favor introduce el mes en que fue matrículado el vehículo',
        errorMessage:
          'Por favor, verifica la fecha en que tu vehículo fue matriculado por primera vez'
      },
      year: {
        text: 'AAAA',
        placeholder: 'AAAA',
        ariaLabel:
          'Por favor introduce el año en que fue matrículado el vehículo',
        errorMessage:
          'Por favor introduce el año en que fue matrículado el vehículo'
      }
    }
  },
  it_it: {
    incorrectDate: {
      text: 'Modifica la data della prima immatricolazione',
      ariaLabel:
        'Clicca qui per modificare la data della prima immatricolazione'
    },
    trimInputCopy: {
      ariaLabel: 'Inserire il tipo di rivestimento degli interni',
      label: 'Allestimento e motorizzazione',
      placeholder: 'Allestimento e motorizzazione',
      errorMessage: 'Seleziona una scelta'
    },
    engineInputCopy: {
      ariaLabel: 'Inserire i cavalli',
      label: 'Cavalli',
      placeholder: 'Cavalli',
      errorMessage: 'Seleziona gli optional'
    },
    fuelInputCopy: {
      ariaLabel: 'Seleziona il tipo di carburante',
      placeholder: 'Carburante',
      label: 'Carburante',
      errorMessage: 'Selecciona una de las opciones'
    },
    bodyInputCopy: {
      ariaLabel: 'Seleziona una carrozzeria',
      label: 'Carrozzeria',
      placeholder: 'Carrozzeria',
      errorMessage: 'Selecciona una de las opciones'
    },
    gearInputCopy: {
      ariaLabel: 'Seleziona una trasmissione',
      label: 'Trasmissione',
      placeholder: 'Trasmissione',
      errorMessage: 'Selecciona una de las opciones'
    },
    doorInputCopy: {
      ariaLabel: 'Seleziona il numero di porte',
      label: 'Numero di porte',
      placeholder: 'Numero di porte',
      errorMessage: 'Selecciona una de las opciones'
    },
    forwardButton: {
      ariaLabel: 'Clicca qui per andare al passaggio successivo',
      text: 'Successivo'
    },
    backButton: {
      ariaLabel: 'Clicca qui per tornare indietro',
      text: 'Indietro'
    },
    submitButton: {
      ariaLabel: 'Submit',
      text: 'Submit'
    },
    registrationDate: {
      ariaLabel: 'Data di acquisto',
      text: 'Data di acquisto',
      month: {
        text: 'MM',
        placeholder: 'MM',
        ariaLabel: 'Inserisci il mese di acquisto',
        errorMessage: 'Inserisci la data di acquisto'
      },
      year: {
        text: 'AAAA',
        placeholder: 'AAAA',
        ariaLabel: 'Inserisci il mese di acquisto',
        errorMessage: 'Inserisci la data di acquisto'
      },
      date: {
        text: 'DD',
        placeholder: 'DD',
        ariaLabel: 'Inserisci il mese di acquisto',
        errorMessage: 'Inserisci la data di acquisto'
      }
    },
    releaseDate: {
      ariaLabel: 'Registration Date ES',
      text: 'Data di immatricolazione',
      month: {
        text: 'MM',
        placeholder: 'MM',
        ariaLabel: 'Inserisci il mese di immatricolazione',
        errorMessage: 'Verifica la data di immatricolazione'
      },
      year: {
        text: 'AAAA',
        placeholder: 'AAAA',
        ariaLabel: "Inserisci l'anno di immatricolazione",
        errorMessage: 'Verifica la data di immatricolazione'
      }
    }
  },
  de_de: {
    makeSelect: {
      ariaLabel: 'Marke auswählen',
      initialValue: '',
      label: 'Marke',
      placeholder: 'Marke',
      errorMessage: 'Marke ist erforderlich'
    },
    modelSelect: {
      ariaLabel: 'Modell auswählen',
      initialValue: '',
      label: 'Modell',
      placeholder: 'Modell',
      errorMessage: 'Modell ist erforderlich'
    },
    yearSelect: {
      ariaLabel: 'Jahr der Fahrzeugzulassung an',
      initialValue: '',
      label: 'Erstzulassung auswählen',
      placeholder: 'Erstzulassung auswählen',
      errorMessage: 'Diese Angabe ist erforderlich'
    },
    designSelect: {
      ariaLabel: 'Bauform auswählen',
      initialValue: '',
      label: 'Bauform',
      placeholder: 'Bauform',
      errorMessage: 'Diese Angabe ist erforderlich'
    },
    variantSelect: {
      ariaLabel: 'Modellvariante auswählen',
      initialValue: '',
      label: 'Modellvariante',
      placeholder: 'Modellvariante',
      errorMessage: 'Diese Angabe ist erforderlich'
    },
    typeSelect: {
      ariaLabel: 'Typ auswählen',
      initialValue: '',
      label: 'Typ',
      placeholder: 'Typ',
      errorMessage: 'Diese Angabe ist erforderlich'
    },
    kiloSelect: {
      ariaLabel: 'Kilometer auswählen',
      initialValue: '',
      label: 'Kilometer',
      placeholder: 'Kilometer',
      errorMessage: 'Diese Angabe ist erforderlich'
    },
    errorMessage: 'Bitte wählen Sie eine Option',
    forwardButton: {
      text: 'Weiter',
      ariaLabel:
        'Hier klicken, um zum nächsten Schritt der Fahrzeugbewertung zu gelangen'
    }
  }
}
