/* Example Usage
  @media (max-width: ${mediaSizes.phablet}px) {
    max-width: 300px;
  `};
*/

const mediaSizes = {
  desktop: '1024px',
  tablet: '768px',
  mobile: '540px'
}

export const mediaQueries = {
  overS: `(min-width: ${mediaSizes.mobile})`,
  overM: `(min-width: ${mediaSizes.tablet})`,
  overL: `(min-width: ${mediaSizes.desktop})`,
  underS: `(max-width: ${mediaSizes.mobile})`,
  underM: `(max-width: ${mediaSizes.tablet})`,
  underL: `(max-width: ${mediaSizes.desktop})`
}
