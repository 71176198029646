import React, { useContext, useState, useEffect } from 'react'
import { navigate } from 'hookrouter'
import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import api from '../../../utils/api'
import RadioButton from '../../common/pageElements/RadioButton'
import focusError from '../../../utils/focusError'
import { UseRadio } from '../../common/customhooks/forms'
import TradeinButton from '../../common/pageElements/TradeinButton'
import { vehicleCondition } from './constants'
import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders } from './Copy'
import ScrollToTop from '../../common/pageElements/ScrollToTop'
import { StyledVehicleCodition } from '../../styled-components/screens/StyledVehicleCondtion'
import { pageLoadTags, onClick } from '../../../utils/analytics/index'

const VehicleCondition = () => {
  // const myRef = React.createRef()
  const { appState, appDispatch, market, brand } = useContext(AppContext)
  const { dataState, dataDispatch } = useContext(DataContext)
  const [showErrors, setShowErrors] = useState(false)
  const [isloaded, setLoaded] = useState(false)
  const buttonText =
    appState.financeType === 'Own' ? 'See your appraisal' : 'Next'

  // add initialValue to all the radio objects
  const vehicleObj = vehicleCondition.map((v) => {
    const val = dataState[v.id.replace('-radio-group', '')]
    v.initialValue = val === true ? 'Yes' : val === false ? 'No' : val
    return v
  })

  const radioObj = vehicleObj.map((item) => UseRadio(item))

  useEffect(() => {
    if (!isloaded) {
      setLoaded(true)
      pageLoadTags.vehicleCondition()
    }
    if (appState.visitedSteps.indexOf('vehiclecondition') === -1) {
      appDispatch({
        type: AppACTIONS.visitedStepsUpdated,
        payload: 'vehiclecondition'
      })
    }
  }, [appDispatch, appState.visitedSteps])

  const { appraisalId, color, mileage, trim, twoOrMoreKeys } = dataState

  let vehicleBasicsPayLoad = {
    color: color,
    mileage: mileage,
    trim: trim,
    twoOrMoreKeys: twoOrMoreKeys
  }
  const valid = (currentValue) =>
    currentValue.value !== null && currentValue.value !== ''
  const pageValid = radioObj.every(valid)

  function handleSubmit() {
    // check if there is existing data for overallCondition
    if (!dataState.overallCondition) setShowErrors(true)

    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })
    const valid = (currentValue) =>
      currentValue.value !== null && currentValue.value !== ''
    const vehicleConditionPayload = {}

    if (radioObj.every(valid)) {
      radioObj.forEach((item) => {
        if (item.apiField === 'overallCondition') {
          let val = ''
          if (item.apiField) {
            if (isNaN(parseInt(item.value))) {
              val = item.value.toUpperCase()
            } else {
              val = item.options[item.value].toUpperCase()
            }
          }

          vehicleConditionPayload[item.apiField] = val
          dataDispatch({
            type: DataACTIONS.overallConditionUpdated,
            payload: val
          })
        } else {
          const val = item.value === 'Yes'
          saveValue(item.dataAction, item.value)
          if (item.apiField) {
            vehicleConditionPayload[item.apiField] = val
          }
        }
      })

      if (appState.financeType === 'Own') {
        if (appState.completedSteps.indexOf('vehiclecondition') === -1) {
          appDispatch({
            type: AppACTIONS.completedStepsUpdated,
            payload: 'vehiclecondition'
          })
        }

        api
          .appraiseVehicle({
            bypassCache: false,
            appraisalId: appraisalId,
            vehicleBasics: vehicleBasicsPayLoad,
            vehicleCondition: vehicleConditionPayload
          })
          .then((data) => {
            dataDispatch({
              type: 'SHOW_LOADING_SPINNER',
              payload: false
            })
            if (data.appraisalQuote) {
              dataDispatch({
                type: 'APPRAISAL_QUOTE_UPDATED',
                payload: data.appraisalQuote
              })
              dataDispatch({
                type: 'APPRAISAL_MAX_UPDATED',
                payload: data.appraisalMax
              })
              dataDispatch({
                type: 'APPRAISAL_MIN_UPDATED',
                payload: data.appraisalMin
              })

              const nextStep = 'tradeinappraisal'
              appDispatch({
                type: AppACTIONS.jumpToStepUpdated,
                payload: nextStep
              })
              navigate('/' + nextStep)
            } else {
              dataDispatch({
                type: 'SHOW_LOADING_SPINNER',
                payload: false
              })

              if (brand === 'ford') {
                navigate('/tradeinappraisal')
              } else {
                navigate('/sorryCannotAppraise')
                appDispatch({
                  type: AppACTIONS.jumpToStepUpdated,
                  payload: 'sorryCannotAppraise'
                })
              }
            }
          })
          .catch((error) => {
            console.log(error)
            dataDispatch({
              type: 'SHOW_LOADING_SPINNER',
              payload: false
            })
            if (brand === 'ford') {
              navigate('/tradeinappraisal')
            } else {
              navigate('/sorryCannotAppraise')
              appDispatch({
                type: AppACTIONS.jumpToStepUpdated,
                payload: 'sorryCannotAppraise'
              })
            }
          })
      }
      if (appState.financeType === 'Finance') {
        if (appState.completedSteps.indexOf('vehiclecondition') === -1) {
          appDispatch({
            type: AppACTIONS.completedStepsUpdated,
            payload: 'vehiclecondition'
          })
        }
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        api
          .updateAppraisalInfo({
            appraisalId,
            vehicleCondition: vehicleConditionPayload
          })
          .then((response) => {
            let nextStep = 'financing'
            if (dataState.enteredBalance || dataState.enteredObligation) {
              nextStep = 'confirmbalance'
            }
            appDispatch({
              type: AppACTIONS.jumpToStepUpdated,
              payload: nextStep
            })
            navigate('/' + nextStep)
            dataDispatch({
              type: 'SHOW_LOADING_SPINNER',
              payload: false
            })
          })
          .catch((error) => {
            dataDispatch({
              type: 'SHOW_LOADING_SPINNER',
              payload: false
            })
            console.log(error)
          })
      }
    } else {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: false
      })
      focusError(radioObj)
    }
  }

  function saveValue(dataAction, dataValue) {
    dataDispatch({
      type: dataAction,
      payload: dataValue
    })
  }

  // Handles edit
  // Goes back to summary page
  function handleEdit() {
    radioObj.forEach((item) => {
      saveValue(item.dataAction, item.value)
    })
    appDispatch({
      type: AppACTIONS.jumpToStepUpdated,
      payload: 'summary'
    })
    navigate('/summary')
  }

  return (
    <StyledVehicleCodition className='brand'>
      <ScrollToTop />
      <StepHeader
        mainHead={StepHeaders.vehicleCondition.main}
        subHead={StepHeaders.vehicleCondition.sub}
        maxWidth='840px'
      />
      <div className={`vehiclecondition ${brand}`}>
        {brand === 'lincoln' && (
          <div className='tradein-vc-required'>*All fields are required</div>
        )}
        {radioObj.map((obj, i) => (
          <div
            className={`tradein-vc-cb-wrapper ${
              brand === 'ford' && i == 0 ? 'overallcondition' : ''
            }`}
            key={i}
          >
            <RadioButton
              {...obj}
              index={i}
              showErrors={showErrors}
              isUS
              analyticsEvent={onClick.vehicleCondition}
            />
          </div>
        ))}
        {brand === 'lincoln' ? (
          <div className='tradein-buttons-container'>
            {dataState.isEdit === false ? (
              <React.Fragment>
                <TradeinButton type='back' target='vehiclebasics' />
                <TradeinButton
                  type='primary'
                  text={buttonText}
                  action={handleSubmit}
                  iconType='nextIcon'
                />
              </React.Fragment>
            ) : (
              <TradeinButton
                type='primary'
                text={buttonText}
                action={handleEdit}
              />
            )}
          </div>
        ) : (
          <div
            className={`tradein-buttons-container tradein-button-ford-wrapper`}
          >
            <TradeinButton
              type='USFordBack'
              text='Back'
              iconType='prevIcon'
              target='vehiclebasics'
            />
            <TradeinButton
              type='USFordPrimary'
              text='Continue'
              iconType='nextIcon'
              disabled={!pageValid}
              action={() => handleSubmit()}
            />
          </div>
        )}
      </div>
    </StyledVehicleCodition>
  )
}

export default VehicleCondition
