import { DataACTIONS } from '../../../common/context/DataContext'
import { conditions } from './Copy'

const radioButtonId = '-radio-group'

export const vehicleCondition = {
  fr_ch: [
    {
      id: `overallCondition${radioButtonId}`,
      label: 'Comment évaluez-vous l’état général de votre véhicule?',
      apiField: 'overallCondition',
      options: ['Excellent', 'Bon', 'Moyen', 'Médiocre', 'Mauvais'],
      text: [
        conditions('fr_ch', 'excellent'),
        conditions('fr_ch', 'good'),
        conditions('fr_ch', 'average'),
        conditions('fr_ch', 'fair'),
        conditions('fr_ch', 'poor')
      ],
      tooltip: 'Veuillez indiquer l’état général de votre véhicule.',
      tooltipAriaLabel: "Plus d'information",
      ariaLabels: [
        'Sélectionnez cette option si votre véhicule correspond à la définition de l’état «Excellent»',
        'Sélectionnez cette option si votre véhicule correspond à la définition de l’état «Bon»',
        'Sélectionnez cette option si votre véhicule correspond à la définition de l’état «Moyen»',
        'Sélectionnez cette option si votre véhicule correspond à la définition de l’état «moyen».',
        'Sélectionnez cette option si votre véhicule correspond à la définition de l’état «Mauvais».'
      ]
    }
  ],
  de_ch: [
    {
      id: `overallCondition${radioButtonId}`,
      label: 'Wie schätzen Sie den Gesamtzustand Ihres Fahrzeugs ein?',
      apiField: 'overallCondition',
      options: [
        'Ausgezeichnet',
        'Gut',
        'Durchschnittlich',
        'Mäsig',
        'Schlecht'
      ],
      text: [
        conditions('de_ch', 'excellent'),
        conditions('de_ch', 'good'),
        conditions('de_ch', 'average'),
        conditions('de_ch', 'fair'),
        conditions('de_ch', 'poor')
      ],

      tooltip: 'Bitte geben Sie den Gesamtzustand Ihres Fahrzeugs an.',
      tooltipAriaLabel: 'Mehr Informationen',
      ariaLabels: [
        'Wählen Sie dieses Feld aus, wenn Ihr Fahrzeug der Definition eines «ausgezeichneten» Zustands entspricht.',
        'Wählen Sie dieses Feld aus, wenn Ihr Fahrzeug der Definition eines «guten» Zustands entspricht.',
        'Wählen Sie dieses Feld aus, wenn Ihr Fahrzeug der Definition eines «durchschnittlichen» Zustands entspricht.',
        'Wählen Sie dieses Feld aus, wenn Ihr Fahrzeug der Definition eines «mässigen» Zustands entspricht.',
        'Wählen Sie dieses Feld aus, wenn Ihr Fahrzeug der Definition eines «schlechten» Zustands entspricht.'
      ]
    }
  ],
  it_ch: [
    {
      id: `overallCondition${radioButtonId}`,
      label: 'Come valuti in generale le condizioni del tuo veicolo?',
      apiField: 'overallCondition',
      options: ['Eccellenti', 'Buone', 'Medie', 'Mediocri', 'Pessime'],
      text: [
        conditions('it_ch', 'excellent'),
        conditions('it_ch', 'good'),
        conditions('it_ch', 'average'),
        conditions('it_ch', 'fair'),
        conditions('it_ch', 'poor')
      ],
      tooltip:
        'Vi preghiamo di dare un’occhiata alle categorie e alle descrizioni sotto per fornire una valutazione delle condizioni generali del vostro veicolo.',
      tooltipAriaLabel: 'Maggiori informazioni',
      ariaLabels: [
        'Selezionate se le condizioni del vostro veicolo sono considerabili «eccellenti»',
        'Selezionate se le condizioni del vostro veicolo sono considerabili «buone»',
        'Selezionate se le condizioni del vostro veicolo sono considerabili «discrete»',
        'Selezionate se le condizioni del vostro veicolo sono considerabili «mediocri»',
        'Selezionate se le condizioni del vostro veicolo sono considerabili «pessime»'
      ]
    }
  ],
  de_de: [
    {
      ariaLabels: [
        'Wählen Sie „ausgezeichnet“, wenn Ihr Fahrzeug in einem ausgezeichneten Zustand ist.',
        'Wählen Sie „gut“, wenn Ihr Fahrzeug in einem guten Zustand ist.',
        'Wählen Sie „durchschnittlich", wenn Ihr Fahrzeug in einem durchschnittlichen Zustand ist.',
        'Wählen Sie „mäßig", wenn Ihr Fahrzeug in einem mäßigen Zustand ist.',
        'Wählen Sie „schlecht", wenn Ihr Fahrzeug in einem schlechten Zustand ist.'
      ],
      id: `overallCondition${radioButtonId}`,
      label: 'Wie schätzen Sie den Gesamtzustand Ihres Fahrzeugs ein?',
      apiField: 'overallCondition',
      options: [
        'Ausgezeichnet',
        'Gut',
        'Durchschnittlich',
        'Mäßig',
        'Schlecht'
      ],
      text: [
        conditions('de_de', 'excellent'),
        conditions('de_de', 'good'),
        conditions('de_de', 'average'),
        conditions('de_de', 'fair'),
        conditions('de_de', 'poor')
      ]
    }
  ],
  de_at: [
    {
      ariaLabels: [
        'Bitte wählen Sie diese Option, wenn Ihr Fahrzeug der Definition „Ausgezeichnet“ entspricht',
        'Bitte wählen Sie diese Option, wenn Ihr Fahrzeug der Definition „Gut“ entspricht',
        'Bitte wählen Sie diese Option, wenn Ihr Fahrzeug der Definition „Durchschnittlich“ entspricht',
        'Bitte wählen Sie diese Option, wenn Ihr Fahrzeug der Definition „Mäßig“ entspricht',
        'Bitte wählen Sie diese Option, wenn Ihr Fahrzeug der Definition „Schlecht“ entspricht'
      ],
      id: `overallCondition${radioButtonId}`,
      label: 'Wie schätzen Sie den Gesamtzustand Ihres Fahrzeugs ein?',
      apiField: 'overallCondition',
      options: [
        'AUSGEZEICHNET',
        'GUT',
        'DURCHSCHNITTLICH',
        'MÄSSIG',
        'SCHLECHT'
      ],

      text: [
        conditions('de_at', 'excellent'),
        conditions('de_at', 'good'),
        conditions('de_at', 'average'),
        conditions('de_at', 'fair'),
        conditions('de_at', 'poor')
      ]
    }
  ],
  nb_no: [
    {
      id: `overallCondition${radioButtonId}`,
      label: 'Hvordan er den generelle tilstanden til bilen?',
      apiField: 'overallCondition',
      options: ['Utmerket', 'God', 'Middels', 'Ok', 'Dårlig'],

      text: [
        conditions('nb_no', 'excellent'),
        conditions('nb_no', 'good'),
        conditions('nb_no', 'average'),
        conditions('nb_no', 'fair'),
        conditions('nb_no', 'poor')
      ],
      tooltip:
        'Vennligst indiker hvordan den generelle tilstanden til bilen er.',
      tooltipAriaLabel: 'Mer informasjon',
      ariaLabels: [
        'Velg om bilens tilstand er utmerket.',
        'Velg om bilens tilstand er god.',
        'Velg om bilens tilstand er middels.',
        'Velg om bilens tilstand er OK.',
        'Velg om bilens tilstand er Dårlig.'
      ]
    }
  ],
  fi_fi: [
    {
      id: `overallCondition${radioButtonId}`,
      label: 'Mikä on ajoneuvosi yleinen kunto?',
      apiField: 'overallCondition',
      options: ['Erinomainen', 'Hyvä', 'Tyydyttävä', 'Välttävä', 'Huono'],

      text: [
        conditions('fi_fi', 'excellent'),
        conditions('fi_fi', 'good'),
        conditions('fi_fi', 'average'),
        conditions('fi_fi', 'fair'),
        conditions('fi_fi', 'poor')
      ],
      tooltip: 'Arvioi ajoneuvosi yleinen kunto.',
      tooltipAriaLabel: 'Lisää tietoa',
      ariaLabels: [
        'Valitse, jos ajoneuvosi kunto on erinomainen.',
        'Valitse, jos ajoneuvosi kunto on hyvä',
        'Valitse, jos ajoneuvosi kunto on tyydyttävä.',
        'Valitse, jos ajoneuvosi kunto on välttävä.',
        'Valitse, jos ajoneuvosi kunto on huono.'
      ]
    }
  ],
  da_dk: [
    {
      ariaLabels: [
        'Vælg fremragende, hvis din bil passer til denne definition',
        'Vælg god, hvis din bil passer til denne definition',
        'Vælg middel, hvis din bil passer til denne definition',
        'Vælg mindre god, hvis din bil passer til denne definition',
        'Vælg dårlig, hvis din bil passer til denne definition'
      ],
      id: `overallCondition${radioButtonId}`,
      label: 'Hvad er bilens generelle stand?',
      apiField: 'overallCondition',
      options: ['Fremragende', 'God', 'Middel', 'Mindre god', 'Dårlig'],

      text: [
        conditions('da_dk', 'excellent'),
        conditions('da_dk', 'good'),
        conditions('da_dk', 'average'),
        conditions('da_dk', 'fair'),
        conditions('da_dk', 'poor')
      ]
    }
  ],
  fr_be: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Quel est l'état général de votre véhicule ?`,
      apiField: 'overallCondition',
      options: ['Excellent', 'Bon', 'Moyen', 'Modere', 'Mauvais'],

      text: [
        conditions('fr_be', 'excellent'),
        conditions('fr_be', 'good'),
        conditions('fr_be', 'average'),
        conditions('fr_be', 'fair'),
        conditions('fr_be', 'poor')
      ],
      tooltip: `Veuillez indiquer l'état général de votre véhicule.`,
      tooltipAriaLabel: "Plus d'information",
      ariaLabels: [
        'Veuillez sélectionner si votre véhicule correspond à un état défini comme « excellent »',
        'Veuillez sélectionner si votre véhicule correspond à un état défini comme « bon »',
        'Veuillez sélectionner si votre véhicule correspond à un état défini comme « moyen »',
        `Veuillez sélectionner si l'état de votre véhicule est considéré comme modere`,
        'Veuillez sélectionner si votre véhicule correspond à la définition de « mauvais » état'
      ]
    }
  ],
  nl_be: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Wat is de algemene staat van uw voertuig?`,
      apiField: 'overallCondition',
      options: ['Uitstekend', 'Goed', 'Gemiddeld', 'Matig', 'Slecht'],

      text: [
        conditions('nl_be', 'excellent'),
        conditions('nl_be', 'good'),
        conditions('nl_be', 'average'),
        conditions('nl_be', 'fair'),
        conditions('nl_be', 'poor')
      ],
      tooltip: `Geef de algemene staat van uw voertuig aan.`,
      tooltipAriaLabel: 'Meer informatie',
      ariaLabels: [
        'Selecteer als uw voertuig voldoet aan de definitie "uitstekende" staat',
        'Selecteer als uw voertuig voldoet aan de definitie "goede" staat',
        'Selecteer als uw voertuig voldoet aan de definitie "gemiddelde" staat',
        'Selecteer als uw voertuig voldoet aan de definitie "matige" staat',
        'Selecteer als uw voertuig voldoet aan de definitie "slechte" staat'
      ]
    }
  ],
  en_ie: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `What is the overall condition of your vehicle?`,
      apiField: 'overallCondition',
      options: ['Excellent', 'Good', 'Average', 'Moderate', 'Bad'],

      text: [
        conditions('en_ie', 'excellent'),
        conditions('en_ie', 'good'),
        conditions('en_ie', 'average'),
        conditions('en_ie', 'fair'),
        conditions('en_ie', 'poor')
      ],
      tooltip: `Please indicate the overall condition of your vehicle.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Select if your vehicle fits the "Excellent" condition definition',
        'Select if your vehicle fits the "Good" condition definition',
        'Select if your vehicle fits the "Average" condition definition',
        'Select if your vehicle fits the "Moderate" condition definition',
        'Select if your vehicle fits the "Bad" condition definition'
      ]
    }
  ],
  en_gb: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `What is the overall condition of your vehicle?`,
      apiField: 'overallCondition',
      options: ['Excellent', 'Good', 'Average', 'Moderate', 'Bad'],

      text: [
        conditions('en_gb', 'excellent'),
        conditions('en_gb', 'good'),
        conditions('en_gb', 'average'),
        conditions('en_gb', 'fair'),
        conditions('en_gb', 'poor')
      ],
      tooltip: `Please indicate the overall condition of your vehicle.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Select if your vehicle fits the "Excellent" condition definition',
        'Select if your vehicle fits the "Good" condition definition',
        'Select if your vehicle fits the "Average" condition definition',
        'Select if your vehicle fits the "Moderate" condition definition',
        'Select if your vehicle fits the "Bad" condition definition'
      ]
    }
  ],
  cs_cz: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Jaký je celkový stav vašeho vozu?`,
      apiField: 'overallCondition',
      options: ['Výborný', 'Dobrý', 'Průměrný', 'Přiměřený', 'Špatný'],

      text: [
        conditions('cs_cz', 'excellent'),
        conditions('cs_cz', 'good'),
        conditions('cs_cz', 'average'),
        conditions('cs_cz', 'fair'),
        conditions('cs_cz', 'poor')
      ],
      tooltip: `Uveďte celkový stav vozu.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Vyberte, pokud vůz odpovídá definici stavu „Výborný“',
        'Vyberte, pokud vůz odpovídá definici stavu „Dobrý“',
        'Vyberte, pokud váš vůz odpovídá definici „průměrného“ stavu',
        'Vyberte, pokud vůz odpovídá definici „přiměřený“ stav',
        'Vyberte, pokud vůz odpovídá definici „špatného“ stavu'
      ]
    }
  ],
  pl_pl: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Jaki jest stan Twojego pojazdu?`,
      apiField: 'overallCondition',
      options: ['Doskonały', 'Dobry', 'Średni', 'Przeciętny', 'Zły'],

      text: [
        conditions('pl_pl', 'excellent'),
        conditions('pl_pl', 'good'),
        conditions('pl_pl', 'average'),
        conditions('pl_pl', 'fair'),
        conditions('pl_pl', 'poor')
      ],
      tooltip: `Podajesz subiektywną ocenę stanu swojego pojazdu, jednak pamiętaj, że końcowa ocena zostanie dokonana przez przedstawiciela Dealera. Stan pojazdu wpływa na jego końcową wycenę.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Wybierz, jeśli Twój pojazd pasuje do definicji stanu „Doskonałego”.',
        'Wybierz, jeśli Twój pojazd pasuje do definicji stanu „Dobrego”.',
        'Wybierz, jeśli Twój pojazd pasuje do definicji stanu „Przeciętnego”.',
        'Wybierz, jeśli Twój pojazd pasuje do definicji stanu „Średniego”.',
        'Wybierz, jeśli Twój pojazd pasuje do definicji stanu „Złego”.'
      ]
    }
  ],
  pt_pt: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Qual o estado geral do veículo?`,
      apiField: 'overallCondition',
      options: ['Excelente', 'Bom', 'Médio', 'Moderado', 'Mau'],

      text: [
        conditions('pt_pt', 'excellent'),
        conditions('pt_pt', 'good'),
        conditions('pt_pt', 'average'),
        conditions('pt_pt', 'fair'),
        conditions('pt_pt', 'poor')
      ],
      tooltip: `Indicar o estado geral do veículo.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Selecionar se o veículo se enquadra na definição de estado "Excelente"',
        'Selecionar se o veículo se enquadra na definição de estado "Bom"',
        'Selecionar se o veículo se enquadra na definição de estado "Médio"',
        'Selecionar se o veículo se enquadra na definição de estado "Moderado"',
        'Selecionar se o veículo se enquadra na definição de estado "Mau'
      ]
    }
  ],
  el_gr: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Ποια είναι η γενική κατάσταση του οχήματός σας;`,
      apiField: 'overallCondition',
      options: ['ΕΞΑΙΡΕΤΙΚΗ', 'ΠΟΛΥ ΚΑΛΗ', 'ΚΑΛΗ', 'ΜΕΤΡΙΑ', 'ΚΑΚΗ'],

      text: [
        conditions('el_gr', 'excellent'),
        conditions('el_gr', 'good'),
        conditions('el_gr', 'average'),
        conditions('el_gr', 'fair'),
        conditions('el_gr', 'poor')
      ],

      tooltip: `Υποδείξτε τη γενική κατάσταση του οχήματός σας`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Επίλεξε εάν η κατάσταση του οχήματός σου ανταποκρίνεται στον ορισμό «Εξαιρετική»',
        'Επίλεξε εάν η κατάσταση του οχήματός σου ανταποκρίνεται στον ορισμό «Πολύ καλή»',
        'Επίλεξε εάν η κατάσταση του οχήματός σου ανταποκρίνεται στον ορισμό «Καλή»',
        'Επίλεξε εάν η κατάσταση του οχήματός σου ανταποκρίνεται στον ορισμό «Μέτρια»',
        'Επίλεξε εάν η κατάσταση του οχήματός σου ανταποκρίνεται στον ορισμό «Κακή»'
      ]
    }
  ],
  hu_hu: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Milyen a járműve általános állapota?`,
      apiField: 'overallCondition',
      options: ['Kitűnő', 'Jó', 'Átlagos', 'Átlagon aluli', 'Rossz'],

      text: [
        conditions('hu_hu', 'excellent'),
        conditions('hu_hu', 'good'),
        conditions('hu_hu', 'average'),
        conditions('hu_hu', 'fair'),
        conditions('hu_hu', 'poor')
      ],
      tooltip: `Kérjük adja meg járműve általános állapotát!`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Válaszd ki, hogy járműved megfelel-e a fent leírt „Kitűnő” állapotnak!',
        'Válaszd ki, hogy a járműved megfelel-e a fent leírt „Jó” állapotnak!',
        'Válaszd ki, hogy járműved megfelel-e a fent leírt „Átlagos” állapotnak!',
        'Válaszd ki, hogy a járműved megfelel-e a fent leírt „Átlagon aluli” állapotnak!',
        'Válaszd ki, hogy a járműved megfelel-e a fent leírt „Rossz” állapotnak!'
      ]
    }
  ],
  ro_ro: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Care este starea generală a vehiculului dvs.?`,
      apiField: 'overallCondition',
      options: ['Excelentă', 'Bună', 'Medie', 'Moderată', 'Rea'],

      text: [
        conditions('ro_ro', 'excellent'),
        conditions('ro_ro', 'good'),
        conditions('ro_ro', 'average'),
        conditions('ro_ro', 'fair'),
        conditions('ro_ro', 'poor')
      ],
      tooltip: `Vă rugăm să indicați starea generală a vehiculului.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Selectați dacă vehiculul dvs. se potrivește cu definiția condiției „Excelentă”',
        'Selectați dacă vehiculul dvs. se potrivește definiției stării „bună”',
        'Selectați dacă vehiculul dvs. se potrivește cu definiția stării „Medie”',
        'Selectați dacă vehiculul dvs. se potrivește definiției stării „moderate”',
        'Selectați dacă vehiculul dvs. se potrivește cu definiția stării „rea”'
      ]
    }
  ],
  es_es: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `¿Estado general del vehículo?`,
      apiField: 'overallCondition',
      options: ['Excelente', 'Bueno', 'Medio', 'Aceptable', 'Malo'],

      text: [
        conditions('es_es', 'excellent'),
        conditions('es_es', 'good'),
        conditions('es_es', 'average'),
        conditions('es_es', 'fair'),
        conditions('es_es', 'poor')
      ],
      tooltip: `Indica el estado general de tu vehículo.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Elige esta opción si tu vehículo se encuadra dentro de esta definición de «Excelente».',
        'Elige esta opción si tu vehículo se encuadra dentro de esta definición de «Bueno».',
        'Elige esta opción si tu vehículo se encuadra dentro de esta definición de «Medio».',
        'Elige esta opción si tu vehículo se encuadra dentro de esta definición de «Aceptable».',
        'Elige esta opción si tu vehículo se encuadra dentro de esta definición de «Malo».'
      ]
    }
  ],
  it_it: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Quali sono le condizioni generali del veicolo?`,
      apiField: 'overallCondition',
      options: ['Ottime', 'Buone', 'Nella media', 'Modeste', 'Pessime'],

      text: [
        conditions('it_it', 'excellent'),
        conditions('it_it', 'good'),
        conditions('it_it', 'average'),
        conditions('it_it', 'fair'),
        conditions('it_it', 'poor')
      ],
      tooltip: `Indica le condizioni generali del tuo veicolo.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Seleziona se il tuo veicolo rientra nella definizione di condizioni "Ottime"',
        'Seleziona se il tuo veicolo rientra nella definizione condizioni "Buone"',
        'Seleziona se il tuo veicolo rientra nella definizione di condizioni "Nella media"',
        'Seleziona se il tuo veicolo rientra nella definizione di condizioni "Modeste"',
        'Seleziona se il tuo veicolo rientra nella definizione di condizioni "Pessime"'
      ]
    }
  ],
  fr_fr: [
    {
      id: `overallCondition${radioButtonId}`,
      label: `Quel est l'état général de votre véhicule ?`,
      apiField: 'overallCondition',
      options: ['Excellent', 'Bon', 'Moyen', 'Médiocre', 'Mauvais'],

      text: [
        conditions('fr_fr', 'excellent'),
        conditions('fr_fr', 'good'),
        conditions('fr_fr', 'average'),
        conditions('fr_fr', 'fair'),
        conditions('fr_fr', 'poor')
      ],
      tooltip: `Veuillez indiquer l'état général de votre véhicule.`,
      tooltipAriaLabel: 'More information',
      ariaLabels: [
        'Sélectionnez cette option si votre véhicule est dans un état défini comme « excellent ».',
        'Sélectionnez cette option si votre véhicule est dans un état défini comme « bon ».',
        'Sélectionnez cette option si votre véhicule est dans un état défini comme « moyen ».',
        'Sélectionnez cette option si votre véhicule est dans un état défini comme « médiocre ».',
        'Sélectionnez cette option si votre véhicule est dans un état défini comme « mauvais ».'
      ]
    }
  ]
}

// can refactor to switch case in the future
export const currencyFormat = {
  fr_ch: 'CHF',
  de_ch: 'CHF',
  it_ch: 'CHF',
  nb_no: 'NOK',
  de_de: 'EUR',
  fr_be: 'EUR',
  nl_be: 'EUR',
  fi_fi: 'EUR',
  de_at: 'EUR',
  da_dk: 'DKK',
  pl_pl: 'PLN',
  pt_pt: 'EUR',
  cs_cz: 'CZK',
  en_ie: 'EUR',
  en_gb: 'EUR',
  el_gr: 'EUR',
  hu_hu: 'HUF',
  ro_ro: 'RON',
  en_gb: 'GBP',
  nl_nl: 'EUR',
  fr_fr: 'EUR',
  es_es: 'EUR',
  it_it: 'EUR'
}
