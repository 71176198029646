// FRANCE VEHICLE FOUND COPY

export const StepHeaders = {
  fr_fr: {
    main: 'Est-ce bien votre véhicule?',
    sub: ''
  },
  en_gb: {
    main: "Here's What We Found",
    sub: 'Is this your vehicle?'
  },
  nl_nl: {
    main: 'Is dit jouw auto?',
    sub: ''
  },
  es_es: {
    main: 'esto es lo que encontramos ',
    sub: '¿Es este tu vehículo?'
  },
  it_it: {
    main: 'ecco cosa abbiamo trovato ',
    sub: 'Sei il proprietario di questo veicolo?'
  }
}

export const vehicleFoundCopy = {
  fr_fr: {
    trimInputCopy: {
      ariaLabel:
        'Cliquez ici pour sélectionner le niveau de finition de votre véhicule.',
      initialValue: '',
      label: 'Finition',
      placeholder: 'Finition',
      errorMessage: 'Veuillez sélectionner une option'
    },
    vehicleCardCopy: {
      regNum: "Plaque d'immatriculation",
      mileage: "Kilométrage actuel (au moment de l'estimation"
    },
    confirmSubmitForwardButton: {
      ariaLabel: "Cliquez ici pour confirmer et passer à l'étape suivante.",
      text: 'Confirmer'
    },
    incorrectVehicleForwardButton: {
      ariaLabel: 'Cliquez ici pour corriger les données incorrectes.',
      text: 'Ces données sont incorrectes ? Corrigez-les'
    },
    backButton: {
      ariaLabel: "Cliquez ici pour revenir à l'étape précédente.",
      text: 'Retour'
    },
    submitButton: {
      ariaLabel: 'Submit',
      text: 'Submit'
    }
  },
  en_gb: {
    vehicleCardCopy: {
      regNum: 'Registration Number:',
      mileage: 'Current Mileage:'
    },
    confirmSubmitForwardButton: {
      ariaLabel: 'Click here to confirm the vehicle and move to the next step',
      text: 'Confirm'
    },
    incorrectVehicleForwardButton: {
      ariaLabel: 'Click here if the vehicle information is incorrect',
      text: 'Vehicle info is incorrect'
    },
    backButton: {
      ariaLabel: 'Click here to go back to the previous step of the appraisal',
      text: 'Back'
    },
    submitButton: {
      ariaLabel: 'Submit',
      text: 'Submit'
    }
  },
  nl_nl: {
    vehicleCardCopy: {
      regNum: 'Kenteken:',
      mileage: 'Huidige aantal',
      mileageTwo: 'kilometers:'
    },
    confirmSubmitForwardButton: {
      ariaLabel: 'Klik "bevestig" om door te gaan',
      text: 'Bevestig'
    },
    incorrectVehicleForwardButton: {
      ariaLabel: '',
      text: ''
    },
    backButton: {
      ariaLabel: 'Kies "terug" als je een van je antwoorden wilt veranderen',
      text: 'Terug'
    },
    submitButton: {
      ariaLabel: 'Submit',
      text: 'Submit'
    }
  },
  es_es: {
    trimInputCopy: {
      ariaLabel:
        'Cliquez ici pour sélectionner le niveau de finition de votre véhicule.',
      initialValue: '',
      label: 'Finition',
      placeholder: 'Finition',
      errorMessage: 'Veuillez sélectionner une option'
    },
    vehicleCardCopy: {
      regNum: 'Matrícula',
      mileage: 'Kilómetros actuales (en el momento de la valoración)'
    },
    confirmSubmitForwardButton: {
      ariaLabel: 'Pincha aquí para confirmar y continuar al siguiente paso',
      text: 'Confirmar'
    },
    incorrectVehicleForwardButton: {
      ariaLabel: 'Cliquez ici pour corriger les données incorrectes.',
      text: 'Ces données sont incorrectes ? Corrigez-les'
    },
    backButton: {
      ariaLabel: 'Pincha aquí para volver',
      text: 'Volver'
    },
    submitButton: {
      ariaLabel: 'Submit',
      text: 'Submit'
    }
  },
  it_it: {
    trimInputCopy: {
      ariaLabel:
        'Cliquez ici pour sélectionner le niveau de finition de votre véhicule.',
      initialValue: '',
      label: 'Finition',
      placeholder: 'Finition',
      errorMessage: 'Veuillez sélectionner une option'
    },
    vehicleCardCopy: {
      regNum: 'Targa',
      mileage: 'Chilometraggio'
    },
    confirmSubmitForwardButton: {
      ariaLabel: 'Clicca qui per confermare e andare al passaggio successivo',
      text: 'Conferma'
    },
    incorrectVehicleForwardButton: {
      ariaLabel: 'Cliquez ici pour corriger les données incorrectes.',
      text: 'Ces données sont incorrectes ? Corrigez-les'
    },
    backButton: {
      ariaLabel: 'Clicca qui per tornare indietro',
      text: 'Indietro'
    },
    submitButton: {
      ariaLabel: 'Submit',
      text: 'Submit'
    }
  }
}
