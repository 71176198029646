import React, { useContext, useState, useEffect } from 'react'
import { navigate } from 'hookrouter'
import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import * as filters from '../../../utils/filters'
import focusError from '../../../utils/focusError'
import TextInput from '../../common/pageElements/TextInput'
import { StepHeaders } from './Copy'
import ScrollToTop from '../../common/pageElements/ScrollToTop'
import SelectInput from '../../common/pageElements/SelectInput'
import { useInput } from '../../common/customhooks/forms'
import { reasonsForDiscrepancy } from '../../../constants/forms'
import TradeinLink from '../../common/pageElements/TradeinLink'
import TradeinButton from '../../common/pageElements/TradeinButton'
import styled from 'styled-components'
import { pageLoadTags, onClick } from '../../../utils/analytics/index'

import api from '../../../utils/api'

const StyledRemainingBalance = styled.div`
  max-width: 740px;
  margin: auto;
  text-align: center;
  h2.tradein-subtext {
    margin-bottom: 50px;
  }
  .tradein-input-container {
    max-width: 430px;
    margin: auto;
  }
  &.ford {
    .tradein-currency-title.tradein-xl {
      font-size: var(--fz-xxxl2);
      font-weight: 400;
      color: var(--fordBlue);
      margin-bottom: 30px;
      text-align: left;
      &.incorrectBal {
        text-align: center;
      }
    }
    .tradein-nonfmcc-subtext {
      font-size: var(--fz-md);
      color: var(--fordBlack);
      text-align: left;
      margin-bottom: 20px;
    }
    .tradein-subtext {
      letter-spacing: 0;
      margin-left: 0;
    }
    .secondary-link-text {
      font-size: var(--fz-xs);
    }
  }
`

const StyledIncorrectLink = styled(TradeinLink)`
  && {
    display: inline;
    text-align: center;
    &.ford {
      display: flex;
      color: var(--fordBlue);
      font-size: var(--fz-md);
      line-height: 16px;
      letter-spacing: 0px;
    }
  }
`

const ConfirmBalance = () => {
  const { appState, appDispatch } = useContext(AppContext)
  const { dataState, dataDispatch } = useContext(DataContext)
  const [incorrectBal, setIncorrectBal] = useState(false)
  const financeType = appState.financeType
  const isFinance = financeType === 'Finance'
  const {
    financeSource,
    enteredBalance,
    enteredObligation,
    appraisalId,
    remainingBalance
  } = dataState
  const { brand, market } = useContext(AppContext)

  const buttonText =
    financeType === 'Finance' ? 'See your appraisal' : 'Review Details'
  const isFordOrLincoln =
    financeSource.name === 'Ford Credit' || financeSource.name === 'Lincoln'
  const [isloaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!isloaded) {
      setLoaded(true)
      financeType === 'Finance'
        ? pageLoadTags.balanceFinanceInfoIncorrect()
        : pageLoadTags.balanceLeaseInfoIncorrect()
    }

    if (appState.visitedSteps.indexOf('confirmbalance') === -1) {
      appDispatch({
        type: AppACTIONS.visitedStepsUpdated,
        payload: 'confirmbalance'
      })
    }
  }, [appState.visitedSteps, appDispatch, financeType])

  function saveValues(actualBalance) {
    dataDispatch({
      type: 'ACTUAL_REMAINING_BALANCE_UPDATED',
      payload: actualBalance
    })

    if (isFinance) {
      dataDispatch({
        type: 'ENTER_FINANCE_MANUALLY_UPDATED',
        payload: true
      })
      dataDispatch({
        type: 'ENTERED_BALANCE_UPDATED',
        payload: actualBalance
      })
    } else {
      dataDispatch({
        type: 'ENTERED_OBLIGATION_UPDATED',
        payload: actualBalance
      })
    }
  }

  function renderSubText() {
    let subtext = ''
    if (!incorrectBal) {
      subtext = StepHeaders.confirmBalance[financeType].sub.confirm
      if (!isFinance && brand === 'lincoln') {
        if (isFordOrLincoln) {
          subtext = StepHeaders.confirmBalance[financeType].sub.fmcc
        } else {
          subtext = StepHeaders.confirmBalance[financeType].sub.nonFmcc
        }
      }
    } else {
      subtext = StepHeaders.confirmBalance[financeType].sub.incorrectBal
    }
    return subtext
  }

  function getParsedBalance() {
    const actualRemainingBal = actualRemainingBalance.value
    const enteredRemainingBal = enteredBalance
    let parseBalance = null
    // if actualRemainingBalance is not empty or null
    if (actualRemainingBal !== null && actualRemainingBal !== '') {
      parseBalance = filters.deformatCurrency(actualRemainingBal, 'US')
      return parseBalance
      // if there is enteredBalance use this value
    } else if (enteredRemainingBal) {
      return enteredRemainingBal
    } else {
      return null
    }
  }

  function appraiseVehicle() {
    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })

    const updateAppPayload = isFinance
      ? { appraisalId, enteredBalance: enteredBalance || getParsedBalance() }
      : {
          appraisalId,
          enteredObligation: enteredObligation || getParsedBalance()
        }
    api
      .updateAppraisalInfo(updateAppPayload)
      .then((response) => {})
      .catch((error) => {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        console.log(error)
      })

    if (financeType !== 'Lease') {
      const {
        color,
        mileage,
        trim,
        twoOrMoreKeys,
        accidents,
        warningLights,
        majorDamage,
        mechanicalDefects,
        minorDamage,
        odors,
        overallCondition
      } = dataState
      api
        .appraiseVehicle({
          bypassCache: false,
          enteredBalance: getParsedBalance(),
          appraisalId: appraisalId,
          vehicleBasics: {
            color: color.toUpperCase(),
            mileage: mileage,
            trim: trim || ['Not found'],
            twoOrMoreKeys: twoOrMoreKeys === 'Yes' || false
          },
          vehicleCondition: {
            hasAccidents: accidents === 'Yes' || false,
            hasIlluminatedWarningLights: warningLights === 'Yes' || false,
            hasMajorDamage: majorDamage === 'Yes' || false,
            hasMechanicalDefects: mechanicalDefects === 'Yes' || false,
            hasMinorDamage: minorDamage === 'Yes' || false,
            hasOdors: odors === 'Yes' || false,
            overallCondition: overallCondition.toUpperCase()
          }
        })
        .then((data) => {
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          dataDispatch({
            type: 'APPRAISAL_QUOTE_UPDATED',
            payload: data.appraisalQuote
          })
          dataDispatch({
            type: 'APPRAISAL_MAX_UPDATED',
            payload: data.appraisalMax
          })
          dataDispatch({
            type: 'APPRAISAL_MIN_UPDATED',
            payload: data.appraisalMin
          })
          const nextStep = 'tradeinappraisal'
          appDispatch({
            type: AppACTIONS.jumpToStepUpdated,
            payload: nextStep
          })
          navigate('/' + nextStep)
        })
        .catch((error) => {
          console.log(error)
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          appDispatch({
            type: AppACTIONS.jumpToStepUpdated,
            payload: 'sorryCannotAppraise'
          })
          navigate('/sorryCannotAppraise')
        })
    } else {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: false
      })
      const nextStep = 'tradeinappraisal'
      appDispatch({
        type: AppACTIONS.jumpToStepUpdated,
        payload: nextStep
      })
      navigate('/' + nextStep)
    }
  }

  function handleSubmit() {
    // if no changes made to remainining balance, direct to trade in appraisal page
    saveValues(getParsedBalance())

    if (!incorrectBal) {
      if (appState.completedSteps.indexOf('confirmbalance') === -1) {
        appDispatch({
          type: AppACTIONS.completedStepsUpdated,
          payload: 'confirmbalance'
        })
      }

      appraiseVehicle()
    } else {
      const reason = reasonForDiscrepancy.value.value
      const pageValid = actualRemainingBalance.valid

      if (isFinance) {
        onClick.financeBalanceIncorrect(getParsedBalance(), reason)
      } else {
        onClick.leaseBalanceIncorrect(getParsedBalance(), reason)
      }

      if (pageValid) {
        if (appState.completedSteps.indexOf('confirmbalance') === -1) {
          appDispatch({
            type: AppACTIONS.completedStepsUpdated,
            payload: 'confirmbalance'
          })
        }
        appraiseVehicle()
      } else {
        focusError([actualRemainingBalance])
      }
    }
  }

  function handleIncorrectLink() {
    setIncorrectBal(true)
    dataDispatch({
      type: DataACTIONS.enterFinanceManuallyUpdated,
      payload: true
    })
  }

  function handleBack() {
    setIncorrectBal(false)
    if (
      (!enteredObligation && financeType === 'Lease') ||
      (!enteredBalance && financeType === 'Finance')
    )
      dataDispatch({
        type: DataACTIONS.enterFinanceManuallyUpdated,
        payload: false
      })
  }

  function displayBalance() {
    const balance = remainingBalance || enteredBalance || enteredObligation
    if (balance) {
      return filters.currencyUSD(balance)
    } else {
      return 0
    }
  }

  const actualRemainingBalance = useInput({
    initialValue: '',
    label: 'Actual Remaining Balance',
    filter: filters.inputUSD
  })

  const reasonForDiscrepancy = useInput({
    initialValue: '',
    label: 'Reason For Discrepancy (Optional)'
  })

  return (
    <StyledRemainingBalance className={` ${brand} ${market}`}>
      <ScrollToTop />

      <h1 className='tradein-main-header'>
        {StepHeaders.confirmBalance[financeType].main}
      </h1>
      <h2 className='tradein-subtext'>{renderSubText()}</h2>
      <div
        className={`tradein-currency-title tradein-xl ${
          incorrectBal ? 'incorrectBal' : ''
        }`}
      >
        {displayBalance()}
      </div>

      {brand === 'ford' && !isFinance && (
        <p className='tradein-nonfmcc-subtext'>
          {StepHeaders.confirmBalance[financeType].sub.nonFmcc}
        </p>
      )}

      {incorrectBal ? (
        <div className='incorrectBal'>
          <div className='tradein-input-container'>
            <TextInput {...actualRemainingBalance} />
            <SelectInput
              list={reasonsForDiscrepancy}
              {...reasonForDiscrepancy}
            />
          </div>
          {brand === 'ford' ? (
            <div
              className={`tradein-buttons-container tradein-button-ford-wrapper`}
            >
              <TradeinButton
                type='USFordBack'
                text='Back'
                iconType='prevIcon'
                target='financing'
              />
              <TradeinButton
                type='USFordPrimary'
                text={buttonText}
                iconType='nextIcon'
                action={handleSubmit}
              />
            </div>
          ) : (
            <div className='tradein-buttons-container'>
              <TradeinButton type='back' target='financing' />
              <TradeinButton
                type='primary'
                text={buttonText}
                iconType='nextIcon'
                action={handleSubmit}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          <StyledIncorrectLink
            className={` ${brand} ${market}`}
            type='secondary'
            text={
              brand === 'ford'
                ? 'Loan information is incorrect'
                : 'This amount is incorrect'
            }
            action={handleIncorrectLink}
          />
          {brand === 'ford' ? (
            <div
              className={`tradein-buttons-container tradein-button-ford-wrapper`}
            >
              <TradeinButton
                type='USFordBack'
                text='Back'
                iconType='prevIcon'
                target='financing'
              />
              <TradeinButton
                type='USFordPrimary'
                text={buttonText}
                iconType='nextIcon'
                action={handleSubmit}
              />
            </div>
          ) : (
            <div className='tradein-buttons-container'>
              <TradeinButton type='back' target='financing' />
              <TradeinButton
                type='primary'
                text={buttonText}
                iconType='nextIcon'
                action={handleSubmit}
              />
            </div>
          )}
        </div>
      )}
    </StyledRemainingBalance>
  )
}

export default ConfirmBalance
