// FRANCE VEHICLE FOUND COPY

export const TermsAndConditions = {
  nl_nl: {
    mainTitle: 'Algemene voorwaarden voor inruilen',
    subTitle:
      'U kunt de Ford Online-website gebruiken om de inruil van uw bestaande auto te regelen. U kunt het geld gebruiken voor uw aanbetaling voor uw nieuwe voertuig.',
    body: `
    <p>Uw inruil wordt afgehandeld via de door u gekozen dealer op basis van een taxatie van Autotelex. De taxatie van uw auto wordt gebaseerd op wat u ons heeft verteld en de geschatte leverdatum van uw nieuwe voertuig. Als een van deze aannames verandert, behoudt de dealer zich het recht voor om de waardering van uw inruilwagen te wijzigen. U wordt zo snel als redelijkerwijs mogelijk door de dealer geïnformeerd als er wijzigingen worden aangebracht.</p>
    <p>De dealer zal dezelfde criteria gebruiken om uw inruilauto te waarderen als u, maar als er een verschil is, zullen we verlangen dat dit wordt betaald op het moment van de overdracht. De algemene voorwaarden van de dealer zijn van toepassing op het inruilproces, die hier toegankelijk zijn.</p>
    <p>Wanneer u uw bestaande voertuig inruilt, heeft de dealer een bewijs nodig dat u de enige eigenaar bent. U bent verantwoordelijk om aan te geven bij de dealer of er nog een (deel) financiering op de auto loopt en of er eerst bij een financieringsmaatschappij of bank een openstaand saldo moet worden ingelost voordat u de auto kunt verkopen aan de dealer.</p>
    <h2>Als er een openstaande financiering is voor uw bestaande voertuig, moet u:</h2>
    <ul>
        <li>ervoor te zorgen dat de informatie die u aan ons verstrekt, met betrekking tot uw bestaande financiering nauwkeurig en up-to-date is;</li>
        <li>geef ons alle relevante referentienummers en de contactgegevens van uw bestaande financier;</li>
        <li>uw dealer kan u vragen om foto’s van uw inruilauto en extra informatie via een link in een e-mail of als u naar het dealerbedrijf zelf komt.</li>
    </ul>
    <p>Wanneer u uw bestaande voertuig aan uw dealer overhandigt, voorziet u de dealer van de kentekencard met tenaamstellingscodes, alle sleutels, onderhoudsgeschiedenis, reservewiel en sleutel van de wielsloten (indien van toepassing), radiocodes (i.v.t.), alarmcertificaat (i.v.t.) en alle andere zaken die bij het voertuig horen. U wordt hier door de dealer aan herinnerd voordat het voertuig wordt overgedragen of opgehaald. De dealer zal u een vrijwaringsbewijs overhandigen en daarmee is het eigendom overgedragen naar de dealer en bent u vanaf dat moment gevrijwaard.</p>`
  }
}
