import React, { useContext, useState } from 'react'

import { AppContext } from '../common/context/AppContext'
import { DataContext } from '../common/context/DataContext'
import TradeinLink from './pageElements/TradeinLink'
import TradeinButton from '../common/pageElements/TradeinButton'
import { headerName, headerCancel } from './HeaderCopy'

import { StyledCancelModal } from '../styled-components/screens/StyledCancelModal'
import styled from 'styled-components'
import { mediaQueries } from '../styled-components/mediaQueries'

import { onClick } from '../../utils/analytics/index'

const StyledHeader = styled.header`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 56px;
    background: var(--fordMedLightGrey);

    @media ${mediaQueries.underS} {
      padding: 14px 16px;
      height: 56px;
    }

    .tradein-header-text {
      font-size: var(--fz-lg2);
      font-weight: 600;
      line-height: 28px; /* 140% */
      color: var(--black);
      @media ${mediaQueries.underS} {
        font-size: var(--fz-md);
      }
    }
    span.tradein-header-text + a {
      color: black;
      font-size: var(--fz-sm);
      text-decoration: none;
      font-weight: bold;
    }

    .updatedDesign.ford
      .tradein-popover-modal
      .tradein-popover-modal-inner
      .tradein-popover-modal-body {
      @media ${mediaQueries.underS} {
        .tradein-buttons-container {
          flex-direction: column !important;
          button {
            width: 100%;
            &:not(:last-child) {
              margin-right: 0 !important;
              margin-bottom: 16px !important;
            }
          }
        }
      }
    }
    &.lincoln {
      .tradein-header-text {
        letter-spacing: 3px;
        text-transform: uppercase;
        font-size: var(--fz-md2);
        font-weight: 200;
      }
      .secondary-link-text {
        font-weight: 600;
      }
    }
  }
`
const StyledCancelLink = styled(TradeinLink)`
  && {
  }
`

const Header = () => {
  const [showAreYouSure, setShowAreYouSure] = useState(false)
  const { dataState } = useContext(DataContext)
  const { language, market, resetApp, brand, application } =
    useContext(AppContext)

  function handleYesSure() {
    onClick.cancelModal.exitCart('back to cart')
    resetApp(true, dataState.appraisalId)
  }
  function handleContinueAppraisal() {
    onClick.cancelModal.exitCart('continue appraisal')
    setShowAreYouSure(false)
  }

  const isFusionRecallFlow = application === 'FUSION_RECALL'

  const lang =
    headerName.hasOwnProperty(language) && headerCancel.hasOwnProperty(language)
      ? language
      : 'en_us'

  function handleClick() {
    if (market === 'US') {
      setShowAreYouSure(true)
    } else {
      resetApp(true, dataState.appraisalId)
    }
  }

  return (
    <StyledHeader className={market}>
      <span className='tradein-header-text'>
        {isFusionRecallFlow
          ? headerName.fusion_recall_en.text
          : headerName[lang].text}
      </span>
      <StyledCancelLink
        type='secondary'
        text={headerCancel[lang].text}
        ariaLabel={headerCancel[lang].ariaLabel}
        action={handleClick}
        icon={close}
      />
      {showAreYouSure && (
        <StyledCancelModal
          headerText={
            brand === 'ford'
              ? 'Are you sure you want to leave this page?'
              : 'Are You Sure?'
          }
          showModal={showAreYouSure}
          updatedDesign={brand === 'ford' || false}
          closeModal={() => setShowAreYouSure(false)}
        >
          <div className='tradein-cancel-modal-subtitle'>
            Any information you entered will be lost and nothing will be added
            to your cart.
          </div>
          <div className='tradein-buttons-container'>
            <TradeinButton
              type={brand === 'ford' ? 'USFordPrimary' : 'primary'}
              text={brand === 'ford' ? 'Yes, leave this page' : "Yes, I'm sure"}
              action={() => handleYesSure()}
            />
            {brand === 'ford' ? (
              <TradeinButton
                type={brand === 'ford' ? 'USFordBack' : 'primary'}
                text='Continue appraisal'
                action={() => handleContinueAppraisal()}
              />
            ) : (
              <TradeinLink
                type='secondary'
                text='Continue Appraisal'
                action={() => handleContinueAppraisal()}
              />
            )}
          </div>
        </StyledCancelModal>
      )}
    </StyledHeader>
  )
}

export default Header
