import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { DataContext } from '../context/DataContext'
import { useRoutes, navigate, usePath } from 'hookrouter'

import FusionRecallTnC from 'components/custom/US/fusionRecallTnC'
import TermsAndConditionsUS from '../../custom/US/TermsAndConditions'

import LandingPageUS from '../../custom/US/LandingPage_US'
import VehicleFound from '../../custom/US/VehicleFound'

import VehicleBasics from '../../custom/US/VehicleBasics'
import VehicleCondition from '../../custom/US/VehicleCondition'
import ConfirmBalance from '../../custom/US/ConfirmBalance'

import VehicleBasicsESIT from '../../custom/EU/VehicleBasics_ES_IT'
import VehicleBasicsDE from '../../custom/EU/VehicleBasics_DE'
import SelectFinanceMethod from '../../custom/US/SelectFinanceMethod'

import { STEP_HEADER } from '../../custom/EU/offline/Copy'
import { StepHeaders } from '../../custom/EU/copy/LandingPageEUCopy'

import {
  SorryCannotAppraise,
  SorryCannotAppraiseFordUS,
  SorryCannotAppraiseFusionRecall,
  VehicleNotFound,
  VehicleNotFoundEU,
  ErrorMissingRef,
  ErrorInvalidRef
} from '../messages/Messages'
import Health from '../messages/Health'

import TradeInAppraisal from '../../custom/US/TradeInAppraisal'

import Financing from '../../custom/US/Financing'
import LoadingSpinner from '../pageElements/LoadingSpinner'

// EU Imports
// import AppraisalErrorEU from '../../custom/EU/AppraisalError_EU'
// import VehicleNotFoundEU from '../../custom/EU/VehicleNotFound_EU'

import LandingPageEU from '../../custom/EU/LandingPage_EU'
import TradeInAppraisalEU from '../../custom/EU/TradeInAppraisal_EU'
import VehicleConditionEU from '../../custom/EU/VehicleCondition_EU'
import VehicleFoundEU from '../../custom/EU/VehicleFound_EU'
import TermsAndConditionsEU from '../../custom/EU/TermsAndConditionsEU'

// EU
import OfflineValuationEU from '../../custom/EU/offline/OfflineValuation_EU'

const StepContent = () => {
  const { dataState } = useContext(DataContext)
  const { market, referralId, application } = useContext(AppContext)

  // get offline markets in array
  const offlineEU = [
    ...new Set(
      Object.keys(STEP_HEADER).map((v) =>
        v.slice(v.length - 2, v.length).toUpperCase()
      )
    )
  ]
  // online markets in array
  const onlineEU = [
    ...new Set(
      Object.keys(StepHeaders).map((v) =>
        v.slice(v.length - 2, v.length).toUpperCase()
      )
    )
  ]
  // check application for DE
  const routes = {
    '/': () => {
      if (market) {
        if (
          market === 'US' &&
          !dataState.vin &&
          application !== 'FUSION_RECALL'
        ) {
          return <SelectFinanceMethod />
        } else if (market === 'US' && application === 'FUSION_RECALL') {
          return <LandingPageUS />
        } else if (market === 'US' && dataState.vin) {
          return <VehicleFound />
        } else if (market === 'DE') {
          return <VehicleBasicsDE />
        } else if (onlineEU.includes(market)) {
          return <LandingPageEU />
        } else if (offlineEU.includes(market)) {
          return <OfflineValuationEU />
        }
      } else {
        return <LoadingSpinner showLoadingSpinner />
      }
    },
    '/ownershiptype': () => <SelectFinanceMethod />,

    // US own path
    '/landingpage_US': () => <LandingPageUS />,
    '/vehicleBasics': () => <VehicleBasics />,
    '/vehiclefound': () => <VehicleFound />,
    '/vehiclebasics': () => <VehicleBasics />,
    // '/vehiclefeatures': () => <VehicleFeatures />,
    '/vehiclecondition': () => <VehicleCondition />,
    '/tradeinappraisal': () => <TradeInAppraisal />,
    '/termsandconditions': () => <VehicleCondition />,

    // finance || leased
    '/financing': () => <Financing />,
    '/confirmbalance': () => <ConfirmBalance />,

    '/termsandconditionsUS': () => <TermsAndConditionsUS />,
    '/fusionrecalltnc': () => <FusionRecallTnC />,

    // EU path
    '/landingpage_EU': () => <LandingPageEU />,
    '/vehiclefound_EU': () => <VehicleFoundEU />,
    '/vehiclecondition_EU': () => <VehicleConditionEU />,
    '/vehiclebasics_es_it': () => <VehicleBasicsESIT />,
    '/vehiclebasics_de': () => <VehicleBasicsDE />,
    '/tradeinappraisal_EU': () => <TradeInAppraisalEU />,
    '/vehiclenotfound_EU': () => <VehicleNotFoundEU />,
    '/offlinevaluation_EU': () => <OfflineValuationEU />,
    '/termsandconditions_NL': () => <TermsAndConditionsEU />,

    // error
    '/sorryCannotAppraiseFordUS': () => <SorryCannotAppraiseFordUS />,
    '/sorryCannotAppraiseFusionRecall': () => (
      <SorryCannotAppraiseFusionRecall />
    ),

    '/sorryCannotAppraise': () => <SorryCannotAppraise />,
    '/vehicleNotFound': () => <VehicleNotFound />,
    '/errorMissingRef': () => <ErrorMissingRef />,
    '/errorInvalidRef': () => <ErrorInvalidRef />,

    '/health': () => <Health />
  }

  const routeResult = useRoutes(routes)
  const currentPath = usePath()

  const renderRoute = () => {
    const siteReferrer = document.referrer
    if (
      !referralId &&
      currentPath !== '/health' &&
      !currentPath.includes('termsandconditions') &&
      !currentPath.includes('fusionrecalltnc')
    ) {
      if (siteReferrer.includes('ford.com')) {
        navigate('/errorMissingRef')
        return <ErrorMissingRef />
      } else {
        navigate('/errorInvalidRef')
        return <ErrorInvalidRef />
      }
    }
    return routeResult
  }

  return (
    <div>
      <LoadingSpinner showLoadingSpinner={dataState.showLoadingSpinner} />
      {renderRoute()}
    </div>
  )
}
export default StepContent
