// FRANCE VEHICLE FOUND COPY

export const CookieCopy = {
  en_gb: {
    cookieTitle: 'What is a cookie?',
    cookieText: `At Ford, we use technologies like cookies, pixels, and local storage to make our websites easier to use and to tailor content to be more relevant for you. This guide is designed to explain what cookies are the purpose of those used on Ford websites and the options to control them – if you decide you want to. <br><br> Almost every website uses cookie technology. A cookie is a small file which a server downloads to your personal computer (PC) or mobile when you visit a website. When you next return to the site using the same device, your PC checks to see if it has a cookie that the website recognises. If it has such a cookie, it communicates with the website and enables the website to read the information held in that. This allows the site to recognise that the browser has been visited before and, in some cases, may alter the content shown to reflect this.<br><br>`,
    cookieLinksText: 'You can find more information about cookies at:',
    cookieLinks:
      '<li><a href="www.allaboutcookies.org">www.allaboutcookies.org</a></li> <li><a href="www.youronlinechoices.eu">www.youronlinechoices.eu</a></li> <li><a href=">www.google.co.uk/policies/technologies/cookies">www.google.co.uk/policies/technologies/cookies</a></li>',
    preferencesTitle: 'Preferences',
    btnAcceptAll: 'Accept All',
    btnSaveAndExit: 'Save and Exit',
    btnManage: 'Manage',
    btnAccept: 'Accept',
    bannerText1:
      'Ford Motor Company Limited uses cookies and similar technologies on this website to improve your online experience and to show tailored advertising to you.',
    bannerText2:
      'You can manage cookies at any time on the Manage Cookie Settings page but this may limit or prevent use of certain features on the website. Please see the website privacy and cookie policy for further information.',

    preferences: [
      {
        id: 'cookieStrictly',
        title: 'Strictly Necessary Cookies',
        text: 'These cookies are essential in letting you move around the website and use its features, such as accessing secure areas of the website. Without these cookies, services you have asked for such as shopping baskets or e-billing, cannot be provided. ',
        strictly: true
      },
      {
        id: 'cookiePerformance',
        title: 'Performance Cookies',
        text: 'These cookies collect information about how our visitors use the website. For example, it enables us to know which pages visitors go to most often, and if they get error messages from web pages. These cookies don’t collect information that identifies a visitor. All information collected by these cookies is aggregated and therefore anonymous. It is only ever used to improve how our website works. <br/> ',
        strictly: false
      },
      {
        id: 'cookieFunctionality',
        title: 'Functionality Cookies',
        text: `These cookies allow the website to remember any choices you make. This could include your user name, language or the region you are in, and provide more enhanced, and personal features. For instance, a website may be able to provide you with local weather reports or traffic news by storing in a cookie the region in which you are currently located. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you’ve customised. <br><br>  They may also be used to provide services you have asked for, such as watching a video or commenting on a blog. Information collected by these cookies collect may be anonymised and therefore cannot track your browsing activity on other websites. `,
        strictly: false
      },
      {
        id: 'cookieTargeting',
        title: 'Targeting & Advertising Cookies',
        text: 'These cookies are used to deliver adverts more relevant and suited to you and your interests. They’re also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They are usually placed by advertising networks with the website operator’s permission. They remember what websites you have visited and this information is shared with other organisations such as advertisers. Quite often, targeting cookies will be linked to site functionality provided by the other organisation. <br><br>  Some of our Web pages may contain electronic images known as Web Beacons (sometimes known as clear gifs) that allow us to count then number of users who have visited these pages. <br><br>  Web Beacons collect only limited information, these include a cookie number; the time and date of a page view, and a description of the page where the Web Beacon resides. <br><br> We may also carry Web Beacons that are placed by third party advertisers. These Beacons do not carry any personally identifiable information and are only used to track the level of effectiveness of a particular campaign. ',
        strictly: false
      }
    ]
  }
}
