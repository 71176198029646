export default function focusError(formFields) {
  const invalidFields = []
  // formFields is always an array
  formFields.forEach((fields) => {
    // check if its an object or array and get empty value fields
    if (fields.constructor.name === 'Object') {
      if (typeof fields.value === 'object') {
        // for checking empty select object
        if (!fields.value || !fields.value.value || !fields.value.label) {
          invalidFields.push(fields)
        }
      } else if (
        (!fields.valid && !fields.value) ||
        fields.value === '$' ||
        fields.valid === false
      ) {
        invalidFields.push(fields)
      }
    } else {
      // for radio buttons in an array
      fields.forEach((field) => {
        if (!field.value) {
          invalidFields.push(field)
        }
      })
    }
  })

  // after filtering out !values
  // put the first item to last, so the first item will be focused
  const newInvalidFields = [...invalidFields.slice(1), invalidFields[0]]

  function fieldArray(fields) {
    const { id, label, placeholder, selectId } = fields
    let inputProperty = selectId || id || label || placeholder
    // if field is radio, focus on the first radio button instead of label
    // all radio buttons must have an id with "radio" or "Radio" in it
    inputProperty =
      inputProperty.includes('radio') || inputProperty.includes('Radio')
        ? inputProperty + 0
        : inputProperty

    if (inputProperty) {
      document.getElementById(inputProperty).focus()
    } else {
      console.warn(`Field needs id/label/placeholder to be focused on error`)
    }
  }
  newInvalidFields.forEach((field) => {
    fieldArray(field)
  })
}
