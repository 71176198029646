export const headerName = {
  fusion_recall_en: {
    text: "Ford's Vehicle Refund Estimator"
  },
  en_us: {
    text: 'Ford Trade-In Value Estimator'
  },
  en_gb: {
    text: 'Trade-In'
  },
  de_de: {
    text: 'Inzahlungnahme'
  },
  fr_fr: {
    text: 'Reprise'
  },
  nb_no: {
    text: 'Innbytte'
  },
  nl_nl: {
    text: 'Inruilen'
  },
  fr_ch: {
    text: 'Offre de reprise'
  },
  de_ch: {
    text: 'Inzahlungnahme'
  },
  it_ch: {
    text: 'Permuta'
  },
  es_es: {
    text: 'Valora tu vehículo usado'
  },
  it_it: {
    text: 'Permuta'
  },
  fr_be: {
    text: 'Reprise'
  },
  nl_be: {
    text: 'Inruilen'
  },
  fi_fi: {
    text: 'Vaihtoajoneuvo'
  },
  de_at: {
    text: 'Inzahlungnahme'
  },
  da_dk: {
    text: 'Byttebil'
  },
  en_ie: {
    text: 'Trade-in'
  },
  cs_cz: {
    text: 'Výkup vozu'
  },
  pl_pl: {
    text: 'Wymiana'
  },
  pt_pt: {
    text: 'Retoma'
  },
  el_gr: {
    text: 'ΑΝΤΑΛΛΑΓΗ ΟΧΗΜΑΤΟΣ'
  },
  hu_hu: {
    text: 'Csereajánlat'
  },
  ro_ro: {
    text: 'Schimb'
  }
}

export const headerCancel = {
  en_us: {
    ariaLabel: 'Click here to cancel your trade-in appraisal.',
    text: 'Cancel'
  },
  en_gb: {
    ariaLabel: 'Click here to cancel your trade-in valuation request',
    text: 'Cancel'
  },
  de_de: {
    ariaLabel: 'Klicken Sie hier, um die Inzahlungnahme abzubrechen.',
    text: 'Abbrechen'
  },
  fr_fr: {
    ariaLabel: "Cliquez ici pour annuler votre demande d'évaluation de reprise",
    text: 'Annuler'
  },
  nb_no: {
    ariaLabel:
      'Klikk her for å avbryte forespørselen din om estimert innbyttepris',
    text: 'Avbryt'
  },
  nl_nl: {
    ariaLabel:
      'Kies annuleren als je de inruilwaarde niet wilt toevoegen aan je overeenkomst',
    text: 'Annuleren'
  },
  fr_ch: {
    ariaLabel: "Cliquez ici pour annuler votre demande d'estimation de reprise",
    text: 'Annuler'
  },
  de_ch: {
    ariaLabel:
      'Hier klicken, um Ihre Inzahlungnahme-Bewertungsanfrage zu stornieren',
    text: 'abbrechen'
  },
  it_ch: {
    ariaLabel:
      'Clicchi qui per cancellare la sua richiesta di valutazione di permuta',
    text: 'Cancella'
  },
  es_es: {
    ariaLabel:
      'Haz clic aquí para cancelar la solicitud de valoración de tu vehículo usado',
    text: 'Cancelar'
  },
  it_it: {
    ariaLabel:
      'Fai clic qui per cancellare la tua richiesta di valutazione di permuta',
    text: 'Annulla'
  },
  fr_be: {
    ariaLabel: `Cliquez ici pour annuler votre demande d'évaluation de reprise.`,
    text: 'Annuler'
  },
  nl_be: {
    ariaLabel: 'Klik hier om uw verzoek tot inruilwaardering te annuleren',
    text: 'Annuleren'
  },
  fi_fi: {
    ariaLabel: 'Peruuta arvonmäärityspyyntösi klikkaamalla tätä',
    text: 'Peruuta'
  },
  de_at: {
    ariaLabel:
      'Klicken Sie hier, um Ihre Schätzung zur Inzahlungnahme zu stornieren.',
    text: 'ABBRECHEN'
  },
  da_dk: {
    ariaLabel:
      'Klik her for at annullere din anmodning om værdiansættelse af indbytning',
    text: 'Fortryd'
  },
  en_ie: {
    ariaLabel: 'Click here to cancel your trade-in valuation request',
    text: 'Cancel'
  },
  cs_cz: {
    ariaLabel:
      'Klepnutím sem stornujete svoji žádost o nacenění vozu na protiúčet.',
    text: 'Storno'
  },
  pl_pl: {
    ariaLabel:
      'Kliknij tutaj, aby anulować prośbę o wycenę w modelu „wymień stary na nowy”',
    text: 'Wróć do koszyka'
  },
  pt_pt: {
    ariaLabel: 'Clique aqui para cancelar o seu pedido de avaliação de troca',
    text: 'Cancelar'
  },
  el_gr: {
    ariaLabel:
      'Κάντε κλικ εδώ για να ακυρώσετε το αίτημά σας για εμπορική αποτίμηση',
    text: 'Άκυρο'
  },
  hu_hu: {
    ariaLabel:
      'Kattintson ide a beszámítási értékbecslésre vonatkozó kérelme törléséhez',
    text: 'Mégse'
  },
  ro_ro: {
    ariaLabel:
      'Fă clic aici pentru a anula solicitarea ta de evaluare a vehiculului pentru schimb',
    text: 'Revocare'
  }
}
