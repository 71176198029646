export const nonLetters = /[^a-zA-Z]/g
export const nonNumbers = /[^0-9]/g
export const nonAlphanumeric = /[^a-zA-Z0-9]/g
export const nonAlphanumericDash = /[^a-zA-Z0-9-]/g
export const nonAlphanumericDashSpace = /[^a-zA-Z0-9\s-]/g
export const nonAlphanumericSpace = /[^a-zA-Z0-9\s]/g
export const asciiSymbols = /[!-\/:-@[-`{-~]/g
export const asciiSymbolsDash = /[!\/:-@[-`{-~]/g
export const dashSpace = / |-/g
export const vinRegex = /\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/gm

export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const formattingCharacters = /^[,/()]+$/
