import React, { useContext, useState, useEffect } from 'react'
import { AppContext, AppACTIONS } from '../context/AppContext'
import Icon from '../icons/Icon'
import { getSteps } from '../../../constants/step'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import { navigate } from 'hookrouter'

const StyledStepTracker = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
  &.hide {
    display: none;
  }
  .tradein-stepTracker-container {
    .fmc-tracker__step .fmc-tracker__dot {
      letter-spacing: 2px;
      &:focus {
        outline: none;
      }
      &:focus-visible {
        + .fmc-tracker__title::before {
          pointer-events: none;
          position: absolute;
          width: 60px;
          height: 60px;
          border: var(--fds-outline);
          content: '';
          bottom: 21px;
          right: calc(30% + 20px);
          @media ${mediaQueries.underS} {
            right: calc(18% + 20px);
          }
        }
      }
      .fmc-tracker[data-steps='2'] {
        .fmc-tracker__step
          .fmc-tracker__dot:focus-visible
          + .fmc-tracker__title::before {
          @media ${mediaQueries.underS} {
            right: calc(18% + 30px);
          }
        }
      }
    }
    .fmc-tracker__dot--success .fds-font--ford-icons__success {
      background-repeat: no-repeat no-repeat;
      background-position: center center;
      background-size: 100%;
      width: 20px;
      height: 20px;
      display: block;
      animation: showEdit 1.5s forwards;
      &::before {
        animation: showTick 1.5s forwards;
      }
    }
    @keyframes showTick {
      100% {
        content: '';
      }
    }
    @keyframes showEdit {
      100% {
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m15 5.143-10 10M12 2.143l-10 10M5 15.143l-4 1 1-4zM15 5.143c1.334-1.333 1.501-2.5.5-3.5s-2.168-.834-3.5.5l3 3z'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
  }
`

const StepTracker = () => {
  const { appState, appDispatch, brand } = useContext(AppContext)
  const { visitedSteps, financeType, completedSteps, currentStep } = appState
  const [showTracker, setShowTracker] = useState(true)

  const goToSteps = (s) => {
    if (visitedSteps.find((step) => step === s.nav)) {
      appDispatch({ type: AppACTIONS.jumpToStepUpdated, payload: s.nav })
      navigate('/' + s.nav)
    }
  }

  const hideTracker = () => {
    if (currentStep !== 'tradeinappraisal' || currentStep !== 'message') {
    }
  }

  useEffect(() => {
    if (window.location.pathname.includes('tradeinappraisal')) {
      setShowTracker(false)
    } else {
      setShowTracker(true)
    }
  })

  const steps = getSteps(financeType)

  return (
    <StyledStepTracker className={`brand ${showTracker ? '' : 'hide'}`}>
      <div className='tradein-stepTracker-container'>
        <ul className='fmc-tracker ' data-steps={steps.length}>
          {steps.map((s) => {
            return (
              <li
                className={`fmc-tracker__step ${
                  completedSteps.find((step) => step === s.nav)
                    ? 'fmc-tracker__step--complete'
                    : ''
                }`}
              >
                <button
                  className={`fmc-tracker__dot
                  ${
                    s.nav === currentStep
                      ? 'fmc-tracker__dot--active'
                      : completedSteps.find((step) => step === s.nav)
                      ? 'fmc-tracker__dot--success'
                      : ''
                  }`}
                  onClick={() => goToSteps(s)}
                  aria-label={s.ariaLabel}
                >
                  {!completedSteps.find((step) => step === s.nav) ||
                  s.nav === currentStep ? (
                    s.step + '/' + steps.length
                  ) : (
                    <span className='fds-icon fds-font--ford-icons__success'></span>
                  )}
                </button>
                <p className='fmc-tracker__title'>{s.name}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </StyledStepTracker>
  )
}

export default StepTracker
