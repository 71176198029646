import { createContext } from 'react'

export const AppContext = createContext()

export const AppACTIONS = {
  financeTypeUpdated: 'FINANCE_TYPE_UPDATED',
  jumpToStepUpdated: 'JUMP_TO_STEP_UPDATED',
  visitedStepsUpdated: 'VISITED_STEPS_UPDATED',
  completedStepsUpdated: 'COMPLETED_STEPS_UPDATED',
  stepsReset: 'STEPS_RESET',
  showAreYouSureModalUpdated: 'RENDER_ARE_YOU_SURE_MODAL',
  isGuaranteedFlowUpdated: 'IS_GUARANTEED_FLOW_UPDATED_UPDATED'
}

export const appReducer = (state, action) => {
  switch (action.type) {
    case AppACTIONS.financeTypeUpdated:
      console.log(
        `Finance Type Updated from ${state.financeType} to ${action.payload}`
      )
      return { ...state, financeType: action.payload }
    case AppACTIONS.jumpToStepUpdated:
      console.log(
        `Jump-To Step Updated (Via AppContext Reducer) from ${state.currentStep} to ${action.payload}`
      )
      return {
        ...state,
        currentStep: action.payload,
        previousStep: state.currentStep
      }
    case AppACTIONS.isGuaranteedFlowUpdated:
      console.log(
        `Flow Type Updated from ${state.isGuaranteedFlow} to ${action.payload}`
      )
      return { ...state, isGuaranteedFlow: action.payload }
    case AppACTIONS.visitedStepsUpdated:
      console.log(
        `Visted Steps updated (Via Appcontext Reducer) to include ${action.payload}`
      )
      return {
        ...state,
        visitedSteps: state.visitedSteps.concat(action.payload)
      }
    case AppACTIONS.completedStepsUpdated:
      console.log(
        `Completed Steps updated (Via Appcontext Reducer) to include ${action.payload}`
      )
      return {
        ...state,
        completedSteps: state.completedSteps.concat(action.payload)
      }
    case AppACTIONS.showAreYouSureModalUpdated:
      console.log(`Render Are You Sure Modal (Via Appcontext Reducer)`)
      return {
        ...state,
        showAreYouSureModal: action.payload
      }

    case AppACTIONS.stepsReset:
      console.log(`Completed and Visited Steps reset (Via Appcontext Reducer)`)
      return {
        ...state,
        visitedSteps: ['i'],
        completedSteps: action.payload
      }
    default:
      return state
  }
}

export const initialAppState = {
  financeType: '',
  isGuaranteedFlow: true,
  currentStep: '',
  previousStep: 'x',
  visitedSteps: ['i'],
  completedSteps: [],
  alwaysValid: ['i', 'ii', 'iii'],
  showAreYouSureModal: false
}
