import React, { useContext, useState, useEffect, useCallback } from 'react'
import { AppContext } from '../../../common/context/AppContext'
import { DataContext } from '../../../common/context/DataContext'
import { useInput, UseRadio } from '../../../common/customhooks/forms'
import StepHeader from '../../../common/pageElements/StepHeader'

import { STEP_HEADER, STEP_BODY, ERROR_MESSAGE, ENGINE } from './Copy'
import { vehicleCondition } from './constants'
import focusError from '../../../../utils/focusError'

import * as regex from '../../../../utils/regex'
import * as filters from '../../../../utils/filters'
import TradeinButton from '../../../common/pageElements/TradeinButton'
import TextInput from '../../../common/pageElements/TextInput'
import SelectInput from '../../../common/pageElements/SelectInput'
import RadioButton from '../../../common/pageElements/RadioButton'
import PopoverModal from '../../../common/pageElements/PopoverModal'
import { CookieCopy } from '../copy/CookieCopy'

import { dayRange, monthRange } from '../../../../constants/forms'
import api from '../../../../utils/api'
import { StyledVehicleBasicsFR } from '../../../styled-components/screens/StyledVehicleBasics'
import styled from 'styled-components'
import { mediaQueries } from '../../../styled-components/mediaQueries'
import { getTradeInUrl } from '../../../../constants/staticUrls'

const StyledOfflineValuation = styled(StyledVehicleBasicsFR)`
  &&& {
    h1.tradein-main-header {
      font-size: 34px;
      font-weight: 200;
      line-height: 1.29;
      margin-top: 0px;
    }
    .tradein-date-inputs-container {
      margin-bottom: 40px;
      margin: auto;
      @media ${mediaQueries.underS} {
        flex-wrap: wrap;
        .tradein-selectInput-wrap {
          flex: unset;
        }
      }
    }
    .tradein-vc-cb-wrapper {
      border-bottom: var(--borderInput);
      max-width: 810px;
      margin: auto;
    }
    .tradein-vb-column {
      max-width: 656px;
      margin: auto;
      div[role='radiogroup'] {
        .tradein-rb-wrapper {
          width: 100%;
        }
      }
      .tradein-inputs-container {
        margin: auto;
      }
    }
    .tradein-card-row {
      .tradein-card-subtitle {
        margin-bottom: 0px;
      }
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .fmc-check .radio-text-list p {
      font-size: 11px;
      &:nth-child(1) {
        font-size: 12px;
        margin-bottom: 15px;
      }
    }

    .tradein-buttons-container {
      margin-top: 100px;
    }
    .tradein-rb-text .radio-text-list p {
      font-size: var(--fz-xxs);
      color: var(--primary);
    }

    .tradein-cookie-eu {
      background: #1b394e;
      padding: 32px;
      position: fixed;
      bottom: 0;
      z-index: 2;
      width: 100%;
      left: 0;
      text-align: center;
      p {
        color: white;
        font-size: var(--fz-sm);
      }
      button {
        background: #2d96cd;
        color: white;
        padding: 8px 10px;
        text-align: center;
        width: 385px;
        display: block;
        margin: 15px auto;
        height: 40px;
      }
    }
  }
`
const CookieModalUK = styled(PopoverModal)`
  .tradein-popover-modal {
    text-align: left;
    color: #102b4e;
    font-family: var(--fontSecondary);
    .tradein-body-wrap {
      padding: 48px 15px 32px;
      h3.tradein-popover-modal-header-text {
        font-size: var(--fz-xl);
        letter-spacing: 3px;
        color: #102b4e;
        font-weight: 400;
        margin: 20px 0;
      }
      h4.cookie-subheading {
        font-size: var(--fz-md);
        color: var(--linkBlue);
        font-size: 21px;
        width: 100%;
      }
      p {
        font-size: var(--fz-xs);
        line-height: 15px;
        ul {
          line-height: 20px;
          padding: 6px 0 6px 25px;
          li a {
            color: var(--linkBlue);
          }
        }
      }
      .tradein-cookie-preferences {
        .tradein-cookie-row {
          margin: 20px 0;
          .fmc-toggle[disabled] {
            .fmc-toggle__checked-option {
              visibility: visible;
            }
            .fmc-toggle__track {
              position: relative;
              &.strictly {
                background: var(--linkBlue);
                &:before {
                  display: none;
                }
                &:after {
                  display: none;
                }
              }
            }
          }
          .fmc-toggle__track {
            color: white;
          }
          .fmc-toggle input + .fmc-toggle__track {
            &:after {
              display: block;
              content: 'Off';
              position: absolute;
              right: 15px;
            }
          }
          .fmc-toggle input:checked + .fmc-toggle__track {
            background: var(--linkBlue);
            &:after {
              display: block;
              content: 'On';
              position: absolute;
              left: 15px;
            }
          }
        }
      }
    }

    .tradein-popover-modal-inner {
      max-width: 1030px;
      z-index: 4;
      top: 5%;
      overflow: scroll;
      height: 85vh;

      .tradein-popover-modal-body {
        margin: 10px 0;
        padding-bottom: 0;
      }
      .tradein-popover-modal-header {
        border-bottom: var(--borderLight);
      }
    }
  }
`

const OfflineValuationEU = () => {
  const { exitUrl, market, referralId, application } = useContext(AppContext)
  let { language, buildShowroomUrl } = useContext(AppContext)
  const { dataState, dataDispatch } = useContext(DataContext)
  const [showErrors, setShowErrors] = useState(false)

  language = language || 'en_ie'
  const radioObj = vehicleCondition[language].map((item) => UseRadio(item))

  const [currentMthYr, setCurrentMthYr] = useState({})
  const [currentRegDay, setCurrentRegDay] = useState(null)

  const [yearRange, setYearRange] = useState()
  const [newDateRange, setNewDateRange] = useState(dayRange)
  const [disableDate, setDisableDate] = useState(true)

  const [showCookieModal, setShowCookieModal] = useState(false)
  const [showCookieBanner, setShowCookieBanner] = useState(true)

  const [cookiePerformance, setCookiePerformance] = useState(false)
  const [cookieFunctionality, setCookieFunctionality] = useState(false)
  const [cookieTargeting, setCookieTargeting] = useState(false)

  const stepBodyCopy = STEP_BODY[language]

  const appraisePayload = {
    countryCode: market,
    referralId,
    tradeInUrl: getTradeInUrl()
  }

  // All the yes values in different languages
  const optionYes = vehicleCondition[language][0].options[0]
  const conditionValues = ['EXCELLENT', 'GOOD', 'AVERAGE', 'FAIR', 'POOR']

  useEffect(() => {
    const currentYear = new Date().getFullYear()
    const currentYearRange = []

    // 30 = max years we accept
    for (let i = currentYear - 30; i <= currentYear; i++) {
      currentYearRange.push({ value: i, label: i })
    }
    setYearRange(currentYearRange.reverse())
  }, [])

  const stepHeaderCopy = STEP_HEADER[language]
  const stepErrorMessageCopy = ERROR_MESSAGE[language]
  // might convert to enum in the future

  const engineSelect = ENGINE[language].map((x, i) => ({
    value: x.toUpperCase(),
    label: x.toUpperCase(),
    index: i
  }))

  const regDay = useInput({
    initialValue: '',
    labelReader: stepBodyCopy.dateLabelReader,
    label: stepBodyCopy.dateLabel,
    ariaLabel: stepBodyCopy.dateLabel,
    placeholder: stepBodyCopy.datePlaceholder,
    selectId: 'offline_day'
  })

  const regMonth = useInput({
    initialValue: '',
    labelReader: stepBodyCopy.monthLabelReader,
    label: stepBodyCopy.monthLabel,
    ariaLabel: stepBodyCopy.monthLabel,
    placeholder: stepBodyCopy.monthPlaceholder,
    selectId: 'offline_month'
  })

  const regYear = useInput({
    initialValue: '',
    labelReader: stepBodyCopy.yearLabelReader,
    label: stepBodyCopy.yearLabel,
    ariaLabel: stepBodyCopy.yearLabel,
    placeholder: stepBodyCopy.yearPlaceholder,
    selectId: 'offline_year'
  })

  const make = useInput({
    label: stepBodyCopy.makeLabel,
    placeholder: stepBodyCopy.makeLabel,
    ariaLabel: stepBodyCopy.makeAriaLabel,
    invalidCharacters: regex.asciiSymbols,
    filter: filters.valueToUpperCase,
    id: 'offline_make'
  })

  const model = useInput({
    label: stepBodyCopy.modelLabel,
    placeholder: stepBodyCopy.modelLabel,
    ariaLabel: stepBodyCopy.modelAriaLabel,
    invalidCharacters: regex.asciiSymbols,
    filter: filters.valueToUpperCase,
    id: 'offline_model'
  })

  const engine = useInput({
    label: stepBodyCopy.enginePlaceholder || stepBodyCopy.engineLabel,
    placeholder: stepBodyCopy.enginePlaceholder || stepBodyCopy.engineLabel,
    tooltip: stepBodyCopy.engineTooltip,
    ariaLabel: stepBodyCopy.engineAriaLabel,
    tooltipAriaLabel: stepBodyCopy.engineTooltipHover,
    filter: filters.valueToUpperCase,
    selectId: 'offline_engine'
  })

  const mileageInput = useInput({
    label: stepBodyCopy.mileageLabel,
    placeholder: stepBodyCopy.mileageLabel,
    ariaLabel: stepBodyCopy.mileageAriaLabel,
    invalidCharacters: regex.nonNumbers,
    filter: filters.numberCommasEU,
    id: 'offline_mileage',
    maxLength: {
      value: 11
    }
  })

  const valid = (currentValue) =>
    typeof currentValue.value === 'string' && currentValue.value.length > 0

  const validFields = [make, model]

  const pageValid =
    validFields.every(valid) &&
    radioObj.every(valid) &&
    engine.value &&
    regDay.value.value &&
    mileageInput.value

  function handleSubmit() {
    setShowErrors(true)

    if (pageValid) {
      const registrationDate = createDateString(
        regDay.value.value,
        regMonth.value.value,
        regYear.value.value
      )

      const vehMake = make.value.toUpperCase()
      const vehModel = model.value.toUpperCase()
      const vehEngine = engine.value.value
      const mileage = mileageInput.value

      saveValues(registrationDate, vehMake, vehModel, vehEngine, mileage)

      radioObj.forEach((item) => {
        if (item.apiField !== 'overallCondition') {
          const val = item.value === optionYes
          if (item.apiField) {
            appraisePayload[item.apiField] = val
          }
        } else {
          if (item.apiField) {
            // const lastIndex = Object.keys(vehicleCondition[language]).length - 1
            // const selectedCondition =
            //   language === 'el_gr' || language === 'de_at'
            //     ? item.value
            //     : filters.valueToCamelCase(item.value)
            // const conditionIndex =
            //   vehicleCondition[language][lastIndex].options.indexOf(
            //     selectedCondition
            //   )
            // appraisePayload[item.apiField] = conditionValues[conditionIndex]
            appraisePayload[item.apiField] = conditionValues[item.value]
          }
        }
      })
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
      /* API Call - Submit Offline */
      api
        .acceptAppraisalOffline(appraisePayload)
        .then((data) => {
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          window.location.assign(
            application === 'SHOWROOM'
              ? buildShowroomUrl(exitUrl, dataState.appraisalId)
              : exitUrl
          )
        })
        .catch((error) => {
          console.log(error)
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
        })
    } else {
      const invalidFields = [
        regYear,
        regMonth,
        regDay,
        make,
        model,
        engine,
        mileageInput,
        radioObj
      ]

      focusError(invalidFields)
    }
  }

  function saveValues(registrationDate, vehMake, vehModel, vehEngine, mileage) {
    appraisePayload.registrationDate = registrationDate
    appraisePayload.make = vehMake
    appraisePayload.model = vehModel
    appraisePayload.engine =
      language === 'en_ie' ? vehEngine : getStandardizedEngineValue(vehEngine)
    appraisePayload.mileage = mileage
  }

  function getStandardizedEngineValue(vehEngine) {
    switch (
      engineSelect.find(function (engine) {
        return engine.label === vehEngine
      }).index
    ) {
      case 0:
        return 'GASOLINE'
      case 1:
        return 'DIESEL'
      case 2:
        return 'ELECTRIC'
      case 3:
        return 'HYBRID'
      default:
        return null
    }
  }

  function createDateString(day, month, year) {
    const datesWithDay = [year, month, day].join('-')
    const datesWithoutDay = [year, month].join('-')
    if (day) {
      return datesWithDay
    } else {
      return datesWithoutDay
    }
  }

  const updateDayRange = (type) => (e) => {
    const handleDayUpdated = (year, month) => {
      const numberOfDays = new Date(year, month, 0).getDate()
      const currentDate = regDay.value.value
      const daysObj = []
      for (let i = 0; i < numberOfDays; i++) {
        const days = i + 1
        const v = days < 10 ? '0' + days : days
        daysObj.push({ value: v, label: v })
      }
      if (currentDate > numberOfDays) {
        // reset regDay's values
        setCurrentRegDay(null)
        // regDay.value.value = null
        regDay.valid = false

        setNewDateRange(daysObj)
      } else {
        setDisableDate(false)
        setNewDateRange(daysObj)
      }
    }
    if (type === 'year') {
      setCurrentMthYr({
        ...currentMthYr,
        year: e.value
      })
      if (currentMthYr.month) {
        handleDayUpdated(e.value, currentMthYr.month)
      }
    } else {
      setCurrentMthYr({
        ...currentMthYr,
        month: e.value
      })
      if (currentMthYr.year) {
        handleDayUpdated(currentMthYr.year, e.value)
      }
    }
  }

  const handleDateChange = (e) => {
    setCurrentRegDay(e.value)
  }

  const renderCookieEu = () => {
    const guxfoeCookie = document.cookie.includes('guxfoe-cookiedisclaimer')
    if (!guxfoeCookie && market === 'GB' && showCookieBanner) {
      return (
        <div className='tradein-cookie-eu'>
          <p>{CookieCopy['en_gb'].bannerText1}</p>
          <button onClick={() => setShowCookieModal(true)}>
            {CookieCopy['en_gb'].btnManage}
          </button>
          <button onClick={() => saveCookies(true)}>
            {CookieCopy['en_gb'].btnAccept}
          </button>
          <p>{CookieCopy['en_gb'].bannerText2}</p>
        </div>
      )
    }
  }

  function onCookieChange(e) {
    if (e.currentTarget.id === 'cookiePerformance' && !cookiePerformance) {
      setCookiePerformance(true)
    } else if (
      e.currentTarget.id === 'cookieFunctionality' &&
      !cookieFunctionality
    ) {
      setCookieFunctionality(true)
    } else if (e.currentTarget.id === 'cookieTargeting' && !cookieTargeting) {
      setCookieTargeting(true)
    }
  }

  function saveCookies(all) {
    document.cookie = `guxfoe-cookiedisclaimer=${Date.now()}`
    if (all) {
      const cookieConfig = encodeURI(
        `{timestamp:${Date.now()}, value:{strictly necessary: true, performance: true, functionality: true, targeting: true}}`
      )
      document.cookie = `cookie-configuration=${cookieConfig}`
      setShowCookieModal(false)
      setShowCookieBanner(false)
    } else {
      const cookieConfig = encodeURI(
        `timestamp:${Date.now()}, value:{strictly necessary: true, performance: ${cookiePerformance}, functionality: ${cookieFunctionality}, targeting: ${cookieTargeting}`
      )
      document.cookie = `cookie-configuration=${cookieConfig}`

      setShowCookieModal(false)
      setShowCookieBanner(false)
    }
  }

  return (
    <StyledOfflineValuation>
      <StepHeader mainHead={stepHeaderCopy.main} subHead={stepHeaderCopy.sub} />
      <div className='tradein-vb-column'>
        {/* Month Year Select Dropdown Section */}
        <div className='tradein-inputs-container'>
          <label className='tradein-selectInput-label'>
            {stepBodyCopy.vehRegLabel}
          </label>
          <div className='tradein-date-inputs-container'>
            <SelectInput
              {...regYear}
              customOnSelect={updateDayRange('year')}
              list={yearRange}
              errorMessage={stepErrorMessageCopy.selectInput}
            />
            <SelectInput
              {...regMonth}
              customOnSelect={updateDayRange('month')}
              list={monthRange}
              errorMessage={stepErrorMessageCopy.selectInput}
            />
            {/* {market !== 'GB' && ( */}
            <SelectInput
              {...regDay}
              isDisabled={disableDate}
              list={newDateRange}
              value={
                currentRegDay
                  ? { value: currentRegDay, label: currentRegDay }
                  : regDay.initialValue
              }
              valid={currentRegDay ? !currentRegDay.value : false}
              customOnSelect={handleDateChange}
              errorMessage={stepErrorMessageCopy.selectInput}
            />
          </div>
          {/* TextInput Section */}
          <span style={{ borderTop: '1px solid lightgrey', width: '50%' }} />
          <div className='tradein-offlineText-container'>
            <TextInput
              {...make}
              errorMessage={stepErrorMessageCopy.textInput}
            />
            <TextInput
              {...model}
              errorMessage={stepErrorMessageCopy.textInput}
            />

            <SelectInput
              {...engine}
              errorMessage={stepErrorMessageCopy.selectInput}
              customClass='offline'
              list={engineSelect}
            />

            <TextInput
              {...mileageInput}
              errorMessage={stepErrorMessageCopy.textInput}
            />
          </div>
        </div>
        {/* Radio Section */}
        {radioObj.map((obj, i) => (
          <div className='tradein-vc-cb-wrapper' key={i}>
            <RadioButton
              {...obj}
              directionStyle='vertical'
              index={i}
              showErrors={showErrors}
              errorMessage={stepErrorMessageCopy.radioButton}
              isoffline
              selectText={stepBodyCopy.selectText || ''}
            />
          </div>
        ))}

        {/* Next */}
        <div className='tradein-buttons-container'>
          <TradeinButton
            text={stepBodyCopy.acceptLabel}
            ariaLabel={stepBodyCopy.acceptArialLabel}
            type='USFordPrimary'
            iconType='nextIcon'
            disabled={!pageValid}
            id='offline_confirm'
            action={handleSubmit}
          />
        </div>
      </div>
      {renderCookieEu()}

      {showCookieModal && (
        <CookieModalUK
          closeModal={() => setShowCookieModal(false)}
          showModal
          headerText='Manage My Cookie Preferences '
        >
          <h4 className='cookie-subheading'>
            {CookieCopy['en_gb'].cookieTitle}
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: CookieCopy['en_gb'].cookieText
            }}
          ></p>
          <p>
            {CookieCopy['en_gb'].cookieLinksText}
            <ul
              dangerouslySetInnerHTML={{
                __html: CookieCopy['en_gb'].cookieLinks
              }}
            ></ul>
            <h4 className='cookie-subheading'>
              {CookieCopy['en_gb'].preferencesTitle}
            </h4>
            <div className='tradein-cookie-preferences'>
              {CookieCopy['en_gb'].preferences.map((obj, i) => (
                <div className='tradein-cookie-row'>
                  <label
                    disabled={obj.strictly}
                    className='fmc-toggle fmc-toggle--skeuomorphic'
                  >
                    <span
                      className='fmc-toggle__unchecked-option'
                      aria-hidden='true'
                    ></span>
                    <input
                      aria-label='Toggle Switch'
                      type='checkbox'
                      name='toggle-button-1'
                      id={obj.id}
                      onChange={onCookieChange}
                      disabled={obj.strictly}
                    />
                    <span
                      className={
                        obj.strictly
                          ? 'strictly fmc-toggle__track'
                          : 'fmc-toggle__track'
                      }
                    >
                      {obj.strictly ? 'On' : ''}
                    </span>
                    <span
                      className='fmc-toggle__checked-option'
                      aria-hidden='true'
                    >
                      <div className='preferences-title'>{obj.title}</div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: obj.text
                        }}
                      ></div>
                    </span>
                  </label>
                </div>
              ))}
            </div>
            <div className='tradein-buttons-column'>
              <TradeinButton
                type='primary'
                text='Accept all'
                iconType='nextIcon'
                action={() => saveCookies(true)}
              />
              <TradeinButton
                type='primary'
                text='Save and Exit'
                iconType='nextIcon'
                action={() => saveCookies(false)}
              />
            </div>
          </p>
        </CookieModalUK>
      )}
    </StyledOfflineValuation>
  )
}

export default OfflineValuationEU
