import React, { useEffect, useContext, useState, useReducer } from 'react'
import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import focusError from '../../../utils/focusError'
import SelectInput from '../../common/pageElements/SelectInput'
import { useInput } from '../../common/customhooks/forms'
import TradeinButton from '../../common/pageElements/TradeinButton'
import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders, vehicleBasicsCopy } from './copy/VehicleBasicsCopy'
import api from '../../../utils/api'
import { StyledVehicleBasicsFR } from '../../styled-components/screens/StyledVehicleBasics'
import { navigate } from 'hookrouter'

const VehicleBasicsDE = () => {
  const {
    appDispatch,
    deliveryDate,
    language,
    customerGUID,
    market,
    referralId
  } = useContext(AppContext)

  const { dataDispatch } = useContext(DataContext)
  const marketContent = {
    header: StepHeaders[language],
    copy: vehicleBasicsCopy[language],
    payload: 'tradeinappraisal_EU'
  }
  const marketLowerCase = market.toLowerCase()
  const [allSelectData, setSelectDataState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      make: '',
      model: '',
      year: '',
      design: '',
      trim: '',
      type: '',
      kilo: '',
      variant: ''
    }
  )

  const appraisePayload = {
    deliveryDate: deliveryDate,
    customerGUID: customerGUID,
    referralId: referralId
  }

  function handleSubmit() {
    const makeValid = makeSelect.value.value
    const modelValid = modelSelect.value.value
    const yearValid = yearSelect.value.value
    const designValid = designSelect.value.value
    const variantValid = variantSelect.value.value
    const typeValid = typeSelect.value.value
    const kiloValid = kiloSelect.value.value

    const pageValid =
      makeValid &&
      modelValid &&
      yearValid &&
      designValid &&
      variantValid &&
      typeValid &&
      kiloValid
    if (pageValid) {
      appraisePayload.builtYear = Number(yearValid)
      appraisePayload.datTypeId = typeValid
      appraisePayload.km = Number(kiloValid)
      apiCall(api.appraiseVehicleDE(appraisePayload))
    } else {
      focusError([
        makeSelect,
        modelSelect,
        yearSelect,
        designSelect,
        variantSelect,
        typeSelect,
        kiloSelect
      ])
    }
  }
  function apiCall(apiFn) {
    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })
    apiFn
      .then((data) => {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        dataDispatch({
          type: DataACTIONS.appraisalIdUpdated,
          payload: data.appraisalId
        })
        if (!data.hasOwnProperty('deliveryAppraisalQuote')) {
          appDispatch({
            type: AppACTIONS.jumpToStepUpdated,
            payload: 'sorryCannotAppraise'
          })
          navigate('/sorryCannotAppraise')

          // DE does not have future value
        } else if (!data.currentAppraisalQuote) {
          appDispatch({
            type: AppACTIONS.jumpToStepUpdated,
            payload: 'sorryCannotAppraise'
          })
          navigate('/sorryCannotAppraise')
        } else {
          saveValue(
            makeSelect.value.label,
            modelSelect.value.value,
            yearSelect.value.value,
            data.currentAppraisalQuote
          )
          appDispatch({
            type: AppACTIONS.jumpToStepUpdated,
            payload: marketContent.payload
          })
          navigate('/tradeinappraisal_EU')
        }
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: 'sorryCannotAppraise'
        })
      })
  }

  function saveValue(make, modelId, year, quote) {
    dataDispatch({
      type: DataACTIONS.vehicleMakeUpdated,
      payload: make
    })
    dataDispatch({
      type: DataACTIONS.vehicleModelUpdated,
      payload: modelId
    })
    dataDispatch({
      type: DataACTIONS.vehicleYearUpdated,
      payload: year
    })
    dataDispatch({
      type: DataACTIONS.currentAppraisalQuoteUpdated,
      payload: quote
    })
  }

  const makeSelect = useInput({
    initialValue: '',
    label: marketContent.copy.makeSelect.label,
    errorMessage: marketContent.copy.makeSelect.errorMessage,
    placeholder: marketContent.copy.makeSelect.placeholder
  })

  const modelSelect = useInput({
    initialValue: '',
    label: marketContent.copy.modelSelect.label,
    errorMessage: marketContent.copy.modelSelect.errorMessage,
    placeholder: marketContent.copy.modelSelect.placeholder
  })

  const yearSelect = useInput({
    initialValue: '',
    label: marketContent.copy.yearSelect.label,
    errorMessage: marketContent.copy.yearSelect.errorMessage,
    placeholder: marketContent.copy.yearSelect.placeholder
  })

  const designSelect = useInput({
    initialValue: '',
    label: marketContent.copy.designSelect.label,
    errorMessage: marketContent.copy.designSelect.errorMessage,
    placeholder: marketContent.copy.designSelect.placeholder
  })

  const variantSelect = useInput({
    initialValue: '',
    label: marketContent.copy.variantSelect.label,
    errorMessage: marketContent.copy.variantSelect.errorMessage,
    placeholder: marketContent.copy.variantSelect.placeholder
  })

  const typeSelect = useInput({
    initialValue: '',
    label: marketContent.copy.typeSelect.label,
    errorMessage: marketContent.copy.typeSelect.errorMessage,
    placeholder: marketContent.copy.typeSelect.placeholder
  })

  const kiloSelect = useInput({
    initialValue: '',
    label: marketContent.copy.kiloSelect.label,
    errorMessage: marketContent.copy.kiloSelect.errorMessage,
    placeholder: marketContent.copy.kiloSelect.placeholder
  })

  useEffect(() => {
    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })
    api
      .deMake()
      .then((data) => {
        let initialSelectData = {}

        const vehicleMake = data.map((el) => {
          const name = el.name
          return { value: el.id, label: name }
        })
        initialSelectData.make = vehicleMake
        api.deKilo().then((data) => {
          const vehicleKilo = data.map((el) => {
            const name = el.name
            return { value: el.id, label: name }
          })
          initialSelectData = { ...initialSelectData, kilo: vehicleKilo }
          setSelectDataState(initialSelectData)
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
        })
      })

      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }, [dataDispatch])

  const initialSelectState = {
    make: '',
    makeId: '',
    model: '',
    modelId: '',
    year: '',
    design: '',
    designId: '',
    variant: '',
    variantId: '',
    type: ''
  }

  const selectArr = [
    'makeId',
    'modelId',
    'year',
    'designId',
    'variantId',
    'typeId'
  ]

  function selectReducer(state, action) {
    const { type, value, label, updateType } = action
    const updateTypeId = updateType === 'year' ? updateType : updateType + 'Id'
    let resetObj = ''

    if (type === 'reset') {
      // take the index of the item in the array
      const arrIndex = selectArr.indexOf(updateTypeId)
      // start the loop from the index and reset the next ones
      for (let i = arrIndex + 1; i < selectArr.length; i++) {
        resetObj = state[selectArr[i]] = ''
      }
    }

    switch (type) {
      case 'updateSelect':
        return { ...state, [updateType]: label, [updateTypeId]: value }
      case 'reset':
        return {
          ...state,
          [updateType]: label,
          [updateType + 'Id']: value,
          resetObj
        }
      default:
        throw new Error()
    }
  }

  const [selectState, selectDispatch] = useReducer(
    selectReducer,
    initialSelectState
  )

  const selectHandler = (type) => (e) => {
    const apiField = e.value
    const displayLabel = e.label

    selectDispatch({
      type: 'updateSelect',
      updateType: type,
      label: displayLabel,
      value: apiField
    })

    if (type !== 'type') {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
    }

    let apiPayload = selectState
    let apiCall

    switch (type) {
      case 'make':
        apiPayload = { makeId: apiField }
        apiCall = 'Model'

        if (selectState.modelId || selectState.typeId) {
          modelSelect.value.value = null
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break

      case 'model':
        apiPayload = { makeId: selectState.makeId, modelId: apiField }
        apiCall = 'Year'
        if (selectState.year || selectState.typeId) {
          yearSelect.value.value = null
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break

      case 'year':
        apiPayload = {
          makeId: selectState.makeId,
          modelId: selectState.modelId,
          year: apiField
        }
        apiCall = 'Design'
        if (selectState.designId || selectState.typeId) {
          designSelect.value.value = null
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'design':
        apiPayload = {
          makeId: selectState.makeId,
          modelId: selectState.modelId,
          year: selectState.year,
          designId: apiField
        }
        apiCall = 'Variant'
        if (selectState.variantId || selectState.typeId) {
          variantSelect.value.value = null
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'variant':
        apiPayload = { ...apiPayload, modelVariantId: apiField }
        apiCall = 'Type'
        if (selectState.typeId) {
          typeSelect.value.value = null
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'type':
        break
      default:
        break
    }

    const handleFn = (data) => {
      const selectData = data.map((el) => {
        return { value: el.id, label: el.name }
      })

      const yearSelectData = selectData.slice().reverse()

      setSelectDataState({
        ...allSelectData,
        [apiCall.toLowerCase()]: type !== 'model' ? selectData : yearSelectData
      })
    }
    if (type !== 'type') {
      handleApiCall(marketLowerCase + apiCall, apiPayload, handleFn)
    }
  }

  function handleApiCall(apiRoute, payload, handleResultsFn) {
    const { makeId, modelId, year, modelVariantId, designId } = payload
    const apiPayload = { makeId, modelId, year, modelVariantId, designId }
    api[apiRoute](apiPayload)
      .then((data) => {
        handleResultsFn(data)

        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  return (
    <StyledVehicleBasicsFR>
      <div className='tradein-vb-column'>
        <StepHeader
          mainHead={marketContent.header.main}
          subHead={marketContent.header.sub}
          maxWidth='420px'
        />
        <div className='tradein-inputs-container'>
          <SelectInput
            {...makeSelect}
            list={allSelectData.make}
            customOnSelect={selectHandler('make')}
          />
          <SelectInput
            {...modelSelect}
            customOnSelect={selectHandler('model')}
            isDisabled={!selectState.makeId}
            list={allSelectData.model}
            value={
              selectState.modelId
                ? {
                    value: selectState.modelId,
                    label: selectState.model
                  }
                : {
                    value: null,
                    label: marketContent.copy.modelSelect.placeholder
                  }
            }
            valid={selectState.modelId || false}
          />

          <SelectInput
            {...yearSelect}
            customOnSelect={selectHandler('year')}
            isDisabled={!selectState.modelId}
            list={allSelectData.year}
            value={
              selectState.year
                ? {
                    value: selectState.yearId,
                    label: selectState.year
                  }
                : {
                    value: null,
                    label: marketContent.copy.yearSelect.placeholder
                  }
            }
            valid={selectState.year || false}
          />
          <SelectInput
            {...designSelect}
            customOnSelect={selectHandler('design')}
            isDisabled={!selectState.year}
            list={allSelectData.design}
            value={
              selectState.designId
                ? {
                    value: selectState.designId,
                    label: selectState.design
                  }
                : {
                    value: null,
                    label: marketContent.copy.designSelect.placeholder
                  }
            }
            valid={selectState.designId || false}
          />
          <SelectInput
            {...variantSelect}
            customOnSelect={selectHandler('variant')}
            isDisabled={!selectState.designId}
            list={allSelectData.variant}
            value={
              selectState.variantId
                ? {
                    value: selectState.variantId,
                    label: selectState.variant
                  }
                : {
                    value: null,
                    label: marketContent.copy.variantSelect.placeholder
                  }
            }
            valid={selectState.variantId || false}
          />
          <SelectInput
            {...typeSelect}
            customOnSelect={selectHandler('type')}
            isDisabled={!selectState.variantId}
            list={allSelectData.type}
            value={
              selectState.typeId
                ? {
                    value: selectState.typeId,
                    label: selectState.type
                  }
                : {
                    value: null,
                    label: marketContent.copy.typeSelect.placeholder
                  }
            }
            valid={selectState.typeId || false}
          />
          <SelectInput {...kiloSelect} list={allSelectData.kilo} />
        </div>
        <div className='tradein-buttons-container'>
          <TradeinButton
            ariaLabel={marketContent.copy.forwardButton.ariaLabel}
            type='primary'
            text={marketContent.copy.forwardButton.text}
            iconType='nextIcon'
            action={handleSubmit}
          />
        </div>
      </div>
    </StyledVehicleBasicsFR>
  )
}

export default VehicleBasicsDE
