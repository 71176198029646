import styled from 'styled-components'
import PopoverModal from '../../common/pageElements/PopoverModal'
import { mediaQueries } from '../../styled-components/mediaQueries'

export const StyledCancelModal = styled(PopoverModal)`
  &&& {
    .tradein-popover-modal {
      .tradein-popover-modal-body {
        padding: 30px 25px;
        padding-top: 0;
        @media ${mediaQueries.underS} {
          height: 80%;
        }
      }
      .tradein-popover-modal-inner {
        height: 419px;
      }
      .tradein-popover-modal-header-text {
        margin-top: 16px;
        font-size: var(--fz-xl);
        line-height: 1.17;
        letter-spacing: 1px;
        color: var(--secondary);
        text-transform: none;
        font-family: var(--fontPrimary);
        font-weight: 500;
      }
      .tradein-cancel-modal-subtitle {
        max-width: 540px;
        line-height: 1.5;
        margin: 13px 10px 45px 10px;
        @media ${mediaQueries.underS} {
          text-align: center;
          margin: 10% 0;
        }
        p {
          color: var(--fordSlate);
        }
      }
      .tradein-buttons-container {
        flex-direction: column;
        @media ${mediaQueries.underM} {
          flex-direction: column;
        }
        margin-top: 0;
        justify-content: space-evenly;
        max-width: 500px;
        button {
          box-shadow: none;
          /* width: 200px; */
        }
        a {
          margin-top: 24px;
        }
      }
    }
    &.lincoln {
      .tradein-buttons-container {
        flex-direction: column;
        a {
          padding-top: 5px;
        }
        button {
          &:hover {
            box-shadow: inset 0 -3px 0 var(--defaultHover);
          }
        }
      }
    }
  }
`
