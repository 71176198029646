import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import Icon from '../icons/Icon'

const StyledLink = styled.a`
  && {
    font-family: var(--fontPrimary);
    text-align: left;
    color: var(--secondary);
    text-decoration: underline;
    display: block;
    svg {
      margin-left: 15px;
    }
    .primary-link-text {
      font-size: var(--fz-xs);
    }
    :focus {
      outline: 1px solid var(--secondary);
      outline-offset: 8px;
      color: var(--grabber);
    }
    :hover {
      color: var(--grabber);
    }
    @media ${mediaQueries.underS} {
      font-size: var(--fz-xxs);
    }
    &.lincoln {
      text-decoration: ${(props) => (props.primary ? 'underline' : 'none')};
      border-bottom: ${(props) =>
        props.primary ? '0' : '2px solid var(--lincolnOrange)'};
      line-height: 1.2;
      :hover {
        border-bottom: ${(props) =>
          props.primary ? '0' : '2px solid var(--mediumBlue)'};
        color: var(--mediumBlue);
      }
      :focus {
        border-bottom: ${(props) =>
          props.primary ? '0' : '2px solid var(--mediumBlue)'};
        color: var(--mediumBlue);
      }
      /* display: inline; */
      font-weight: 600;
      font-size: var(--fz-md);
    }
  }
`

const FordStyledLink = styled(StyledLink)`
  && {
    text-decoration: none;
    color: var(--fordAccentBlue);
    svg {
      color: white;
      g {
          fill: var(--fordAccentBlue);
        }
      }

      vertical-align: middle;
      margin-left: 16px;
      font-size: 1.6rem;
    }
  }
`

const TradeinLink = ({
  type,
  link,
  text,
  action,
  ariaLabel,
  className,
  icon
}) => {
  const { brand } = useContext(AppContext) || ''
  const linkClassName = className
  switch (type) {
    case 'primary':
      return (
        <StyledLink
          aria-label={ariaLabel}
          onClick={action}
          className={linkClassName}
          href={link || '#'}
          target={link ? '_blank' : ''}
          primary
          tabIndex='0'
        >
          <span className='primary-link-text'>{text}</span>
        </StyledLink>
      )
    case 'secondary':
      return (
        <StyledLink
          aria-label={ariaLabel}
          className={linkClassName}
          onClick={action}
          href={link}
          target={link ? '_blank' : ''}
          secondary
          tabIndex='0'
        >
          <span className='secondary-link-text'>{text}</span>
          {icon && <Icon icon='newFordClose' title='Close Tooltip' />}
        </StyledLink>
      )
    case 'fordSecondary':
      return (
        <FordStyledLink
          aria-label={ariaLabel}
          className={linkClassName}
          onClick={action}
          href={link}
          target={link ? '_blank' : ''}
          secondary
          tabIndex='0'
        >
          <span className='secondary-link-text'>{text}</span>
          {icon && <Icon icon={icon} title='Close Tooltip' />}
        </FordStyledLink>
      )
    default:
      return (
        <StyledLink
          aria-label={ariaLabel}
          className={className}
          onClick={action}
        >
          <span>{text}</span>
        </StyledLink>
      )
  }
}

TradeinLink.propTypes = {
  ariaLabel: PropTypes.string,
  action: PropTypes.func,
  link: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string.isRequired
}

TradeinLink.defaultProps = {
  action: () => console.log('ForwardButton, Default Prop Action Was Clicked'),
  text: 'Submit',
  type: 'submit'
}

export default TradeinLink
