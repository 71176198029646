import React, { useContext, useState, useEffect } from 'react'
import { navigate } from 'hookrouter'

import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import * as regex from '../../../utils/regex'
import * as filters from '../../../utils/filters'
import focusError from '../../../utils/focusError'
import TextInput from '../../common/pageElements/TextInput'
import SelectInput from '../../common/pageElements/SelectInput'
import RadioButton from '../../common/pageElements/RadioButton'
import { useInput, UseRadio } from '../../common/customhooks/forms'
import TradeinButton from '../../common/pageElements/TradeinButton'
import StepHeader from '../../common/pageElements/StepHeader'
import ScrollToTop from '../../common/pageElements/ScrollToTop'

import { StepHeaders } from './Copy'
import { vehicleColors } from '../../../constants/forms.js'
import { StyledVehicleBasics } from '../../styled-components/screens/StyledVehicleBasics'
import { pageLoadTags } from '../../../utils/analytics/index'
import api from '../../../utils/api'

const VehicleBasics = () => {
  const { appState, appDispatch, brand, market, isGuaranteedFlow } =
    useContext(AppContext)
  const { dataState, dataDispatch } = useContext(DataContext)
  const [showErrors, setShowErrors] = useState(false)
  const { trim, mileage, twoOrMoreKeys, color, appraisalId } = dataState
  const [isloaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!isloaded) {
      setLoaded(true)
      pageLoadTags.vehicleBasics()
    }
    if (appState.visitedSteps.indexOf('vehiclebasics') === -1) {
      appDispatch({
        type: AppACTIONS.visitedStepsUpdated,
        payload: 'vehiclebasics'
      })
    }
  }, [appDispatch, appState.visitedSteps])

  const defaultTrim =
    dataState.trimOptions.length === 1
      ? {
          value: dataState.trimOptions[0].value,
          label: dataState.trimOptions[0].label
        }
      : ''

  let initalKeyValue = ''
  if (twoOrMoreKeys !== null && twoOrMoreKeys !== '') {
    if (brand === 'lincoln') {
      initalKeyValue = twoOrMoreKeys ? 'Yes' : 'No'
    } else {
      initalKeyValue = twoOrMoreKeys ? '2 or more' : '1'
    }
  }

  const keyQuestion = UseRadio({
    id: 'twoSetsOfKeys-radio-group',
    initialValue: initalKeyValue,
    label:
      brand === 'lincoln'
        ? 'Do you have two sets of keys?'
        : 'How many sets of keys do you have for this vehicle?',
    options: brand === 'lincoln' ? ['Yes', 'No'] : ['1', '2 or more']
  })

  const trimInput = useInput({
    initialValue: trim ? { value: trim, label: trim } : defaultTrim,
    label: 'Select Trim'
  })

  const mileageInput = useInput({
    initialValue: mileage ? filters.numberCommas(mileage) : '',
    label: 'Mileage',
    placeholder: 'Enter Mileage',
    filter: filters.numberCommas,
    id: 'vb_mileage',
    invalidCharacters: regex.nonNumbers,
    maxLength: {
      value: 11
    }
  })
  const colorInput = useInput({
    initialValue: color ? { value: color, label: color } : '',
    label: 'Select Color'
  })

  const selectedTrim = trimInput.value.value
  // if keyQuestion.value !== null or ""
  let keyAnswer = ''
  if (keyQuestion.value !== null && keyQuestion.value !== '') {
    keyAnswer = keyQuestion.value
  }
  const trimValid = dataState.trim ? true : selectedTrim
  const mileageValid = dataState.mileage ? true : mileageInput.valid
  const colorValid = dataState.color ? true : colorInput.valid
  const keyValid = keyAnswer !== '' && keyQuestion.value !== ''

  const pageValid = trimValid && mileageValid && colorValid && keyValid

  function handleSubmit() {
    if (dataState.twoOrMoreKeys === null || dataState.twoOrMoreKeys === '')
      setShowErrors(true)
    const selectedTrim = trimInput.value.value
    const enteredMileage = mileageInput.value
      ? mileageInput.value.replace(',', '')
      : null
    const selectedColor = colorInput.value.value

    const pageValid = trimValid && mileageValid && colorValid && keyValid

    if (pageValid) {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
      saveValues(selectedTrim, enteredMileage, selectedColor, keyAnswer)
      if (appState.completedSteps.indexOf('vehiclebasics') === -1) {
        appDispatch({
          type: AppACTIONS.completedStepsUpdated,
          payload: 'vehiclebasics'
        })
      }
    } else {
      focusError([trimInput, mileageInput, keyQuestion, colorInput])
    }
  }

  function saveValues(trim, mileage, color, keyAnswer) {
    const trimValue = trim || ['Not found']
    const colorValue = color.toUpperCase()
    let keyValue = ''
    if (brand === 'lincoln') {
      keyValue = keyAnswer === 'Yes' || false
    } else {
      keyValue = keyAnswer === '2 or more' || false
    }
    const mileageValue = parseInt(mileage)

    dataDispatch({
      type: DataACTIONS.trimUpdated,
      payload: trimValue
    })
    dataDispatch({
      type: DataACTIONS.mileageUpdated,
      payload: mileageValue
    })
    dataDispatch({
      type: DataACTIONS.colorUpdated,
      payload: colorValue
    })
    dataDispatch({
      type: DataACTIONS.keysUpdated,
      payload: keyValue
    })
    api
      .updateAppraisalInfo({
        appraisalId,
        vehicleBasics: {
          color: colorValue,
          mileage: mileageValue,
          trim: trimValue,
          twoOrMoreKeys: keyValue
        }
      })
      .then((response) => {
        const nextStep = 'vehiclecondition'

        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: nextStep
        })
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        navigate('/' + nextStep)
      })
      .catch((error) => {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        console.log(error)
      })
  }

  // handles edit
  // goes back to summary page
  function handleEdit() {
    saveValues(
      trimInput.value.value,
      mileageInput.value.replace(',', ''),
      colorInput.value.value,
      keyQuestion.value
    )

    appDispatch({
      type: AppACTIONS.jumpToStepUpdated,
      payload: 'summary'
    })
    navigate('/summary')
  }

  const vbClass = `${market} ${brand}`

  return (
    <StyledVehicleBasics className={vbClass}>
      <ScrollToTop />
      <StepHeader
        mainHead={StepHeaders.vehicleBasics.main}
        subHead={StepHeaders.vehicleBasics.sub}
        maxWidth={430}
      />
      <div className='tradein-vb-column'>
        <SelectInput {...trimInput} list={dataState.trimOptions} />
        <TextInput {...mileageInput} />

        {isGuaranteedFlow && (
          <p className='tradein-note'>
            Please note that upon turn in, the mileage cannot be more than 250
            miles over the entered amount.
          </p>
        )}

        {brand === 'lincoln' ? (
          <div>
            <RadioButton {...keyQuestion} showErrors={showErrors} />
            <div className='tradein-divider' />{' '}
            <SelectInput list={vehicleColors} {...colorInput} />
            <div className='tradein-buttons-container'>
              {dataState.isEdit === false ? (
                <React.Fragment>
                  <TradeinButton type='back' target='vehiclefound' />
                  <TradeinButton
                    type='primary'
                    text='Next'
                    action={handleSubmit}
                    iconType='nextIcon'
                  />
                </React.Fragment>
              ) : (
                <TradeinButton type='primary' text='Save' action={handleEdit} />
              )}
            </div>
          </div>
        ) : (
          <div>
            <SelectInput list={vehicleColors} {...colorInput} />
            <RadioButton
              {...keyQuestion}
              showErrors={showErrors}
              directionStyle='vertical'
            />
            <div
              className={`tradein-buttons-container tradein-button-ford-wrapper`}
            >
              <TradeinButton
                type='USFordBack'
                text='Back'
                iconType='prevIcon'
                target='vehiclefound'
              />
              <TradeinButton
                type='USFordPrimary'
                text='Continue'
                iconType='nextIcon'
                disabled={!pageValid}
                action={() => handleSubmit()}
              />
            </div>
          </div>
        )}
      </div>
    </StyledVehicleBasics>
  )
}

export default VehicleBasics
