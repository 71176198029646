import React, { useContext, useState } from 'react'
import { navigate } from 'hookrouter'
import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import { StepHeaders, StepBody } from './copy/LandingPageEUCopy'

import * as regex from '../../../utils/regex'
import * as filters from '../../../utils/filters'
import focusError from '../../../utils/focusError'
import api from '../../../utils/api'

import StepHeader from '../../common/pageElements/StepHeader'
import TextInput from '../../common/pageElements/TextInput'
import { useInput } from '../../common/customhooks/forms'
import TradeinButton from '../../common/pageElements/TradeinButton'

import { StyledLandingPage } from '../../styled-components/screens/StyledLandingPage'

const LandingPageEU = () => {
  const { appDispatch, market, language, referralId } = useContext(AppContext)
  const { dataDispatch, dataState } = useContext(DataContext)
  const marketContent = {
    header: StepHeaders[language],
    copy: StepBody[language]
  }
  const regNumberInput = useInput({
    initialValue: dataState.registrationNumber || '',
    label: marketContent.copy.regPlateText,
    errorMessage: marketContent.copy.regPlateError,
    invalidCharacters:
      market === 'GB'
        ? regex.nonAlphanumericSpace
        : market === 'NL'
        ? regex.nonAlphanumericDash
        : regex.nonAlphanumericDashSpace
  })

  const mileageInput = useInput({
    initialValue: dataState.mileage
      ? market === 'GB'
        ? filters.numberCommas(dataState.mileage)
        : filters.numberCommasEU(dataState.mileage)
      : '',
    label: marketContent.copy.mileageText,
    errorMessage: marketContent.copy.mileageError,
    invalidCharacters: regex.nonNumbers,
    filter: market === 'GB' ? filters.numberCommas : filters.numberCommasEU,
    maxLength: {
      value: 11
    }
  })

  const [regValue, setRegValue] = useState(regNumberInput.value)
  const [mileageValue, setMileageValue] = useState(false)

  function saveRegMileage(regNumber, mileage) {
    dataDispatch({
      type: DataACTIONS.registrationNumberUpdated,
      payload: regNumber
    })
    dataDispatch({
      type: DataACTIONS.mileageUpdated,
      payload: mileage
    })
  }

  function saveValues(data) {
    dataDispatch({
      type: DataACTIONS.appraisalIdUpdated,
      payload: data.appraisalId
    })
    dataDispatch({
      type: DataACTIONS.vehicleMakeUpdated,
      payload: data.make
    })
    dataDispatch({
      type: DataACTIONS.vehicleModelUpdated,
      payload: data.model
    })
    dataDispatch({
      type: DataACTIONS.vehicleYearUpdated,
      payload: data.year || ''
    })
    dataDispatch({
      type: DataACTIONS.monthUpdated,
      payload: data.month
    })
    dataDispatch({
      type: DataACTIONS.fuelUpdated,
      payload: { id: data.fuelId, name: data.fuelName }
    })
    dataDispatch({
      type: DataACTIONS.bodyUpdated,
      payload: { id: data.bodyId, name: data.bodyName }
    })
    dataDispatch({
      type: DataACTIONS.gearUpdated,
      payload: { id: data.gearId, name: data.gearName }
    })
    dataDispatch({
      type: DataACTIONS.doorsUpdated,
      payload: data.doors
    })
    if (market === 'FR') {
      const arrOfObjs = []
      data.trimSet.map((e) => arrOfObjs.push({ value: e, label: e }))

      dataDispatch({
        type: DataACTIONS.trimOptionsUpdated,
        payload: arrOfObjs
      })
    }
    if (market === 'ES') {
      dataDispatch({
        type: DataACTIONS.esLookupObjUpdated,
        payload: data
      })
    } else if (market === 'IT') {
      dataDispatch({
        type: DataACTIONS.itLookupObjUpdated,
        payload: data
      })
    }
  }

  function handleSubmit() {
    const regNumberValid =
      dataState.registrationNumber && regNumberInput.value
        ? true
        : regNumberInput.valid
    const mileageValid =
      dataState.mileage && mileageInput.value ? true : mileageInput.valid
    const pageValid = regNumberValid && mileageValid
    const regNumber = regNumberInput.value.toUpperCase()
    const vehMileage = mileageInput.value
      .replaceAll('.', '')
      .replaceAll(',', '')
      .replaceAll(/\s/g, '')

    const cleanRegNum = regNumberInput.value
      ? regNumberInput.value.replaceAll(regex.dashSpace, '')
      : ''

    const apiPayload =
      market === 'GB'
        ? { mileage: vehMileage, vrm: cleanRegNum, referralId: referralId }
        : {
            mileage: vehMileage,
            registrationNumber: cleanRegNum,
            referralId: referralId
          }

    if (pageValid) {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
      saveRegMileage(regNumber, vehMileage)
      api
        .vehicleLookupEU(apiPayload)
        .then((data) => {
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          if (data.make && data.model !== null) {
            saveValues(data)
            appDispatch({
              type: AppACTIONS.jumpToStepUpdated,
              payload: 'vehiclefound_EU'
            })

            navigate('/vehiclefound_EU')
          } else {
            appDispatch({
              type: AppACTIONS.jumpToStepUpdated,
              payload: 'vehiclenotfound_EU'
            })
            navigate('/vehiclenotfound_EU')
          }
        })
        .catch((err) => {
          console.log(err)
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          appDispatch({
            type: AppACTIONS.jumpToStepUpdated,
            payload: 'vehiclenotfound_EU'
          })
          navigate('/vehiclenotfound_EU')
        })
    } else {
      focusError([regNumberInput, mileageInput])
    }
  }

  return (
    <StyledLandingPage>
      <StepHeader
        mainHead={marketContent.header.main}
        subHead={marketContent.header.sub}
        maxWidth='680px'
      />
      <div className='tradein-landingpage-wrap'>
        <TextInput
          customOnChange={(event) => setRegValue(event.target.value)}
          {...regNumberInput}
        />
        <TextInput
          customOnChange={(event) => setMileageValue(event.target.value)}
          {...mileageInput}
        />

        <div className='tradein-buttons-container'>
          <TradeinButton
            type='USFordPrimary'
            text={marketContent.copy.submitText}
            ariaLabel={marketContent.copy.submitAriaLabel}
            action={() => handleSubmit()}
            iconType='nextIcon'
            disabled={!regValue || !mileageValue}
          />
        </div>
      </div>
    </StyledLandingPage>
  )
}

export default LandingPageEU
