import styled from 'styled-components'
import { mediaQueries } from '../mediaQueries'

export const StyledVehicleCodition = styled.div`
  &.ford {
    .tradein-vc-cb-wrapper {
      legend {
        color: #00142e;
      }
      .ford {
        padding: 0;
        margin-bottom: 30px;

        .tradein-radio-button-card-subtitle {
          font-size: var(--fz-md);
          color: var(--fordBlue);
        }
        .tradein-radio-button-card-text {
          margin-top: 8px;
          font-size: var(--fz-sm);
          color: var(--fordSlate);
          line-height: 24px;
        }
        .tradein-radio-button-card-content {
          padding-right: 16px;
        }
      }
    }
  }
  .vehiclecondition {
    &.ford {
      margin-top: 32px;
      max-width: 650px;
    }
    fieldset.fmc-check {
      margin: 16px 0;
    }
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 930px;
    margin: 0 auto;

    .tradein-annualMileageWrapper {
      height: 140px;
      width: 70%;
      max-width: 80%;
      margin-top: 1.6rem;
      @media ${mediaQueries.underL} {
        width: 100%;
        div.fmc-floating-label {
          margin-bottom: 2rem;
          label.fmc-floating-label > span.fmc-floating-label__text {
            font-size: 1.2rem !important;
          }
        }
      }

      .tradein-text-input-wrap {
        max-width: 397px;
        margin: 25px 0px;
        padding-bottom: 25px;
        .currencySymbol {
          color: var(--secondary);
          padding-left: 5px;
        }
      }
      .tradein-input-label {
        max-width: 310px;
      }
      svg {
        margin-top: 25px;
      }
    }
    .tradein-divider-div {
      margin-top: 20px;
      border-bottom: var(--borderInput);
      width: 100%;
    }
    .tradein-divider-top {
      width: 100%;
      height: 3px;
      border-radius: 10px;
      margin: 25px 0px 10px 0px;
      box-shadow: 0 1px 0 0 #ffffff, inset 0 1px 2px 0 #bfbfbf;
      background-color: var(--bgDarkGrey);
    }
    .tradein-vc-required {
      color: var(--primary);
      line-height: 26px;
      letter-spacing: 1px;
      padding: 2% 0;
      font-size: var(--fz-xxs);
      border-bottom: var(--borderInput);
    }
    #overallCondition-radio-group + .btn-group {
      display: flex;
      flex-direction: column;
      @media ${mediaQueries.underM} {
        .tradein-rb-wrapper {
          width: 90%;
        }
      }
    }

    .radio-text-list {
      @media ${mediaQueries.underM} {
        min-width: 235px;
      }
      p {
        margin-bottom: 3px;
      }
      li {
        margin-bottom: 3px;
      }
    }
    &.lincoln {
      .tradein-vc-cb-wrapper {
        border-bottom: var(--borderInput);
      }
    }
    .vehicleBasics {
      .tradein-vb-column {
        max-width: 440px;
        margin: 10px auto 0px auto;
        .more-info-wrapper {
          svg {
            margin-top: 60px;
          }
        }
      }
    }
  }
`
