import React from 'react'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'

const StyledTab = styled.div`
  color: var(--secondary);
  position: relative;
  width: 80%;
  margin: 40px auto 60px auto;
  font-weight: bold;
  @media ${mediaQueries.underM} {
    width: 100%;
  }
  ul {
    display: flex;
    padding-left: 0;
  }
  .tradein-nav-item {
    list-style-type: none;
    text-align: center;
    cursor: pointer;
    background: transparent;
    font-family: var(--fontSecondary);
    text-transform: uppercase;
    font-size: var(--fz-md);
    letter-spacing: 2px;
    color: var(--primary);
    font-weight: 400;
    text-decoration: none;

    &:first-of-type {
      width: 75%;
    }
    &:nth-child(2) {
      width: 25%;
    }

    @media ${mediaQueries.underS} {
      &:first-of-type {
        width: 60%;
      }
      &:nth-child(2) {
        width: 40%;
      }
    }
    &.active {
      &:first-child ~ .tradein-tab-slider {
        transform: translateX(0) scaleX(0.7);
        @media ${mediaQueries.underS} {
          transform: translateX(0) scaleX(0.6);
        }
      }
      &:nth-child(2) ~ .tradein-tab-slider {
        transform: translateX(77%) scaleX(0.2);
        @media ${mediaQueries.underS} {
          transform: translateX(69%) scaleX(0.2);
        }
      }
    }
    :focus {
      border: 1px solid var(--lightBlue);
    }
  }

  a.tradein-nav-link {
    border: 0;
    color: var(--primary);
    &:hover {
      border: 0;
    }
  }
  .tradein-tab-slider {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--secondary);
    transform-origin: 0 0;
    transition: transform 0.25s;
    top: 40px;
  }
  &.ford {
    width: 100%;
    font-weight: 500;
    margin: 0;
    .tradein-tab-slider {
      display: none;
    }
    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 32px;
      .tradein-nav-item {
        flex-basis: 50%;
        width: 50%;
        font-size: 20px;
        line-height: 24px;
        text-transform: none;
        color: var(--borderDarkGrey);
        letter-spacing: 0;

        &:after {
          margin-top: 8px;
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          background: var(--borderDarkGrey);
          transform-origin: 0 0;
          transition: transform 0.25s;
        }
        &.active {
          color: var(--buttonBlue);
          &:after {
            background: var(--buttonBlue);
          }
        }
      }
    }
  }
  &.lincoln {
    .tradein-nav-link {
      letter-spacing: normal;
      font-weight: 600;
    }
    .tradein-tab-slider {
      background: var(--lincolnOrange);
    }
  }
`

const types = ['License Plate', 'VIN']
const TabGroup = (props, type) => {
  return (
    <StyledTab className={props.className}>
      <ul>
        {types.map((type) => (
          <li
            className={`tradein-nav-item ${
              props.active === type ? 'active' : ''
            }`}
            key={type}
            onClick={props.action}
            tabIndex='0'
          >
            {type}
          </li>
        ))}
        <div className='tradein-tab-slider' />
      </ul>

      {props.children}
    </StyledTab>
  )
}

export default TabGroup
