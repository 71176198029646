// for vehicle condition
import {
  excellentFr,
  goodFr,
  averageFr,
  fairFr,
  poorFr,
  excellentUK,
  goodUK,
  averageUK,
  fairUK,
  poorUK,
  excelllentES,
  goodES,
  fairES,
  poorES,
  excelllentIT,
  goodIT,
  averageIT,
  fairIT,
  poorIT,
  badES
} from './VehicleConditionCopy'
import { DataACTIONS } from '../../../common/context/DataContext'

const radioButtonId = '-radio-group'
// Note:  we need vehicle condition radio button tooltip copy for ES and IT and aria labels if
// they are to follow the same format as other markets
export const vehicleCondition = {
  fr_fr: [
    {
      ariaLabels: [
        'Sélectionnez "Oui" si votre véhicule a été importé en France depuis un autre pays par un canal autre que la distribution officielle du constructeur.',
        "Sélectionnez 'Non' s'il a été acheté par l'intermédiaire du réseau de distributeurs français."
      ],
      id: `vehicle_import${radioButtonId}`,
      dataAction: DataACTIONS.importUpdated,
      apiField: 'isImported',
      label: 'Votre véhicule est-il importé ?',
      options: ['Oui', 'Non'],
      tooltip:
        'Veuillez indiquer si vous avez personnellement importé votre véhicule dans le pays.'
    },
    {
      ariaLabels: [
        "Sélectionnez Oui' si le véhicule est opérationnel.",
        "Sélectionnez 'Non' si le véhicule n'est pas opérationnel."
      ],
      id: `runner${radioButtonId}`,
      dataAction: DataACTIONS.runnerUpdated,
      apiField: 'isRunning',
      label: 'Votre véhicule est-il roulant ?',
      options: ['Oui', 'Non'],
      tooltip: 'Veuillez indiquer si votre véhicule est opérationnel.'
    },
    {
      ariaLabels: [
        "Sélectionnez 'Oui' si il a été modifié.",
        "Sélectionnez 'Non' si il n'a pas été modifié."
      ],
      id: `tuned_or_Modified${radioButtonId}`,
      dataAction: DataACTIONS.tunedOrModifiedUpdated,
      apiField: 'isModified',
      label:
        'Votre véhicule a-t-il été modifié par rapport au modèle original (tuning, peinture, pièces…)?',
      options: ['Oui', 'Non'],
      tooltip: 'Veuillez indiquer si votre véhicule a été modifié.'
    },
    {
      ariaLabels: [
        "Sélectionnez 'Oui' si votre véhicule a déjà été accidenté.",
        "Sélectionnez 'Non' si votre véhicule n'a jamais été accidenté."
      ],
      id: `crashed${radioButtonId}`,
      dataAction: DataACTIONS.crashedUpdated,
      apiField: 'hasMajorDamage',
      label:
        'Votre véhicule a-t-il été lourdement accidenté (passage au marbre…) ?',
      options: ['Oui', 'Non'],
      tooltip: 'Veuillez indiquer si votre véhicule a été accidenté.'
    },
    {
      ariaLabels: [
        'Sélectionnez "Oui" si vous utilisez votre véhicule à des fins professionnelles.',
        "Sélectionnez 'Non' si vous n'utilisez pas votre véhicule à des fins professionnelles."
      ],
      id: `personal_vehicle${radioButtonId}`,
      dataAction: DataACTIONS.personalUseUpdated,
      apiField: 'isPersonal',
      label:
        'Votre véhicule est-il utilisé exclusivement pour des trajets privés (incluant domicile-travail) ?',
      options: ['Oui', 'Non'],
      tooltip:
        'Veuillez indiquer si vous utilisez votre véhicule à des fins professionnelles.'
    },
    {
      ariaLabels: [
        'Sélectionnez "Oui" si il y a des voyants allumés sur votre tableau de bord.',
        "Sélectionnez 'Non' si aucun des voyants de votre tableau de bord n'est allumé."
      ],
      id: `engine_management_lights_lit${radioButtonId}`,
      dataAction: DataACTIONS.warningLightsUpdated,
      apiField: 'hasIlluminatedWarningLights',
      label: 'Y-a-t-il des voyants allumés sur le tableau de bord ?',
      options: ['Oui', 'Non'],
      tooltip:
        "Veuillez indiquer si le véhicule présente des problèmes mécaniques majeurs au niveau du moteur, de la boîte de vitesses, de la transmission ou d'autres systèmes clés ou si l'un des voyants de votre tableau de bord est allumé."
    },
    {
      ariaLabels: [
        "Sélectionnez 'Oui' si il y a des défauts nécessitant des réparations sur votre véhicule.",
        "Sélectionnez 'Non' si il y a aucun défaut nécessitant des réparations sur votre véhicule."
      ],
      id: `mechanical_or_electronic_default${radioButtonId}`,
      dataAction: DataACTIONS.mechanicalDefectsUpdated,
      apiField: 'hasDefaults',
      label:
        'Y-a-t-il des défauts nécessitant des réparations importantes sur votre véhicule ?',
      options: ['Oui', 'Non'],
      tooltip:
        'Veuillez indiquer si il y a des défauts nécessitant des réparations importantes sur votre véhicule.'
    },
    {
      ariaLabels: [
        "Sélectionnez 'Oui' si vous avez fumé et/ou transporté des animaux dans votre véhicule.",
        "Sélectionnez 'Non' si vous n'avez ni fumé ni transporté des animaux dans votre véhicule."
      ],
      id: `smoking_vehicle_or_animal_transportation${radioButtonId}`,
      dataAction: DataACTIONS.smokingOrAnimalsUpdated,
      apiField: 'hasOdors',
      label:
        'Votre véhicule est-il fumeur ? Transportez-vous des animaux dans votre véhicule ?',
      options: ['Oui', 'Non'],
      tooltip:
        'Veuillez indiquer si vous avez fumé et/ou transporté des animaux dans votre véhicule.'
    },
    {
      ariaLabels: [
        'Sélectionnez si votre véhicule se trouve dans un état excellent.',
        'Sélectionnez si votre véhicule se trouve dans un bon état.',
        'Sélectionnez si votre véhicule se trouve dans un état moyen.',
        'Sélectionnez si votre véhicule se trouve dans un état médiocre.',
        'Sélectionnez si votre véhicule se trouve dans un mauvais état.'
      ],
      directionStyle: 'vertical',
      id: `overall_condition${radioButtonId}`,
      dataAction: DataACTIONS.overallConditionUpdated,
      apiField: 'overallCondition',
      label: 'Dans quel état se trouve votre véhicule ?',
      options: ['EXCELLENT', 'BON', 'MOYEN', 'MEDIOCRE', 'MAUVAIS'],
      tooltip: "Veuillez indiquer l'état de votre véhicule.",
      text: [excellentFr(), goodFr(), averageFr(), fairFr(), poorFr()]
    },
    {
      ariaLabels: [
        "Sélectionnez 'Oui' si vous avez le carnet d'entretien à jour du véhicule.",
        "Sélectionnez 'Non' si vous n'avez pas le carnet d'entretien à jour du véhicule."
      ],
      id: `servicing_history${radioButtonId}`,
      dataAction: DataACTIONS.hasMaintenanceRecordsUpdated,
      apiField: 'hasMaintenanceHistory',
      label:
        'Avez-vous le carnet d’entretien à jour du véhicule ? (Incluant factures)',
      options: ['Oui', 'Non'],
      tooltip:
        "Veuillez indiquer si vous avez en votre possession le carnet d'entretien à jour du véhicule."
    },
    {
      ariaLabels: [
        "Sélectionnez 'Oui' si vous êtes propriétaire du véhicule.",
        "Sélectionnez 'Non' si vous n'êtes pas propriétaire du véhicule."
      ],
      id: `ownership${radioButtonId}`,
      dataAction: DataACTIONS.ownershipUpdated,
      apiField: 'isOwner',
      label: 'Êtes-vous propriétaire du véhicule ?',
      options: ['Oui', 'Non'],
      tooltip: 'Veuillez indiquer si vous êtes propriétaire du véhicule.'
    },
    {
      ariaLabels: [
        "Sélectionnez 'Oui' si votre véhicule a une peinture métallisée.",
        "Sélectionnez 'Non' si votre véhicule n'a pas de peinture métallisée."
      ],
      id: `metallic_paint${radioButtonId}`,
      dataAction: DataACTIONS.metallicPaintUpdated,
      apiField: 'hasMetallicPaint',
      label: 'Votre véhicule a-t-il une peinture métallisée ?',
      options: ['Oui', 'Non'],
      tooltip: 'Veuillez indiquer si votre véhicule a une peinture métallisée.'
    }
  ],
  en_gb: [
    {
      ariaLabels: [
        'Select if you have two sets of keys',
        'Select if you do not have two sets of keys'
      ],
      id: `twoSetKeys${radioButtonId}`,
      dataAction: DataACTIONS.keysUpdated,
      label: 'Do you have 2 sets of keys?',
      apiField: 'twoOrMoreKeys',
      options: ['Yes', 'No'],
      tooltip: 'Yes if you have two sets of working keys.'
    },
    {
      ariaLabels: [
        'Select if your vehicle has a full service history log',
        'Select if your vehicle does not have a full service history log'
      ],
      id: `service_history${radioButtonId}`,
      dataAction: DataACTIONS.hasMaintenanceRecords,
      label: 'Do you have a full service history?',
      apiField: 'hasMaintenanceHistory',
      options: ['Yes', 'No'],
      tooltip:
        'Yes if your vehicle has an up to date full service history log, fully stamped by a mixture of franchised or non franchised dealers.'
    },
    {
      ariaLabels: [
        'Select if your vehicle has more than 1 month M.O.T.',
        'Select if your vehicle does not have more than 1 month M.O.T.'
      ],
      id: `MOT${radioButtonId}`,
      dataAction: DataACTIONS.motUpdated,
      label: 'Does the vehicle have more than 1 month MOT?',
      apiField: 'hasMot',
      options: ['Yes', 'No'],
      tooltip:
        'Yes if your vehicle has more than 1 month on the MOT or less than 3 years old and so does not require an MOT.'
    },
    {
      ariaLabels: [
        'Select if you would like to keep your own vehicle registration number',
        'Select if you do not want to keep your own vehicle registration number'
      ],
      id: `vehicle_registration_number${radioButtonId}`,
      dataAction: DataACTIONS.keepRegNumberUpdated,
      label: 'Would you like to keep your own vehicle registration number?',
      apiField: 'keepOwnReg',
      options: ['Yes', 'No'],
      tooltip:
        'Yes if you intend on keeping your own registration plate. To speed up the process you should put the plate you wish to keep on retention. You will receive a new V5C document from the DVLA which you must bring to the handover.'
    },
    {
      ariaLabels: [
        'Select if you are the owner of the vehicle and hold the V5C',
        'Select if you are not the owner of the vehicle and do not hold the V5C'
      ],
      id: `vehicle_hold${radioButtonId}`,
      dataAction: DataACTIONS.ownerVSCUpdated,
      label: 'Are you the owner of the vehicle and hold the V5C?',
      apiField: 'hasV5C',
      options: ['Yes', 'No'],
      tooltip:
        'Yes if you have owned the vehicle a minimum of 3 months or hold the V5C document.'
    },
    {
      ariaLabels: [
        'Select if your vehicle been used for leisure and commuting purposes only',
        'Select if your vehicle has been used for purposes other than leisure and commuting '
      ],
      id: `leisure_commuting${radioButtonId}`,
      dataAction: DataACTIONS.personalUseUpdated,
      label:
        'Has the vehicle been used for leisure and commuting purposes only?',
      apiField: 'hasProvidedServices',
      options: ['Yes', 'No'],
      tooltip:
        'Yes if the vehicle has never previously been used as an emergency services, taxi, rental or driving school vehicle.'
    },
    {
      ariaLabels: [
        'Select if there is major damage to your vehicle',
        'Select if there is not any major damage to your vehicle'
      ],
      id: `major_damage${radioButtonId}`,
      dataAction: DataACTIONS.majorDamageUpdated,
      label:
        'Is there any major damage to your vehicle that we should know about?',
      apiField: 'hasMajorDamage',
      options: ['Yes', 'No'],
      tooltip:
        'Yes if the vehicle has any significant damage to the body panels or the interior seats or upholstery beyond that mentioned in the selected condition grade.'
    },
    {
      ariaLabels: [
        'Select if there are engine management lights lit on your dashboard',
        'Select if there are no engine management lights lit on your dashboard'
      ],
      id: `engine_management_lights_lit${radioButtonId}`,
      dataAction: DataACTIONS.warningLightsUpdated,
      label: 'Are there any engine management lights lit on your dashboard?',
      apiField: 'hasIlluminatedWarningLights',
      options: ['Yes', 'No'],
      tooltip:
        'Yes if the vehicle has any major mechanical issues with the engine, gearbox, transmission, or other key systems or if any of the dashboard warning lights are lit.'
    },
    {
      ariaLabels: [
        'Select if your vehicle is an import',
        'Select if your vehicle is not an import'
      ],
      id: `vehicle_import${radioButtonId}`,
      dataAction: DataACTIONS.importUpdated,
      label: 'Is your vehicle an import?',
      apiField: 'isImport',
      options: ['Yes', 'No'],
      tooltip:
        ' Yes if your vehicle was imported to the UK from another country through any channel other than the manufacturers official distribution.'
    }
  ],
  nl_nl: [
    {
      ariaLabels: [
        'Kies "ja" als je 2 paar werkende sleutels hebt voor je auto',
        'Kies "nee" als je geen 2 paar werkende sleutels hebt voor je auto'
      ],
      id: `twoOrMoreKeys${radioButtonId}`,
      dataAction: DataACTIONS.keysUpdated,
      label: 'Heb je 2 sleutels?',
      apiField: 'twoOrMoreKeys',
      options: ['Ja', 'Nee'],
      tooltip: 'Ja, als je twee werkende sleutels hebt?'
    },
    {
      ariaLabels: [
        'Kies "ja" als jouw auto een up-to-date onderhoudsoverzicht/gestempeld serviceboekje heeft bij erkende of geen erkende Ford dealers',
        'Kies "nee" als jouw auto geen up-to-date onderhoudsoverzicht/gestempeld serviceboekje heeft bij erkende of geen erkende Ford dealers'
      ],
      id: `hasMaintenanceRecords${radioButtonId}`,
      dataAction: DataACTIONS.hasMaintenanceRecordsUpdated,
      label:
        'Heb je een compleet onderhoudsoverzicht/gestempeld onderhoudsboekje?',
      apiField: 'hasMaintenanceHistory',
      options: ['Ja', 'Nee'],
      tooltip: 'Geef ons informatie over je onderhoudsoverzicht van je auto'
    },
    {
      ariaLabels: [
        'Kies "ja" als je auto grote schade heeft en een grote reparatie nodig heeft',
        'Kies "nee" als je auto geen grote schade heeft en geen grote reparatie nodig heeft'
      ],
      id: `majorDamage${radioButtonId}`,
      dataAction: DataACTIONS.majorDamageUpdated,
      label: 'Is er (grote) schade aan je auto?',
      apiField: 'hasMajorDamage',
      options: ['Ja', 'Nee'],
      tooltip: 'Laat ons weten of er (grote) schade is aan jouw auto'
    },
    {
      ariaLabels: [
        'Kies "ja" als er waarschuwingslichtjes branden',
        'Kies "nee" als er geen waarschuwingslichtjes branden'
      ],
      id: `warningLights${radioButtonId}`,
      dataAction: DataACTIONS.warningLightsUpdated,
      label:
        'Branden er lichtjes voor problemen met je motor op het dashboard?',
      apiField: 'hasIlluminatedWarningLights',
      options: ['Ja', 'Nee'],
      tooltip:
        'Geef ons aan of er continu waarschuwingslichtjes branden op het dashboard'
    },
    {
      ariaLabels: [
        'Kies "ja" als je auto alleen voor privé gebruikt is',
        'Kies "nee" als je je auto gebruikt hebt voor taxi of andere zakelijke doeleinden'
      ],
      id: `rideSharing${radioButtonId}`,
      dataAction: DataACTIONS.rideSharingUpdated,
      label: 'Rijd jij je auto particulier?',
      apiField: 'hasProvidedServices',
      options: ['Ja', 'Nee'],
      tooltip: 'Graag bevestigen dat je auto alleen voor privé gebruikt is'
    }
  ],
  es_es: [
    {
      ariaLabels: [
        'Selecciona "Sí" si tu vehículo ha sido importado en España desde otro país a través de un canal distinto a la red de concesionarios oficiales.',
        "Seleccione 'No' si se compró a través de la red distribuidora española."
      ],
      id: `vehicle_import${radioButtonId}`,
      dataAction: DataACTIONS.importUpdated,
      apiField: 'isImported',
      label: '¿Tu vehículo es importado?',
      options: ['Sí', 'No'],
      tooltip:
        'Por favor, responsa sí, si sabe que su vehículo ha sido matriculado por primera vez fuera de España'
    },
    {
      ariaLabels: [
        'Seleccione "Sí" si su vehículo está en estado de funcionamiento',
        'Seleccione "No" si su vehículo no arranca y no se encuentra en estado de funcionamiento'
      ],
      id: `runner${radioButtonId}`,
      dataAction: DataACTIONS.runnerUpdated,
      apiField: 'isRunning',
      label: '¿Está tu vehículo operativo?',
      options: ['Sí', 'No'],
      tooltip: 'Confirma que tu vehículo funciona correctamente'
    },
    {
      ariaLabels: [
        'seleccione "Si" si  ha hecho alguna modificación en su vehículo más allá de las especificaciones originales del fabricante',
        'seleccione "No" si no ha modificado en su vehículo las especificaciones originales del fabricante'
      ],
      id: `tuned_or_Modified${radioButtonId}`,
      dataAction: DataACTIONS.tunedOrModifiedUpdated,
      apiField: 'isModified',
      label: '¿Tu vehículo ha sido modificado (tuneado, pintura mate, etc.)?',
      options: ['Sí', 'No'],
      tooltip: 'Por favor, confirmar si tu vehículo ha sido modificado'
    },
    {
      ariaLabels: [
        'Seleccione "Sí" si su vehículo ha sufrido un accidente grave',
        'Seleccione "No" si su vehículo no ha sufrido un accidente grave'
      ],
      id: `crashed${radioButtonId}`,
      dataAction: DataACTIONS.crashedUpdated,
      apiField: 'hasMajorDamage',
      label: '¿Ha tenido tu vehículo algún accidente?',
      options: ['Sí', 'No'],
      tooltip: 'Por favor, confirma si tu vehículo ha tenido algún accidente'
    },
    {
      ariaLabels: [
        'Seleccione "Sí" si solo ha utilizado su vehículo para disfrute personal y los desplazamientos.',
        'Seleccione "No" si ha utilizado su vehículo con fines comerciales, por ejemplo un taxi'
      ],
      id: `leisure${radioButtonId}`,
      dataAction: DataACTIONS.personalUseUpdated,
      apiField: 'isPersonal',
      label:
        '¿Se ha utilizado el vehículo solo con fines de ocio y desplazamientos?',
      options: ['Sí', 'No'],
      tooltip:
        'Es decir, confirme que el vehículo no ha sido utilizado para fines profesionales.'
    },
    {
      ariaLabels: [
        'Seleccione "Sí" si hay luces encendidas en su tablero que indiquen un problema mecánico con su vehículo.',
        'Selecciona "No" si no hay luces encendidas en su cuadro de mandos que indiquen un problema mecánico con su vehículo.'
      ],
      id: `engine_management_lights_lit${radioButtonId}`,
      dataAction: DataACTIONS.warningLightsUpdated,
      apiField: 'hasIlluminatedWarningLights',
      label:
        '¿Hay algún piloto o luz de aviso de mantenimiento encendido en el cuadro de mandos de tu vehículo?',
      options: ['Sí', 'No'],
      tooltip:
        'Por favor, indica si tu vehículo muestra algún problema motor o de mecánica importante, (caja de cambios, transmisión, otros sistemas esenciales, etc.) o problemas electrónicos (luces del cuadro que permanezcan encendidas, etc.)'
    },
    {
      ariaLabels: [
        'Seleccione "Sí" si su vehículo requiere reparaciones importantes',
        'Seleccione "No" si su vehículo no necesita reparaciones importantes'
      ],
      id: `mechanical_or_electronic_default${radioButtonId}`,
      dataAction: DataACTIONS.mechanicalDefectsUpdated,
      apiField: 'hasDefaults',
      label: '¿Necesita tu vehículo alguna reparación importante?',
      options: ['Sí', 'No'],
      tooltip:
        'Por favor, confirma si tu vehículo necesita alguna reparación importante'
    },
    {
      ariaLabels: [
        'Seleccione "Sí" si ha transportado animales o si ha fumado en su vehículo.',
        'Seleccione "No" si no ha transportado animales o si no ha fumado en su vehículo'
      ],
      id: `smoking_vehicle_or_animal_transportation${radioButtonId}`,
      dataAction: DataACTIONS.smokingOrAnimalsUpdated,
      apiField: 'hasOdors',
      label:
        '¿Has fumado en tu vehículo? ¿Se ha utilizado tu vehículo para transportar animales?',
      options: ['Sí', 'No'],
      tooltip:
        'Por favor, indica si has fumado y/o transportado animales en tu vehículo.'
    },
    {
      ariaLabels: [
        'Seleccione si su vehículo se ajusta a la definición de condición "Excelente"',
        'Seleccione si su vehículo se ajusta a la definición de "Buena" condición',
        'Seleccione si su vehículo se ajusta a la definición de condición "Aceptable"',
        'Seleccione si su vehículo se ajusta a la definición condición "Pobre".',
        'Seleccione si su vehículo se ajusta a la definición de"Mala" condición.'
      ],
      id: `overall_condition${radioButtonId}`,
      dataAction: DataACTIONS.overallConditionUpdated,
      apiField: 'overallCondition',
      label: '¿En que estado se encuentra tu vehículo?',
      options: ['EXCELENTE', 'BUENO', 'ACEPTABLE', 'POBRE', 'MALO'],
      tooltip: 'Por favor, indica el estado en que se encuentra tu vehículo.',
      text: [excelllentES(), goodES(), fairES(), poorES(), badES()],
      directionStyle: 'vertical'
    },
    {
      ariaLabels: [
        'Seleccione si tiene el libro de mantenimiento del vehículo actualizado',
        'Seleccione si no tiene el libro de mantenimiento del vehículo actualizado'
      ],
      id: `servicing_history${radioButtonId}`,
      dataAction: DataACTIONS.hasMaintenanceRecordsUpdated,
      apiField: 'hasMaintenanceHistory',
      label:
        '¿Tiene el manual de servicio / libro de mantenimiento del vehículo actualizado (incluidas las facturas)?',
      options: ['Sí', 'No'],
      tooltip:
        'Por favor, confirme si tiene el manual de servicio / libro de mantenimiento del vehículo actualizado.'
    },
    {
      ariaLabels: [
        'Seleccione si su vehículo tiene pintura metalizada.',
        'Seleccione si su vehículo no tiene pintura metalizada.'
      ],
      id: `ownership${radioButtonId}`,
      dataAction: DataACTIONS.ownershipUpdated,
      apiField: 'isOwner',
      label: '¿Eres el propietario del vehículo?',
      options: ['Sí', 'No'],
      tooltip: 'Por favor, confirmar si eres el propietario del vehículo.'
    },
    {
      ariaLabels: [
        'Por favor, confirma si tu vehículo tiene pintura metalizada',
        'Por favor, confirma si tu vehículo tiene pintura metalizada'
      ],
      id: `metallic_paint${radioButtonId}`,
      dataAction: DataACTIONS.metallicPaintUpdated,
      apiField: 'hasMetallicPaint',
      label: '¿Tiene tu vehículo pintura metalizada?',
      options: ['Sí', 'No'],
      tooltip: 'Por favor, confirma si tu vehículo tiene pintura metalizada'
    }
  ],
  it_it: [
    {
      ariaLabels: [
        'Conferma se il veicolo è stato importato',
        'Conferma se il veicolo è stato importato'
      ],
      id: `vehicle_import${radioButtonId}`,
      dataAction: DataACTIONS.importUpdated,
      apiField: 'isImported',
      label: 'Il veicolo è stato importato?',
      options: ['Si', 'No'],
      tooltip: 'Conferma se il veicolo è stato importato'
    },
    {
      ariaLabels: [
        'Conferma se il veicolo è marciante',
        'Conferma se il veicolo è marciante'
      ],
      id: `runner${radioButtonId}`,
      dataAction: DataACTIONS.runnerUpdated,
      apiField: 'isRunning',
      label: 'Il veicolo è marciante?',
      options: ['Si', 'No'],
      tooltip: 'Conferma se il veicolo è marciante'
    },
    {
      ariaLabels: [
        'Conferma se il veicolo ha subito modifiche',
        'Conferma se il veicolo ha subito modifiche'
      ],
      id: `tuned_or_Modified${radioButtonId}`,
      dataAction: DataACTIONS.tunedOrModifiedUpdated,
      apiField: 'isModified',
      label:
        'Il veicolo ha subito delle modifiche (tuning, cambio di colore, etc.)?',
      options: ['Si', 'No'],
      tooltip: 'Conferma se il veicolo ha subito modifiche'
    },
    {
      ariaLabels: [
        'Conferma se il veicolo è incidentato',
        'Conferma se il veicolo è incidentato'
      ],
      id: `crashed${radioButtonId}`,
      dataAction: DataACTIONS.crashedUpdated,
      apiField: 'hasMajorDamage',
      label: 'Il veicolo è incidentato?',
      options: ['Si', 'No'],
      tooltip: 'Conferma se il veicolo è incidentato'
    },
    {
      ariaLabels: [
        'Seleziona "Si" se il veicolo è stato utilizzato solo per il tempo libero e nel tragitto casa-lavoro.',
        'Seleziona "No" se il veicolo è stato utilizzato per scopi lavorativi (taxi, etc.)'
      ],
      id: `personal_vehicle${radioButtonId}`,
      dataAction: DataACTIONS.personalUseUpdated,
      apiField: 'isPersonal',
      label:
        'Il veicolo è stato utilizzato solo per il tempo libero e nel tragitto casa-lavoro?',
      options: ['Si', 'No'],
      tooltip:
        'Conferma se il veicolo è stato utilizzato solo per il tempo libero e nel tragitto casa-lavoro.'
    },
    {
      ariaLabels: [
        'Confema se, sul quadro strumenti, sono presenti messaggi su eventuali problemi meccanici (cambio, trasmissione, spia motore, etc.) o problemi di elettronica',
        'Confema se, sul quadro strumenti, sono presenti messaggi su eventuali problemi meccanici (cambio, trasmissione, spia motore, etc.) o problemi di elettronica'
      ],
      id: `engine_management_lights_lit${radioButtonId}`,
      dataAction: DataACTIONS.warningLightsUpdated,
      apiField: 'hasIlluminatedWarningLights',
      label: 'Ci sono spie motore accese?',
      options: ['Si', 'No'],
      tooltip:
        'Confema se, sul quadro strumenti, sono presenti messaggi su eventuali problemi meccanici (cambio, trasmissione, spia motore, etc.) o problemi di elettronica'
    },
    {
      ariaLabels: [
        'Conferma se sono stati fatti interventi di ripristino significativ',
        'Conferma se sono stati fatti interventi di ripristino significativi'
      ],
      id: `mechanical_or_electronic_default${radioButtonId}`,
      dataAction: DataACTIONS.mechanicalDefectsUpdated,
      apiField: 'hasDefaults',
      label: 'Il veicolo ha subito riparazioni importanti?',
      options: ['Si', 'No'],
      tooltip:
        'Conferma se sono stati fatti interventi di ripristino significativi'
    },
    {
      ariaLabels: [
        'Conferma il veicolo è utilizzato da un fumatore o è stato utilizzato per il trasporto di animali',
        'Conferma il veicolo è utilizzato da un fumatore o è stato utilizzato per il trasporto di animali'
      ],
      id: `smoking_vehicle_or_animal_transportation${radioButtonId}`,
      dataAction: DataACTIONS.smokingOrAnimalsUpdated,
      apiField: 'hasOdors',
      label:
        'Il veicolo è stato utilizzato da un fumatore? Hai trasportato animali nel veicolo?',
      options: ['Si', 'No'],
      tooltip:
        'Conferma il veicolo è utilizzato da un fumatore o è stato utilizzato per il trasporto di animali'
    },
    {
      ariaLabels: [
        'Conferma le condizioni attuali del veicolo',
        'Conferma le condizioni attuali del veicolo',
        'Conferma le condizioni attuali del veicolo',
        'Conferma le condizioni attuali del veicolo',
        'Conferma le condizioni attuali del veicolo'
      ],
      id: `overall_condition${radioButtonId}`,
      dataAction: DataACTIONS.overallConditionUpdated,
      apiField: 'overallCondition',
      label: 'Quali sono le condizioni attuali del veicolo?',
      options: ['OTTIME', 'BUONE', 'MEDIE', 'SCARSE', 'PESSIME'],
      tooltip: 'Conferma le condizioni attuali del veicolo',
      text: [excelllentIT(), goodIT(), averageIT(), fairIT(), poorIT()],
      directionStyle: 'vertical'
    },
    {
      ariaLabels: [
        'Confermare se è disponibile il registro completo degli interventi di manutenzione.',
        'Confermare se è disponibile il registro completo degli interventi di manutenzione.'
      ],
      id: `servicing_history${radioButtonId}`,
      dataAction: DataACTIONS.hasMaintenanceRecordsUpdated,
      apiField: 'hasMaintenanceHistory',
      label:
        'È disponibile il registro completo degli interventi di manutenzione e la copia delle fatture?',
      options: ['Si', 'No'],
      tooltip:
        'Confermare se è disponibile il registro completo degli interventi di manutenzione.'
    },
    {
      ariaLabels: [
        'Indicare se si è il proprietario del veicolo.',
        'Indicare se si è il proprietario del veicolo.'
      ],
      id: `ownership${radioButtonId}`,
      dataAction: DataACTIONS.ownershipUpdated,
      apiField: 'isOwner',
      label: 'Sei il proprietario del veicolo?',
      options: ['Si', 'No'],
      tooltip: 'Indicare se si è il proprietario del veicolo.'
    },
    {
      ariaLabels: [
        'Indicare se il veicolo ha la vernice metallizzata.',
        'Indicare se il veicolo ha la vernice metallizzata.'
      ],
      id: `metallic_paint${radioButtonId}`,
      dataAction: DataACTIONS.metallicPaintUpdated,
      apiField: 'hasMetallicPaint',
      label: 'Il veicolo ha la vernice metallizzata?',
      options: ['Si', 'No'],
      tooltip: 'Indicare se il veicolo ha la vernice metallizzata.'
    }
  ]
}

export const overallConditionUK = {
  en_gb: [
    {
      ariaLabels: [
        'Select if the condition of your vehicle is excellent',
        'Select if the condition of your vehicle is good',
        'Select if the condition of your vehicle is average',
        'Select if the condition of your vehicle is fair',
        'Select if the condition of your vehicle is poor'
      ],
      id: `overallCondition-radio`,
      dataAction: DataACTIONS.overallConditionUpdated,
      label: 'What is the overall condition of your vehicle?',
      apiField: 'overallCondition',
      options: ['EXCELLENT', 'GOOD', 'AVERAGE', 'FAIR', 'POOR'],
      tooltip:
        'You should enter the current accurate mileage of your vehicle and review the detailed description of the condition, to check you have selected the condition that most closely matches your vehicle.',
      text: [excellentUK(), goodUK(), averageUK(), fairUK(), poorUK()]
    }
  ]
}

export const getMonthNL = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december'
]
