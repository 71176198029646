import React, { useContext, useState } from 'react'
import { navigate } from 'hookrouter'
import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import ScrollToTop from '../../common/pageElements/ScrollToTop'
import TradeinButton from '../../common/pageElements/TradeinButton'
import TextInput from '../../common/pageElements/TextInput'
import RadioButton from '../../common/pageElements/RadioButton'
import { useInput, UseRadio } from '../../common/customhooks/forms'
import focusError from '../../../utils/focusError'
import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders, StepBody } from './copy/VehicleConditionCopy'
import { vehicleCondition, overallConditionUK } from './copy/constants'
import { StyledVehicleCodition } from '../../styled-components/screens/StyledVehicleCondtion'
import * as regex from '../../../utils/regex'
import * as filters from '../../../utils/filters'
import api from '../../../utils/api'
import styled from 'styled-components'

const StyledVehicleConditionEU = styled(StyledVehicleCodition)`
  && {
    .tradein-annualMileageWrapper {
      height: 130px;
      .tradein-note {
        margin-top: -8px;
        font-style: normal;
      }
      div.fmc-floating-label {
        margin-bottom: 0;
        padding-bottom: 4px;
      }
      .tradein-error-message {
        margin-bottom: -4px;
        margin-left: -6px;
      }
    }
  }
`

const VehicleConditionEU = () => {
  const { appDispatch, deliveryDate, market, language } = useContext(AppContext)
  const { dataState, dataDispatch } = useContext(DataContext)
  const [showErrors, setShowErrors] = useState(false)
  const cleanRegNum = dataState.registrationNumber
    ? dataState.registrationNumber.replaceAll(regex.dashSpace, '')
    : ''
  const overallConditionObj = overallConditionUK[language]?.map((item) =>
    UseRadio(item)
  )
  // const templateFR = market === 'FR' || market === 'ES' || market === 'IT'
  const marketContent = {
    header: StepHeaders[language],
    copy: StepBody[language],
    payload: 'tradeinappraisal_EU'
  }

  // add initialValue to all the radio objects
  const vehicleObj = vehicleCondition[language].map((v) => {
    const val = dataState[v.id.replace('-radio-group', '')]
    v.initialValue = val === true ? 'Ja' : val === false ? 'Nee' : val
    return v
  })

  const radioObj = vehicleObj?.map((item) => UseRadio(item))

  function saveValue(dataAction, dataValue) {
    dataDispatch({
      type: dataAction,
      payload: dataValue
    })
  }

  const FrZipCode = useInput({
    initialValue: '',
    label: marketContent.copy?.zipcode?.label,
    placeholder: marketContent.copy?.zipcode?.placeholder,
    ariaLabel: marketContent.copy?.zipcode?.ariaLabel,
    invalidCharacters: regex.nonNumbers,
    length: {
      value: 5,
      errorMessage: marketContent.copy?.zipcode?.errorMessage
    }
  })

  const annualMileage = useInput({
    initialValue: '',
    label: marketContent.copy?.annualMileage?.label,
    filter: filters.numberCommas,
    invalidCharacters: regex.nonNumbers
  })

  const deliveryMileage = useInput({
    dataAction: DataACTIONS.deliveryMileageUpdated,
    initialValue: filters.numberCommasEU(dataState.deliveryMileage) || '',
    label: marketContent.copy?.deliveryMileage?.label,
    ariaLabel: marketContent.copy?.deliveryMileage?.ariaLabel,
    placeholder: marketContent.copy?.deliveryMileage?.placeholder,
    filter: filters.numberCommasEU,
    invalidCharacters: regex.nonNumbers,
    maxLength: {
      value: 11
    }
  })

  const valid = (currentValue) =>
    currentValue.value !== null && currentValue.value !== ''
  const mileageValid = deliveryMileage.valid || dataState.deliveryMileage
  const pageValid = radioObj.every(valid) && mileageValid

  function handleSubmit() {
    setShowErrors(true)
    const appraisePayload = {
      appraisalId: dataState.appraisalId
    }
    const usValues = ['EXCELLENT', 'GOOD', 'AVERAGE', 'FAIR', 'POOR']
    // add additional market language values here
    const eurValues = [
      ['EXCELLENT', 'EXCELENTE', 'OTTIME'],
      ['BON', 'BUENO', 'BUONE'],
      ['MOYEN', 'ACEPTABLE', 'MEDIE'],
      ['MEDIOCRE', 'POBRE', 'SCARSE'],
      ['MAUVAIS', 'MALO', 'PESSIME']
    ]

    appraisePayload.registrationNumber = cleanRegNum

    if (radioObj.every(valid) && mileageValid) {
      radioObj.forEach((item) => {
        const val = item.value === 'Ja' || false
        if (item.apiField) {
          appraisePayload[item.apiField] = val
        }
        saveValue(item.dataAction, val)
      })

      const mileageValue = filters.deformatCurrency(deliveryMileage.value, 'NL')

      saveValue(deliveryMileage.dataAction, mileageValue)

      appraisePayload.deliveryMileage = mileageValue
      appraisePayload.mileage = dataState.mileage
      apiCall(api.appraiseVehicleEU(appraisePayload))
    } else {
      focusError([deliveryMileage, radioObj])
    }
  }

  function apiCall(apiFn) {
    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })
    apiFn
      .then((data) => {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        if (
          data.hasOwnProperty('deliveryAppraisalQuote') &&
          data.deliveryAppraisalQuote
        ) {
          saveValue(
            DataACTIONS.currentAppraisalQuoteUpdated,
            data.currentAppraisalQuote
          )
          saveValue(
            DataACTIONS.deliveryAppraisalQuoteUpdated,
            data.deliveryAppraisalQuote
          )
          saveValue(DataACTIONS.appraisalIdUpdated, data.appraisalId)
        }
        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: marketContent.payload
        })
        navigate('/tradeinappraisal_EU')
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        console.log(error)
        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: marketContent.payload
        })
        navigate('/tradeinappraisal_EU')
      })
  }

  return (
    <StyledVehicleConditionEU>
      <ScrollToTop />
      <div className='vehiclecondition ford'>
        <StepHeader
          mainHead={marketContent.header.main}
          subHead={marketContent.header.sub}
          maxWidth='840px'
        />
        {/* {templateFR && (
          <React.Fragment>
            <div className='tradein-input-text-wrapper'>
              <TextInput
                {...FrZipCode}
                errorMessage={marketContent.copy.zipcode.errorMessage}
              />
            </div>
            {radioObj.map((obj, i) => (
              <div className='tradein-vc-cb-wrapper' key={i}>
                <RadioButtonVehCondEU
                  {...obj}
                  index={i}
                  showErrors={showErrors}
                  errorMessage={marketContent.copy.radioObj.errorMessage}
                  tooltipAriaLabel={
                    marketContent.copy.radioObj.tooltipAriaLabel
                  }
                />
              </div>
            ))}
          </React.Fragment>
        )}

        {market === 'GB' && (
          <React.Fragment>
            <div className='tradein-divider-top' />
            {overallConditionObj.map((obj, i) => (
              <div className='tradein-vc-cb-wrapper' key={i}>
                <RadioButton {...obj} index={i} showErrors={showErrors} />
              </div>
            ))}
            <div className='tradein-annualMileageWrapper'>
              <TextInput
                {...annualMileage}
                tooltip={marketContent.copy.annualMileage.tooltip}
                id='annual-mileage-tooltip'
              />
            </div>
            <div className='tradein-divider-div' />
            {radioObj.map((obj, i) => (
              <div className='tradein-vc-cb-wrapper' key={i}>
                <RadioButton {...obj} index={i} showErrors={showErrors} />
              </div>
            ))}
          </React.Fragment>
        )} */}

        {market === 'NL' && (
          <React.Fragment>
            <div className='tradein-annualMileageWrapper'>
              <TextInput
                {...deliveryMileage}
                errorMessage={marketContent.copy.deliveryMileage.errorMessage}
              />
              <div className='tradein-note'>
                Geschatte leveringsdatum:{filters.dateFormatEU(deliveryDate)}
              </div>
            </div>
            <div className='tradein-divider-div' />
            {radioObj.map((obj, i) => (
              <div className='tradein-vc-cb-wrapper' key={i}>
                <RadioButton
                  {...obj}
                  index={i}
                  showErrors={showErrors}
                  errorMessage={
                    marketContent.copy.vehicleValuationObj.errorMessage
                  }
                  tooltipAriaLabel={
                    marketContent.copy.vehicleValuationObj.tooltipAriaLabel
                  }
                />
              </div>
            ))}
          </React.Fragment>
        )}

        <div
          className={`tradein-buttons-container tradein-button-ford-wrapper`}
        >
          <TradeinButton
            type='USFordBack'
            ariaLabel={marketContent.copy.backButton.ariaLabel}
            text={marketContent.copy.backButton.text}
            target={marketContent.copy.backButton.target}
            iconType='prevIcon'
          />
          <TradeinButton
            ariaLabel={marketContent.copy.forwardButton.ariaLabel}
            type='USFordPrimary'
            text={marketContent.copy.forwardButton.text}
            action={handleSubmit}
            iconType='nextIcon'
            disabled={!pageValid}
          />
        </div>
      </div>
    </StyledVehicleConditionEU>
  )
}
export default VehicleConditionEU
