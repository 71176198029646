import styled from 'styled-components'
import { mediaQueries } from '../mediaQueries'

export const StyledVehicleFound = styled.div`
   {
    .ford.vehiclefound {
      max-width: 650px;
      margin: 0 auto;

      h2 {
        margin-bottom: 16px;
        font-size: var(--fx-mxl2);
      }
      .tradein-veh-image-wrapper {
        img,
        svg {
          width: 100%;
          min-height: 200px;
        }
        &.no-img {
          margin: 10% 0;
          p {
            font-size: var(--fz-xxs);
            color: var(--primary);
          }
        }
        .tradein-vehicle-tradein-subtext {
          text-align: right;
        }
        &.veh-img {
          margin-top: -15%;
          margin-bottom: -5%;
          position: relative;
          .tradein-vehicle-tradein-subtext {
            font-size: var(--fz-xxs);
            text-align: right;
            position: absolute;
            bottom: 15%;
            right: 0;
          }
        }
      }
      .tradein-button-ford-wrapper {
        margin-top: 32px;
      }

      .tradein-vehicle-text {
        font-weight: bold;
        font-size: var(--fz-lg2);
        .tradein-vehicle-value {
          margin-top: 8px;
          margin-bottom: 16px;
          font-weight: normal;
          font-size: var(--fz-md);
        }
      }
      .link-button-container {
        a {
          margin-top: 32px;
          text-align: center;
          font-size: var(--fz-xs);
        }
      }
    }

    .tradein-input-wrapper {
      max-width: 252px;
      width: 100%;
      margin: auto;
      .tradein-veh-card {
        background-color: white;
        box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
        font-size: var(--fz-xxs);

        .tradein-veh-image-wrapper {
          background-color: var(--bgGrey);
          text-align: center;
          padding: 5px 0;
          p {
            font-size: var(--fz-xxs);
            color: var(--primary);
          }
          img,
          svg {
            width: 90%;
            margin-left: 12px;
          }
          svg {
            height: 100px;
          }
        }
        .tradein-buttons-column {
          margin-top: 10px;
        }

        .tradein-vehicle-tradein-subtext {
          margin-top: -40px;
          letter-spacing: 0.5px;
        }

        .tradein-vehicle-title {
          margin-top: 15px;
          color: var(--secondary);
          font-family: var(--fontSecondary);
          font-weight: 400;
          font-size: var(--fz-md);
          text-transform: uppercase;
        }
        .tradein-veh-text-container {
          letter-spacing: 1px;
          padding: 10px;
        }
        .tradein-veh-text-wrapper {
          margin: 15px 0;
          width: 100%;
        }
        .tradein-vehicle-text {
          font-weight: bold;
          text-transform: uppercase;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .no-mouseflow,
          span {
            font-family: var(--fontPrimary);
            font-weight: normal;
          }
        }
      }
    }
  }
  &.lincoln {
    .tradein-input-wrapper {
      .tradein-veh-card {
        box-shadow: none;
        border: solid 1px var(--borderLightGrey);
      }
    }
    .tradein-veh-image-wrapper svg {
      width: 80% !important;
      margin-top: 10px;
    }
    .tradein-no-vehicle-tradein-subtext {
      margin-top: -20px;
    }
    .tradein-veh-image-wrapper {
      padding: 2px 0 !important;
    }
    .tradein-vehicle-title {
      font-size: var(--fz-md2);
    }
    .tradein-veh-text-wrapper {
      font-size: var(--fz-xs2);
    }
    .secondary-link-text {
      font-size: var(--fz-md) !important;
    }
  }
`
