import { createContext } from 'react'

export const DataContext = createContext()

export const DataACTIONS = {
  languageUpdated: 'LANGUAGE_UPDATED',
  marketUpdated: 'MARKET_UPDATED',
  urlUpdated: 'URL_UPDATED',
  colorUpdated: 'COLOR_UPDATED',
  vinUpdated: 'VIN_UPDATED',
  keysUpdated: 'KEYS_UPDATED',
  motUpdated: 'MOT_UPDATED',
  licensePlateUpdated: 'LICENSE_PLATE_UPDATED',
  toolDescriptorUpdated: 'TOOL_DESCRIPTOR_UPDATED',
  mileageUpdated: 'MILEAGE_UPDATED',
  annualMileageUpdated: 'ANNUAL_MILEAGE_UPDATED',
  deliveryMileageUpdated: 'DELIVERY_MILEAGE_UPDATED',
  majorDamageUpdated: 'MAJOR_DAMAGE_UPDATED',
  usStateUpdated: 'US_STATE_UPDATED',
  usStateNameUpdated: 'US_STATE_NAME_UPDATED',
  trimOptionsUpdated: 'TRIM_OPTIONS_UPDATED',
  trimUpdated: 'TRIM_UPDATED',
  loanAccountUpdated: 'LOAN_ACCOUNT_UPDATED',
  financeSourceUpdated: 'FINANCE_SOURCE_UPDATED',
  vehicleImageUrlUpdated: 'VEHICLE_IMAGE_URL_UPDATED',
  vehicleMakeUpdated: 'VEHICLE_MAKE_UPDATED',
  vehicleModelUpdated: 'VEHICLE_MODEL_UPDATED',
  vehicleYearUpdated: 'VEHICLE_YEAR_UPDATED',
  currentAppraisalQuoteUpdated: 'CURRENT_APPRAISAL_QUOTE_UPDATED',
  appraisalQuoteUpdated: 'APPRAISAL_QUOTE_UPDATED',
  appraisalMaxUpdated: 'APPRAISAL_MAX_UPDATED',
  appraisalMinUpdated: 'APPRAISAL_MIN_UPDATED',
  appraisalIdUpdated: 'APPRAISAL_ID_UPDATED',
  deliveryAppraisalQuoteUpdated: 'DELIVERY_APPRAISAL_QUOTE_UPDATED',
  remainingBalanceUpdated: 'REMAINING_BALANCE_UPDATED',
  remainingBalanceNoFeesUpdated: 'REMAINING_BALANCE_NO_FEES_UPDATED',
  lateFeesUpdated: 'LATE_FEES_UPDATED',
  otherFeesUpdated: 'OTHER_FEES_UPDATED',
  enterFinanceManuallyUpdated: 'ENTER_FINANCE_MANUALLY_UPDATED',
  actualRemainingBalanceUpdated: 'ACTUAL_REMAINING_BALANCE_UPDATED',
  reasonForDiscrepancyUpdated: 'REASON_FOR_DISCREPENCY_UPDATED',
  enteredBalanceUpdated: 'ENTERED_BALANCE_UPDATED',
  enteredObligationUpdated: 'ENTERED_OBLIGATION_UPDATED',
  isEditUpdated: 'IS_EDIT_UPDATED',
  showLoadingSpinner: 'SHOW_LOADING_SPINNER',
  registrationDateUpdated: 'REGISTRATION_DATE_UPDATED',
  registrationNumberUpdated: 'REGISTRATION_NUMBER_UPDATED',
  manufacturerUpdated: 'MANUFACTURER_UPDATED',
  modelRangeUpdated: 'MODEL_RANGE_UPDATED',
  derivativeUpdated: 'DERIVATIVE_UPDATED',
  warningLightsUpdated: 'WARNING_LIGHTS_UPDATED',
  bodyUpdated: 'BODY_UPDATED',
  doorsUpdated: 'DOORS_UPDATED',
  overallConditionUpdated: 'OVERALL_CONDITION_UPDATED',
  accidentsUpdated: 'ACCIDENTS_UPDATED',
  odorsUpdated: 'ODORS_UPDATED',
  mechanicalDefectsUpdated: 'MECHANICAL_DEFECTS_UPDATED',
  minorDamageUpdated: 'MINOR_DAMAGE_UPDATED',
  keepRegNumberUpdated: 'KEEP_REG_NUMBER_UPDATED',
  ownerVSCUpdated: 'OWNER_VSC_UPDATED',
  personalUseUpdated: 'PERSONAL_USE_UPDATED',
  importUpdated: 'IMPORT_UPDATED',
  runnerUpdated: 'RUNNER_UPDATED',
  tunedOrModifiedUpdated: 'TUNED_OR_MODIFIED_UPDATED',
  crashedUpdated: 'CRASHED_UPDATED',
  smokingOrAnimalsUpdated: 'SMOKING_OR_ANIMALS_UPDATED',
  engineUpdated: 'ENGINE_UPDATED',
  resetData: 'RESET_US_VALUES',
  releaseDateUpdated: 'RELEASE_DATE_UPDATED',
  rideSharingUpdated: 'RIDE_SHARING_UPDATED',
  hasMaintenanceRecordsUpdated: 'MAINTENANCE_RECORDS_UPDATED',
  privateOfferUpdated: 'PRIVATE_OFFER_UPDATED',

  // FR condition
  frZipCodeUpdated: 'FR_ZIPCODE_UPDATED',
  ownershipUpdated: 'OWNERSHIP_UPDATED',
  metaliicPaint: 'METALLIC_PAINT_UPDATED',

  monthUpdated: 'MONTH_UPDATED',
  gearUpdated: 'GEAR_UPDATED',
  fuelUpdated: 'FUEL_UPDATED',

  esLookupObjUpdated: 'ES_LOOKUP_OBJ_UPDATED',
  itLookupObjUpdated: 'IT_LOOKUP_OBJ_UPDATED',
  euSavedStateUpdated: 'EU_SAVED_STATE_UPDATED',
  euSavedIncorrectStateUpdated: 'EU_SAVED_INCORRECT_STATE_UPDATED',
  euAllSelectDataListUpdated: 'EU_ALL_SELECT_DATA_LIST_UPDATED'
}

export const dataReducer = (state, action) => {
  switch (action.type) {
    // reset all US values to empty string
    case DataACTIONS.resetData:
      console.log('RESET ALL VALUES')
      return {
        ...initialDataState
      }

    case DataACTIONS.languageUpdated:
      console.log('Language Updated via Reducer - New Value:' + action.payload)
      return { ...state, language: action.payload }

    case DataACTIONS.marketUpdated:
      console.log('Market Updated via Reducer - New Value:' + action.payload)
      return { ...state, market: action.payload }

    case DataACTIONS.urlUpdated:
      console.log('Url Updated via Reducer - New Value:' + action.payload)
      return { ...state, url: action.payload }

    case DataACTIONS.vinUpdated:
      console.log('VIN Updated via Reducer - New Value:' + action.payload)
      return { ...state, vin: action.payload } /// Brings in entire state object, only overwrites vin property
    case DataACTIONS.licensePlateUpdated:
      console.log(
        'License Plate Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, licensePlate: action.payload }
    case DataACTIONS.toolDescriptorUpdated:
      console.log(
        'Tool Descriptor Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, toolDescriptor: action.payload }
    case DataACTIONS.colorUpdated:
      console.log('Color Updated via Reducer - New Value:' + action.payload)
      return { ...state, color: action.payload }
    case DataACTIONS.mileageUpdated:
      console.log('Mileage Updated via Reducer - New Value:' + action.payload)
      return { ...state, mileage: action.payload }
    case DataACTIONS.annualMileageUpdated:
      console.log(
        'Annual Mileage Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, annualMileage: action.payload }
    case DataACTIONS.loanAccountUpdated:
      console.log(
        'Loan Account Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, loanAccount: action.payload }
    case DataACTIONS.financeSourceUpdated:
      console.log(
        'Finance Source Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, financeSource: action.payload }

    case DataACTIONS.deliveryMileageUpdated:
      console.log(
        'Delivery Mileage Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, deliveryMileage: action.payload }
    case DataACTIONS.keysUpdated:
      console.log(
        'twoOrMoreKeys Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, twoOrMoreKeys: action.payload }
    case DataACTIONS.usStateUpdated:
      console.log('STATE Updated via Reducer - New Value:' + action.payload)
      return { ...state, state: action.payload }
    case DataACTIONS.usStateNameUpdated:
      console.log(
        'STATE NAME Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, stateName: action.payload }
    case DataACTIONS.trimUpdated:
      console.log('Trim Updated via Reducer - New Value:' + action.payload)
      return { ...state, trim: action.payload }
    case DataACTIONS.trimOptionsUpdated:
      console.log(
        'Trim Options Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, trimOptions: action.payload }

    case DataACTIONS.overallConditionUpdated:
      console.log(
        'Overall Condition Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, overallCondition: action.payload }
    case DataACTIONS.accidentsUpdated:
      console.log('Accidents Updated via Reducer - New Value:' + action.payload)
      return { ...state, accidents: action.payload }
    case DataACTIONS.odorsUpdated:
      console.log('Odors Updated via Reducer - New Value:' + action.payload)
      return { ...state, odors: action.payload }
    case DataACTIONS.mechanicalDefectsUpdated:
      console.log(
        'Mechanical Defects Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, mechanicalDefects: action.payload }
    case DataACTIONS.minorDamageUpdated:
      console.log(
        'Minor Damage Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, minorDamage: action.payload }
    case DataACTIONS.vehicleImageUrlUpdated:
      console.log(
        'vehicleImageUrl Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, vehicleImageUrl: action.payload }
    case DataACTIONS.vehicleMakeUpdated:
      console.log('Make Updated via Reducer - New Value:' + action.payload)
      return { ...state, make: action.payload }
    case DataACTIONS.vehicleModelUpdated:
      console.log('Model Updated via Reducer - New Value:' + action.payload)
      return { ...state, model: action.payload }
    case DataACTIONS.vehicleYearUpdated:
      console.log('Year Updated via Reducer - New Value:' + action.payload)
      return { ...state, year: action.payload }
    case DataACTIONS.appraisalQuoteUpdated:
      console.log(
        'Appraisal Quote Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, appraisalQuote: action.payload }
    case DataACTIONS.appraisalMaxUpdated:
      console.log(
        'Appraisal Max Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, appraisalMax: action.payload }
    case DataACTIONS.appraisalMinUpdated:
      console.log(
        'Appraisal Min Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, appraisalMin: action.payload }
    case DataACTIONS.appraisalIdUpdated:
      console.log(
        'Current Appraisal ID Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, appraisalId: action.payload }
    case DataACTIONS.currentAppraisalQuoteUpdated:
      console.log(
        'Current Appraisal Quote Updated via Reducer - New Value:' +
          action.payload
      )
      return { ...state, currentAppraisalQuote: action.payload }
    case DataACTIONS.deliveryAppraisalQuoteUpdated:
      console.log(
        'Delivery Appraisal Quote Updated via Reducer - New Value:' +
          action.payload
      )
      return { ...state, deliveryAppraisalQuote: action.payload }
    case DataACTIONS.actualRemainingBalanceUpdated:
      console.log(
        'Actual Remaining Balance Updated via Reducer - New Value:' +
          action.payload
      )
      return { ...state, actualRemainingBalance: action.payload }
    case DataACTIONS.remainingBalanceUpdated:
      console.log(
        'Remaining Balance Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, remainingBalance: action.payload }
    case DataACTIONS.remainingBalanceNoFeesUpdated:
      console.log(
        'Remaining Balance Without Fees Updated via Reducer - New Value:' +
          action.payload
      )
      return { ...state, remainingBalanceNoFees: action.payload }
    case DataACTIONS.lateFeesUpdated:
      console.log('Late Fees Updated via Reducer - New Value:' + action.payload)
      return { ...state, lateFees: action.payload }
    case DataACTIONS.otherFeesUpdated:
      console.log(
        'Other Fees Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, otherFees: action.payload }
    case DataACTIONS.enterFinanceManuallyUpdated:
      console.log(
        'Enter Finance Info manually Updated via Reducer - New Value:' +
          action.payload
      )
      return { ...state, enterFinanceManually: action.payload }
    case DataACTIONS.reasonForDiscrepancyUpdated:
      console.log(
        'Reason For Discrepency Updated via Reducer - New Value:' +
          action.payload
      )
      return { ...state, reasonForDiscrepancy: action.payload }

    case DataACTIONS.enteredBalanceUpdated:
      console.log(
        'Entered Balance Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, enteredBalance: action.payload }
    case DataACTIONS.enteredObligationUpdated:
      console.log(
        'Entered Obligation Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, enteredObligation: action.payload }

    case DataACTIONS.privateOfferUpdated:
      console.log(
        'Private Offer Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, privateOffer: action.payload }

    case DataACTIONS.isEditUpdated:
      console.log('Edit Updated via Reducer - New Value:' + action.payload)
      return { ...state, isEdit: action.payload }
    case DataACTIONS.runnerUpdated:
      console.log('Runner Updated via Reducer - New Value:' + action.payload)
      return { ...state, runner: action.payload }
    case DataACTIONS.tunedOrModifiedUpdated:
      console.log(
        'Tuned Or Modified Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, tunedOrModified: action.payload }
    case DataACTIONS.crashedUpdated:
      console.log('Crashed Updated via Reducer - New Value:' + action.payload)
      return { ...state, crashed: action.payload }
    case DataACTIONS.smokingOrAnimalsUpdated:
      console.log(
        'Smoking Or Animals Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, smokingOrAnimals: action.payload }

    case DataACTIONS.registrationDateUpdated:
      console.log(
        'Registration Date Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, registrationDate: action.payload }
    case DataACTIONS.registrationNumberUpdated:
      console.log(
        'Registration Number Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, registrationNumber: action.payload }
    case DataACTIONS.majorDamageUpdated:
      console.log(
        'Major Damage Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, majorDamage: action.payload }
    case DataACTIONS.manufacturerUpdated:
      console.log(
        'Manufacturer Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, manufacturer: action.payload }
    case DataACTIONS.modelRangeUpdated:
      console.log(
        'Model Range Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, modelRange: action.payload }

    case DataACTIONS.engineUpdated:
      console.log('Engine Updated via Reducer - New Value:' + action.payload)
      return { ...state, engine: action.payload }

    case DataACTIONS.derivativeUpdated:
      console.log(
        'Derivative Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, derivative: action.payload }
    case DataACTIONS.warningLightsUpdated:
      console.log(
        'Warning Lights Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, warningLights: action.payload }
    case DataACTIONS.motUpdated:
      console.log('MOT Updated via Reducer - New Value:' + action.payload)
      return { ...state, mot: action.payload }
    case DataACTIONS.keepRegNumberUpdated:
      console.log(
        'Keep Registration Number Updated via Reducer - New Value:' +
          action.payload
      )
      return { ...state, keepRegNumber: action.payload }
    case DataACTIONS.ownerVSCUPDATED:
      console.log(
        'Owner VSC Updated Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, ownerVSC: action.payload }
    case DataACTIONS.personalUseUpdated:
      console.log(
        'Personal Use Updated Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, personalUse: action.payload }
    case DataACTIONS.importUpdated:
      console.log(
        'Import Updated Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, import: action.payload }

    case DataACTIONS.bodyUpdated:
      console.log('Body Updated via Reducer - New Value:' + action.payload)
      return { ...state, body: action.payload }
    case DataACTIONS.doorsUpdated:
      console.log('Doors Updated via Reducer - New Value:' + action.payload)
      return { ...state, doors: action.payload }

    case DataACTIONS.showLoadingSpinner:
      console.log('Show loader update via reducer -New Value:' + action.payload)
      return { ...state, showLoadingSpinner: action.payload }

    // FR veh condition
    case DataACTIONS.ownershipUpdated:
      console.log('ownership Updated via Reducer - New Value:' + action.payload)
      return { ...state, ownership: action.payload }
    case DataACTIONS.frZipCodeUpdated:
      console.log(
        'FR Zip code Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, frZipCode: action.payload }

    case DataACTIONS.metallicPaintUpdated:
      console.log(
        'metallic paint Updated via Reducer - New Value:' + action.payload
      )
      return { ...state, metallicPaint: action.payload }
    case DataACTIONS.monthUpdated:
      console.log('Month updated via reducer - New Value:' + action.payload)
      return { ...state, month: action.payload }
    case DataACTIONS.gearUpdated:
      console.log('Gear updated via reducer - New Value:' + action.payload)
      return { ...state, gear: action.payload }
    case DataACTIONS.fuelUpdated:
      console.log('Fuel updated via reducer - New Value:' + action.payload)
      return { ...state, fuel: action.payload }

    case DataACTIONS.esLookupObjUpdated:
      console.log(
        'esLookupObj updated via reducer - New Value:' + action.payload
      )
      return { ...state, esLookupObj: action.payload }
    case DataACTIONS.itLookupObjUpdated:
      console.log(
        'itLookupObj updated via reducer - New Value:' + action.payload
      )
      return { ...state, itLookupObj: action.payload }
    case DataACTIONS.euAllSelectDataListUpdated:
      console.log(
        'euAllSelectDataList updated via reducer - New Value:' + action.payload
      )
      return { ...state, euAllSelectDataList: action.payload }

    case DataACTIONS.euSavedStateUpdated:
      console.log(
        'euSavedStateUpdated updated via reducer - New Value:' + action.payload
      )
      return { ...state, euSavedState: action.payload }
    case DataACTIONS.euSavedIncorrectStateUpdated:
      console.log(
        'euSavedIcorrectState updated via reducer - New Value:' + action.payload
      )
      return { ...state, euSavedIncorrectState: action.payload }

    case DataACTIONS.releaseDateUpdated:
      console.log(
        'Release Date updated via reducer - New Value:' + action.payload
      )
      return { ...state, releaseDate: action.payload }
    case DataACTIONS.rideSharingUpdated:
      console.log(
        'Ride Sharing updated via reducer - New Value:' + action.payload
      )
      return { ...state, rideSharing: action.payload }
    case DataACTIONS.hasMaintenanceRecordsUpdated:
      console.log(
        'Maintenance Record updated via reducer - New Value:' + action.payload
      )
      return { ...state, hasMaintenanceRecords: action.payload }
    default:
      return state
  }
}

export const initialDataState = {
  // query params
  language: '',
  market: '',
  appraisalId: null,
  url: '',
  /// Your Vehicle
  vin: '',
  registrationNumber: null, // AU61OHO
  licensePlate: '',
  toolDescriptor: '',
  state: '',
  stateName: '',
  /// Vehicle Basics
  vehicleImageUrl: '',
  make: '',
  model: '',
  year: '',
  engine: '',
  trim: null,
  trimOptions: [
    { value: 'trim1', label: 'trim1' },
    { value: 'trim2', label: 'trim2' }
  ],
  mileage: null,
  financeSource: 'N/A',
  loanAccount: 'N/A',
  annualMileage: '',
  deliveryMileage: '',
  twoOrMoreKeys: '',
  color: '',
  overallCondition: '',
  accidents: '',
  odors: '',
  mechanicalDefects: '',
  warningLights: '',
  minorDamage: '',
  majorDamage: '',
  appraisalQuote: null,
  appraisalMax: null,
  appraisalMin: null,
  currentAppraisalQuote: null,
  deliveryAppraisalQuote: null,
  isEdit: false,
  enterFinanceManually: false,
  remainingBalance: '',
  remainingBalanceNoFees: '',
  otherFees: '',
  lateFees: '',
  term: '',
  actualRemainingBalance: '',
  reasonForDiscrepancy: '',
  enteredBalance: null,
  enteredObligation: null,
  privateOffer: null, //fusionRecallFlow
  // end of US

  // EU general
  registrationDate: null,

  // uk
  mot: '',
  keepRegNumber: '',
  ownerVSC: '',

  // fr
  personalUse: '',
  import: '',
  runner: '',
  crashed: '',
  smokingOrAnimals: '',
  metallicPaint: false,
  ownership: false,
  tunedOrModified: '',

  // de
  month: '',
  gear: {},
  fuel: {},
  body: {},
  doors: '',

  // ES dropdown values
  releaseDate: null,
  esLookupObj: '',
  itLookupObj: '',
  euSavedState: false,
  euSavedIncorrectState: false,
  euAllSelectDataList: {},

  rideSharing: '',
  hasMaintenanceRecords: ''
}
