import { getMonthNL } from '../components/custom/EU/copy/constants'
// displaying values in USD
export const currencyUSD = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value)
}

// displaying values in Euro
export const currencyEuro = (value) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  }).format(value)
}

// displaying values in Pound
export const currencyPound = (value) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(value)
}

// displaying values in NL
export const currencyNL = (value) => {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
  }).format(value)
}

// need refactor ideas
function currencyRegex(amount, type) {
  if (
    !(type === 'usd' || type === 'pound' || type === 'kr' || type === 'euro2')
  ) {
    return (
      amount
        // deletes all non numeric characters except ,
        .replace(/(?!,)\D/g, '')
        // removes all extra , except the first ,
        .replace(/,/, '#')
        .replace(/,/g, '')
        .replace(/#/, ',')
        // deletes everything after 2 decimal places
        .replace(/^(\d+.?\d{0,2})\d*$/, '$1')
        //  inserts period at appropriate places
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    )
  } else if (type === 'euro2') {
    return (
      amount
        // deletes all non numeric characters except .
        .replace(/(?!\.)\D/g, '')
        // removes all extra . except the first .
        .replace(/\./, '#')
        .replace(/\./g, '')
        .replace(/#/, '.')
        // deletes everything after 2 decimal places
        .replace(/^(\d+.?\d{0,2})\d*$/, '$1')
        //  inserts commas at appropriate places
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    )
  } else {
    return (
      amount
        // deletes all non numeric characters except .
        .replace(/(?!\.)\D/g, '')
        // removes all extra . except the first .
        .replace(/\./, '#')
        .replace(/\./g, '')
        .replace(/#/, '.')
        // deletes everything after 2 decimal places
        .replace(/^(\d+.?\d{0,2})\d*$/, '$1')
        //  inserts commas at appropriate places
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    )
  }
}

export const deformatCurrency = (amount, market) => {
  if (amount && market) {
    switch (market) {
      case 'NO':
        return parseFloat(amount.substring(3).replace(',', ''))
      case 'DE':
      case 'NL':
      case 'FR':
      case 'DK':
      case 'BE':
      case 'AT':
      case 'ES':
      case 'IT':
        return parseFloat(amount.replace('.', '').replace(',', '.'))
      case 'US':
        return parseFloat(amount.replace('$', '').replace(',', ''))
      case 'GB':
        return parseFloat(amount.replace('£', '').replace(',', ''))
      case 'FI':
        return parseFloat(amount.replace(' ', ''))
      case 'CH':
        return parseFloat(
          amount.substring(4).replace('.', '').replace(',', '.')
        )
      default:
        return amount.replace('$', '').replace(',', '')
    }
  }
  return 0
}

// US
export const inputUSD = (value) => {
  if (value) {
    return '$' + currencyRegex(value, 'usd')
  }
}

// FR, NED
export const inputEuro = (value) => {
  if (value) {
    return currencyRegex(value, 'euro')
  }
}

// NOR
export const inputKr = (value) => {
  if (value) {
    return 'kr ' + currencyRegex(value, 'kr')
  }
}

// UK
export const inputPound = (value) => {
  if (value) {
    return '£' + currencyRegex(value, 'pound')
  }
}

// SUI
export const inputFr = (value) => {
  if (value) {
    return 'Fr. ' + currencyRegex(value, 'fr')
  }
}

export const inputFi = (value) => {
  if (value) {
    return currencyRegex(value, 'euro2')
  }
}

export const numberCommas = (value) => {
  if (value) {
    return parseInt(value).toLocaleString(navigator.language, {
      minimumFractionDigits: 0
    })
  }
}

export const numberCommasEU = (value, market) => {
  if (value) {
    // eu standard
    return parseInt(value).toLocaleString('nl-NL', {
      minimumFractionDigits: 0
    })
  }
}

export const currency = (value) => {
  if (value) {
    return `$${numberCommas(value)}.00`
  }
}

export const phoneNumber = (number) => {
  const textValue = `${number}`.slice(0, 10).toString()
  const textLength = textValue.length

  if (textLength < 4) {
    return textValue
  }
  if (textLength <= 6) {
    return `(${textValue.slice(0, 3)}) ${textValue.slice(3, 6)}`
  }
  return `(${textValue.slice(0, 3)}) ${textValue.slice(3, 6)}-${textValue.slice(
    6,
    10
  )}`
}

export const dateFormatFR = (str) => {
  return str.split('-').reverse().join('/')
}

// change date from "YYYY-MM-DD" to "DD/MM/YYYY"
export const reverseDateString = (dateString) => {
  return dateString.split('-').reverse().join('/')
}

// change date format from "YYYY-MM-DD" to 4th June 2021 format
export const dateFormatEU = (dateString) => {
  const tmpDate = dateString.split('-').join('/')
  var formattedDate = new Date(tmpDate)
  // write switch stmts in future if more lang come up.
  const formattedMonth = getMonthNL[formattedDate.getMonth()]
  return `${formattedDate.getDate()} ${formattedMonth} ${formattedDate.getFullYear()}`
}

const nth = function (d) {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}
export const valueToUpperCase = (value) => {
  return value.toUpperCase()
}
export const valueToCamelCase = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}
