import React, { useContext, useState, useEffect } from 'react'
import { navigate, usePath } from 'hookrouter'

import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import TradeinLink from '../../common/pageElements/TradeinLink'
import TradeinButton from '../../common/pageElements/TradeinButton'
import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders } from './Copy'
import PopoverModal from '../../common/pageElements/PopoverModal'
import Image from '../../common/icons/Image'
import ScrollToTop from '../../common/pageElements/ScrollToTop'
import { stateList } from '../../../constants/forms.js'

import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import { StyledVehicleFound } from '../../styled-components/screens/StyledVehicleFound'
import { pageLoadTags, onClick } from '../../../utils/analytics/index'
import api from 'utils/api'
import * as filters from '../../../utils/filters'

const VehInfoIncorrectModal = styled(PopoverModal)`
  .tradein-popover-modal {
    .tradein-popover-modal-inner {
      max-width: 816px;
      padding: 10px 30px 50px;
      top: 17%;
      line-height: 1.63;

      @media ${mediaQueries.underM} {
        max-width: 720px;
        padding: 10px;
      }
      @media ${mediaQueries.underS} {
        width: 100%;
        height: 100%;
        top: 0%;
      }
      .tradein-popover-modal-header {
        padding: 8px;
        border-bottom: none;
      }
      .tradein-popover-modal-body {
        padding: 0px;
        @media ${mediaQueries.underS} {
          padding: 0 25px 10px 25px;
        }
        .tradein-body-wrap {
          letter-spacing: 1px;
          .tradein-popover-modal-header-text {
            font-family: var(--fontSecondary);
            font-weight: 200;
            font-size: var(--fz-xl);
          }
          .tradein-subtext {
            font-size: var(--fz-md);
            max-width: 756px;
            margin: 10px 0 25px 0;
            text-align: left;
            line-height: 1.63;
          }
          .veh-info-wrapper {
            font-size: var(--fz-md);
            width: 100%;
            .veh-info {
              display: flex;
              justify-content: space-between;
              text-transform: uppercase;
              font-weight: bold;
              margin-bottom: 5px;
              span {
                font-weight: normal;
                font-family: var(--fontPrimary);
              }
            }
          }
        }
      }
    }
  }
`
const VehicleFound = () => {
  const { appDispatch, appState, brand, market, application } =
    useContext(AppContext)
  const { dataState, dataDispatch } = useContext(DataContext)
  const [showVehicleInfoIncorrect, setShowVehicleInfoIncorrect] =
    useState(false)

  const {
    state,
    stateName,
    vehicleImageUrl,
    year,
    make,
    model,
    toolDescriptor,
    vin,
    licensePlate,
    mileage,
    appraisalId
  } = dataState

  useEffect(() => {
    pageLoadTags.selectVehicle(toolDescriptor, year, make, model)
  }, [])
  const imageUrl = vehicleImageUrl ? vehicleImageUrl : ''
  const isFusionRecallFlow = application === 'FUSION_RECALL'
  function handleSubmit() {
    const nextStep =
      appState.financeType === 'Lease'
        ? dataState.enteredObligation
          ? 'confirmbalance'
          : 'financing'
        : 'vehiclebasics'

    appDispatch({
      type: AppACTIONS.jumpToStepUpdated,
      payload: nextStep
    })
    if (isFusionRecallFlow) {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
      api
        .appraisalFusionRecall({
          appraisalId,
          mileage
        })
        .then((data) => {
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          if (data.appraisalQuote) {
            dataDispatch({
              type: 'APPRAISAL_QUOTE_UPDATED',
              payload: data.appraisalQuote
            })
            dataDispatch({
              type: 'PRIVATE_OFFER_UPDATED',
              payload: data.privateOffer
            })
            navigate('/tradeinappraisal')
          } else {
            navigate('/sorryCannotAppraiseFusionRecall')
          }
        })
        .catch((error) => {
          dataDispatch({
            type: 'SHOW_LOADING_SPINNER',
            payload: false
          })
          navigate('/sorryCannotAppraiseFusionRecall')
          console.log(error)
        })
    } else {
      navigate('/' + nextStep)
    }
  }

  function handleVehicleInfoIncorrect() {
    onClick.selectVehicle()
    setShowVehicleInfoIncorrect(true)
  }

  function handleClick() {
    dataDispatch({ type: DataACTIONS.resetData })
    appDispatch({
      type: AppACTIONS.jumpToStepUpdated,
      payload: 'landingpage_US'
    })
    navigate('/landingpage_US')
  }
  let initialStateName = stateName
  if (state && !stateName) {
    initialStateName = stateList.find((s) => s.value === state).name
  }

  const currentPath = usePath()
  const fordUS = brand === 'ford' && currentPath === '/vehiclefound'
  function error() {
    console.log('error')
    dataDispatch({
      type: DataACTIONS.vehicleImageUrlUpdated,
      payload: ''
    })
  }
  return (
    <StyledVehicleFound className={brand}>
      <ScrollToTop />
      <StepHeader
        mainHead={StepHeaders.vehicleFound.main}
        subHead={StepHeaders.vehicleFound.sub}
      />
      {brand === 'lincoln' ? (
        <div className='tradein-input-wrapper'>
          <div className='tradein-veh-card'>
            <div className='tradein-veh-content-wrapper'>
              {vehicleImageUrl ? (
                <div className='tradein-veh-image-wrapper'>
                  <img
                    className='veh-image'
                    src={imageUrl}
                    onError={error}
                    alt='test'
                  />
                  <p className='tradein-vehicle-tradein-subtext'>
                    Vehicle image is only an example
                  </p>
                </div>
              ) : (
                <div className='tradein-veh-image-wrapper'>
                  <Image
                    img={
                      brand === 'ford'
                        ? 'placeholderCar'
                        : 'lincolnPlaceholderCar'
                    }
                  />
                  <p className='tradein-no-vehicle-tradein-subtext'>
                    No Image Available
                  </p>
                </div>
              )}
              <div className='tradein-veh-text-container'>
                <div className='tradein-vehicle-title'>
                  {year} {make} {model}
                </div>
                <div className='tradein-veh-text-wrapper'>
                  {vin && (
                    <div className='tradein-vehicle-text'>
                      VIN: <div className='no-mouseflow'>{vin}</div>
                    </div>
                  )}
                  {licensePlate && (
                    <div className='tradein-vehicle-text'>
                      License Plate:
                      <div className='no-mouseflow'>{licensePlate}</div>
                    </div>
                  )}
                  <div className='tradein-vehicle-text'>
                    State: <span>{initialStateName}</span>
                  </div>
                </div>
              </div>
              <div className='tradein-buttons-column'>
                <TradeinButton
                  type='primary'
                  text='Confirm'
                  iconType='nextIcon'
                  action={handleSubmit}
                />
                <div className='link-button-container'>
                  <TradeinLink
                    type='secondary'
                    text='Vehicle info is incorrect'
                    action={handleVehicleInfoIncorrect}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='tradein-buttons-container'>
            <TradeinButton type='back' target='landingpage_US' />
          </div>
        </div>
      ) : (
        <div class='ford vehiclefound'>
          {vehicleImageUrl ? (
            <div className='tradein-veh-image-wrapper veh-img'>
              <img
                className='veh-image'
                src={imageUrl}
                onError={error}
                alt='test'
              />
              <div className='tradein-vehicle-tradein-subtext'>
                Vehicle image is only an example
              </div>
            </div>
          ) : (
            <div className='tradein-veh-image-wrapper no-img'>
              <Image img={'placeholderCar'} />
              <div className='tradein-vehicle-tradein-subtext'>
                No Image Available
              </div>
            </div>
          )}
          <h2>
            {year} {make} {model}
          </h2>
          <div className='tradein-veh-text-wrapper'>
            {vin && (
              <div className='tradein-vehicle-text'>
                VIN
                <div className='no-mouseflow tradein-vehicle-value'>{vin}</div>
              </div>
            )}
            {licensePlate && (
              <div className='tradein-vehicle-text'>
                License Plate
                <div className='no-mouseflow tradein-vehicle-value'>
                  {licensePlate}
                </div>
              </div>
            )}
            <div className='tradein-vehicle-text'>
              State
              <div className='tradein-vehicle-value'>{initialStateName}</div>
            </div>
            {isFusionRecallFlow && (
              <div className='tradein-vehicle-text'>
                Mileage
                <div className='tradein-vehicle-value'>
                  {filters.numberCommas(dataState.mileage)}
                </div>
              </div>
            )}
          </div>
          <div className='link-button-container'>
            <TradeinLink
              type='secondary'
              text='Vehicle info is incorrect'
              action={handleVehicleInfoIncorrect}
            />
          </div>
          <div
            className={`tradein-buttons-container tradein-button-ford-wrapper`}
          >
            <TradeinButton
              type='USFordBack'
              text='Back'
              iconType='prevIcon'
              target='landingpage_US'
            />
            <TradeinButton
              type='USFordPrimary'
              text={isFusionRecallFlow ? 'Submit' : 'Continue'}
              iconType='nextIcon'
              action={() => handleSubmit()}
            />
          </div>
        </div>
      )}
      {/* ford */}
      {showVehicleInfoIncorrect && brand === 'ford' && (
        <PopoverModal
          headerText={'Sorry, your vehicle info is incorrect'}
          showModal={showVehicleInfoIncorrect}
          closeModal={() => setShowVehicleInfoIncorrect(false)}
          updatedDesign={brand === 'ford'}
        >
          <div className='tradein-cancel-modal-subtitle'>
            Confirm you entered the number correctly, or try again with the
            other option (either license plate or VIN) instead.
          </div>

          <div className='tradein-vehicle-info'>
            <div className='tradein-vehicle-info'>
              {vin && (
                <div className='tradein-vehicle-info-row'>
                  <span className='tradein-modal-label'>VIN</span>
                  <span className='tradein-modal-value  no-mouseflow'>
                    {vin}
                  </span>
                </div>
              )}
              {licensePlate && (
                <div className='tradein-vehicle-info-row'>
                  <span className='tradein-modal-label'>License Plate</span>
                  <span className='tradein-modal-value  no-mouseflow'>
                    {licensePlate}
                  </span>
                </div>
              )}
              <div className='tradein-vehicle-info-row'>
                <span className='tradein-modal-label'>State</span>
                <span className='tradein-modal-value'>{stateName}</span>
              </div>
            </div>
          </div>

          <div className='tradein-buttons-container'>
            <TradeinButton
              type={brand === 'ford' ? 'USFordPrimary' : 'primary'}
              text='Try Again'
              action={() => handleClick()}
            />
          </div>
        </PopoverModal>
      )}
      {/* lincoln */}
      {showVehicleInfoIncorrect && brand !== 'ford' && (
        <VehInfoIncorrectModal
          VehicleInfoIncorrect
          headerText='Sorry about that'
          showModal={showVehicleInfoIncorrect}
          closeModal={() => setShowVehicleInfoIncorrect(false)}
          styleName='vehicleInfoIncorrect'
        >
          <div className='tradein-subtext'>
            Confirm you entered the number correctly, or try again with the
            other option (either license plate or VIN) instead.
          </div>

          <div className='veh-info-wrapper'>
            {vin && (
              <div className='veh-info'>
                VIN
                <span>{vin}</span>
              </div>
            )}
            {licensePlate && (
              <div className='veh-info'>
                License Plate
                <span>{licensePlate}</span>
              </div>
            )}
            <div className='veh-info'>
              State
              <span>{stateName}</span>
            </div>
          </div>
          <div className='tradein-buttons-container'>
            <TradeinButton
              type={brand === 'ford' ? 'USFordPrimary' : 'primary'}
              text='Try Again'
              action={() => handleClick()}
            />
          </div>
        </VehInfoIncorrectModal>
      )}
    </StyledVehicleFound>
  )
}

export default VehicleFound
