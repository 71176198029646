import styled from 'styled-components'

import { mediaQueries } from '../../styled-components/mediaQueries'

export const StyledLandingPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &.eu {
    h1.tradein-main-header {
      font-size: 34px;
      font-weight: 200;
      line-height: 1.29;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin-top: 0px;
    }
    input {
      text-transform: uppercase;
    }
  }

  .tradein-landingpage-wrap {
    width: 100%;
    max-width: 430px;
    text-align: center;
    &.ford {
      width: 430px;
      @media ${mediaQueries.underM} {
        width: 100%;
      }
    }

    @media ${mediaQueries.underM} {
      margin-top: 20px;
    }
  }
  div.fmc-floating-label {
    padding-bottom: 4px;
    div:first-of-type {
      padding: 0;
    }
    input.fmc-input {
      text-transform: uppercase;
    }
  }
  .tradein-back-button.tradein-forward-link-button {
    margin-bottom: 70px;
    margin-top: -10px;
    font-size: var(--fz-lg) !important;
  }
  .tradein-link-button {
    font-size: var(--fz-xxs);
    padding-left: 0;
  }

  .tradein-submit-button {
    margin-left: 0px;
  }
  .tradein-error-message {
    padding: 4px 0 8px 0;
  }
  &.lincoln {
    .tradein-modal-description {
      font-size: var(--fz-md) !important;
    }
  }
`
