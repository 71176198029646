import React, { useState, useContext, useRef, useEffect } from 'react'
import Icon from '../icons/Icon'
import { AppContext } from '../context/AppContext'

import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'

const StyledPopUpTip = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  .tradein-popuptip-button {
    margin-bottom: 5px;
    background: none;
  }
  .popuptiptext {
    position: absolute;
    width: 752px;
    margin: 0 auto;
    display: flex;
    background-color: #fff;
    border: solid 1px #6b7786;
    text-align: left;
    border-radius: 4px;
    font-family: var(--primary);
    padding: 16px;
    right: 0;
    transform: translateX(50%);
    font-size: 12px;
    z-index: 1060;
    .close {
      text-align: right;
      margin-bottom: -5px;
      button {
        width: 16px;
        height: 16px;
        position: relative;
        top: -2px;
        background: none;
        right: 8px;
      }
    }
    @media ${mediaQueries.underL} {
      width: 570px;
      transform: translateX(10%);
      margin-top: -2px;
    }
    @media ${mediaQueries.underS} {
      width: 250px;
      transform: translateX(-7%);
      height: 476px;
      margin-top: -25px;
    }
  }

  // POPUPTIP MOBILE
  .tradein-popuptip-mobile-popover {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    background-color: white;
    z-index: 2;
    padding: 75px 20px;
    font-size: var(--fz-lg);
    line-height: 26px;
    font-family: var(--fontPrimary);
  }
  .tradein-popuptip-mobile-close-icon-wrapper {
    position: absolute;
    background: none;
    right: -2px;
    margin-bottom: 10px;
    top: 1px;
    padding: 10px;
    svg {
      width: 24px;
      height: 24px;
    }
  a {
    color: #1f3047 !important;
    margin-left: 2px;
    margin-right: 2px;
  }
`

const PopUpTip = ({ id, popuptipBody, ariaLabel, analyticsEvent }) => {
  const [popuptipOpen, setPopuptipOpen] = useState(false)
  const [widthSmall, setWdithSmall] = useState(false)
  const tooltipRef = useRef(null)
  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setPopuptipOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggle = () => {
    const mq = window.matchMedia(mediaQueries.underS)
    if (mq.matches) {
      setWdithSmall(true)
    } else {
      setWdithSmall(false)
    }
    setPopuptipOpen(!popuptipOpen)
  }

  const { brand } = useContext(AppContext)
  return (
    <StyledPopUpTip className={brand}>
      <button
        id={id}
        className='tradein-popuptip-button'
        aria-label={ariaLabel}
        aria-labelledby={id + '-popuptip-body'}
        onClick={toggle}
      >
        <Icon icon='blueiBall' title={ariaLabel} />
      </button>
      {popuptipOpen && !widthSmall && (
        <div ref={tooltipRef}>
          <div className='popuptiptext'>
            {popuptipBody}
            <div className='close'>
              <button
                id={id}
                aria-label={ariaLabel}
                aria-labelledby={id + '-popuptip-body'}
                onClick={analyticsEvent ? onClick : toggle}
              >
                <Icon icon='closePopupTip' title={ariaLabel} />
              </button>
            </div>
          </div>
        </div>
      )}
      {popuptipOpen && widthSmall && (
        <div className='tradein-popuptip-mobile-popover'>
          <button
            className='tradein-popuptip-mobile-close-icon-wrapper'
            onClick={toggle}
            aria-label='Close Popuptip'
          >
           <Icon icon='closePopupTip' title={ariaLabel} />
          </button>
          {popuptipBody}
        </div>
      )}
    </StyledPopUpTip>
  )
}
PopUpTip.defaultProps = {
  ariaLabel: 'More Information'
}
export default PopUpTip
