import React, { useContext, useState, useCallback } from 'react'
import { DataContext } from '../../common/context/DataContext'
import { AppContext } from '../../common/context/AppContext'
import { UseRadio, useInput } from '../../common/customhooks/forms'
import TradeinButton from '../../common/pageElements/TradeinButton'
import ScrollToTop from '../../common/pageElements/ScrollToTop'
import RadioButton from '../../common/pageElements/RadioButton'
import TextInput from '../../common/pageElements/TextInput'
import Tooltip from '../../common/pageElements/Tooltip'
import api from '../../../utils/api'
import focusError from '../../../utils/focusError'
import * as filters from '../../../utils/filters'
import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders, TradeinAppraisalCopy } from './copy/TradeinAppraisalCopy'
import { sorryCannotAppraiseCopy } from '../../common/messages/MessageCopy'

import { getTCUrl } from '../../../constants/staticUrls'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import { StyledTradeinAppraisal } from '../../styled-components/screens/StyledTradeinAppraisal'
import Image from '../../common/icons/Image'

const RadioButtonEU = styled(RadioButton)`
  padding: 20px 30px;
  border-top: var(--borderInput);
  border-bottom: var(--borderInput);

  .tradein-rb-wrapper {
    display: flex;
    @media ${mediaQueries.overM} {
      width: ${(props) => (props.finance ? '50%' : 'auto')};
    }
  }
`

const TradeInAppraisalEU = () => {
  const { dataState, dataDispatch } = useContext(DataContext)
  const {
    market,
    language,
    financeType,
    deliveryDate,
    exitUrl,
    resetApp,
    application,
    buildShowroomUrl,
    closeAppAndAddToCart,
    referralId
  } = useContext(AppContext)

  /* dummy testing data
  const market = 'NL'
  const language = 'nl_nl'
  const deliveryDate = '2024-01-01'
  const financeType = 'FINANCE'
  */
  const tcUrl = getTCUrl(market, language)

  const {
    currentAppraisalQuote,
    deliveryAppraisalQuote,
    year,
    make,
    model,
    appraisalId,
    registrationNumber
  } = dataState

  // const deliveryAppraisalQuote = null

  const [rawCurrencyValue, setRawValue] = useState(0)
  const callback = useCallback((count) => {
    setRawValue(count)
  }, [])

  // For radio buttons
  const [value, setValue] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [showBalanceTooltip, setShowBalanceTooltip] = useState(false)
  const toolTipText = TradeinAppraisalCopy[language].toolTipText
  const [remainingBalChecked, setChecked] = useState(false)
  const [remainingBalValue, setRemainingValue] = useState(0)

  const [checkOverBalance, setOverBalance] = useState(false)

  const appShowroom = application === 'SHOWROOM'
  const showroomTemplate = market === 'DE' || appShowroom

  function filterPicker(language, quote) {
    switch (language) {
      case 'en_gb':
        return filters.currencyPound(quote)
      case 'nl_nl':
        return filters.currencyNL(quote)
      default:
        return filters.currencyEuro(quote)
    }
  }

  const handledChecked = (event) => {
    const eValue = event.target.value

    const checkedValid =
      TradeinAppraisalCopy[language].remainingBalRadioText.options[0] === eValue

    if (showErrors) {
      setShowErrors(false)
    }
    if (checkedValid) {
      handleOverBalance(remainingBalInput.displayValue)
      setShowBalanceTooltip(toolTipText)
    } else {
      setShowBalanceTooltip(false)
      setOverBalance(false)
      setRemainingValue(0)
    }

    setValue(eValue)
    setChecked(checkedValid)
  }

  const remainingBalRadio = {
    ariaLabels: TradeinAppraisalCopy[language].remainingBalRadioText.ariaLabels,
    id: 'remainingBalRadio',
    label: TradeinAppraisalCopy[language].remainingBalRadioText.label,
    options: TradeinAppraisalCopy[language].remainingBalRadioText.options,
    value: value,
    errorMessage:
      TradeinAppraisalCopy[language].remainingBalRadioText.errorMessage
  }

  const remainingBalInput = useInput({
    label: TradeinAppraisalCopy[language].remainingBalanceInputLabel,
    placeholder:
      TradeinAppraisalCopy[language].remainingBalanceInputPlaceholder,
    initialValue: '',
    errorMessage: TradeinAppraisalCopy[language].remainingBalanceInputError
  })

  const applyFinanceRadio = UseRadio({
    ariaLabels: TradeinAppraisalCopy[language].applyFinanceRadioText.ariaLabels,
    id: 'applyFinanceRadio',
    label: TradeinAppraisalCopy[language].applyFinanceRadioText.label,
    options: TradeinAppraisalCopy[language].applyFinanceRadioText.options
  })

  function handleExit() {
    resetApp(true, appraisalId)
  }

  function checkPaymentMethod() {
    if (financeType === 'FINANCE' && !checkOverBalance && !appShowroom) {
      return (
        <RadioButtonEU
          finance
          {...applyFinanceRadio}
          showErrors={showErrors}
          errorMessage={
            TradeinAppraisalCopy[language].applyFinanceRadioText.error
          }
        />
      )
    }
  }

  function handleAcceptClose() {
    const valid = (currentValue) => currentValue.value !== ''
    setShowErrors(true)

    let acctValid = false

    if (financeType === 'FINANCE') {
      if (remainingBalChecked) {
        acctValid =
          valid(remainingBalRadio) &&
          valid(applyFinanceRadio) &&
          valid(remainingBalInput)
      } else {
        acctValid =
          (valid(remainingBalRadio) && valid(applyFinanceRadio)) ||
          market === 'IT'
      }
    } else {
      if (remainingBalChecked) {
        acctValid = valid(remainingBalRadio) && valid(remainingBalInput)
      } else {
        acctValid = valid(remainingBalRadio) || market === 'IT'
      }
    }

    if (acctValid) {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
      callAcceptAppraisal()
    } else {
      let activeElements = []
      if (financeType === 'FINANCE') {
        if (remainingBalChecked) {
          activeElements = [
            remainingBalRadio,
            remainingBalInput,
            applyFinanceRadio
          ]
        } else {
          activeElements = [remainingBalRadio, applyFinanceRadio]
        }
      } else {
        if (remainingBalChecked) {
          activeElements = [remainingBalRadio, remainingBalInput]
        } else {
          activeElements = [remainingBalRadio]
        }
      }

      focusError([activeElements])
    }
  }

  function callAcceptAppraisal() {
    const addToFinanceArr =
      TradeinAppraisalCopy[language].applyFinanceRadioText.options
    // check whether the option is the first or second to determine whether it is true
    const applyToFinance =
      addToFinanceArr.indexOf(applyFinanceRadio.value) === 0 || false
    const netValue = showroomTemplate
      ? currentAppraisalQuote - remainingBalValue
      : deliveryAppraisalQuote - remainingBalValue

    api
      .acceptAppraisalEU({
        addToFinance: applyToFinance,
        appraisalId,
        remainingBalance: remainingBalValue,
        netValue
      })
      .then((data) => {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
        window.sessionStorage.setItem('appraisalId', appraisalId)
        if (appShowroom) {
          window.location.assign(buildShowroomUrl(exitUrl, appraisalId))
        } else {
          window.location.assign(exitUrl)
        }
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  function customOnBlur(e) {
    if (!e.target.value) {
      setRemainingValue(0)
    }
    handleOverBalance(e.target.value)
  }

  function handleOverBalance(enteredBalance) {
    if (enteredBalance) {
      let overBalance
      const decimalSeparator = rawCurrencyValue.charAt(
        rawCurrencyValue.length - 3
      )
      const remainingBalInt =
        decimalSeparator === ','
          ? rawCurrencyValue.replace(',', '.')
          : rawCurrencyValue

      if (!showroomTemplate) {
        overBalance = deliveryAppraisalQuote - remainingBalInt <= 0
      } else {
        overBalance = currentAppraisalQuote - remainingBalInt <= 0
      }
      setOverBalance(overBalance)
      setRemainingValue(remainingBalInt)
    }
  }

  // only GB in online markets don't use EUR
  const intlConfig = {
    locale: language.replace('_', '-'),
    currency: language === 'en_gb' ? 'GBP' : 'EUR'
  }

  return (
    <StyledTradeinAppraisal className='ford'>
      <ScrollToTop />
      {!deliveryAppraisalQuote ? (
        <div className={`tradein-appraisal ford`}>
          <StepHeader
            mainHead={sorryCannotAppraiseCopy.nl_nl.header}
            subHead={sorryCannotAppraiseCopy.nl_nl.subtext}
          />
          <div>
            <div className='tradein-appraisalCar defaultImg'>
              <Image img={'placeholderCar'} />
            </div>
          </div>

          <div className='tradein-buttons-column'>
            <TradeinButton
              type='USFordPrimary'
              ariaLabel={sorryCannotAppraiseCopy[language].buttonAriaLabel}
              text={sorryCannotAppraiseCopy[language].button}
              action={() => closeAppAndAddToCart()}
              iconType='addIcon'
            />
          </div>
        </div>
      ) : (
        <div className={`tradein-appraisal ford`}>
          <StepHeader mainHead={StepHeaders[language].main} />

          <div className='tradein-appraisal-content'>
            <div className='tradein-appraisal-header'>
              {/* NOT DE OR SHOWROOM FLOW */}
              {!showroomTemplate ? (
                <div>
                  <div className='tradein-subheader'>
                    {StepHeaders[language].sub}
                  </div>
                  <h2 className='tradein-xl tradein-currency-title'>
                    {filterPicker(language, deliveryAppraisalQuote)}
                  </h2>
                  <div className='tradein-subheader-two'>
                    {TradeinAppraisalCopy[language].valuationText}
                  </div>
                  <div className='tradein-s tradein-currency-title'>
                    {filterPicker(language, currentAppraisalQuote)}
                  </div>

                  <div className='tradein-subtext'>
                    {TradeinAppraisalCopy[language].validUntilText(
                      filters.dateFormatFR,
                      deliveryDate,
                      tcUrl
                    )}
                  </div>
                  <div className='tradein-appraisalCar defaultImg'>
                    <Image img={'placeholderCar'} />
                  </div>
                </div>
              ) : (
                <div>
                  <div className='tradein-subheader-two'>
                    {TradeinAppraisalCopy[language].valuationText}
                  </div>
                  <h2 className='tradein-xl tradein-currency-title'>
                    {filterPicker(language, currentAppraisalQuote)}
                  </h2>
                  {market === 'DE' && (
                    <div className='tradein-subtext'>
                      <div className='tradein-disclaimer-header'>
                        {TradeinAppraisalCopy[language].disclaimerHeader}
                      </div>
                      <div className='tradein-disclaimer-text'>
                        {TradeinAppraisalCopy[language].disclaimerText}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className='tradein-appraisal-main-wrapper'>
              <div className='tradein-appraisal-main'>
                <h2 className='tradein-l tradein-currency-title'>
                  {year} {make} {model}
                </h2>
                {market !== 'IT' && (
                  <div style={{ marginBottom: 16 }}>
                    <RadioButtonEU
                      {...remainingBalRadio}
                      onChange={handledChecked}
                      style={{
                        borderBottom: 0,
                        marginBottom: 0
                      }}
                      value={value}
                      padding='20px 40px'
                      showErrors={showErrors}
                      tooltip={showBalanceTooltip}
                      tooltipAriaLabel={
                        TradeinAppraisalCopy[language]
                          .remainingBalTooltipAriaLabel
                      }
                    />
                    {!remainingBalChecked && (
                      <label className='tradein-bal-appraisal-legal-no'>
                        <span>
                          {TradeinAppraisalCopy[language].financeAdvisoryText}
                        </span>
                      </label>
                    )}
                    {remainingBalChecked && (
                      <div className='tradein-appraisal-remaining'>
                        <TextInput
                          {...remainingBalInput}
                          currency={intlConfig}
                          value={remainingBalValue}
                          parentCallback={callback}
                          customOnBlur={customOnBlur}
                        />
                        {language !== 'de_de' && (
                          <label className='bal-appraisal-legal-yes'>
                            <span>
                              {
                                TradeinAppraisalCopy[language]
                                  .appraisalLegalText
                              }
                            </span>
                          </label>
                        )}
                        <div className='tradein-appraisal-cal'>
                          <div className='tradein-appraisal-cal-row'>
                            <div className='tradein-appraisal-cal-label'>
                              {
                                TradeinAppraisalCopy[language]
                                  .appraisalValueText
                              }
                            </div>
                            {!showroomTemplate ? (
                              <div className='tradein-appraisal-cal-value'>
                                {filterPicker(language, deliveryAppraisalQuote)}
                              </div>
                            ) : (
                              <div className='tradein-appraisal-cal-value'>
                                {filterPicker(language, currentAppraisalQuote)}
                              </div>
                            )}
                          </div>
                          <div className='tradein-appraisal-cal-row hightlight'>
                            <div className='tradein-appraisal-cal-label'>
                              {TradeinAppraisalCopy[language].balanceOwedText}
                            </div>
                            <div className='tradein-appraisal-cal-value'>
                              {remainingBalValue
                                ? filterPicker(language, remainingBalValue)
                                : 0}
                            </div>
                          </div>
                          <div className='tradein-appraisal-cal-row total'>
                            <div className='tradein-appraisal-cal-label'>
                              {TradeinAppraisalCopy[language].netValueText}
                              <Tooltip
                                tooltipBody={
                                  TradeinAppraisalCopy[language].netValueToolTip
                                }
                                ariaLabel={
                                  TradeinAppraisalCopy[language]
                                    .netValueToolTipAriaLabel
                                }
                                id='netValueTT'
                              />
                            </div>
                            <div className='tradein-appraisal-cal-value'>
                              {!showroomTemplate
                                ? filterPicker(
                                    language,
                                    deliveryAppraisalQuote - remainingBalValue
                                  )
                                : filterPicker(
                                    language,
                                    currentAppraisalQuote - remainingBalValue
                                  )}
                            </div>
                          </div>
                        </div>
                        {checkOverBalance && (
                          <span className='tradein-error-message'>
                            {TradeinAppraisalCopy[language].overbalanceError}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {checkPaymentMethod()}
                {financeType !== 'FINANCE' && (
                  <hr className='tradein-appraisal-divider' />
                )}
                {
                  checkOverBalance || (
                    // (market === 'IT' && applyFinanceRadio.value === 'No') ? (
                    //   <div className='tradein-buttons-column'>
                    //     <TradeinButton
                    //       ariaLabel={
                    //         TradeinAppraisalCopy[language].continueButtonAriaLabel
                    //       }
                    //       type='primary'
                    //       text={TradeinAppraisalCopy[language].continueButtonText}
                    //       iconType='nextIcon'
                    //       action={handleExit}
                    //     />
                    //   </div>
                    // ) : (
                    <div className='tradein-buttons-column'>
                      <TradeinButton
                        ariaLabel={
                          TradeinAppraisalCopy[language].forwardButtonAriaLabel
                        }
                        type='USFordPrimary'
                        text={
                          appShowroom
                            ? TradeinAppraisalCopy[language].showroomButtonText
                            : TradeinAppraisalCopy[language].forwardButtonText
                        }
                        iconType='addIcon'
                        action={handleAcceptClose}
                      />
                      <TradeinButton
                        ariaLabel={
                          TradeinAppraisalCopy[language].declineButtonAriaLabel
                        }
                        type='USFordBack'
                        text={TradeinAppraisalCopy[language].declineButtonText}
                        action={handleExit}
                      />
                    </div>
                  )
                  //)
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledTradeinAppraisal>
  )
}

export default TradeInAppraisalEU
