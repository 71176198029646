export const stateList = [
  { name: 'Alabama', label: 'Alabama', value: 'AL' },
  { name: 'Alaska', label: 'Alaska', value: 'AK' },
  { name: 'Arizona', label: 'Arizona', value: 'AZ' },
  { name: 'Arkansas', label: 'Arkansas', value: 'AR' },
  { name: 'California', label: 'California', value: 'CA' },
  { name: 'Colorado', label: 'Colorado', value: 'CO' },
  { name: 'Connecticut', label: 'Connecticut', value: 'CT' },
  { name: 'Delaware', label: 'Delaware', value: 'DE' },
  {
    name: 'District Of Columbia',
    label: 'District Of Columbia',
    value: 'DC'
  },
  { name: 'Florida', label: 'Florida', value: 'FL' },
  { name: 'Georgia', label: 'Georgia', value: 'GA' },
  { name: 'Hawaii', label: 'Hawaii', value: 'HI' },
  { name: 'Idaho', label: 'Idaho', value: 'ID' },
  { name: 'Illinois', label: 'Illinois', value: 'IL' },
  { name: 'Indiana', label: 'Indiana', value: 'IN' },
  { name: 'Iowa', label: 'Iowa', value: 'IA' },
  { name: 'Kansas', label: 'Kansas', value: 'KS' },
  { name: 'Kentucky', label: 'Kentucky', value: 'KY' },
  { name: 'Louisiana', label: 'Louisiana', value: 'LA' },
  { name: 'Maine', label: 'Maine', value: 'ME' },
  { name: 'Maryland', label: 'Maryland', value: 'MD' },
  { name: 'Massachusetts', label: 'Massachusetts', value: 'MA' },
  { name: 'Michigan', label: 'Michigan', value: 'MI' },
  { name: 'Minnesota', label: 'Minnesota', value: 'MN' },
  { name: 'Mississippi', label: 'Mississippi', value: 'MS' },
  { name: 'Missouri', label: 'Missouri', value: 'MO' },
  { name: 'Montana', label: 'Montana', value: 'MT' },
  { name: 'Nebraska', label: 'Nebraska', value: 'NE' },
  { name: 'Nevada', label: 'Nevada', value: 'NV' },
  { name: 'New Hampshire', label: 'New Hampshire', value: 'NH' },
  { name: 'New Jersey', label: 'New Jersey', value: 'NJ' },
  { name: 'New Mexico', label: 'New Mexico', value: 'NM' },
  { name: 'New York', label: 'New York', value: 'NY' },
  { name: 'North Carolina', label: 'North Carolina', value: 'NC' },
  { name: 'North Dakota', label: 'North Dakota', value: 'ND' },
  { name: 'Ohio', label: 'Ohio', value: 'OH' },
  { name: 'Oklahoma', label: 'Oklahoma', value: 'OK' },
  { name: 'Oregon', label: 'Oregon', value: 'OR' },
  { name: 'Pennsylvania', label: 'Pennsylvania', value: 'PA' },
  { name: 'Rhode Island', label: 'Rhode Island', value: 'RI' },
  { name: 'South Carolina', label: 'South Carolina', value: 'SC' },
  { name: 'South Dakota', label: 'South Dakota', value: 'SD' },
  { name: 'Tennessee', label: 'Tennessee', value: 'TN' },
  { name: 'Texas', label: 'Texas', value: 'TX' },
  { name: 'Utah', label: 'Utah', value: 'UT' },
  { name: 'Vermont', label: 'Vermont', value: 'VT' },
  { name: 'Virginia', label: 'Virginia', value: 'VA' },
  { name: 'Washington', label: 'Washington', value: 'WA' },
  { name: 'West Virginia', label: 'West Virginia', value: 'WV' },
  { name: 'Wisconsin', label: 'Wisconsin', value: 'WI' },
  { name: 'Wyoming', label: 'Wyoming', value: 'WY' }
]

export const vehicleColors = [
  { value: 'Black', label: 'Black' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Copper', label: 'Copper' },
  { value: 'Gold', label: 'Gold' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Green', label: 'Green' },
  { value: 'Maroon', label: 'Maroon' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Purple', label: 'Purple' },
  { value: 'Red', label: 'Red' },
  { value: 'Silver', label: 'Silver' },
  { value: 'Tan', label: 'Tan' },
  { value: 'White', label: 'White' },
  { value: 'Yellow', label: 'Yellow' },
  { value: 'Other', label: 'Other' }
]

export const reasonsForDiscrepancy = [
  {
    value: 'I recently made a monthly payment',
    label: 'I recently made a monthly payment'
  },
  {
    value: 'I recently paid off a past due balance',
    label: 'I recently paid off a past due balance'
  },
  {
    value: 'I recently paid off the remaining balance',
    label: 'I recently paid off the remaining balance'
  },
  {
    value: 'I recently refinanced my vehicle',
    label: 'I recently refinanced my vehicle'
  },
  {
    value: 'The amount returned is incorrect',
    label: 'The amount returned is incorrect'
  }
]

export const dayRange = [
  {
    value: '01',
    label: '01'
  },
  {
    value: '02',
    label: '02'
  },
  {
    value: '03',
    label: '03'
  },
  {
    value: '04',
    label: '04'
  },
  {
    value: '05',
    label: '05'
  },
  {
    value: '06',
    label: '06'
  },
  {
    value: '07',
    label: '07'
  },
  {
    value: '08',
    label: '08'
  },
  {
    value: '09',
    label: '09'
  },
  {
    value: '10',
    label: '10'
  },
  {
    value: '11',
    label: '11'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '13',
    label: '13'
  },
  {
    value: '14',
    label: '14'
  },
  {
    value: '15',
    label: '15'
  },
  {
    value: '16',
    label: '16'
  },
  {
    value: '17',
    label: '17'
  },
  {
    value: '18',
    label: '18'
  },
  {
    value: '19',
    label: '19'
  },
  {
    value: '20',
    label: '20'
  },
  {
    value: '21',
    label: '21'
  },
  {
    value: '22',
    label: '22'
  },
  {
    value: '23',
    label: '23'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '25',
    label: '25'
  },
  {
    value: '26',
    label: '26'
  },
  {
    value: '27',
    label: '27'
  },
  {
    value: '28',
    label: '28'
  },
  {
    value: '29',
    label: '29'
  },
  {
    value: '30',
    label: '30'
  },
  {
    value: '31',
    label: '31'
  }
]

export const monthRange = [
  {
    value: '01',
    label: '01'
  },
  {
    value: '02',
    label: '02'
  },
  {
    value: '03',
    label: '03'
  },
  {
    value: '04',
    label: '04'
  },
  {
    value: '05',
    label: '05'
  },
  {
    value: '06',
    label: '06'
  },
  {
    value: '07',
    label: '07'
  },
  {
    value: '08',
    label: '08'
  },
  {
    value: '09',
    label: '09'
  },
  {
    value: '10',
    label: '10'
  },
  {
    value: '11',
    label: '11'
  },
  {
    value: '12',
    label: '12'
  }
]

export const yearRange = [
  {
    value: '1990',
    label: '1990'
  },
  {
    value: '1991',
    label: '1991'
  },
  {
    value: '1992',
    label: '1992'
  },
  {
    value: '1993',
    label: '1993'
  },
  {
    value: '1994',
    label: '1994'
  },
  {
    value: '1995',
    label: '1995'
  },
  {
    value: '1996',
    label: '1996'
  },
  {
    value: '1997',
    label: '1997'
  },
  {
    value: '1998',
    label: '1998'
  },
  {
    value: '1999',
    label: '1999'
  },
  {
    value: '2000',
    label: '2000'
  },
  {
    value: '2001',
    label: '2001'
  },
  {
    value: '2002',
    label: '2002'
  },
  {
    value: '2003',
    label: '2003'
  },
  {
    value: '2004',
    label: '2004'
  },
  {
    value: '2005',
    label: '2005'
  },
  {
    value: '2006',
    label: '2006'
  },
  {
    value: '2007',
    label: '2007'
  },
  {
    value: '2008',
    label: '2008'
  },
  {
    value: '2009',
    label: '2009'
  },
  {
    value: '2010',
    label: '2010'
  },
  {
    value: '2011',
    label: '2011'
  },
  {
    value: '2012',
    label: '2012'
  },
  {
    value: '2013',
    label: '2013'
  },
  {
    value: '2014',
    label: '2014'
  },
  {
    value: '2015',
    label: '2015'
  },
  {
    value: '2016',
    label: '2016'
  },
  {
    value: '2017',
    label: '2017'
  },
  {
    value: '2018',
    label: '2018'
  },
  {
    value: '2019',
    label: '2019'
  },
  {
    value: '2020',
    label: '2020'
  },
  {
    value: '2021',
    label: '2021'
  }
]

export const vehicleManufacturers = [
  {
    value: 'Dodge',
    label: 'Dodge'
  },
  {
    value: 'Ford',
    label: 'Ford'
  }
]

export const modelRanges = [
  {
    value: 'SXT',
    label: 'SXT'
  },
  {
    value: 'Ecoboost',
    label: 'Ecoboost'
  }
]

export const vehicleModels = [
  {
    value: 'Grand Caravan',
    label: 'Grand Caravan'
  },
  {
    value: 'F 150 XL',
    label: 'F 150 XL'
  }
]

export const vehicleDerivative = [
  {
    value: 'V6, 3.8 Liter',
    label: 'V6, 3.8 Liter'
  },
  {
    value: 'V6, 3.3 Liter',
    label: 'V6, 3.3 Liter'
  }
]

export const financeLenders = [
  {
    name: 'Ford Credit',
    label: 'Ford Credit',
    value: 'FORD_CREDIT'
  },
  {
    name: 'Lincoln AFS',
    label: 'Lincoln AFS',
    value: 'LINCOLN_AFS'
  },

  {
    name: '1st Interstate Bank',
    label: '1st Interstate Bank',
    value: 'FIRST_INTERSTATE_BANK'
  },
  {
    name: '4Front Credit Union',
    label: '4Front Credit Union',
    value: 'FOUR_FRONT_CREDIT_UNION'
  },
  {
    name: 'Aberdeen Proving Grounds FCU',
    label: 'Aberdeen Proving Grounds FCU',
    value: 'ABERDEEN_PROVING_GROUNDS_FCU'
  },
  {
    name: 'Ally',
    label: 'Ally',
    value: 'ALLY'
  },
  {
    name: 'AltaOne FCU',
    label: 'AltaOne FCU',
    value: 'ALTAONE_FCU'
  },
  {
    name: 'Amarillo National Bank',
    label: 'Amarillo National Bank',
    value: 'AMARILLO_NATIONAL_BANK'
  },
  {
    name: 'American Bank Center',
    label: 'American Bank Center',
    value: 'AMERICAN_BANK_CENTER'
  },
  {
    name: 'American National Bank',
    label: 'American National Bank',
    value: 'AMERICAN_NATIONAL_BANK'
  },
  {
    name: 'Aston Martin Financial Services',
    label: 'Aston Martin Financial Services',
    value: 'ASTON_MARTIN_FINANCIAL_SERVICES'
  },
  {
    name: 'Automotive Credit Corporation',
    label: 'Automotive Credit Corporation',
    value: 'AUTOMOTIVE_CREDIT_CORPORATION'
  },
  {
    name: 'Bank of America',
    label: 'Bank of America',
    value: 'BANK_OF_AMERICA'
  },
  {
    name: 'BB&T Dealer Finance',
    label: 'BB&T Dealer Finance',
    value: 'BB_T_DEALER_FINANCE'
  },
  {
    name: 'BBVA',
    label: 'BBVA',
    value: 'BBVA'
  },
  {
    name: 'BCI Financial Corporation',
    label: 'BCI Financial Corporation',
    value: 'BCI_FINANCIAL_CORPORATION'
  },
  {
    name: 'Bristol County Savings Bank',
    label: 'Bristol County Savings Bank',
    value: 'BRISTOL_COUNTY_SAVINGS_BANK'
  },
  {
    name: 'C&F Finance',
    label: 'C&F Finance',
    value: 'C_F_FINANCE'
  },
  {
    name: 'CapitalOne',
    label: 'CapitalOne',
    value: 'CAPITALONE'
  },
  {
    name: 'Cashmere Valley Bank',
    label: 'Cashmere Valley Bank',
    value: 'CASHMERE_VALLEY_BANK'
  },
  {
    name: 'Central Bank of Audrain County',
    label: 'Central Bank of Audrain County',
    value: 'CENTRAL_BANK_OF_AUDRAIN_COUNTY'
  },
  {
    name: 'Central Bank of Boone County',
    label: 'Central Bank of Boone County',
    value: 'CENTRAL_BANK_OF_BOONE_COUNTY'
  },
  {
    name: 'Central Bank of Moberly',
    label: 'Central Bank of Moberly',
    value: 'CENTRAL_BANK_OF_MOBERLY'
  },
  {
    name: 'Central Bank of Sedalia',
    label: 'Central Bank of Sedalia',
    value: 'CENTRAL_BANK_OF_SEDALIA'
  },
  {
    name: 'Central Bank of the Midwest',
    label: 'Central Bank of the Midwest',
    value: 'CENTRAL_BANK_OF_THE_MIDWEST'
  },
  {
    name: 'Central Bank of the Ozarks',
    label: 'Central Bank of the Ozarks',
    value: 'CENTRAL_BANK_OF_THE_OZARKS'
  },
  {
    name: 'Central Trust Bank',
    label: 'Central Trust Bank',
    value: 'CENTRAL_TRUST_BANK'
  },
  {
    name: 'Chase',
    label: 'Chase',
    value: 'CHASE'
  },
  {
    name: 'Chrysler Capital',
    label: 'Chrysler Capital',
    value: 'CHRYSLER_CAPITAL'
  },
  {
    name: 'CIG Financial',
    label: 'CIG Financial',
    value: 'CIG_FINANCIAL'
  },
  {
    name: 'Citizens One',
    label: 'Citizens One',
    value: 'CITIZENS_ONE'
  },
  {
    name: 'Coastal Outsourced Solutions',
    label: 'Coastal Outsourced Solutions',
    value: 'COASTAL_OUTSOURCED_SOLUTIONS'
  },
  {
    name: 'Cobalt Credit Union (Sac Federal Credit Union)',
    label: 'Cobalt Credit Union (Sac Federal Credit Union)',
    value: 'COBALT_CREDIT_UNION'
  },
  {
    name: 'Commerce Bank',
    label: 'Commerce Bank',
    value: 'COMMERCE_BANK'
  },
  {
    name: 'Connex Credit Union',
    label: 'Connex Credit Union',
    value: 'CONNEX_CREDIT_UNION'
  },
  {
    name: 'CRB Auto',
    label: 'CRB Auto',
    value: 'CRB_AUTO'
  },
  {
    name: 'Credit Union Loan Source',
    label: 'Credit Union Loan Source',
    value: 'CREDIT_UNION_LOAN_SOURCE'
  },
  {
    name: 'Crescent Bank and Trust',
    label: 'Crescent Bank and Trust',
    value: 'CRESCENT_BANK_AND_TRUST'
  },
  {
    name: 'Desert Financial Credit Union',
    label: 'Desert Financial Credit Union',
    value: 'DESERT_FINANCIAL_CREDIT_UNION'
  },
  {
    name: 'Enterprise Auto Finance',
    label: 'Enterprise Auto Finance',
    value: 'ENTERPRISE_AUTO_FINANCE'
  },
  {
    name: 'Evansville Teachers FCU',
    label: 'Evansville Teachers FCU',
    value: 'EVANSVILLE_TEACHERS_FCU'
  },
  {
    name: 'Evansville Teachers Federal Credit Union',
    label: 'Evansville Teachers Federal Credit Union',
    value: 'EVANSVILLE_TEACHERS_FEDERAL_CREDIT_UNION'
  },
  {
    name: 'Fidelity',
    label: 'Fidelity',
    value: 'FIDELITY'
  },
  {
    name: 'Fifth Third Bank N.A.',
    label: 'Fifth Third Bank N.A.',
    value: 'FIFTH_THIRD_BANK'
  },
  {
    name: 'First Citizens FCU',
    label: 'First Citizens FCU',
    value: 'FIRST_CITIZENS_FCU'
  },
  {
    name: 'First Commonwealth Bank',
    label: 'First Commonwealth Bank',
    value: 'FIRST_COMMONWEALTH_BANK'
  },
  {
    name: 'First National Bank of Pennsylvania',
    label: 'First National Bank of Pennsylvania',
    value: 'FIRST_NATIONAL_BANK_OF_PENNSYLVANIA'
  },
  {
    name: 'Flagship Credit Acceptance',
    label: 'Flagship Credit Acceptance',
    value: 'FLAGSHIP_CREDIT_ACCEPTANCE'
  },
  {
    name: 'FNCB Bank',
    label: 'FNCB Bank',
    value: 'FNCB_BANK'
  },
  {
    name: 'Fort Community Credit Union',
    label: 'Fort Community Credit Union',
    value: 'FORT_COMMUNITY_CREDIT_UNION'
  },
  {
    name: 'Forum Credit Union',
    label: 'Forum Credit Union',
    value: 'FORUM_CREDIT_UNION'
  },
  {
    name: 'Genesis Finance',
    label: 'Genesis Finance',
    value: 'GENESIS_FINANCE'
  },
  {
    name: 'Global Lending Services',
    label: 'Global Lending Services',
    value: 'GLOBAL_LENDING_SERVICES'
  },
  {
    name: 'HarborOne Bank',
    label: 'HarborOne Bank',
    value: 'HARBORONE_BANK'
  },
  {
    name: 'Huntington National Bank',
    label: 'Huntington National Bank',
    value: 'HUNTINGTON_NATIONAL_BANK'
  },
  {
    name: 'Hyundai Motor Finance',
    label: 'Hyundai Motor Finance',
    value: 'HYUNDAI_MOTOR_FINANCE'
  },
  {
    name: 'Idaho Central CU',
    label: 'Idaho Central CU',
    value: 'IDAHO_CENTRAL_CU'
  },
  {
    name: 'Independent Bank',
    label: 'Independent Bank',
    value: 'INDEPENDENT_BANK'
  },
  {
    name: 'INTRUST Bank N.A.',
    label: 'INTRUST Bank N.A.',
    value: 'INTRUST_BANK'
  },
  {
    name: 'Jaguar Financial Group',
    label: 'Jaguar Financial Group',
    value: 'JAGUAR_FINANCIAL_GROUP'
  },
  {
    name: 'Jefferson Bank of Missouri',
    label: 'Jefferson Bank of Missouri',
    value: 'JEFFERSON_BANK_OF_MISSOURI'
  },
  {
    name: 'Keesler Federal Credit Union',
    label: 'Keesler Federal Credit Union',
    value: 'KEESLER_FEDERAL_CREDIT_UNION'
  },
  {
    name: 'KeyBank, N.A.',
    label: 'KeyBank, N.A.',
    value: 'KEYBANK_N_A'
  },
  {
    name: 'Kia Motors Finance',
    label: 'Kia Motors Finance',
    value: 'KIA_MOTORS_FINANCE'
  },
  {
    name: 'Land Rover Financial Group',
    label: 'Land Rover Financial Group',
    value: 'LAND_ROVER_FINANCIAL_GROUP'
  },
  {
    name: 'Landmark',
    label: 'Landmark',
    value: 'LANDMARK'
  },
  {
    name: 'Langley Federal Credit Union',
    label: 'Langley Federal Credit Union',
    value: 'LANGLEY_FEDERAL_CREDIT_UNION'
  },
  {
    name: 'M&T Bank',
    label: 'M&T Bank',
    value: 'M_T_BANK'
  },
  {
    name: 'Maserati Capital USA',
    label: 'Maserati Capital USA',
    value: 'MASERATI_CAPITAL_USA'
  },
  {
    name: 'Members 1st FCU',
    label: 'Members 1st FCU',
    value: 'MEMBERS_1ST_FCU'
  },
  {
    name: 'Navigant Credit Union',
    label: 'Navigant Credit Union',
    value: 'NAVIGANT_CREDIT_UNION'
  },
  {
    name: 'NorthWest Bank',
    label: 'NorthWest Bank',
    value: 'NORTHWEST_BANK'
  },
  {
    name: 'Old National Bank',
    label: 'Old National Bank',
    value: 'OLD_NATIONAL_BANK'
  },
  {
    name: 'Park National Bank',
    label: 'Park National Bank',
    value: 'PARK_NATIONAL_BANK'
  },
  {
    name: 'PNC Bank',
    label: 'PNC Bank',
    value: 'PNC_BANK'
  },
  {
    name: 'Prestige Financial Services',
    label: 'Prestige Financial Services',
    value: 'PRESTIGE_FINANCIAL_SERVICES'
  },
  {
    name: 'Regional Acceptance',
    label: 'Regional Acceptance',
    value: 'REGIONAL_ACCEPTANCE'
  },
  {
    name: 'Regions Bank',
    label: 'Regions Bank',
    value: 'REGIONS_BANK'
  },
  {
    name: 'Rhinebeck Bank',
    label: 'Rhinebeck Bank',
    value: 'RHINEBECK_BANK'
  },
  {
    name: 'SAFCO',
    label: 'SAFCO',
    value: 'SAFCO'
  },
  {
    name: 'Santander Consumer USA',
    label: 'Santander Consumer USA',
    value: 'SANTANDER_CONSUMER_USA'
  },
  {
    name: 'Southeast Toyota Finance',
    label: 'Southeast Toyota Finance',
    value: 'SOUTHEAST_TOYOTA_FINANCE'
  },
  {
    name: 'Strike Acceptance',
    label: 'Strike Acceptance',
    value: 'STRIKE_ACCEPTANCE'
  },
  {
    name: 'Subaru Motor Finance',
    label: 'Subaru Motor Finance',
    value: 'SUBARU_MOTOR_FINANCE'
  },
  {
    name: 'SunTrust Bank Inc.',
    label: 'SunTrust Bank Inc.',
    value: 'SUNTRUST_BANK_INC'
  },
  {
    name: 'TD Auto Finance',
    label: 'TD Auto Finance',
    value: 'TD_AUTO_FINANCE'
  },
  {
    name: 'TDECU',
    label: 'TDECU',
    value: 'TDECU'
  },
  {
    name: "Teacher's Credit Union.",
    label: "Teacher's Credit Union.",
    value: 'TEACHERS_CREDIT_UNION'
  },
  {
    name: 'Union Bank and Trust',
    label: 'Union Bank and Trust',
    value: 'UNION_BANK_AND_TRUST'
  },
  {
    name: 'United Auto Credit',
    label: 'United Auto Credit',
    value: 'UNION_BANK_AND_TRUST'
  },
  {
    name: 'United Auto Credit',
    label: 'United Auto Credit',
    value: 'UNITED_AUTO_CREDIT'
  },
  {
    name: 'United Bank, Division of United Bankshares',
    label: 'United Bank, Division of United Bankshares',
    value: 'UNITED_BANK_DIVISION_OF_UNITED_BANKSHARES'
  },
  {
    name: 'US Bank',
    label: 'US Bank',
    value: 'US_BANK'
  },
  {
    name: 'Valley National Bank',
    label: 'Valley National Bank',
    value: 'VALLEY_NATIONAL_BANK'
  },
  {
    name: 'Veridian Credit Union',
    label: 'Veridian Credit Union',
    value: 'VERIDIAN_CREDIT_UNION'
  },
  {
    name: 'Wells Fargo Auto',
    label: 'Wells Fargo Auto',
    value: 'WELLS_FARGO_AUTO'
  }
]
export const leaseLenders = [
  {
    name: 'Ford Credit',
    label: 'Ford Credit',
    value: 'FORD_CREDIT'
  },
  {
    name: 'Lincoln AFS',
    label: 'Lincoln AFS',
    value: 'LINCOLN_AFS'
  },
  {
    name: 'Ally',
    label: 'Ally',
    value: 'ALLY'
  },
  {
    name: 'Aston Martin Financial Services',
    label: 'Aston Martin Financial Services',
    value: 'ASTON_MARTIN_FINANCIAL_SERVICES'
  },
  {
    name: 'Chase',
    label: 'Chase',
    value: 'CHASE'
  },
  {
    name: 'Desert Financial Credit Union',
    label: 'Desert Financial Credit Union',
    value: 'DESERT_FINANCIAL_CREDIT_UNION'
  },
  {
    name: 'Fifth Third Bank N.A.',
    label: 'Fifth Third Bank N.A.',
    value: 'FIFTH_THIRD_BANK'
  },
  { name: 'Ford Credit', label: 'Ford Credit', value: 'FORD_CREDIT' },
  {
    name: 'Genesis Finance',
    label: 'Genesis Finance',
    value: 'GENESIS_FINANCE'
  },
  {
    name: 'Huntington National Bank',
    label: 'Huntington National Bank',
    value: 'HUNTINGTON_NATIONAL_BANK'
  },
  {
    name: 'Hyundai Motor Finance',
    label: 'Hyundai Motor Finance',
    value: 'HYUNDAI_MOTOR_FINANCE'
  },
  {
    name: 'Jaguar Financial Group',
    label: 'Jaguar Financial Group',
    value: 'JAGUAR_FINANCIAL_GROUP'
  },
  {
    name: 'Kia Motors Finance',
    label: 'Kia Motors Finance',
    value: 'KIA_MOTORS_FINANCE'
  },
  {
    name: 'Land Rover Financial Group',
    label: 'Land Rover Financial Group',
    value: 'LAND_ROVER_FINANCIAL_GROUP'
  },
  {
    name: 'Maserati Capital USA',
    label: 'Maserati Capital USA',
    value: 'MASERATI_CAPITAL_USA'
  },
  {
    name: 'Members 1st FCU',
    label: 'Members 1st FCU',
    value: 'MEMBERS_1ST_FCU'
  },
  {
    name: 'Southeast Toyota Finance',
    label: 'Southeast Toyota Finance',
    value: 'SOUTHEAST_TOYOTA_FINANCE'
  },
  {
    name: 'Subaru Motor Finance',
    label: 'Subaru Motor Finance',
    value: 'SUBARU_MOTOR_FINANCE'
  },
  {
    name: 'US Bank',
    label: 'US Bank',
    value: 'US_BANK'
  }
]
