import React, { useState, useContext } from 'react'
import Icon from '../icons/Icon'
import { AppContext } from '../context/AppContext'

import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'

const StyledTooltip = styled.div`
  display: inline-block;
  position: ${(props) => (props.superscript ? 'unset' : 'relative')};
  margin-left: 10px;
  &.lincoln {
    .tradein-tooltip-popover-body {
      color: var(--lincolnBlue);
    }
  }
  .tradein-tooltip-button {
    position: absolute;
    left: -10px;
    top: -14px;
    vertical-align: middle;
    background: transparent;
    &:focus {
      border: 1px solid var(--lightBlue);
    }
  }

  .superscript-tooltip-body {
    background-color: white;
    font-size: var(--fz-xxxs);
    color: var(--linkBlue);
    vertical-align: super;
  }

  .tradein-tooltip-fade-show {
    transition: opacity 0.15s linear;
  }
  .tradein-tooltip-popover-show {
    padding-top: 2px;
    position: absolute;
    margin-left: 25px;
    margin-top: -48px;
    z-index: 1060;
    display: block;
    max-width: 90rem;
    background-color: white;
    background-clip: padding-box;
    border: solid 1px var(--fordOnLightGrey);
    border-radius: 0.3rem;
    /* box-shadow: 0 2rem 2rem 0 rgb(0 0 0 / 10%), 0 1rem 1rem 0 rgb(0 0 0 / 10%); */
    width: 320px;
  }

  // TOOLTIP BODY
  .tradein-tooltip-popover-body {
    font-size: var(--fz-md);
    line-height: 24px;
    font-family: FordMotion;
    font-weight: 400;
    max-height: 355px;
    overflow-y: scroll;
    padding: 10px;
    padding-right: 35px;
    color: var(--fordLightDefault);
    p {
      margin-bottom: 0;
    }
  }

  // TOOLTIP OVERLAY
  .tooltip-overlay-close {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
  }

  // TOOLTIP MOBILE
  .tradein-tooltip-mobile-popover {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 2;
    padding: 75px 20px;
    font-size: var(--fz-lg);
    line-height: 26px;
    font-family: var(--fontPrimary);
  }
  .tradein-tooltip-mobile-close-icon-wrapper {
    position: absolute;
    background: none;
    right: -2px;
    margin-bottom: 10px;
    top: 1px;
    padding: 10px;
    svg {
      width: 24px;
      height: 24px;
    }

    @media ${mediaQueries.underS} {
      position: fixed;
      top: 15px;
      right: 20px;
      background-color: white;
      padding: 10px;
      padding-top: 20px;
      border-radius: 0.3rem;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`

const Tooltip = ({
  id,
  tooltipBody,
  ariaLabel,
  superscript,
  analyticsEvent
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [widthSmall, setWdithSmall] = useState(false)

  const toggle = () => {
    const mq = window.matchMedia(mediaQueries.underS)
    if (mq.matches) {
      setWdithSmall(true)
    } else {
      setWdithSmall(false)
    }
    setTooltipOpen(!tooltipOpen)
  }

  const onClick = () => {
    const arr = id.split('-')
    const toolTipId = arr[0] + '-iball'
    analyticsEvent(toolTipId)
    toggle()
  }
  const { brand } = useContext(AppContext) || ''

  return (
    <StyledTooltip superscript={superscript} className={brand}>
      {!superscript && (
        <button
          id={id}
          className='tradein-tooltip-button'
          aria-label={ariaLabel}
          aria-labelledby={id + '-tooltip-body'}
          onClick={analyticsEvent ? onClick : toggle}
        >
          <Icon
            icon={brand === 'ford' ? 'blueiBall' : 'iBall'}
            title={ariaLabel}
          />
        </button>
      )}
      {superscript && (
        <button
          id={id}
          className={id + '-tooltip-body'}
          aria-label={ariaLabel}
          aria-labelledby={id + '-tooltip-body'}
          onClick={analyticsEvent ? onClick : toggle}
        >
          XX
        </button>
      )}
      {tooltipOpen && !widthSmall && (
        <div id='tradein-tooltip-container'>
          <div className='tradein-tooltip-fade-show'>
            <div className='tradein-tooltip-popover-show'>
              <div role='tooltip'>
                <div
                  id={id + '-tooltip-body'}
                  className='tradein-tooltip-popover-body'
                >
                  {tooltipBody}
                  <div className='tradein-tooltip-mobile-close-icon-wrapper'>
                    <button
                      className='tradein-tooltip-mobile-close-icon-wrapper'
                      onClick={toggle}
                      aria-label='Close Tooltip'
                    >
                      <Icon icon='close' title='Close Tooltip' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {tooltipOpen && !widthSmall && (
        <div className='tooltip-overlay-close' onClick={toggle} />
      )}
      {tooltipOpen && widthSmall && (
        <div className='tradein-tooltip-mobile-popover'>
          <button
            className='tradein-tooltip-mobile-close-icon-wrapper'
            onClick={toggle}
            aria-label='Close Tooltip'
          >
            <Icon icon='close' title='Close Tooltip' />
          </button>
          {tooltipBody}
        </div>
      )}
    </StyledTooltip>
  )
}

Tooltip.defaultProps = {
  ariaLabel: 'More Information'
}

export default Tooltip
