import styled from 'styled-components'
export const StyledVehicleBasics = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.ford {
    .tradein-vb-column {
      margin: auto;
      p.tradein-note {
        font-style: normal;
        color: var(--fordSlate);
        letter-spacing: 0;
        margin-top: -12px;
        font-size: var(--fz-xs);
      }
    }
  }

  .tradein-vb-column {
    margin: auto;
    width: 100%;
    flex-direction: column;
    max-width: 430px;
  }

  .tradein-divider {
    width: 90%;
    height: 1px;
    border-radius: 10px;
    margin: 10px 0px;
    box-shadow: 0 1px 0 0 #ffffff, inset 0 1px 2px 0 #bfbfbf;
    background-color: #eeeeee;
  }
`

export const StyledVehicleBasicsFR = styled(StyledVehicleBasics)`
  .tradein-selectInput-label {
    margin-bottom: 5px;
    &.reg {
      margin-top: 35px;
    }
  }
  .tradein-selectInput-wrap {
    margin-top: 30px;
  }

  .tradein-date-inputs-container {
    display: flex;
    .tradein-selectInput-wrap {
      flex: 2;
      margin-right: 10px;
      &:first-child {
        flex: 3;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
