import React from 'react'

export const STEP_HEADER = {
  fr_ch: {
    main: 'Valeur estimée de votre véhicule',
    sub: 'Veuillez saisir les informations suivantes concernant votre véhicule pour demander une estimation de reprise auprès du concessionnaire de votre choix.'
  },
  de_ch: {
    main: 'Schätzwert Ihres Fahrzeugs',
    sub: 'Geben Sie bitte die folgenden Informationen über Ihr Fahrzeug ein, um eine Bewertung von Ihrem ausgewählten Händler anzufordern.'
  },
  it_ch: {
    main: 'Stima del vostro veicolo',
    sub: 'Inserisca le seguenti informazioni sul suo veicolo per richiedere una valutazione al concessionario da lei scelto.'
  },
  nb_no: {
    main: 'VERDIVURDERING AV INNBYTTEBIL',
    sub: 'Vennligst skriv inn følgende informasjon om bilen din for å få vurdering fra forhandleren du har valgt.'
  },
  de_de: {
    main: 'Fahrzeugbewertung',
    sub: 'Wir benötigen nur ein paar Informationen zu Ihrem aktuellen Fahrzeug, um Ihnen einen Schätzwert für eine Inzahlungnahme übermitteln können.'
  },
  fr_be: {
    main: 'Estimation de la valeur du véhicule',
    sub: 'Veuillez saisir les informations suivantes au sujet de votre véhicule pour demander une évaluation auprès du distributeur de votre choix.'
  },
  nl_be: {
    main: 'Waardebepaling voertuig',
    sub: 'Voer de volgende informatie over uw voertuig in om een waardering aan te vragen bij de door u gekozen verdeler.'
  },
  fi_fi: {
    main: 'Ajoneuvon arviointi',
    sub: 'Syötä seuraavat ajoneuvoasi koskevat tiedot, niin voit pyytää arviota valitsemaltasi jälleenmyyjältä.'
  },
  de_at: {
    main: 'Fahrzeugbewertung',
    sub: 'Geben Sie bitte die folgenden Informationen über Ihr Fahrzeug ein, um eine Schätzung von Ihrem ausgewählten Händler anzufordern.'
  },
  da_dk: {
    main: 'VURDERING AF BYTTEBIL',
    sub: 'Indtast følgende oplysninger om din bil for at anmode om en værdiansættelse fra din valgte forhandler.'
  },
  pl_pl: {
    main: 'Wycena pojazdu',
    sub: 'Podaj informacje o swoim pojeździe, aby otrzymać od Dealera wycenę jego odkupu'
  },
  pt_pt: {
    main: 'Avaliação do veículo',
    sub: 'Introduza as seguintes informações sobre o seu veículo para solicitar uma avaliação ao concessionário selecionado.'
  },
  cs_cz: {
    main: 'Ocenění vozidla',
    sub: 'Abychom mohli u vybraného prodejce požádat o nacenění vašeho vozu, vyplňte prosím požadované informace.'
  },
  en_ie: {
    main: 'Vehicle Valuation',
    sub: 'Please input the followng information about your vehicle to request a valuation from your chosen dealer.'
  },
  en_gb: {
    main: 'Vehicle Valuation',
    sub: 'Please input the followng information about your vehicle to request a valuation from your chosen dealer.'
  },
  el_gr: {
    main: 'ΕΚΤΙΜΗΣΗ ΑΞΙΑΣ ΟΧΗΜΑΤΟΣ',
    sub: 'Εισαγάγετε τις ακόλουθες πληροφορίες σχετικά με το όχημά σας, για να ζητήσετε μια αποτίμηση από τον επιλεγμένο έμπορο.'
  },
  hu_hu: {
    main: 'JÁRMŰ ÉRTÉKBECSLÉS',
    sub: 'Kérjük, a kiválasztott márkakereskedőhöz benyújtandó, értékbecslésre vonatkozó kérelméhez adja meg a következő információkat a járművéről.'
  },
  ro_ro: {
    main: 'Evaluare vehicul',
    sub: 'Te rugăm să introduci următoarele informații despre vehiculul tău pentru a solicita o evaluare de la distribuitorul ales.'
  },
  es_es: {
    main: 'Valoración del vehículo',
    sub: 'Introduce la información que se solicita a continuación sobre tu vehículo para pedir una valoración al concesionario elegido'
  },
  it_it: {
    main: 'Valutazione del veicolo',
    sub: 'Inserisci le seguenti informazioni sul tuo veicolo per richiedere una valutazione al concessionario scelto'
  },
  fr_fr: {
    main: 'Évaluation du véhicule',
    sub: 'Veuillez saisir les informations relatives à votre véhicule suivantes pour demander une évaluation auprès du concessionnaire de votre choix'
  }
}

export const ERROR_MESSAGE = {
  fr_ch: {
    textInput: 'Veuillez entrer une valeur',
    selectInput: 'Veuillez choisir une option',
    radioButton: 'Cette réponse est obligatoire'
  },
  de_ch: {
    textInput: 'Bitte geben Sie einen Wert ein.',
    selectInput: 'Bitte wählen Sie eine Option.',
    radioButton: 'Diese Antwort ist erforderlich.'
  },

  it_ch: {
    textInput: 'Inserite un valore',
    selectInput: 'Scegliete un’opzione',
    radioButton: 'È necessario fornire una risposta'
  },
  nb_no: {
    textInput: 'Vennligst skriv inn',
    selectInput: 'Vennligst velg',
    radioButton: 'Svar er påkrevet'
  },
  de_de: {
    textInput: 'Bitte einen Wert eingeben',
    selectInput: 'Bitte wählen Sie eine Option',
    radioButton: 'Diese Antwort ist erforderlich, um fortzufahren'
  },
  fr_be: {
    textInput: 'Veuillez saisir une valeur',
    selectInput: 'Veuillez sélectionner une option',
    radioButton: 'Cette réponse est requise'
  },
  nl_be: {
    textInput: 'Voer een waarde in',
    selectInput: 'Kies een optie',
    radioButton: 'Dit antwoord is verplicht'
  },
  fi_fi: {
    textInput: 'Syötä arvo',
    selectInput: 'Valinta puuttuu',
    radioButton: 'Tämä vastaus on pakollinen'
  },
  de_at: {
    textInput: 'Bitte geben Sie einen Wert ein',
    selectInput: 'Bitte wählen Sie eine Option',
    radioButton: 'Diese Antwort ist erforderlich, um fortzufahren'
  },
  da_dk: {
    textInput: 'Indtast venligst',
    selectInput: 'Vælg venligst en mulighed',
    radioButton: 'Svaret er påkrævet'
  },
  en_ie: {
    textInput: 'Please enter a value',
    selectInput: 'Please choose an option',
    radioButton: 'This answer is required'
  },
  en_gb: {
    textInput: 'Please enter a value',
    selectInput: 'Please choose an option',
    radioButton: 'This answer is required'
  },
  cs_cz: {
    textInput: 'Zadejte hodnotu',
    selectInput: 'Vyberte možnost',
    radioButton: 'Tato odpověď je povinná.'
  },
  pl_pl: {
    textInput: 'Wprowadź wartość',
    selectInput: 'Wybierz opcję',
    radioButton: 'Ta odpowiedź jest obowiązkowa.'
  },
  pt_pt: {
    textInput: 'Introduzir um valor',
    selectInput: 'Escolher uma opção',
    radioButton: 'Esta resposta é obrigatória'
  },
  el_gr: {
    textInput: 'Συμπληρώστε το πεδίο',
    selectInput: 'Επιλέξτε από τη λίστα',
    radioButton: 'Αυτή η απάντηση είναι υποχρεωτική'
  },
  hu_hu: {
    textInput: 'Kérjük, adj meg egy értéket!',
    selectInput: 'Kérjük, válassz egy opciót!',
    radioButton: 'Kötelezően megadandó válasz'
  },
  ro_ro: {
    textInput: 'Introduceți o valoare',
    selectInput: 'Alegeți o opțiune',
    radioButton: 'Acest răspuns este necesar'
  },
  es_es: {
    textInput: 'Introduce un valor',
    selectInput: 'Elige una opción',
    radioButton: 'Es obligatorio responder a esta pregunta'
  },
  it_it: {
    textInput: 'Immetti un valore',
    selectInput: "Seleziona un'opzione",
    radioButton: 'Questa risposta è obbligatoria'
  },
  fr_fr: {
    textInput: 'Veuillez saisir une valeur',
    selectInput: 'Veuillez choisir une option',
    radioButton: 'Cette réponse est obligatoire.'
  }
}

export const COLORS = {
  fr_ch: [
    'NOIR',
    'BLEU',
    'MARRON',
    'CUIVRE',
    'OR',
    'GRIS',
    'VERT',
    'MARRON',
    'ORANGE',
    'VIOLET',
    'ROUGE',
    'ARGENT',
    'TAN',
    'BLANC',
    'JAUNE',
    'AUTRE'
  ],
  de_ch: [
    'SCHWARZ',
    'BLAU',
    'BRAUN',
    'KUPFER',
    'GOLD',
    'GRAU',
    'GRÜN',
    'KASTANIENBRAUN',
    'ORANGE',
    'LILA',
    'ROT',
    'SILBER',
    'BRAUN',
    'WEISS',
    'GELB',
    'ANDERE'
  ],
  it_ch: [
    'NERO',
    'BLU',
    'MARRONE',
    'RAME',
    'ORO',
    'GRIGIO',
    'VERDE',
    'MARRONE',
    'ARANCIONE',
    'VIOLA',
    'ROSSO',
    'ARGENTO',
    'TAN',
    'BIANCO',
    'GIALLO',
    'ALTRO'
  ],
  nb_no: [
    'SORT',
    'BLÅ',
    'BRUN',
    'KOBBER',
    'GULL',
    'GRÅ',
    'GRØNN',
    'MØRK RØD',
    'ORANGE',
    'LILLA',
    'RØD',
    'SØLV',
    'BEIGE',
    'HVIT',
    'GUL',
    'ANNEN'
  ],
  de_de: [
    'SCHWARZ',
    'BLAU',
    'BRAUN',
    'KUPFER',
    'GOLD',
    'GRAU',
    'GRÜN',
    'KASTANIENBRAUN',
    'ORANGE',
    'Violett',
    'ROT',
    'Silber',
    'Bräunlich',
    'Weiß',
    'Gelb',
    'Sonstiges'
  ],
  fr_be: [
    'NOIR',
    'BLEU',
    'BRUN',
    'CUIVRE',
    'OR',
    'GRIS',
    'VERT',
    'BORDEAUX',
    'ORANGE',
    'VIOLET',
    'ROUGE',
    'ARGENT',
    'BEIGE',
    'BLANC',
    'JAUNE',
    'AUTRE'
  ],
  nl_be: [
    'ZWART',
    'BLAUW',
    'BRUIN',
    'KOPER',
    'GOUD',
    'GRIJS',
    'GROEN',
    'BORDEAUX',
    'ORANJE',
    'PAARS',
    'ROOD',
    'ZILVER',
    'BEIGE',
    'WIT',
    'GEEL',
    'ANDERE'
  ],
  fi_fi: [
    'MUSTA',
    'SININEN',
    'RUSKEA',
    'KUPARI',
    'KULTA',
    'HARMAA',
    'VIHREÄ',
    'VIININPUNAINEN',
    'ORANSSI',
    'VIOLETTI',
    'PUNAINEN',
    'HOPEA',
    'BEIGE',
    'VALKOINEN',
    'KELTAINEN',
    'MUU'
  ],
  de_at: [
    'SCHWARZ',
    'BLAU',
    'BRAUN',
    'KUPFER',
    'GOLD',
    'GRAU',
    'GRÜN',
    'KASTANIENBRAUN',
    'ORANGE',
    'LILA',
    'ROT',
    'SILBER',
    'BRAUN',
    'WEISS',
    'GELB',
    'ANDERE'
  ],
  da_dk: [
    'SORT',
    'BLÅ',
    'BRUN',
    'KOBBER',
    'GULD',
    'GRÅ',
    'GRØN',
    'MØRKE RØD',
    'ORANGE',
    'LILLA',
    'RØD',
    'SØLV',
    'BEIGE',
    'HVID',
    'GULD',
    'ANDET'
  ],
  pl_pl: [
    'CZARNY',
    'NIEBIESKI',
    'BRĄZOWY',
    'MIEDZIANY',
    'ZŁOTY',
    'SZARY',
    'ZIELONY',
    'KASZTANOWY',
    'POMARAŃCZOWY',
    'FIOLETOWY',
    'CZERWONY',
    'SREBRNY',
    'JASNOBRĄZOWY',
    'BIAŁY',
    'ŻÓŁTY',
    'INNY'
  ],
  pt_pt: [
    'PRETO',
    'AZUL',
    'CASTANHO',
    'COBRE',
    'DOURADO',
    'CINZENTO',
    'VERDE',
    'CASTANHO AVERMELHADO',
    'LARANJA',
    'ROXO',
    'VERMELHO',
    'PRATEADO',
    'BRONZE',
    'BRANCO',
    'AMARELO',
    'OUTRO'
  ],
  cs_cz: [
    'ČERNÁ',
    'MODRÁ',
    'HNĚDÁ',
    'MĚDĚNÁ',
    'ZLATÁ',
    'ŠEDÁ',
    'ZELENÁ',
    'MAROON',
    'ORANŽOVÁ',
    'FIALOVÁ',
    'ČERVENÁ',
    'STŘÍBRNÁ',
    'BÉŽOVÁ',
    'BÍLÁ',
    'ŽLUTÁ',
    'OSTATNÍ'
  ],
  en_ie: [
    'BLACK',
    'BLUE',
    'BROWN',
    'COPPER',
    'GOLD',
    'GRAY',
    'GREEN',
    'MAROON',
    'ORANGE',
    'PURPLE',
    'RED',
    'SILVER',
    'TAN',
    'WHITE',
    'YELLOW',
    'OTHER'
  ],
  en_gb: [
    'BLACK',
    'BLUE',
    'BROWN',
    'COPPER',
    'GOLD',
    'GRAY',
    'GREEN',
    'MAROON',
    'ORANGE',
    'PURPLE',
    'RED',
    'SILVER',
    'TAN',
    'WHITE',
    'YELLOW',
    'OTHER'
  ],
  el_gr: [
    'ΜΑΥΡΟ',
    'ΜΠΛΕ',
    'ΚΑΦΕ',
    'ΧΑΛΚΙΝΟ',
    'ΧΡΥΣΟ',
    'ΓΚΡΙ',
    'ΠΡΑΣΙΝΟ',
    'ΚΑΣΤΑΝΕΡΥΘΡΟ',
    'ΠΟΡΤΟΚΑΛΙ',
    'ΜΩΒ',
    'ΚΟΚΚΙΝΟ',
    'ΑΣΗΜΙ',
    'ΑΝΟΙΧΤΟ ΚΑΦΕ',
    'ΛΕΥΚΟ',
    'ΚΙΤΡΙΝΟ',
    'ΑΛΛΟ'
  ],
  hu_hu: [
    'FEKETE',
    'KÉK',
    'BARNA',
    'RÉZ',
    'ARANY',
    'SZÜRKE',
    'ZÖLD',
    'GESZTENYEBARNA',
    'NARANCS',
    'LILA',
    'PIROS',
    'EZÜST',
    'CSERSZÍN',
    'FEHÉR',
    'SÁRGA',
    'EGYÉB'
  ],
  ro_ro: [
    'NEGRU',
    'ALBASTRU',
    'MARO',
    'BRONZ',
    'AURIU',
    'GRI',
    'VERDE',
    'MAROON',
    'ORANGE',
    'MOV',
    'ROSU',
    'ARGINTIU',
    'TAN',
    'ALB',
    'GALBEN',
    'ALTA'
  ]
}

export const ENGINE = {
  fr_ch: ['ESSENCE', 'DIESEL', 'ÉLECTRIQUE', 'HYBRIDE'],
  de_ch: ['BENZIN', 'DIESEL', 'ELEKTRISCH', 'HYBRID'],
  it_ch: ['BENZINA', 'DIESEL', 'ELETTRICO', 'HYBRID'],
  nb_no: ['BENSIN', 'DIESEL', 'ELEKTRISK', 'HYBRID'],
  de_de: ['BENZIN', 'DIESEL', 'ELEKTRISCH'],
  fr_be: ['ESSENCE', 'DIESEL', 'ÉLECTRIQUE', 'HYBRIDE'],
  nl_be: ['BENZINE', 'DIESEL', 'ELEKTRISCH', , 'HYBRIDE'],
  fi_fi: ['BENSIINI', 'DIESEL', 'SÄHKÖ', 'HYBRIDI'],
  de_at: ['BENZIN', 'DIESEL', 'ELEKTRISCH', 'HYBRIDFAHRZEUG'],
  da_dk: ['BENZIN', 'DIESEL', 'ELEKTRISk', 'HYBRID'],
  pl_pl: ['BENZYNOWY', 'DIESEL', 'ELEKTRYCZNY', 'HYBRYDA'],
  pt_pt: ['GASOLINA', 'DIESEL', 'ELÉTRICO', 'HÍBRIDO'],
  cs_cz: ['BENZÍN', 'NAFTA', 'ELEKTŘINA', 'HYBRID'],
  en_ie: ['GASOLINE', 'DIESEL', 'ELECTRIC', 'HYBRID'],
  en_gb: ['PETROL', 'DIESEL', 'ELECTRIC', 'HYBRID'],
  el_gr: ['ΒΕΝΖΙΝΟΚΙΝΗΤΗΡΑΣ', 'ΠΕΤΡΕΛΑΙΟΚΙΝΗΤΗΡΑΣ', 'ΗΛΕΚΤΡΙΚΟΣ', 'ΥΒΡΙΔΙΚΟΣ'],
  hu_hu: ['Benzin', 'Dízel', 'Elektromos', 'HIBRID'],
  ro_ro: ['BENZINĂ', 'DIESEL', 'ELECTRIC', 'HIBRID'],
  es_es: ['Gasolina', 'Diésel', 'Eléctrico', 'Híbrido'],
  it_it: ['Benzina', 'Diesel', 'Elettrico', 'Ibrido'],
  fr_fr: ['Essence', 'Diesel', 'Électrique', 'Hybride']
}

export const TRANSMISSION = {
  fr_ch: ['MANUELLE', 'AUTOMATIQUE'],
  de_ch: ['MANUELL', 'AUTOMATISCH'],
  it_ch: ['MANUALE', 'AUTOMATICO'],
  nb_no: ['MANUELL', 'AUTOMATISK'],
  de_de: ['MANUELL', 'AUTOMATISCH'],
  fr_be: ['MANUELLE', 'AUTOMATIQUE'],
  nl_be: ['Manueel', 'Automatisch'],
  fi_fi: ['MANUAALI', 'AUTOMAATTI'],
  de_at: ['MANUELL', 'AUTOMATISCH'],
  da_dk: ['MANUEL', 'AUTOMATISK'],
  pl_pl: ['RĘCZNA', 'AUTOMATYCZNA'],
  pt_pt: ['MNAUAL', 'AUTOMÁTICA'],
  cs_cz: ['MANUÁLNÍ', 'AUTOMATICKÁ'],
  en_ie: ['MANUAL', 'AUTOMATIC'],
  en_gb: ['MANUAL', 'AUTOMATIC'],
  el_gr: ['ΧΕΙΡΟΚΙΝΗΤΟ', 'ΑΥΤΟΜΑΤΟ'],
  hu_hu: ['MANUÁLIS', 'AUTOMATA'],
  ro_ro: ['MANUALA', 'AUTOMATA'],
  es_es: ['MANUAL', 'AUTOMATIC'],
  it_it: ['MANUAL', 'AUTOMATIC'],
  fr_fr: ['MANUAL', 'AUTOMATIC']
}

export const conditionsCopy = {
  fr_ch: {
    excellent: {
      title: 'QU’EST-CE QU’UN ÉTAT «EXCELLENT»?',
      text: 'Le véhicule peut présenter de très petits défauts internes et externes ne nécessitant que des réparations mineures, comme la retouche de petiteséraflures ou bosses.'
    },
    good: {
      title: 'QU’EST-CE QU’UN ÉTAT «BON»?',
      text: `Le véhicule peut présenter de petits défauts internes et externes ne
      nécessitant que des réparations mineures, comme la retouche de petites
      éraflures ou bosses, et des réparations majeures ou mineures d’une seule
      pièce en tôle ou de pare-chocs. Des pièces de rechange plus petites
      peuvent être nécessaires. Il peut également être nécessaire de remplacer
      jusqu’à trois panneaux intérieurs ou extérieurs plus petits (sans pièces
      en tôle).`
    },
    average: {
      title: 'QU’EST-CE QU’UN ÉTAT «MOYEN»?',
      text: `Le véhicule peut présenter de petits défauts internes et externes
      nécessitant des réparations, comme la retouche de petites éraflures ou
      bosses, et jusqu’à trois réparations majeures, cinq réparations mineures
      ou une combinaison des deux. Il peut également être nécessaire de
      remplacer des pièces de garniture internes ou externes essentielles (à
      l’exception des pièces en tôle, mais jusqu’à un pare-chocs). Une
      combinaison de réparations, y compris le remplacement d’une pièce, peut
      être nécessaire, mais cela exclut les pièces structurelles telles que les
      toits et les panneaux latéraux.`
    },
    fair: {
      title: 'QU’EST-CE QU’UN ÉTAT «ACCEPTABLE» ?',
      text: ` Le véhicule peut présenter des défauts internes et externes mineurs qui
      nécessitent des réparations mineures, c’est-à-dire la retouche de petites
      éraflures ou bosses, et jusqu’à trois réparations majeures, cinq
      réparations mineures, ou une combinaison des deux.`
    },
    poor: {
      title: 'QU’EST-CE QU’UN ÉTAT «MAUVAIS»?',
      text: `Le véhicule présente d’importants défauts internes et externes nécessitant
      des travaux de réparation importants, comme la réparation de grosses
      éraflures ou de bosses (par exemple après un accident), et diverses
      réparations majeures ou mineures. Il peut également être nécessaire de
      remplacer des pièces structurelles telles que les toits et les panneaux
      latéraux.`
    }
  },
  de_ch: {
    excellent: {
      title: 'WAS IST EIN «AUSGEZEICHNETER» ZUSTAND?',
      text: `Das Fahrzeug kann innen und aussen sehr kleine Schäden haben, die nur
      kleinere Reparaturen erfordern, z. B. die Ausbesserung kleiner Kratzer
      oder Beulen.`
    },
    good: {
      title: 'WAS IST EIN «GUTER» ZUSTAND?',
      text: ` Das Fahrzeug kann innen und aussen kleine Schäden haben, die nur kleinere
      Reparaturen, z.B. die Ausbesserung kleiner Kratzer oder Beulen, sowie
      grössere oder kleinere Reparaturen an einem einzigen Blechteil oder
      Stossfänger erfordern. Kleinere Ersatzteile können benötigt werden. Es
      kann auch notwendig sein, bis zu drei kleinere Innen- oder Aussenpanels
      (ohne Blechteile) zu ersetzen.`
    },
    average: {
      title: 'WAS IST EIN «DURCHSCHNITTLICHER» ZUSTAND?',
      text: `Das Fahrzeug kann innen und aussen kleinere Schäden haben, die kleinere
      Reparaturen, z. B. die Ausbesserung kleiner Kratzer oder Beulen, sowie bis
      zu drei grössere Reparaturen, fünf kleinere Reparaturen oder eine
      Kombination davon erfordern`
    },
    fair: {
      title: 'WAS IST EIN «MÄSSIGER» ZUSTAND?',
      text: `Das Fahrzeug kann innen und aussen kleine Schäden haben, die Reparaturen,
      z. B. die Ausbesserung kleiner Kratzer oder Beulen, sowie bis zu drei
      grössere Reparaturen, fünf kleinere Reparaturen oder eine Kombination
      davon erfordern. Es kann auch notwendig sein, wichtige Innen- oder
      Aussenverkleidungen zu ersetzen (mit Ausnahme von Blechteilen, aber
      einschliesslich Stossfänger). Eine Kombination von Reparaturen mit einem
      Ersatzteil kann erforderlich sein, aber unter Ausschluss von
      Strukturbauteilen wie dem Dach oder Seitenwänden.`
    },
    poor: {
      title: 'WAS IST EIN «SCHLECHTER» ZUSTAND?',
      text: `Das Fahrzeug hat innen und aussen grosse Schäden, die umfangreiche
      Reparaturen, z.B. die Behebung grosser Kratzer oder Beulen (z.B. nach
      einem Unfall), sowie verschiedene grössere oder kleinere Reparaturen
      erfordern. Es kann auch notwendig sein, Strukturbauteile wie das Dach oder
      Seitenwände zu ersetzen.`
    }
  },
  it_ch: {
    excellent: {
      title: 'COSA SI INTENDE PER «CONDIZIONI ECCELLENTI»?',
      text: `Il veicolo può presentare difetti interni ed esterni minimi che richiedono
      soltanto riparazioni di modesta entità, ad es. ritocchi per piccoli graffi
      o ammaccature.`
    },
    good: {
      title: 'COSA SI INTENDE PER «CONDIZIONI BUONE»?',
      text: ` Il veicolo può presentare lievi difetti interni ed esterni che richiedono
      soltanto riparazioni di modesta entità, ad es. ritocchi per piccoli graffi
      o ammaccature, nonché riparazioni di notevole o modesta entità di una
      singola parte di lamiera o di un paraurti. Possono essere indispensabili
      piccoli ricambi. Può anche essere necessario sostituire fino a tre
      pannelli interni o esterni di piccole dimensioni (lamiere escluse)`
    },
    average: {
      title: 'COSA SI INTENDE PER «CONDIZIONI DISCRETE»?',
      text: `Il veicolo può presentare difetti interni ed esterni di modesta entità che
      richiedono riparazioni di modesta entità, ad es. ritocchi per piccoli
      graffi o ammaccature, nonché fino a tre riparazioni di notevole entità,
      fino a cinque riparazioni di piccola entità o una combinazione di
      entrambi.`
    },
    fair: {
      title: 'COSA SI INTENDE PER «CONDIZIONI MEDIOCRI»?',
      text: `Il veicolo può presentare lievi difetti interni ed esterni che richiedono
      riparazioni, ad es. ritocchi per piccoli graffi o ammaccature, nonché fino
      a tre riparazioni di notevole entità, fino a cinque riparazioni di modesta
      entità o una combinazione di entrambi. Può anche essere necessario
      sostituire parti di carrozzeria interne o esterne essenziali (escluse le
      lamiere, incluso un paraurti). Può essere indispensabile una combinazione
      di riparazioni comprendente un ricambio, ma sono escluse componenti
      strutturali come tetti e fiancate.`
    },
    poor: {
      title: 'COSA SI INTENDE PER «CONDIZIONI PESSIME»?',
      text: ` Il veicolo presenta difetti interni ed esterni estesi che richiedono
      riparazioni importanti, vale a dire rimozione di ammaccature o graffi
      estesi (ad es. in seguito a un incidente), nonché svariate riparazioni di
      notevole o modesta entità. Può anche essere necessario sostituire
      componenti strutturali come tetti e fiancate.
      Difetti meccanici noti che rendono il veicolo non idoneo alla guida
      Considerevoli danni esterni
      Mancanza di parti importanti
      Gravi difetti meccanici noti
      Veicolo non idoneo alla circolazione stradale`
    }
  },
  de_de: {
    excellent: {
      title: 'WAS IST EIN „AUSGEZEICHNETER“ ZUSTAND?',
      text: `Das Fahrzeug kann sehr kleine innere und äußere Mängel aufweisen, die nur
      kleinere Reparaturen erfordern, d.h. Ausbessern von kleinen Kratzern oder
      Dellen.`
    },
    good: {
      title: 'WAS IST EIN „GUTER“ ZUSTAND?',
      text: `Das Fahrzeug kann kleine innere und äußere Mängel aufweisen, die nur
      kleinere Reparaturen erfordern, d.h. Ausbessern von kleinen Kratzern oder
      Dellen, sowie bis zu eine größere oder kleinere Reparaturarbeit an einer
      einzelnen Blechteil oder Stoßstange. Möglicherweise sind kleinere
      Ersatzteile erforderlich. Der Austausch von bis zu drei kleineren inneren
      oder äußeren Verkleidungsteilen (ohne Blechteile) kann ebenfalls
      erforderlich sein.`
    },
    average: {
      title: 'WAS IST EIN „DURCHSCHNITTLICHER“ ZUSTAND?',
      text: `Das Fahrzeug kann kleine innere und äußere Mängel aufweisen, die kleine
      Reparaturen erfordern, d.h. Ausbessern von kleinen Kratzern oder Dellen,
      sowie bis zu drei größere oder fünf kleinere Reparaturarbeiten bzw. eine
      Kombination aus beidem.`
    },
    fair: {
      title: 'WAS IST EIN „MÄSSIGER“ ZUSTAND?',
      text: `Das Fahrzeug kann kleine innere und äußere Mängel aufweisen, die
      Reparaturarbeit erfordert, d.h. Ausbessern von kleinen Kratzern oder
      Dellen, sowie bis zu drei größere oder fünf kleinere Reparaturarbeiten
      bzw. eine Kombination aus beidem. Auch der Austausch wesentlicher interner
      oder externer Verkleidungsteile (ausgenommen Blechteile, jedoch mit bis zu
      einem Stoßfänger) kann ebenfalls erforderlich sein. Es kann eine
      Kombination von Reparaturen einschließlich eines Ersatzteils erforderlich
      sein, aber dies schließt strukturelle Teile wie Dächer und Seitenteile
      aus.`
    },
    poor: {
      title: 'WAS IST EIN „SCHLECHTER“ ZUSTAND?',
      text: `Das Fahrzeug weist große innere und äußere Mängel auf, die umfassende
      Reparaturarbeit erfordert, d.h. Ausbessern von großen Kratzern oder Dellen
      (z. B. nach einem Unfall), sowie verschiedene größere oder kleinere
      Reparaturarbeiten. Auch der Austausch von strukturellen Teilen wie Dächer
      und Seitenteile kann erforderlich sein.`
    }
  },
  de_at: {
    excellent: {
      title: 'WAS IST EIN „AUSGEZEICHNETER“ ZUSTAND?',
      text: `Das Fahrzeug kann sehr kleine innere und äußere Mängel aufweisen, die nur
      kleinere Reparaturen erfordern, d.h. Ausbessern von kleinen Kratzern oder
      Dellen.`
    },
    good: {
      title: 'WAS IST EIN „GUTER“ ZUSTAND?',
      text: `Das Fahrzeug kann kleine innere und äußere Mängel aufweisen, die nur
      kleinere Reparaturen erfordern, d.h. Ausbessern von kleinen Kratzern oder
      Dellen, sowie bis zu eine größere oder kleinere Reparaturarbeit an einer
      einzelnen Blechteil oder Stoßstange. Möglicherweise sind kleinere
      Ersatzteile erforderlich. Der Austausch von bis zu drei kleineren inneren
      oder äußeren Verkleidungsteilen (ohne Blechteile) kann ebenfalls
      erforderlich sein.`
    },
    average: {
      title: 'WAS IST EIN „DURCHSCHNITTLICHER“ ZUSTAND?',
      text: `Das Fahrzeug kann kleine innere und äußere Mängel aufweisen, die kleine
      Reparaturen erfordern, d.h. Ausbessern von kleinen Kratzern oder Dellen,
      sowie bis zu drei größere oder fünf kleinere Reparaturarbeiten bzw. eine
      Kombination aus beidem.`
    },
    fair: {
      title: 'WAS IST EIN „MÄSSIGER“ ZUSTAND?',
      text: ` Das Fahrzeug kann kleine innere und äußere Mängel aufweisen, die
      Reparaturarbeit erfordert, d.h. Ausbessern von kleinen Kratzern oder
      Dellen, sowie bis zu drei größere oder fünf kleinere Reparaturarbeiten
      bzw. eine Kombination aus beidem. Auch der Austausch wesentlicher interner
      oder externer Verkleidungsteile (ausgenommen Blechteile, jedoch mit bis zu
      einem Stoßfänger) kann ebenfalls erforderlich sein. Es kann eine
      Kombination von Reparaturen einschließlich eines Ersatzteils erforderlich
      sein, aber dies schließt strukturelle Teile wie Dächer und Seitenteile
      aus.`
    },
    poor: {
      title: 'WAS IST EIN „SCHLECHTER“ ZUSTAND?',
      text: `Das Fahrzeug weist große innere und äußere Mängel auf, die umfassende
      Reparaturarbeit erfordert, d.h. Ausbessern von großen Kratzern oder Dellen
      (z. B. nach einem Unfall), sowie verschiedene größere oder kleinere
      Reparaturarbeiten. Auch der Austausch von strukturellen Teilen wie Dächer
      und Seitenteile kann erforderlich sein.`
    }
  },
  nb_no: {
    excellent: {
      title: 'HVA MENER VI MED UTMERKET TILSTAND?',
      text: `Innbyttebilen har kun mindre slitasjeskader som kan repareres med SMART
      repair, slik som mindre riper eller bulker.`
    },
    good: {
      title: 'HVA MENER VI MED GOD TILSTAND?',
      text: `Innbyttebilen har enkelte skader og slitasje som krever SMART repair, slik
      som mindre skader og bulker. I tillegg kan bilen ha 1 større eller mindre
      skade som krever verkstedutbedring på en del eller ett område. Bytte av
      mindre deler kan forekommer, inntil tre interiør- eller ekteriørdeler
      (eksludert hele paneler).`
    },
    average: {
      title: 'HVA MENER VI MED MIDDELS TILSTAND?',
      text: `Innbyttebilen har enkelte skader og slitasje som krever SMART repair, slik
      som mindre skader og bulker. I tillegg kan bilen ha inntil 3 større eller
      5 mindre skade som krever verkstedutbedring på en del eller ett område.`
    },
    fair: {
      title: 'HVA MENER VI MED OK TILSTAND?',
      text: `Innbyttebilen har skader og slitasje som krever SMART repair, slik som
      mindre skader og bulker. I tillegg kan bilen ha inntil 3 større eller 5
      mindre skade som krever verkstedutbedring på en del eller ett område.
      Bytte av mindre deler på interiør eller eksteriør (ekskluderer hele
      paneler men kan inkludere inntil en fanger foran eller bak). Reparasjonene
      kan inkludere bytte av inntil ett panel, dette inkluderer ikke
      strukturelle paneler som tak.`
    },
    poor: {
      title: 'HVA MENER VI MED DÅRLIG TILSTAND?',
      text: `Omfattende interiør og eksteriør skader og defekter, herunder større riper
      og/eller bulker som krever karroseri- eller verkstedutbedringer. Bilen kan
      ha vært i en ulykke som krevde at bytte av mer enn 1 strukturell del var
      påkrevet. Biler i denne tilstanden kan vise seg å være uønsket som
      innbyttebil på grunn av kostnadene for nødvendige reparasjoner.`
    }
  },
  fi_fi: {
    excellent: {
      title: 'MITÄ TARKOITTAA "ERINOMAISESSA KUNNOSSA"?',
      text: `Ajoneuvossa voi olla hyvin pieniä sisäisiä ja ulkoisia vikoja, jotka vaativat vain pieniä korjauksia, eli pienten naarmujen tai lommojen korjaamista.`
    },
    good: {
      title: 'MITÄ TARKOITTAA "HYVÄSSÄ KUNNOSSA"?',
      text: `Ajoneuvossa saattaa olla pieniä sisäisiä ja ulkoisia vikoja, jotka
      vaativat vain pieniä korjauksia, eli pienten naarmujen tai lommojen
      korjaamista sekä suuria tai pieniä korjauksia yhdessä peltiosassa tai
      puskurissa. Korjauksiin saatetaan tarvita pieniä varaosia. Voi myös olla
      tarpeen vaihtaa enintään kolme pienempää sisä- tai ulkopaneelia
      (lukuunottamatta peltiosia).`
    },
    average: {
      title: 'MITÄ TARKOITTAA "TYYDYTTÄVÄSSÄ KUNNOSSA"?',
      text: `Ajoneuvossa voi olla pieniä sisäisiä ja ulkoisia vikoja, jotka vaativat
      pieniä korjauksia, kuten pienten naarmujen tai lommojen korjaamista, sekä
      jopa kolme suurta korjausta vaativaa kohtaa, viisi pientä korjausta
      vaativaa kohtaa tai näiden yhdistelmä.`
    },
    fair: {
      title: 'MITÄ TARKOITTAA "VÄLTTÄVÄSSÄ KUNNOSSA"?',
      text: `Ajoneuvossa voi olla pieniä sisäisiä ja ulkoisia vikoja, jotka vaativat
      pieniä korjauksia, kuten pienten naarmujen tai lommojen korjaamista, sekä
      jopa kolme suurta korjausta vaativaa kohtaa, viisi pientä korjausta
      vaativaa kohtaa tai näiden yhdistelmä. Voi olla myös tarpeen vaihtaa
      välttämättömiä sisä- tai ulkoverhoilun osia (lukuunottamatta peltiosia,
      sisältäen puskurin). Korjausten tekeminen ja varaosien hankinta voi olla
      tarpeen, lukuunottamatta rakenteellisia osia, kuten kattoa tai
      sivupaneeleja.`
    },
    poor: {
      title: 'MITÄ TARKOITTAA "HUONOSSA KUNNOSSA"?',
      text: `Ajoneuvossa on suuria sisäisiä ja ulkoisia vikoja, jotka vaativat laajoja
      korjaustöitä eli suurten naarmujen tai lommojen korjaamista (esim.
      onnettomuuden jälkeen) sekä useita suuria tai pieniä korjauksia.
      Rakenteellisten osien, kuten katon tai sivupaneelien, vaihtaminen voi myös
      olla tarpeen.`
    }
  },
  da_dk: {
    excellent: {
      title: 'HVAD ER "FREMRAGENDE" STAND?',
      text: `Bilen kan have meget små indvendige og udvendige defekter, der kun kræver
      mindre reparationer, dvs. korrigering af små ridser eller buler.`
    },
    good: {
      title: 'HVAD ER "GOD" STAND?',
      text: `Bilen kan have små indvendige og udvendige defekter, der kun kræver mindre
      reparationer, dvs. korrigering af små ridser eller buler, samt større
      eller mindre reparationer af en enkelt metalplade eller kofanger. Mindre
      udskiftningsdele kan være påkrævet. Det kan også være nødvendigt at
      udskifte op til tre mindre indvendige eller udvendige paneler (uden
      metalpladedele).`
    },
    average: {
      title: 'HVAD ER "MIDDEL" STAND?',
      text: `Bilen kan have mindre indvendige og udvendige defekter, der kræver mindre
      reparationer, dvs. korrigering af små ridser eller buler, samt op til tre
      større reparationer, fem mindre reparationer eller en kombination af begge
      dele.`
    },
    fair: {
      title: 'HVAD ER "MINDRE GOD" STAND?',
      text: `Bilen kan have små indvendige og udvendige defekter, der kræver
      reparationsarbejde, dvs. korrigering af små ridser eller buler, samt op
      til tre større reparationer, fem mindre reparationer eller en kombination
      af begge dele. Det kan også være nødvendigt at udskifte væsentlige
      indvendige eller udvendige udstyrsdele (med undtagelse af metalpladedele,
      men op til en kofanger). En kombination af reparationer, herunder en
      udskiftningsdel, kan være påkrævet, men dette udelukker konstruktionsdele
      såsom tag og sidepaneler.`
    },
    poor: {
      title: 'HVAD ER "DÅRLIG" STAND?',
      text: `Bilen har store indvendige og udvendige defekter, der kræver omfattende
      reparationsarbejde, dvs. udbedring af store ridser eller buler (f.eks.
      efter en ulykke), samt forskellige større eller mindre reparationer. Det
      kan også være nødvendigt at udskifte konstruktionsdele såsom tag og
      sidepaneler.`
    }
  },
  fr_be: {
    excellent: {
      title: 'QUE CONSIDÈRE-T-ON COMME ÉTANT UN « EXCELLENT » ÉTAT ?',
      text: `Le véhicule peut présenter de très petits défauts internes et externes qui
      ne nécessitent que des réparations mineures, comme la retouche de petites
      rayures ou de bosses.`
    },
    good: {
      title: 'QUE CONSIDÈRE-T-ON COMME ÉTANT UN « BON » ÉTAT ?',
      text: `Le véhicule peut présenter de petits défauts internes et externes qui ne
      nécessitent que des réparations mineures, c'est-à-dire la retouche de
      petites rayures ou de bosses, ainsi que des réparations majeures ou
      mineures sur une seule pièce de tôle ou un seul pare-chocs. De plus
      petites pièces de rechange peuvent être nécessaires. Il se peut que vous
      devriez également remplacer jusqu'à trois panneaux intérieurs ou
      extérieurs plus petits (sans pièces de tôle).`
    },
    average: {
      title: 'QUE CONSIDÈRE-T-ON COMME ÉTANT UN ÉTAT « MOYEN » ?',
      text: `Le véhicule peut présenter de petits défauts internes et externes qui
      nécessitent des réparations mineures, c'est-à-dire la retouche de petites
      rayures ou de bosses, ainsi que jusqu'à trois réparations majeures, cinq
      réparations mineures ou une combinaison des deux.`
    },
    fair: {
      title: 'QUE CONSIDÈRE-T-ON COMME ÉTANT UN ÉTAT « MODÉRÉ » ?',
      text: `Le véhicule peut présenter de petits défauts internes et externes qui
      nécessitent des travaux de réparation, c'est-à-dire la retouche de petites
      rayures ou de bosses, ainsi que jusqu'à trois réparations majeures, cinq
      réparations mineures ou une combinaison des deux. Il se pourrait que
      d'importantes pièces de garniture intérieure ou extérieure (à l'exception
      des pièces en tôle, mais jusqu'au pare-chocs) doivent être remplacées. Une
      combinaison de réparations incluant une pièce de rechange peut être
      nécessaire, mais cela exclut les pièces structurelles telles que les toits
      et les panneaux latéraux.`
    },
    poor: {
      title: 'QUE CONSIDÈRE-T-ON COMME UN « MAUVAIS » ÉTAT ?',
      text: `Le véhicule présente des défauts internes et externes majeurs qui
      nécessitent des travaux de réparation importants, c'est-à-dire la
      réparation de grandes rayures ou de bosses (par exemple après un
      accident), ainsi que diverses réparations majeures ou mineures. Il se
      pourrait que des pièces de structure, telles que les toits et les panneaux
      latéraux doivent également être remplacées.`
    }
  },
  nl_be: {
    excellent: {
      title: 'WAT IS EEN "UITSTEKENDE" STAAT?',
      text: `Het voertuig kan zeer kleine defecten vertonen aan de binnen- en
      buitenkant die slechts kleine reparaties vereisen, dat wil zeggen het
      bijwerken van kleine krassen of deuken.`
    },
    good: {
      title: 'WAT IS EEN "GOEDE" STAAT?',
      text: `Het voertuig kan kleine defecten vertonen aan de binnen- en buitenkant die
      slechts kleine reparaties vereisen, dat wil zeggen het bijwerken van
      kleine krassen of deuken, evenals grote of kleine reparaties aan één
      plaatstalen onderdeel of bumper. Kleinere onderdelen moeten mogelijk
      worden vervangen. Het kan ook nodig zijn om maximaal drie kleinere binnen-
      of buitenpanelen te vervangen (geen plaatwerkdelen).`
    },
    average: {
      title: 'WAT IS EEN "GEMIDDELDE" STAAT?',
      text: `Het voertuig kan kleine defecten vertonen aan de binnen- en buitenkant die
      kleine reparaties vereisen, dat wil zeggen het bijwerken van kleine
      krassen of deuken, evenals maximaal drie grote reparaties, vijf kleine
      reparaties of een combinatie van beide.`
    },
    fair: {
      title: 'WAT IS EEN "MATIGE" STAAT?',
      text: `Het voertuig kan kleine defecten vertonen aan de binnen- en buitenkant die
      reparatiewerkzaamheden vereisen, dat wil zeggen het bijwerken van kleine
      krassen of deuken, evenals maximaal drie grote reparaties, vijf kleine
      reparaties of een combinatie van beide. Het kan ook nodig zijn om
      essentiële onderdelen van de binnen- of buitenbekleding te vervangen (met
      uitzondering van plaatstalen onderdelen, maar met maximaal een bumper).
      Een combinatie van reparaties inclusief een reserveonderdeel kan nodig
      zijn, maar dit sluit structurele onderdelen zoals daken en zijpanelen uit.`
    },
    poor: {
      title: 'WAT IS EEN "SLECHTE" STAAT?',
      text: `Het voertuig heeft grote defecten aan de binnen- en buitenkant die
      uitgebreide reparatiewerkzaamheden vereisen, dat wil zeggen het herstellen
      van grote krassen of deuken (bijv. na een ongeval), evenals verschillende
      grote of kleine reparaties. Het kan ook nodig zijn om structurele
      onderdelen zoals daken en zijpanelen te vervangen.`
    }
  },
  en_ie: {
    excellent: {
      title: 'WHAT IS "EXCELLENT" CONDITION?',
      text: `The vehicle can have very small internal and external defects that only
      require minor repairs, ie touching up small scratches or dents.`
    },
    good: {
      title: 'WHAT IS A "GOOD" CONDITION?',
      text: `The vehicle may have small internal and external defects that only require
      minor repairs, ie touching up small scratches or dents, as well as major
      or minor repairs to a single sheet metal part or bumper. Smaller
      replacement parts may be required. It may also be necessary to replace up
      to three smaller inner or outer panels (without sheet metal parts).`
    },
    average: {
      title: 'WHAT IS AN "AVERAGE" CONDITION?',
      text: `The vehicle may have minor internal and external defects that require
      minor repairs, ie touching up small scratches or dents, as well as up to
      three major repairs, five minor repairs, or a combination of both.`
    },
    fair: {
      title: 'WHAT IS A "MODERATE" CONDITION?',
      text: `The vehicle may have small internal and external defects that require
      repair work, ie touching up small scratches or dents, as well as up to
      three major repairs, five minor repairs, or a combination of both. It may
      also be necessary to replace essential internal or external trim parts
      (with the exception of sheet metal parts, but with up to a bumper). A
      combination of repairs including a replacement part may be required, but
      this excludes structural parts such as roofs and side panels.`
    },
    poor: {
      title: 'WHAT IS A "BAD" CONDITION?',
      text: `The vehicle has large internal and external defects that require extensive
      repair work, that is, mending large scratches or dents (e.g. after an
      accident), as well as various major or minor repairs. It may also be
      necessary to replace structural parts such as roofs and side panels.`
    }
  },
  en_gb: {
    excellent: {
      title: 'WHAT IS "EXCELLENT" CONDITION?',
      text: `The vehicle can have very small internal and external defects that only
      require minor repairs, ie touching up small scratches or dents.`
    },
    good: {
      title: 'WHAT IS A "GOOD" CONDITION?',
      text: `The vehicle may have small internal and external defects that only require
      minor repairs, ie touching up small scratches or dents, as well as major
      or minor repairs to a single sheet metal part or bumper. Smaller
      replacement parts may be required. It may also be necessary to replace up
      to three smaller inner or outer panels (without sheet metal parts).`
    },
    average: {
      title: 'WHAT IS AN "AVERAGE" CONDITION?',
      text: `The vehicle may have minor internal and external defects that require
      minor repairs, ie touching up small scratches or dents, as well as up to
      three major repairs, five minor repairs, or a combination of both.`
    },
    fair: {
      title: 'WHAT IS A "MODERATE" CONDITION?',
      text: `The vehicle may have small internal and external defects that require
      repair work, ie touching up small scratches or dents, as well as up to
      three major repairs, five minor repairs, or a combination of both. It may
      also be necessary to replace essential internal or external trim parts
      (with the exception of sheet metal parts, but with up to a bumper). A
      combination of repairs including a replacement part may be required, but
      this excludes structural parts such as roofs and side panels.`
    },
    poor: {
      title: 'WHAT IS A "BAD" CONDITION?',
      text: `The vehicle has large internal and external defects that require extensive
      repair work, that is, mending large scratches or dents (e.g. after an
      accident), as well as various major or minor repairs. It may also be
      necessary to replace structural parts such as roofs and side panels.`
    }
  },
  cs_cz: {
    excellent: {
      title: 'CO JE „VÝBORNÝ" STAV?',
      text: `Vozidlo může mít jen velmi malé vnitřní a vnější
      vady, které vyžadují pouze drobné opravy, např. opravu malých škrábanců
      nebo promáčknutí.`
    },
    good: {
      title: 'CO JE „DOBRÝ" STAV?',
      text: `Vozidlo může mít malé vnitřní a vnější vady, které vyžadují pouze drobné
      opravy, např. opravu malých škrábanců nebo promáčknutí, i větší nebo menší
      opravy jednoho plechového dílu nebo nárazníku. Může být nutné vyměnit
      některé méně nákladné díly Může být také nezbytné nahradit až tři menší
      vnitřní nebo vnější panely (bez plechových dílů).`
    },
    average: {
      title: 'CO JE „PRŮMĚRNÝ" STAV?',
      text: `Vozidlo může mít drobné vnitřní a vnější vady, které vyžadují drobné
      opravy, např. opravu malých škrábanců nebo promáčknutí, i maximálně tři
      velké opravy, pět drobných oprav, nebo jejich kombinaci.`
    },
    fair: {
      title: 'CO JE „PŘIMĚŘENÝ" STAV?',
      text: `Vozidlo může mít malé vnitřní a vnější vady, které vyžadují opravu, např.
      opravu malých škrábanců nebo promáčknutí, stejně jako až tři velké opravy,
      pět drobných oprav, nebo kombinaci obou. Může být rovněž nezbytné nahradit
      základní díly vnitřní nebo vnější výbavy(s výjimkou plechových dílů, ale
      včetně nárazníku). Může být nutná kombinace oprav včetně výměny dílů, ale
      bez základních konstrukčních dílů, jako je střecha a boční panely.`
    },
    poor: {
      title: 'CO JE „ŠPATNÝ" STAV?',
      text: `Vozidlo má zásadní vnitřní a vnější vady vyžadující rozsáhlé opravy, např.
      opravu velkých rýh nebo promáčknutí (např. po nehodě), stejně jako různé
      větší nebo menší opravy. Možná bude také nutné vyměnit základní
      konstrukční díly, jako je střecha a boční panely.`
    }
  },
  pl_pl: {
    excellent: {
      title: '',
      text: `Pojazd może posiadać bardzo niewielkie wady wewnętrzne i zewnętrzne, które
      wymagają jedynie drobnych napraw, tj. zaprawek niewielkich zadrapań lub
      usunięcia nieznacznych wgnieceń.`
    },
    good: {
      title: '',
      text: `Pojazd może posiadać bardzo niewielkie wady wewnętrzne i zewnętrzne, które
      wymagają jedynie drobnych napraw, tj. zaprawek niewielkich zadrapań lub
      usunięcia nieznacznych wgnieceń, jak również poważnych lub drobnych napraw
      pojedynczego arkusza blachy lub zderzaka. Niezbędne może być użycie
      niewielkich części zamiennych. Konieczna może okazać się również wymiana
      maksymalnie trzech mniejszych paneli wewnętrznych lub zewnętrznych (bez
      elementów z blachy).`
    },
    average: {
      title: '',
      text: `Pojazd może posiadać drobne wady wewnętrzne i zewnętrzne, które wymagają
      drobnych napraw, tj. zaprawek drobnych rys lub usunięcia drobnych
      wgnieceń, a także do trzech większych napraw i pięciu drobnych napraw.`
    },
    fair: {
      title: '',
      text: `Pojazd może mieć małe wady wewnętrzne i zewnętrzne, które wymagają
      naprawy, tj. zaprawek drobnych rys lub usunięcia małych wgnieceń, a także
      do trzech większych napraw i pięciu drobnych napraw. Niezbędna może okazać
      się również wymiana istotnych zewnętrznych i wewnętrznych elementów
      wykończeniowych (za wyjątkiem elementów z blachy do poziomu zderzaka).
      Może być wymagane połączenie napraw obejmujące zastosowanie części
      zamiennej; nie obejmuje to jednak elementów konstrukcyjnych, takich jak
      dachy i panele boczne.`
    },
    poor: {
      title: '',
      text: `Pojazd posiada duże wady wewnętrzne i zewnętrzne, które wymagają
      obszernych prac naprawczych, tj. naprawienia dużych zadrapań lub wgnieceń
      (np. powypadkowych), oraz różnych poważnych lub drobnych napraw. Konieczna
      może być również wymiana elementów konstrukcyjnych, takich jak dachy i
      panele boczne.`
    }
  },
  pt_pt: {
    excellent: {
      title: 'O QUE É O ESTADO "EXCELENTE"?',
      text: `O veículo pode ter alguns riscos ou mossas no interior e exterior que
      impliquem apenas pequenos retoques.`
    },
    good: {
      title: 'O QUE É O ESTADO "BOM"?',
      text: `O veículo pode ter alguns riscos ou mossas no interior e exterior que
      impliquem apenas pequenos retoques, danos ou mossas maiores ou menores num
      único painel da carroçaria ou para-choques. Poderão ser necessárias
      pequenas peças de substituição. Poderá também ser necessário substituir
      até três pequenos painéis interiores ou exteriores (sem peças de metal).`
    },
    average: {
      title: 'O QUE É O ESTADO "MÉDIO"?',
      text: `O veículo pode ter alguns riscos ou mossas no interior e exterior que
      impliquem apenas pequenos retoques, bem como até três grandes reparações,
      cinco pequenas reparações ou uma combinação de ambas.`
    },
    fair: {
      title: 'O QUE É O ESTADO "MODERADO"?',
      text: `O veículo pode ter alguns riscos ou mossas no interior e exterior que
      impliquem apenas pequenos retoques, bem como até três grandes reparações,
      cinco pequenas reparações ou uma combinação de ambas. Poderá também ser
      necessário substituir peças essenciais de acabamento no interior ou
      exterior (até ao pára-choques, com exceção de peças de metal). Poderá ser
      necessário uma combinação de reparações, incluindo uma peça de
      substituição, mas tal exclui peças estruturais, tais como tejadilhos e
      painéis laterais.`
    },
    poor: {
      title: 'O QUE É O ESTADO "MAU"?',
      text: `O veículo apresenta um número de riscos e mossas no interior e exterior
      sigmnificativos que implicam um trabalho de reparação extenso (por
      exemplo, após um acidente), bem como várias reparações grandes e pequenas.
      Poderá também ser necessário substituir peças estruturais, como tejadilhos
      e painéis laterais.`
    }
  },
  el_gr: {
    excellent: {
      title: 'ΠΟΙΑ ΚΑΤΑΣΤΑΣΗ ΟΡΙΖΕΤΑΙ ΩΣ «ΕΞΑΙΡΕΤΙΚΗ»;',
      text: 'Το όχημα μπορεί να έχει πολύ μικρά εσωτερικά και εξωτερικά ελαττώματα που απαιτούν μόνο μικρές επισκευές, όπως η επισκευή μικρών γρατζουνιών ή βαθουλωμάτων.'
    },
    good: {
      title: 'ΠΟΙΑ ΚΑΤΑΣΤΑΣΗ ΟΡΙΖΕΤΑΙ ΩΣ «ΠΟΛΥ ΚΑΛΗ»;',
      text: 'Το όχημα μπορεί να έχει μικρά εσωτερικά και εξωτερικά ελαττώματα που απαιτούν μόνο μικρές επισκευές, όπως η επισκευή μικρών γρατζουνιών ή βαθουλωμάτων, καθώς και μεγάλες ή μικρές επισκευές σε ένα μόνο εξάρτημα λαμαρίνας ή προφυλακτήρα. Ενδέχεται να απαιτούνται μικρότερα ανταλλακτικά. Ενδέχεται επίσης να χρειάζεται να αντικατασταθούν έως τρία μικρότερα εσωτερικά ή εξωτερικά πάνελ (χωρίς εξαρτήματα λαμαρίνας).'
    },
    average: {
      title: 'ΠΟΙΑ ΚΑΤΑΣΤΑΣΗ ΟΡΙΖΕΤΑΙ ΩΣ «ΚΑΛΗ»;',
      text: 'Το όχημα μπορεί να έχει μικρά εσωτερικά και εξωτερικά ελαττώματα που απαιτούν μόνο μικρές επισκευές, όπως η επισκευή μικρών γρατζουνιών ή βαθουλωμάτων, καθώς και έως τρεις μεγάλες επισκευές, πέντε μικρές επισκευές ή συνδυασμό των δύο.'
    },
    fair: {
      title: 'ΠΟΙΑ ΚΑΤΑΣΤΑΣΗ ΟΡΙΖΕΤΑΙ ΩΣ «ΜΕΤΡΙΑ»;',
      text: 'Το όχημα μπορεί να έχει μικρά εσωτερικά και εξωτερικά ελαττώματα που απαιτούν επισκευές, όπως η επισκευή μικρών γρατζουνιών ή βαθουλωμάτων, καθώς και έως τρεις μεγάλες επισκευές, πέντε μικρές επισκευές ή συνδυασμό των δύο. Ενδέχεται επίσης να χρειάζεται να αντικατασταθούν βασικά εσωτερικά ή εξωτερικά εξαρτήματα επένδυσης (εξαιρουμένων των εξαρτημάτων λαμαρίνας, αλλά έως και ένας προφυλακτήρας). Μπορεί να απαιτείται συνδυασμός επισκευών, συμπεριλαμβανομένου ενός ανταλλακτικού, αλλά αυτό αποκλείει δομικά μέρη, όπως οροφές και πλαϊνά πάνελ.'
    },
    poor: {
      title: 'ΠΟΙΑ ΚΑΤΑΣΤΑΣΗ ΟΡΙΖΕΤΑΙ ΩΣ «ΚΑΚΗ»;',
      text: 'Το όχημα μπορεί να έχει μεγάλα εσωτερικά και εξωτερικά ελαττώματα που απαιτούν εκτεταμένες επισκευές, όπως η επισκευή μεγάλων γρατζουνιών ή βαθουλωμάτων, (π.χ. μετά από ατύχημα), καθώς και διάφορες μεγάλες ή μικρές επισκευές. Ενδέχεται επίσης να χρειάζεται να αντικατασταθούν δομικά μέρη, όπως οροφές και πλαϊνά πάνελ.'
    }
  },
  hu_hu: {
    excellent: {
      title: 'MI A „KITŰNŐ” ÁLLAPOT?',
      text: 'A járművön kívül és belül csak minimális, kisebb javításokat igénylő hibák lehetnek, azaz kisebb karcolások vagy horpadások.'
    },
    good: {
      title: 'MI A „JÓ” ÁLLAPOT?',
      text: 'A járművön lehetnek kívül és belül minimális, kisebb javításokat igénylő hibák, azaz kisebb karcolások vagy horpadások; valamint kisebb-nagyobb javításokat igénylő hibák egyetlen fémlemez-alkatrészen vagy a lökhárítón. Kisebb alkatrészcserékre is szükség lehet. Szükség lehet maximum három kisebb belső vagy külső panel cseréjére is (a fémlemezrészeket leszámítva).'
    },
    average: {
      title: 'MI AZ „ÁTLAGOS” ÁLLAPOT?',
      text: 'A járművön lehetnek kívül és belül minimális, kisebb javításokat igénylő hibák, azaz kisebb karcolások vagy horpadások; valamint legfeljebb három nagyobb vagy öt kisebb javítást, esetleg a kettő kombinációját igénylő hibák.'
    },
    fair: {
      title: 'MI AZ „ÁTLAGON ALULI” ÁLLAPOT?',
      text: 'A járművön lehetnek kívül és belül minimális, kisebb javításokat igénylő hibák, azaz kisebb karcolások vagy horpadások; valamint legfeljebb három nagyobb vagy öt kisebb javítást, esetleg a kettő kombinációját igénylő hibák. Ezenkívül szükség lehet az alapvető belső vagy külső szegélyrészek cseréjére is (a fémlemezrészek kivételével, de legfeljebb a lökhárító erejéig). Szükség lehet a fent részletezett javításokból akár többre is, beleértve alkatrészek cseréjét is, de a javításokba nem tartozhatnak bele olyan szerkezeti részek, mint a tetők és az oldalsó panelek.'
    },
    poor: {
      title: 'MI A „ROSSZ” ÁLLAPOT?',
      text: 'A járművön komoly hibák vannak belül és kívül is, amelyek esetében nagy mértékű javításra van szükség, azaz nagyobb karcolások vagy horpadások (pl. baleset után), valamint a jármű különböző egyéb kisebb-nagyobb javításokra is szorulhat. Szükség lehet szerkezeti alkatrészek, például tetők és oldalfalak cseréjére is.'
    }
  },
  ro_ro: {
    excellent: {
      title: 'CE ÎNSEAMNĂ O STARE „EXCELENTĂ”?',
      text: 'Vehiculul poate avea defecte interne și externe foarte mici, care necesită doar reparații minore, adică retușarea zgârieturilor sau loviturilor mici.'
    },
    good: {
      title: 'CE ÎNSEAMNĂ O STARE „BUNĂ”?',
      text: 'Vehiculul poate avea defecte interne și externe mici care necesită doar reparații minore, adică retușarea zgârieturilor sau loviturilor mici, precum și reparații majore sau minore la o singură piesă metalică sau bară de protecție. Ar putea fi necesare piese de schimb mici. De asemenea, poate fi necesară înlocuirea a până la trei panouri interioare sau exterioare mai mici (fără piese din tablă).'
    },
    average: {
      title: 'CE ÎNSEAMNĂ O STARE „MEDIE”?',
      text: 'Vehiculul poate avea defecte interne și externe minore care necesită reparații minore, de exemplu, zgârieturi sau lovituri mici, precum și până la trei reparații majore, cinci reparații minore sau o combinație a acestora.'
    },
    fair: {
      title: 'CE ÎNSEAMNĂ O STARE „MODERATĂ”?',
      text: 'Vehiculul poate avea mici defecte interne și externe care necesită lucrări de reparații, adică retușarea zgârieturilor sau loviturilor mici, precum și până la trei reparații majore, cinci reparații minore sau o combinație a acestora. De asemenea, poate fi necesară înlocuirea pieselor esențiale interioare sau exterioare (cu excepția pieselor din tablă, dar până la o bară de protecție). Poate fi necesară o combinație de reparații, inclusiv o piesă de schimb, dar aceasta exclude părțile structurale, cum ar fi acoperișurile și panourile laterale.'
    },
    poor: {
      title: 'CE ÎNSEAMNĂ O STARE „REA”?',
      text: 'Vehiculul are defecte interne și externe mari care necesită reparații extinse, adică repararea zgârieturilor sau loviturilor mari (de exemplu, după un accident), precum și diverse reparații majore sau minore. De asemenea, poate fi necesară înlocuirea părților structurale, cum ar fi acoperișurile și panourile laterale.'
    }
  },
  es_es: {
    excellent: {
      title: '¿QUÉ SE ENTIENDE POR ESTADO EXCELENTE DEL VEHÍCULO?',
      text: 'El vehículo puede tener defectos interiores y exteriores muy pequeños, que solo requieren reparaciones menores, es decir, retocar pequeños arañazos o abolladuras.'
    },
    good: {
      title: '¿QUÉ SE ENTIENDE POR ESTADO BUENO DEL VEHÍCULO?',
      text: 'El vehículo puede tener pequeños defectos interiores y exteriores que solo requieren reparaciones menores, es decir, retoques de pequeños arañazos o abolladuras, así como reparaciones mayores o menores en una sola pieza de chapa o en el parachoques. Puede que necesite piezas de repuesto pequeñas. También puede ser necesario reemplazar hasta tres paneles interiores o exteriores pequeños (sin piezas de chapa).'
    },
    average: {
      title: '¿QUÉ SE ENTIENDE POR ESTADO MEDIO DEL VEHÍCULO?',
      text: 'El vehículo puede tener defectos menores interiores y exteriores que requieren reparaciones menores, es decir, retocar pequeños arañazos o abolladuras, así como hasta tres reparaciones mayores, cinco menores o una combinación de ambas opciones.'
    },
    fair: {
      title: '¿QUÉ SE ENTIENDE POR ESTADO ACEPTABLE DEL VEHÍCULO?',
      text: 'El vehículo puede tener pequeños defectos interiores y exteriores que requieren reparaciones, es decir, retoques de pequeños arañazos o abolladuras, así como hasta tres reparaciones importantes, cinco menores o una combinación de ambas opciones. También puede ser necesario reemplazar las piezas básicas de acabado interiores o exteriores (a excepción de las piezas de chapa, pero incluido un parachoques). Es posible que el vehículo requiera una combinación de reparaciones que incluya una pieza de repuesto, pero excluyendo las piezas estructurales, como los techos y los paneles laterales.'
    },
    poor: {
      title: '¿QUÉ SE ENTIENDE POR MAL ESTADO DEL VEHÍCULO?',
      text: 'El vehículo tiene grandes defectos interiores y exteriores que requieren grandes reparaciones, es decir, el arreglo de grandes arañazos o abolladuras (por ejemplo, después de un accidente), así como varias reparaciones mayores o menores. También puede ser necesario reemplazar piezas estructurales como techos y paneles laterales.'
    }
  },
  it_it: {
    excellent: {
      title: 'COSA SI INTENDE PER CONDIZIONI "OTTIME"?',
      text: 'Il veicolo può presentare lievi difetti interni ed esterni che richiedono solo piccole riparazioni, ovvero ritocchi a piccoli graffi o ammaccature.'
    },
    good: {
      title: 'COSA SI INTENDE PER CONDIZIONI "BUONE"?',
      text: 'Il veicolo può presentare lievi difetti interni ed esterni che richiedono solo piccole riparazioni, ovvero il ritocco di piccoli graffi o ammaccature, oltre a riparazioni importanti o minori a una singola parte o paraurti in lamiera. Potrebbe essere necessario utilizzare piccole parti di ricambio per la riparazione. Potrebbe inoltre essere necessario sostituire fino a tre piccoli pannelli interni o esterni (non parti in lamiera).'
    },
    average: {
      title: 'COSA SI INTENDE PER CONDIZIONI “NELLA MEDIA”?',
      text: 'Il veicolo può presentare lievi difetti interni ed esterni che richiedono piccole riparazioni, ovvero il ritocco di piccoli graffi o ammaccature, oltre a fino a tre riparazioni importanti, cinque riparazioni minori o una combinazione di entrambe.'
    },
    fair: {
      title: 'COSA SI INTENDE PER CONDIZIONI "MODESTE"?',
      text: "Il veicolo può avere lievi difetti interni ed esterni che richiedono lavori di riparazione, ovvero ritoccare piccoli graffi o ammaccature, oltre fino a tre riparazioni importanti, cinque riparazioni minori o una combinazione di entrambe. Potrebbe inoltre essere necessario sostituire parti essenziali di rivestimento interne o esterne (ad eccezione delle parti in lamiera, ma con l'inclusione del paraurti). Potrebbe essere necessaria una combinazione di riparazioni che includa una parte di ricambio, ma ciò esclude parti strutturali come tetti e pannelli laterali."
    },
    poor: {
      title: 'COSA SI INTENDE PER CONDIZIONI "PESSIME"?',
      text: 'Il veicolo può presentare piccoli difetti interni ed esterni che richiedono solo piccole riparazioni, ovvero il ritocco di piccoli graffi o ammaccature, oltre a riparazioni importanti o minori a una singola parte o paraurti in lamiera. Potrebbe inoltre essere necessario sostituire parti strutturali come tetti e pannelli laterali.'
    }
  },
  fr_fr: {
    excellent: {
      title: '',
      text: 'Le véhicule ne présente aucun défaut (mécanique, carrosserie  ou intérieur) et est très bien entretenu.'
    },
    good: {
      title: '',
      text: 'Le véhicule est bien entretenu et peut présenter de petits défauts qui ne nécessitent que des réparations mineures, comme la retouche de rayures.'
    },
    average: {
      title: '',
      text: 'Le véhicule peut présenter de petits défauts de carrosserie, de mécanique ou d’intérieur nécessitant des réparations.'
    },
    fair: {
      title: '',
      text: 'Le véhicule peut faire l’objet de dysfonctionnements mécaniques, de défauts de carrosserie ou d’usure de l’habitacle (liée à l’âge du véhicule) nécessitant des réparations majeures.'
    },
    poor: {
      title: '',
      text: 'Le véhicule peut faire l’objet de problèmes mécaniques majeurs, plusieurs éléments de carrosserie sont grandement endommagés ou à remplacer et l’intérieur est très usé.'
    }
  }
}

// labels & answers
export const STEP_BODY = {
  fr_ch: {
    vehRegLabel: 'Date de première mise en circulation',
    dateLabel: 'JJ',
    dateLabelReader:
      'Veuillez sélectionner le jour d’immatriculation de votre véhicule',
    datePlaceholder: 'JJ',
    monthLabel: 'MM',
    monthLabelReader:
      'Veuillez sélectionner le mois d’immatriculation de votre véhicule',
    monthPlaceholder: 'p. ex. MM',
    yearLabel: 'AAAA',
    yearLabelReader:
      'Veuillez sélectionner l’année d’immatriculation de votre véhicule`',
    yearPlaceholder: 'p. ex. AAAA',
    makePlaceholder: 'Marque',
    makeTooltip:
      'Veuillez indiquer la marque de votre véhicule (par exemple, Ford)',
    makeLabel: 'Marque',
    makeTooltipHover: "Plus d'information",
    modelPlaceholder: 'Modèle',
    modelTooltip:
      'Veuillez indiquer le modèle de votre véhicule (par exemple, Focus)',
    modelLabel: 'Modèle',
    modelTooltipHover: "Plus d'information",
    seriesPlaceholder: 'Série',
    seriesTooltip:
      'Veuillez saisir la série de votre véhicule (par exemple, Zetec, ST-Line)',
    seriesLabel: 'p. ex. Titanium',
    seriesAriaLabel: 'Veuillez entrer la série de votre véhicule',
    seriesTooltipHover: "Plus d'information",
    enginePlaceholder: 'Moteur',
    engineTooltip:
      'Veuillez indiquer la motorisation de votre véhicule (par exemple, essence)',
    engineAriaLabel: 'Veuillez entrer la motorisation de votre véhicule',
    engineLabel: 'p. ex. essence',
    engineTooltipHover: "Plus d'information",
    transmissionPlaceholder: 'Boîte',
    transmissionTooltip:
      'Veuillez indiquer la transmission de votre véhicule (par exemple, automatique)',
    transmissionLabel: 'p. ex. automatique',
    transmissionAriaLabel: 'Veuillez entrer la transmission de votre véhicule',
    transmissionTooltipHover: "Plus d'information",
    colorPlaceholder: 'Couleurs',
    colorTooltip:
      'Veuillez indiquer la couleur de votre véhicule (par exemple, noir)',
    colorLabel: 'par exemple, noir',
    colorAriaLabel: 'Veuillez indiquer la couleur de votre véhicule',
    colorTooltipHover: "Plus d'information",
    mileagePlaceholder: 'Kilométrage actuel',
    mileageLabel: 'Kilométrage actuel',
    mileageTooltip: 'Veuillez indiquer le kilométrage actuel de votre véhicule',
    mileageAriaLabel:
      'Veuillez indiquer le kilométrage actuel de votre véhicule',
    remainingBalanceLabel: 'par exemple, CHF 3000',
    remainingBalancePlaceholder: 'Solde Restant',
    remainingBalanceAriaLabel:
      'Veuillez indiquer le montant restant à payer pour votre véhicule',
    outstandingFinanceLabel:
      'Vous reste-t-il un paiement à effectuer pour votre véhicule?',
    outstandingFinanceTooltip:
      'Veuillez indiquer si vous devez encore effectuer des paiements finaux pour votre véhicule',
    outstandingFinanceTooltipHover: "Plus d'information",
    outstandingFinanceOptions: ['Oui', 'Non'],
    outstandingFinanceAriaLabels: [
      'Sélectionnez cette option si vous devez payer un solde sur votre véhicule',
      'Sélectionnez cette option si vous n’avez plus de solde à payer sur votre véhicule'
    ],
    additionalInfoLabel:
      'Y a-t-il des informations supplémentaires que nous devrions connaître sur votre véhicule afin de pouvoir en faire une évaluation plus précise?',
    additionalInfoTooltip:
      'Veuillez nous indiquer s’il y a autre chose à savoir sur votre véhicule',
    additionalInfoTooltipHover: "Plus d'information",
    additionalInfoPlaceholder: 'Veuillez saisir les informations ici',
    additionalAriaLabel:
      'Veuillez saisir toute information supplémentaire que nous devrions connaître sur votre véhicule',
    acceptLabel: 'Accepter',
    acceptArialLabel:
      "Cliquez ici pour envoyer une demande d'estimation de reprise",
    selectText: 'Sélectionnez'
  },
  de_ch: {
    vehRegLabel: 'Datum der ersten Inverkehrsetzung',
    dateLabel: 'TT',
    dateLabelReader:
      'Bitte wählen Sie den Tag, an dem Ihr Fahrzeug zugelassen wurde.',
    datePlaceholder: 'TT',
    monthLabel: 'MM',
    monthLabelReader:
      'Bitte wählen Sie den Monat, in dem Ihr Fahrzeug zugelassen wurde.',
    monthPlaceholder: 'z.B. MM',
    yearLabel: 'JJJJ',
    yearLabelReader:
      'Bitte wählen Sie das Jahr, in dem Ihr Fahrzeug zugelassen wurde.',
    yearPlaceholder: 'e.g. JJJJ',
    makePlaceholder: 'Marke',
    makeTooltip: 'Bitte geben Sie die Marke Ihres Fahrzeugs an (z. B. Ford).',
    makeLabel: 'Marke',
    makeAriaLabel: 'Marke Mehr Informationen',
    makeTooltipHover: 'Mehr Informationen',
    modelPlaceholder: 'Modell',
    modelTooltip:
      'Bitte geben Sie das Modell Ihres Fahrzeugs an (z. B. Focus).',
    modelLabel: 'Modell',
    modelTooltipHover: 'Modell Mehr Informationen',
    seriesPlaceholder: 'Ausstattungslinie',
    seriesTooltip:
      'Bitte geben Sie die Serie Ihres Fahrzeugs an (z. B. Titanium, ST-Line).',
    seriesLabel: 'z.B. Titanium',
    seriesAriaLabel: 'Bitte geben Sie die Serie Ihres Fahrzeugs an.',
    seriesTooltipHover: 'Serie Mehr Informationen',
    enginePlaceholder: 'Motor',
    engineTooltip:
      'Bitte geben Sie den Motor Ihres Fahrzeugs an (z. B. Benzin).',
    engineLabel: 'z.B. Benzin',
    engineAriaLabel: 'Bitte geben Sie den Motor Ihres Fahrzeugs an.',
    engineTooltipHover: 'Motor Mehr Informationen',
    transmissionPlaceholder: 'Getriebe',
    transmissionTooltip:
      'Bitte geben Sie das Getriebe Ihres Fahrzeugs an (z. B. Automatik).',
    transmissionLabel: 'z.B. Automatik',
    transmissionAriaLabel: 'Bitte geben Sie das Getriebe Ihres Fahrzeugs an.',
    transmissionTooltipHover: 'Mehr Informationen',
    colorPlaceholder: 'Farbe',
    colorTooltip:
      'Bitte geben Sie die Farbe Ihres Fahrzeugs an (z.B. Schwarz).',
    colorLabel: 'z.B. Schwarz',
    colorAriaLabel: 'Bitte geben Sie die Farbe Ihres Fahrzeugs an.',
    colorTooltipHover: 'Farbe Ihres Fahrzeugs',
    mileagePlaceholder: 'Aktueller Kilometerstand',
    mileageLabel: 'Aktueller Kilometerstand',
    mileageTooltip:
      'Bitte geben Sie den aktuellen Kilometerstand Ihres Fahrzeugs an.',
    mileageAriaLabel:
      'Bitte geben Sie den aktuellen Kilometerstand Ihres Fahrzeugs an.',
    remainingBalanceLabel: 'z. B. CHF 3000',
    remainingBalancePlaceholder: 'Ausstehender Restbetrag',
    remainingBalanceAriaLabel:
      'Bitte geben Sie den Restbetrag an, den Sie für Ihr Fahrzeug noch schulden.',
    outstandingFinanceLabel:
      'Haben Sie noch eine Restzahlung für Ihr Fahrzeug zu leisten?',
    outstandingFinanceTooltip:
      'Bitte geben Sie an, ob Sie noch Abschlusszahlungen für Ihr Fahrzeug zu leisten haben.',
    outstandingFinanceOptions: ['Ja', 'Nein'],
    outstandingFinanceAriaLabels: [
      'Wählen Sie dieses Feld aus, wenn Sie noch einen Restbetrag für Ihr Fahrzeug schulden.',
      'Wählen Sie dieses Feld aus, wenn Sie keinen Restbetrag für Ihr Fahrzeug mehr schulden.'
    ],
    outstandingFinanceTooltipHover: 'Mehr Informationen',
    additionalInfoLabel:
      'Gibt es zusätzliche Informationen, die wir über Ihr Fahrzeug wissen müssen, um eine genaue Bewertung vornehmen zu können?',
    additionalInfoTooltip:
      'Bitte lassen Sie uns wissen, wenn wir noch etwas über Ihr Fahrzeug wissen sollten.',
    additionalInfoPlaceholder: 'Hier eingeben',
    additionalAriaLabel:
      'Bitte lassen Sie uns wissen, wenn wir noch etwas über Ihr Fahrzeug wissen sollten.',
    additionalInfoTooltipHover: 'Mehr Informationen',
    acceptLabel: 'Akzeptieren',
    acceptArialLabel:
      'Hier klicken, um eine Inzahlungnahme-Bewertungsanfrage zu senden',
    selectText: 'Auswählen'
  },
  it_ch: {
    vehRegLabel: 'Data di prima immatricolazione',
    dateLabel: 'GG',
    dateLabelReader:
      'Selezionate il giorno di prima immatricolazione del veicolo',
    datePlaceholder: 'GG',
    monthLabel: 'MM',
    monthLabelReader:
      'Selezionate il mese di prima immatricolazione del veicolo',
    monthPlaceholder: 'ad es. MM',
    yearLabel: 'AAAA',
    yearLabelReader: 'Selezionate l’anno di prima immatricolazione del veicolo',
    yearPlaceholder: 'ad es. AAAA',
    makePlaceholder: 'Marca',
    makeTooltip: 'Inserite la marca del vostro veicolo (ad es. Ford)',
    makeLabel: 'Marca',
    makeTooltipHover: 'Maggiori informazioni',
    modelPlaceholder: 'Modello',
    modelTooltip: 'Inserite il modello del vostro veicolo (ad es. Focus)',
    modelLabel: 'Modello',
    modelTooltipHover: 'Maggiori informazioni',
    seriesPlaceholder: 'Serie',
    seriesTooltip:
      'Inserite la serie del vostro veicolo (ad es. Zetec, ST-Line)',
    seriesLabel: 'ad es. Titanium',
    seriesAriaLabel: 'Inserite la serie del vostro veicolo',
    seriesTooltipHover: 'Maggiori informazioni',
    enginePlaceholder: 'Motore',
    engineTooltip:
      'Inserite il tipo di motore del vostro veicolo (ad es. benzina)',
    engineLabel: 'ad es. benzina',
    engineAriaLabel: 'Inserite il tipo di motore del vostro veicolo',
    engineTooltipHover: 'Maggiori informazioni',
    transmissionPlaceholder: 'Cambio',
    transmissionTooltip:
      'Inserite il tipo di cambio del vostro veicolo (ad es. automatico)',
    transmissionLabel: 'ad es. automatico',
    transmissionAriaLabel: 'Inserite il tipo di cambio del vostro veicolo',
    transmissionTooltipHover: 'Maggiori informazioni',
    colorPlaceholder: 'Colore',
    colorTooltip: 'Inserite il colore del vostro veicolo (ad es. Nero)',
    colorLabel: '(ad es. nero)',
    colorAriaLabel: 'Inserite il colore del vostro veicolo ',
    colorTooltipHover: 'Maggiori informazioni',
    mileagePlaceholder: 'Chilometraggio attuale',
    mileageLabel: 'Chilometraggio attuale',
    mileageTooltip: 'Inserite il chilometraggio attuale del vostro veicolo',
    mileageAriaLabel: 'Inserite il chilometraggio attuale del vostro veicolo',
    remainingBalanceLabel: 'ad es. Fr. 3000.-',
    remainingBalancePlaceholder: 'Saldo rimanente',
    remainingBalanceAriaLabel:
      'Inserite l’importo da saldare per il vostro veicolo',
    outstandingFinanceLabel:
      'Devi ancora effettuare un pagamento residuo per il tuo veicolo?',
    outstandingFinanceTooltip:
      'Vi preghiamo di indicare se dovete effettuare ancora un pagamento residuo per il vostro veicolo.',
    outstandingFinanceTooltipHover: 'Maggiori informazioni',
    outstandingFinanceOptions: ['Sì', 'No'],
    outstandingFinanceAriaLabels: [
      'Indicate se dovete ancora eseguire pagamenti finali riguardanti il vostro veicolo',
      'Selezionate se dovete ancora effettuare un pagamento residuo per il vostro veicolo'
    ],
    additionalInfoLabel:
      'Ci sono ulteriori informazioni che dovremmo conoscere sul tuo veicolo per poter eseguire una valutazione accurata?',
    additionalInfoTooltip:
      'Vi preghiamo di comunicarci se c’è altro da sapere a proposito del vostro veicolo',
    additionalInfoTooltipHover: 'Maggiori informazioni',
    additionalInfoPlaceholder: 'Inserire qui',
    additionalAriaLabel:
      'Inserite eventuali informazioni supplementari che dovremmo conoscere sul vostro veicolo',
    acceptLabel: 'Accettare',
    acceptArialLabel:
      'Clicchi qui per inviare una richiesta di valutazione di permuta',
    selectText: 'Seleziona'
  },
  nb_no: {
    vehRegLabel: 'Registreringsdato',
    dateLabel: 'DD',
    dateLabelReader: 'Vennligst velg dag bilen ble registrert',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Vennligst velg måneden bilen ble registrert',
    monthPlaceholder: 'MM',
    yearLabel: 'AAAA',
    yearLabelReader: 'Vennligst velg året bilen ble registrert',
    yearPlaceholder: 'ÅÅÅÅ',
    makeLabel: 'Merke',
    makeTooltip: 'Vennligst velg merke på din bil (f.eks. Ford)',
    makeTooltipHover: 'Mer informasjon',
    makePlaceholder: 'Merke',
    modelLabel: 'Modell',
    modelTooltip: 'Vennligst velg modell (f.eks. Focus)',
    modelPlaceholder: 'Modell',
    modelTooltipHover: 'Mer informasjon',
    seriesLabel: 'ST-Line, Vignale',
    seriesTooltip: 'Vennligst velg serie (f.eks. ST-Line)',
    seriesPlaceholder: 'Serie',
    seriesAriaLabel: 'Vennligst velg serie',
    seriesTooltipHover: 'Mer informasjon',
    engineLabel: 'Bensin, diesel',
    engineTooltip: 'Vennligst velg type motor (f.eks. diesel)',
    enginePlaceholder: 'Motor',
    engineAriaLabel: 'Vennligst velg type motor ',
    engineTooltipHover: 'Mer informasjon',
    transmissionLabel: 'f.eks. Automat',
    transmissionTooltip: 'Vennligst velg girkasse (f.eks. automat)',
    transmissionPlaceholder: 'Girkasse',
    transmissionAriaLabel: 'Vennligst velg girkasse',
    transmissionTooltipHover: 'Mer informasjon',
    colorLabel: 'Farge',
    colorTooltip: 'Vennligst velg farge (f.eks. sort)',
    colorPlaceholder: 'Farge',
    colorAriaLabel: 'Vennligst velg farge',
    colorTooltipHover: 'Mer informasjon',
    mileagePlaceholder: 'Nåværende kilometerstand',
    mileageLabel: 'Nåværende kilometerstand',
    mileageTooltip: 'Vennligst skriv inn kilometerstand',
    mileageAriaLabel: 'Vennligst skriv inn kilometerstand',
    remainingBalanceLabel: 'Utestående gjeld',
    remainingBalancePlaceholder: 'F.eks. 50.000,-',
    remainingBalanceAriaLabel: 'Vennligst oppgi utestående gjeld på din bil',
    outstandingFinanceLabel: 'Har du restgjeld på bilen?',
    outstandingFinanceTooltip:
      'Har du fortsatt utestående gjeld på bilen? Har du ikke denne informasjonen tilgjengelig velger du JA og skriver «0» i feltet under.',
    outstandingFinanceTooltipHover: 'Mer informasjon',
    outstandingFinanceOptions: ['Ja', 'Nei'],
    outstandingFinanceAriaLabels: [
      'Velg om du har utestående gjeld.',
      'Velg om du ikke har utestående gjeld.'
    ],
    additionalInfoLabel:
      'Er det annen informasjon du mener vi trenger for å gi deg en verdivurdering?',
    additionalInfoTooltip:
      'Vennligst skriv under om det er noe annet du mener vi trenger å vite for å foreta en verdivurdering',
    additionalInfoTooltipHover: 'Mer informasjon',
    additionalInfoPlaceholder: 'Skriv her',
    additionalAriaLabel:
      'Vennligst skriv om det er noe annet du mener vi trenger å vite for å foreta en verdivurdering',
    acceptLabel: 'Gå videre',
    acceptArialLabel:
      'Klikk her for å sende inn forespørsel om estimert innbyttepris',
    selectText: 'Velg'
  },
  de_de: {
    vehRegLabel: 'Datum der Erstzulassung',
    dateLabel: 'TT',
    dateLabelReader: 'Tag der ersten Registrierung',
    datePlaceholder: 'TT',
    monthLabel: 'MM',
    monthLabelReader: 'Monat der ersten Registrierung',
    monthPlaceholder: 'MM',
    yearLabel: 'JJJJ',
    yearLabelReader: 'Jahr der Erstregistrierung',
    yearPlaceholder: 'JJJJ',
    makeLabel: 'Marke, z.B. Ford',
    makeTooltip: 'Von welcher Marke ist Ihr Fahrzeug (z. B. Ford)?',
    makePlaceholder: 'Marke',
    makeTooltipHover: 'Mehr Informationen',
    modelLabel: 'Modell, z.B. Focus',
    modelTooltip: 'Wie heißt das Modell (z. B. Focus)?',
    modelPlaceholder: 'Modell',
    modelTooltipHover: 'Mehr Informationen',
    seriesLabel: 'Serie, z.B. Titanium',
    seriesTooltip:
      'Welche Ausstattungsvariante hat Ihr Fahrzeug (z. B. ST-Line)?',
    seriesPlaceholder: 'Serie',
    seriesTooltipHover: 'Mehr Informationen',
    engineLabel: 'Motor',
    engineTooltip: 'Welchen Motor hat Ihr Fahrzeug (z.B. Benzin)?',
    enginePlaceholder: 'Motor',
    engineTooltipHover: 'Mehr Informationen',
    transmissionLabel: 'Schaltung',
    transmissionTooltip: 'Welches Getriebe hat Ihr Fahrzeug (z.B. Automatik)?',
    transmissionPlaceholder: 'Getriebe',
    transmissionTooltipHover: 'Mehr Informationen',
    colorLabel: 'Außenfarbe',
    colorTooltip: 'Welche Aussenfarbe hat Ihr Fahrzeug?',
    colorPlaceholder: 'z.B. Schwarz',
    colorTooltipHover: 'Mehr Informationen',
    mileagePlaceholder: 'Aktueller Kilometerstand',
    mileageLabel: 'e.g. z.B.',
    mileageTooltip:
      'Bitte geben Sie den aktuellen Kilometerstand Ihres Fahrzeugs ein',
    mileageAriaLabel:
      'Bitte geben Sie den aktuellen Kilometerstand Ihres Fahrzeugs ein',
    remainingBalanceLabel: 'Restzahlung',
    remainingBalancePlaceholder: 'Restzahlung',
    outstandingFinanceLabel:
      'Haben Sie noch eine Restzahlung für Ihr Fahrzeug zu leisten?',
    outstandingFinanceTooltip:
      'Bitte geben Sie an, ob Sie noch eine Restzahlung für Ihr Fahrzeug leisten müssen',
    outstandingFinanceTooltipHover: 'Mehr Informationen',
    outstandingFinanceOptions: ['Ja', 'Nein'],
    outstandingFinanceAriaLabels: [
      'Wählen Sie Ja, wenn noch eine Restzahlung offen ist',
      'Wählen Sie Nein, wenn keine Restzahlung mehr offen ist'
    ],
    additionalInfoLabel:
      'Gibt es zusätzliche Informationen, die wir über Ihr Fahrzeug wissen müssen, um eine genaue Bewertung vornehmen zu können?',
    additionalInfoTooltip:
      'Bitte teilen Sie uns mit, ob wir noch etwas über Ihr Fahrzeug wissen müssen',
    additionalInfoTooltipHover: 'Mehr Informationen',
    additionalInfoPlaceholder: 'Zusätzliche Informationen hier eingeben',
    acceptLabel: 'Bewertung anfragen',
    acceptArialLabel:
      'Klicken Sie hier, um diese Bewertung zu akzeptieren und in Ihren Warenkorb zu legen'
  },
  fr_be: {
    vehRegLabel: `Date d'immatriculation du véhicule`,
    dateLabel: 'JJ',
    dateLabelReader: `Veuillez sélectionner le jour d'immatriculation de votre véhicule`,
    datePlaceholder: 'JJ',
    monthLabel: 'MM',
    monthLabelReader: `Veuillez sélectionner le mois d'immatriculation de votre véhicule`,
    monthPlaceholder: 'MM',
    yearLabel: 'AAAA',
    yearLabelReader: `Veuillez sélectionner l'année d'immatriculation de votre véhicule`,
    yearPlaceholder: 'AAAA',
    makeLabel: 'Marque',
    makeTooltip: 'Veuillez saisir la marque de votre véhicule (p. ex. Ford)',
    makeAriaLabel: 'Veuillez saisir la marque de votre véhicule',
    makePlaceholder: 'Marque',
    makeTooltipHover: "Plus d'information",
    modelLabel: 'Modèle',
    modelTooltip: 'Veuillez saisir le modèle de votre véhicule (p. ex. Focus)',
    modelPlaceholder: 'Modèle',
    modelTooltipHover: "Plus d'information",
    seriesLabel: 'Série',
    seriesTooltip:
      'Veuillez saisir la série de votre véhicule (p. ex. Trend, ST-Line)',
    seriesPlaceholder: 'p. ex. Trend',
    seriesAriaLabel: 'Veuillez saisir la série de votre véhicule',
    seriesTooltipHover: "Plus d'information",
    engineLabel: 'p. ex. essence',
    engineTooltip:
      'Veuillez spécifier le moteur de votre véhicule (p. ex. essence)',
    enginePlaceholder: 'Moteur',
    engineAriaLabel: 'Veuillez spécifier le moteur de votre véhicule',
    engineTooltipHover: "Plus d'information",
    transmissionLabel: 'p. ex. automatique',
    transmissionTooltip:
      'Veuillez spécifier la transmission de votre véhicule (p. ex. automatique)',
    transmissionPlaceholder: 'Transmission',
    transmissionAriaLabel:
      'Veuillez spécifier la transmission de votre véhicule',
    transmissionTooltipHover: "Plus d'information",
    colorLabel: 'p. ex. noir',
    colorTooltip: 'Veuillez saisir la couleur de votre véhicule',
    colorPlaceholder: 'Couleur',
    colorAriaLabel: 'Veuillez saisir la couleur de votre véhicule',
    colorTooltipHover: "Plus d'information",
    mileagePlaceholder: 'Kilométrage actuel',
    mileageLabel: 'Kilométrage actuel',
    mileageTooltip: 'Veuillez saisir le kilométrage actuel de votre véhicule',
    mileageAriaLabel: 'Veuillez saisir le kilométrage actuel de votre véhicule',
    remainingBalanceLabel: 'Solde restant',
    remainingBalancePlaceholder: 'p. ex. 3.000 €',
    outstandingFinanceLabel: `Veuillez indiquer si vous devez encore effectuer des paiements pour finaliser l'achat de votre véhicule`,
    outstandingFinanceTooltip:
      'Bitte geben Sie an, ob Sie noch eine Restzahlung für Ihr Fahrzeug leisten müssen',
    outstandingFinanceTooltipHover: "Plus d'information",
    outstandingFinanceOptions: ['Oui', 'Non'],
    outstandingFinanceAriaLabels: [
      'Veuillez sélectionner si vous devez encore payer un solde pour votre véhicule',
      'Veuillez sélectionner si vous ne devez plus payer de solde pour votre véhicule'
    ],
    additionalInfoLabel:
      'Y a-t-il des informations supplémentaires que nous devrions connaître sur votre véhicule afin de vous fournir une évaluation précise?',
    additionalInfoTooltip: `Merci de nous indiquer d'éventuelles informations sur votre véhicule qui pourraient nous être utiles`,
    additionalInfoTooltipHover: "Plus d'information",
    additionalInfoPlaceholder: 'Veuillez écrire ici',
    additionalInfoAriaLabel:
      'Veuillez saisir toute information supplémentaire que nous devrions connaître sur votre véhicule',
    acceptLabel: 'Accepter',
    acceptArialLabel: `Cliquez ici pour soumettre votre demande d'évaluation de reprise`,
    selectText: 'Sélectionner'
  },
  nl_be: {
    vehRegLabel: 'Registratiedatum voertuig',
    dateLabel: 'DD',
    dateLabelReader: 'Selecteer de dag waarop uw auto werd geregistreerd',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Selecteer de maand waarin uw auto werd geregistreerd',
    monthPlaceholder: 'MM',
    yearLabel: 'JJJJ',
    yearLabelReader: 'Selecteer het jaar waarin uw auto werd geregistreerd',
    yearPlaceholder: 'JJJJ',
    makeLabel: 'Merk',
    makeTooltip: 'Voer het merk van uw voertuig in (bijv. Ford)',
    makePlaceholder: 'Merk',
    makeAriaLabel: 'Voer het model van uw voertuig in',
    makeTooltipHover: 'Meer informatie',
    modelLabel: 'Model',
    modelTooltip: 'Voer het model van uw voertuig in (bijv. Focus)',
    modelPlaceholder: 'Model',
    modelTooltipHover: 'Meer informatie',
    seriesLabel: 'Bijv. Trend',
    seriesTooltip: 'Voer de reeks van uw voertuig in (bijv. Trend, ST-Line)',
    seriesPlaceholder: 'Reeks',
    seriesAriaLabel: 'Voer de reeks van uw voertuig in',
    seriesTooltipHover: 'Meer informatie',
    engineLabel: 'Bijv. benzine',
    engineTooltip: 'Voer de motor van uw voertuig in (bijv. benzine)',
    enginePlaceholder: 'Motor',
    engineAriaLabel: 'Voer de motor van uw voertuig in',
    engineTooltipHover: 'Meer informatie',
    transmissionLabel: 'Bijv. automatisch',
    transmissionTooltip:
      'Voer de transmissie van uw voertuig in (bijv. automatisch)',
    transmissionPlaceholder: 'Transmissie',
    transmissionAriaLabel: 'Voer de transmissie van uw voertuig in',
    transmissionTooltipHover: 'Meer informatie',
    colorLabel: 'p. ex. noir',
    colorTooltip: 'Voer de kleur van uw voertuig in (bijv. zwart)',
    colorPlaceholder: 'Kleur',
    colorAriaLabel: 'Voer de kleur van uw voertuig in',
    colorTooltipHover: 'Meer informatie',
    mileagePlaceholder: 'Huidige kilometerstand',
    mileageLabel: 'Huidige kilometerstand',
    mileageTooltip: 'Voer de huidige kilometerstand van uw voertuig in',
    mileageAriaLabel: 'Voer de huidige kilometerstand van uw voertuig in',
    remainingBalanceLabel: 'Bijv. € 3.000',
    remainingBalancePlaceholder: 'Resterend saldo',
    remainingBalanceAriaLabel:
      'Voer het nog verschuldigde bedrag voor uw voertuig in',
    outstandingFinanceLabel: 'Bent u nog een saldo verschuldigd op uw auto?',
    outstandingFinanceTooltip:
      'Geef aan of u nog betalingen moet doen om de aankoop van uw voertuig te voltooien',
    outstandingFinanceTooltipHover: 'Meer informatie',
    outstandingFinanceOptions: ['Ja', 'Nee'],
    outstandingFinanceAriaLabels: [
      'Selecteer als u nog een saldo op uw voertuig verschuldigd bent',
      'Selecteer als u geen saldo meer verschuldigd bent op uw voertuig'
    ],
    additionalInfoLabel:
      'Is er aanvullende informatie die we moeten weten over uw voertuig voor een nauwkeurige waardebepaling?',
    additionalInfoTooltip:
      'Gelieve ons te laten weten indien we nog iets moeten weten over uw voertuig',
    additionalInfoTooltipHover: 'Meer informatie',
    additionalInfoPlaceholder: 'Typ hier',
    additionalInfoAriaLabel:
      'Voer eventuele aanvullende informatie in die we moeten weten over uw voertuig',
    acceptLabel: 'Aanvaarden',
    acceptArialLabel:
      'Klik hier om een verzoek tot inruilwaardering in te dienen',
    selectText: 'Kies'
  },
  de_at: {
    vehRegLabel: 'Datum der Erstzulassung des Fahrzeugs',
    dateLabel: 'TT',
    dateLabelReader:
      'Wählen Sie bitte den Tag aus, an dem Ihr Fahrzeug zugelassen wurde',
    datePlaceholder: 'TT',
    monthLabel: 'MM',
    monthLabelReader:
      'Wählen Sie bitte den Monat aus, in dem Ihr Fahrzeug zugelassen wurde',
    monthPlaceholder: 'MM',
    yearLabel: 'JJJJ',
    yearLabelReader:
      'Wählen Sie bitte das Jahr aus, in dem Ihr Fahrzeug zugelassen wurde',
    yearPlaceholder: 'JJJJ',
    makeLabel: 'Marke',
    makeTooltip: 'Von welcher Marke ist Ihr Fahrzeug (z. B. Ford)?',
    makePlaceholder: 'Marke',
    makeAriaLabel: 'Geben Sie bitte die Marke Ihres Fahrzeugs ein',
    makeTooltipHover: 'Mehr Informationen',
    modelLabel: 'Modell',
    modelTooltip: 'Wie heißt das Modell (z. B. Focus)?',
    modelPlaceholder: 'Modell',
    modelAriaLabel: 'Bitte geben Sie das Modell Ihres Fahrzeugs ein',
    modelTooltipHover: 'Mehr Informationen',
    seriesLabel: 'Serie, z.B. Titanium',
    seriesTooltip:
      'Welche Ausstattungsvariante hat Ihr Fahrzeug (z. B. Titanium)?',
    seriesPlaceholder: 'Serie',
    seriesAriaLabel:
      'Bitte geben Sie die Ausstattungsvariante Ihres Fahrzeugs ein',
    seriesTooltipHover: 'Mehr Informationen',
    engineLabel: 'Motor, z.B. Benzin',
    engineTooltip: 'Welchen Motor hat Ihr Fahrzeug (z.B. Benzin)?',
    enginePlaceholder: 'Motor',
    engineAriaLabel: 'Bitte geben Sie den Motor Ihres Fahrzeugs ein',
    engineTooltipHover: 'Mehr Informationen',
    transmissionLabel: 'Getriebe, z.B. Automatik',
    transmissionTooltip: 'Welches Getriebe hat Ihr Fahrzeug (z.B. Automatik)?',
    transmissionPlaceholder: 'Getriebe',
    transmissionAriaLabel: 'Bitte geben Sie das Getriebe Ihres Fahrzeugs ein',
    transmissionTooltipHover: 'Mehr Informationen',
    colorLabel: 'Farbe, z.B. Schwarz',
    colorTooltip: 'Welche Aussenfarbe hat Ihr Fahrzeug (z.B. Schwarz)?',
    colorPlaceholder: 'Farbe',
    colorAriaLabel:
      'Bitte geben Sie die Farbe Ihres Fahrzeugs ein (z.B. Schwarz)',
    colorTooltipHover: 'Mehr Informationen',
    mileagePlaceholder: 'Aktueller Kilometerstand',
    mileageLabel: 'Aktueller Kilometerstand',
    mileageTooltip:
      'Bitte geben Sie den Kilometerstand Ihres Fahrzeugs ein (z.B. 97500)',
    mileageAriaLabel: 'Bitte geben Sie den Kilometerstand Ihres Fahrzeugs ein',
    remainingBalanceLabel:
      'Haben Sie noch eine Restzahlung für Ihr Fahrzeug zu leisten?',
    remainingBalancePlaceholder: 'z.B. 3.000 €',
    remainingBalanceAriaLabel:
      'Bitte geben Sie den noch zu begleichenden Betrag für Ihr Fahrzeug an',
    outstandingFinanceLabel:
      'Haben Sie noch eine Restzahlung für Ihr Fahrzeug zu leisten?',
    outstandingFinanceTooltip:
      'Bitte geben Sie an, ob Sie noch eine Restzahlung für Ihr Fahrzeug leisten müssen',
    outstandingFinanceTooltipHover: 'Mehr Informationen',
    outstandingFinanceOptions: ['Ja', 'Nein'],
    outstandingFinanceAriaLabels: [
      'Wählen Sie dies aus, wenn Sie noch eine Restschuld für Ihr Fahrzeug offen haben',
      'Wählen Sie dies aus, wenn Sie keine Restschuld für Ihr Fahrzeug offen haben'
    ],
    additionalInfoLabel:
      'Gibt es zusätzliche Informationen, die wir über Ihr Fahrzeug benötigen, um eine genaue Bewertung vornehmen zu können?',
    additionalInfoTooltip:
      'Lassen Sie uns bitte wissen, wenn wir noch etwas über Ihr Fahrzeug wissen müssen',
    additionalInfoTooltipHover: 'Mehr Informationen',
    additionalInfoPlaceholder: 'Zusätzliche Informationen hier eingeben',
    additionalAriaLabel:
      'Bitte teilen Sie uns mit, ob wir noch etwas über Ihr Fahrzeug wissen müssen',
    acceptLabel: 'Bewertung anfragen',
    acceptArialLabel:
      'Klicken Sie hier, um eine Schätzung zur Inzahlungnahme anzufordern.',
    selectText: 'Auswählen'
  },
  da_dk: {
    vehRegLabel: 'Bilens registreringsdato',
    dateLabel: 'DD',
    dateLabelReader: 'Vælg den dag, din bil blev registreret',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Vælg den måned, din bil blev registreret',
    monthPlaceholder: 'MM',
    yearLabel: 'ÅÅÅÅ',
    yearLabelReader: 'Vælg det år, din bil blev registreret',
    yearPlaceholder: 'ÅÅÅÅ',
    makeLabel: 'Mærke',
    makeTooltip: 'Indtast din bils mærke (f.eks. Ford)',
    makePlaceholder: 'Mærke',
    makeAriaLabel: 'Indtast din bils mærke',
    makeTooltipHover: 'Mere information',
    modelLabel: 'Model',
    modelTooltip: 'Indtast venligst din bils model (f.eks. Focus)',
    modelPlaceholder: 'Model',
    modelAriaLabel: 'Indtast venligst din bils model',
    modelTooltipHover: 'Mere information',
    seriesLabel: 'F.eks. ST-Line',
    seriesTooltip: 'Indtast bilens variant (f.eks. ST-Line)',
    seriesPlaceholder: 'Variant',
    seriesAriaLabel: 'Indtast bilens variant',
    seriesTooltipHover: 'Mere information',
    engineLabel: 'F.eks. benzin',
    engineTooltip: 'Indtast din bils motortype (f.eks. benzin)',
    enginePlaceholder: 'Motortype',
    engineAriaLabel: 'Indtast din bils motortype',
    engineTooltipHover: 'Mere information',
    transmissionLabel: 'F.eks. automatisk',
    transmissionTooltip: 'Indtast din bils geartype (f.eks. automatisk)',
    transmissionPlaceholder: 'Geartype',
    transmissionAriaLabel: 'Indtast din bils geartype',
    transmissionTooltipHover: 'Mere information',
    colorLabel: 'f.eks. sort',
    colorTooltip: 'Indtast din bils farve (f.eks. sort)',
    colorPlaceholder: 'Farve',
    colorAriaLabel: 'Indtast din bils farve',
    colorTooltipHover: 'Mere information',
    mileagePlaceholder: 'Nuværende kilometerstand',
    mileageLabel: 'Nuværende kilometerstand',
    mileageTooltip: 'Indtast din bils aktuelle kilometerstand',
    mileageAriaLabel: 'Indtast din bils aktuelle kilometerstand',
    remainingBalanceLabel: 'Lån',
    remainingBalancePlaceholder: 'F.eks. 3.000 DKK',
    remainingBalanceAriaLabel: 'Indtast restgælden i din bil',
    outstandingFinanceLabel: 'Har du stadig lån i din bil?',
    outstandingFinanceTooltip: 'Angiv venligst, om du stadig har lån i din bil',
    outstandingFinanceTooltipHover: 'Mere information',
    outstandingFinanceOptions: ['Ja', 'Nej'],
    outstandingFinanceAriaLabels: [
      'Vælg ja, hvis du stadig har lån i din bil',
      'Vælg nej, hvis du ikke længere har har lån i din bil'
    ],
    additionalInfoLabel:
      'Er der mere, du vil fortælle os om din bil, før vi laver en vurdering?',
    additionalInfoTooltip:
      'Fortæl os, hvis der er andet, vi har brug for at vide om din bil',
    additionalInfoTooltipHover: 'Mere information',
    additionalInfoPlaceholder: 'Skriv her',
    additionalAriaLabel:
      'Indtast alle yderligere oplysninger, vi bør vide om din bil',
    acceptLabel: 'Accepter',
    acceptArialLabel:
      'Klik her for at indsende anmodning om værdiansættelse af indbytning',
    selectText: 'Vælg'
  },
  fi_fi: {
    vehRegLabel: 'Ajoneuvon ensirekisteröintipäivä',
    dateLabel: 'PP',
    dateLabelReader: 'Valitse päivä, jona ajoneuvosi on ensirekisteröity',
    datePlaceholder: 'PP',
    monthLabel: 'KK',
    monthLabelReader: 'Valitse kuukausi, jona ajoneuvosi on ensirekisteröity',
    monthPlaceholder: 'KK',
    yearLabel: 'VVVV',
    yearLabelReader: 'Valitse vuosi, jona ajoneuvosi on ensirekisteröity',
    yearPlaceholder: 'VVVV',
    makeLabel: 'Merkki',
    makeTooltip: 'Syötä ajoneuvosi merkki (esim. Ford)',
    makePlaceholder: 'Merkki',
    makeAriaLabel: 'Syötä tähän autosi merkki',
    makeTooltipHover: 'Lisää tietoa',
    modelLabel: 'Malli',
    modelTooltip: 'Syötä ajoneuvosi malli (esim. Focus)',
    modelPlaceholder: 'Malli',
    modelAriaLabel: 'Syötä ajoneuvosi malli',
    modelTooltipHover: 'Lisää tietoa',
    seriesLabel: 'Esim. Trend tai en tiedä',
    seriesTooltip:
      'Syötä ajoneuvosi mallisarja (esim. Trend, ST-Line). Voit myös kirjoittaa "en tiedä" jos et tiedä ajoneuvosi mallisarjaa.',
    seriesPlaceholder: 'Mallisarja',
    seriesAriaLabel: 'Syötä tähän ajoneuvosi mallisarja',
    seriesTooltipHover: 'Lisää tietoa',
    engineLabel: 'Esim. bensiini',
    engineTooltip: 'Syötä tiedot ajoneuvosi käyttövoimasta (esim. bensiini)',
    enginePlaceholder: 'Käyttövoima',
    engineAriaLabel: 'Syötä tiedot ajoneuvosi käyttövoimasta',
    engineTooltipHover: 'Lisää tietoa',
    transmissionLabel: 'Esim. automaatti',
    transmissionTooltip:
      'Syötä tiedot ajoneuvosi vaihteistosta (esim. automaatti)',
    transmissionPlaceholder: 'Vaihteisto',
    transmissionAriaLabel: 'Syötä tiedot ajoneuvosi vaihteistosta',
    transmissionTooltipHover: 'Lisää tietoa',
    colorLabel: 'esim. musta',
    colorTooltip: 'Syötä ajoneuvosi väri (esim. musta)',
    colorPlaceholder: 'Väri',
    colorAriaLabel: 'Syötä ajoneuvosi väri',
    colorTooltipHover: 'Lisää tietoa',
    mileagePlaceholder: 'Nykyinen mittarilukema',
    mileageLabel: 'Nykyinen mittarilukema',
    mileageTooltip: 'Syötä ajoneuvosi nykyinen mittarilukema',
    mileageAriaLabel: 'Syötä ajoneuvosi nykyinen mittarilukema',
    remainingBalanceLabel: 'Esim. 3 000 €. Jos et tiedä summaa, kirjoita 1.',
    remainingBalancePlaceholder: 'Jäännösvelan määrä',
    remainingBalanceAriaLabel:
      'Syötä ajoneuvostasi vielä maksamatta oleva jäännösvelka',
    outstandingFinanceLabel: 'Onko ajoneuvosta jäännösvelkaa?',
    outstandingFinanceTooltip: 'Ilmoita, onko ajoneuvostasi jäännösvelkaa',
    outstandingFinanceTooltipHover: 'Lisää tietoa',
    outstandingFinanceOptions: ['Kyllä', 'Ei'],
    outstandingFinanceAriaLabels: [
      'Valitse, jos ajoneuvostasi on jäännösvelkaa',
      'Valitse, jos olet jo maksanut ajoneuvosi kauppahinnan kokonaan ja olet ajoneuvon omistaja'
    ],
    additionalInfoLabel:
      'Haluatko antaa mahdollisia lisätietoja, kuten ajoneuvosi rekisteritunnuksen, arviointia varten?',
    additionalInfoTooltip:
      'Syötä mahdollisia lisätietoja ajoneuvostasi, kuten rekisteritunnus, arviointia varten',
    additionalInfoTooltipHover: 'Lisää tietoa',
    additionalInfoPlaceholder: 'Lisätietoja, kuten rekisteritunnus',
    additionalAriaLabel:
      'Syötä kaikki lisätiedot, jotka meidän tulisi tietää ajoneuvostasi',
    acceptLabel: 'Hyväksy',
    acceptArialLabel: 'Lähetä arvonmäärityspyyntö klikkaamalla tätä',
    selectText: 'Valitse'
  },
  en_ie: {
    vehRegLabel: 'Vehicle Registration Date',
    dateLabel: 'DD',
    dateLabelReader: 'Please select the day your vehicle was registered',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Please select the month your vehicle was registered',
    monthPlaceholder: 'MM',
    yearLabel: 'YYYY',
    yearLabelReader: 'Please select the year your vehicle was registered',
    yearPlaceholder: 'YYYY',
    makeLabel: 'Make',
    makeTooltip: 'Please enter the make of your vehicle (e.g. Ford)',
    makePlaceholder: 'Make',
    makeAriaLabel: 'Please enter the make of your vehicle',
    makeTooltipHover: 'Please enter the make of your vehicle (e.g. Ford)',
    modelLabel: 'Model',
    modelTooltip: 'Please enter the model of your vehicle (e.g. Focus)',
    modelPlaceholder: 'Model',
    modelAriaLabel: 'Please enter the model of your vehicle',
    modelTooltipHover: 'Please enter the model of your vehicle',
    seriesLabel: 'e.g. Titanium',
    seriesTooltip:
      'Please enter the series of your vehicle (e.g. Titanium, ST-Line)',
    seriesPlaceholder: 'Series',
    seriesAriaLabel: 'Please enter the series of your vehicle',
    seriesTooltipHover:
      'Please enter the series of your vehicle (e.g. Titanium, ST-Line)',
    engineLabel: 'e.g. Petrol',
    engineTooltip: 'Please enter the engine of your vehicle (e.g. Petrol)',
    enginePlaceholder: 'Engine',
    engineAriaLabel: 'Please enter the engine of your vehicle',
    engineTooltipHover: 'Please enter the engine of your vehicle (e.g. Petrol)',
    transmissionLabel: 'e.g. Automatic',
    transmissionTooltip:
      'Please enter the transmission of your vehicle (e.g. Automatic)',
    transmissionPlaceholder: 'Transmission',
    transmissionAriaLabel: 'Please enter the transmission of your vehicle',
    transmissionTooltipHover:
      'Please enter the transmission of your vehicle (e.g. Automatic)',
    colorLabel: 'e.g. Black',
    colorTooltip: 'Please enter the colour of your vehicle (e.g. Black)',
    colorPlaceholder: 'Colour',
    colorAriaLabel: 'Please enter the colour of your vehicle',
    colorTooltipHover: 'Please enter the colour of your vehicle (e.g. Black)',
    mileagePlaceholder: 'Current Mileage',
    mileageLabel: 'Current Mileage',
    mileageTooltip: 'Please enter the current mileage of your vehicle',
    mileageAriaLabel: 'Please enter the current mileage of your vehicle',
    remainingBalanceLabel: 'e.g. €3.000',
    remainingBalancePlaceholder: 'Remaining Balance',
    remainingBalanceAriaLabel:
      'Please enter the amount still owed on your vehicle',
    outstandingFinanceLabel: 'Do you still owe a balance on your vehicle?',
    outstandingFinanceTooltip:
      'Please indicate whether you still have to make any final payments on your vehicle',
    outstandingFinanceTooltipHover:
      'Please indicate whether you still have to make any final payments on your vehicle',
    outstandingFinanceOptions: ['Yes', 'No'],
    outstandingFinanceAriaLabels: [
      'Select if you still owe a balance on your vehicle',
      'Select if you do not still owe a balance on your vehicle'
    ],
    additionalInfoLabel:
      'Is there any additional information we need to know about your vehicle to provide an accurate valuation?',
    additionalInfoTooltip:
      'Please let us know if there is anything else we need to know about your vehicle',
    additionalInfoTooltipHover:
      'Please let us know if there is anything else we need to know about your vehicle',
    additionalInfoPlaceholder: 'Type Here',
    additionalAriaLabel:
      'Please enter any addition information we should know about your vehicle',
    acceptLabel: 'Accept',
    acceptArialLabel: 'Click here to submit trade-in valuation request'
  },
  en_gb: {
    vehRegLabel: 'Vehicle Registration Date',
    dateLabel: 'DD',
    dateLabelReader: 'Please select the day your vehicle was registered',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Please select the month your vehicle was registered',
    monthPlaceholder: 'MM',
    yearLabel: 'YYYY',
    yearLabelReader: 'Please select the year your vehicle was registered',
    yearPlaceholder: 'YYYY',
    makeLabel: 'Make',
    makeTooltip: 'Please enter the make of your vehicle (e.g. Ford)',
    makePlaceholder: 'Make',
    makeAriaLabel: 'Please enter the make of your vehicle',
    makeTooltipHover: 'Please enter the make of your vehicle (e.g. Ford)',
    modelLabel: 'Model',
    modelTooltip: 'Please enter the model of your vehicle (e.g. Focus)',
    modelPlaceholder: 'Model',
    modelAriaLabel: 'Please enter the model of your vehicle',
    modelTooltipHover: 'Please enter the model of your vehicle',
    seriesLabel: 'e.g. Titanium',
    seriesTooltip:
      'Please enter the series of your vehicle (e.g. Titanium, ST-Line)',
    seriesPlaceholder: 'Series',
    seriesAriaLabel: 'Please enter the series of your vehicle',
    seriesTooltipHover:
      'Please enter the series of your vehicle (e.g. Titanium, ST-Line)',
    engineLabel: 'e.g. Petrol',
    engineTooltip: 'Please enter the engine of your vehicle (e.g. Petrol)',
    enginePlaceholder: 'Engine',
    engineAriaLabel: 'Please enter the engine of your vehicle',
    engineTooltipHover: 'Please enter the engine of your vehicle (e.g. Petrol)',
    transmissionLabel: 'e.g. Automatic',
    transmissionTooltip:
      'Please enter the transmission of your vehicle (e.g. Automatic)',
    transmissionPlaceholder: 'Transmission',
    transmissionAriaLabel: 'Please enter the transmission of your vehicle',
    transmissionTooltipHover:
      'Please enter the transmission of your vehicle (e.g. Automatic)',
    colorLabel: 'e.g. Black',
    colorTooltip: 'Please enter the colour of your vehicle (e.g. Black)',
    colorPlaceholder: 'Colour',
    colorAriaLabel: 'Please enter the colour of your vehicle',
    colorTooltipHover: 'Please enter the colour of your vehicle (e.g. Black)',
    mileagePlaceholder: 'Current Mileage',
    mileageLabel: 'Current Mileage',
    mileageTooltip: 'Please enter the current mileage of your vehicle',
    mileageAriaLabel: 'Please enter the current mileage of your vehicle',
    remainingBalanceLabel: 'e.g. €3.000',
    remainingBalancePlaceholder: 'Remaining Balance',
    remainingBalanceAriaLabel:
      'Please enter the amount still owed on your vehicle',
    outstandingFinanceLabel: 'Do you still owe a balance on your vehicle?',
    outstandingFinanceTooltip:
      'Please indicate whether you still have to make any final payments on your vehicle',
    outstandingFinanceTooltipHover:
      'Please indicate whether you still have to make any final payments on your vehicle',
    outstandingFinanceOptions: ['Yes', 'No'],
    outstandingFinanceAriaLabels: [
      'Select if you still owe a balance on your vehicle',
      'Select if you do not still owe a balance on your vehicle'
    ],
    additionalInfoLabel:
      'Is there any additional information we need to know about your vehicle to provide an accurate valuation?',
    additionalInfoTooltip:
      'Please let us know if there is anything else we need to know about your vehicle',
    additionalInfoTooltipHover:
      'Please let us know if there is anything else we need to know about your vehicle',
    additionalInfoPlaceholder: 'Type Here',
    additionalAriaLabel:
      'Please enter any addition information we should know about your vehicle',
    acceptLabel: 'Add',
    acceptArialLabel: 'Click here to submit trade-in valuation request'
  },
  cs_cz: {
    vehRegLabel: 'Datum registrace vozidla',
    dateLabel: 'DD',
    dateLabelReader: 'Vyberte den registrace vozidla',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Vyberte měsíc registrace vozidla',
    monthPlaceholder: 'MM',
    yearLabel: 'RRRR',
    yearLabelReader: 'Vyberte rok registrace vozidla',
    yearPlaceholder: 'RRRR',
    makeLabel: 'Značka',
    makeTooltip: 'Zadejte značku vozu (např. Ford)',
    makePlaceholder: 'Značka',
    makeAriaLabel: 'Please enter the make of your vehicle',
    makeTooltipHover: 'Zadejte značku vozu (např. Ford)',
    modelLabel: 'Model',
    modelTooltip: 'Zadejte model vozu (např. Focus)',
    modelPlaceholder: 'Model',
    modelAriaLabel: 'Zadejte model vozu',
    modelTooltipHover: 'Zadejte model vozu',
    seriesLabel: 'např. Titanium',
    seriesTooltip: 'Zadejte stupeň výbavy (např. Titanium, ST-Line)',
    seriesPlaceholder: 'Výbavový stupeň',
    seriesAriaLabel: 'Zadejte stupeň výbavy vozu',
    seriesTooltipHover: 'Zadejte stupeň výbavy (např. Titanium, ST-Line)',
    engineLabel: 'např. Benzín',
    engineTooltip: 'Zadejte pohon (např. benzín)',
    enginePlaceholder: 'Motor',
    engineAriaLabel: 'Syötä tiedot ajoneuvosi moottorista',
    engineTooltipHover: 'Zadejte motor vozu (např. benzín)',
    transmissionLabel: 'např. automatická',
    transmissionTooltip: 'Zadejte převodovku vozu (např. automatická)',
    transmissionPlaceholder: 'Převodovka',
    transmissionAriaLabel: 'Zadejte převodovku vozu',
    transmissionTooltipHover: 'Zadejte převodovku vozu (např. automatická)',
    colorLabel: 'např. černá',
    colorTooltip: 'Zadejte barvu vozu (např. černá)',
    colorPlaceholder: 'Barva',
    colorAriaLabel: 'Zadejte barvu vozu',
    colorTooltipHover: 'Zadejte barvu vozu (např. černá)',
    mileagePlaceholder: 'Stav km',
    mileageLabel: 'Stav km',
    mileageTooltip: 'Zadejte aktuální počet najetých kilometrů vozu',
    mileageAriaLabel: 'Zadejte aktuální počet najetých kilometrů vozu',
    remainingBalanceLabel: 'např. 50 000 Kč',
    remainingBalancePlaceholder: 'Zbývající zůstatek',
    remainingBalanceAriaLabel: 'Zadejte výši dlužné částky za své vozidlo.',
    outstandingFinanceLabel: 'Stále vůz splácíte?',
    outstandingFinanceTooltip:
      'Uveďte, zda ještě musíte provést jakékoli konečné platby za své vozidlo',
    outstandingFinanceTooltipHover:
      'Uveďte, zda ještě musíte provést jakékoli konečné platby za své vozidlo',
    outstandingFinanceOptions: ['Ano', 'Ne'],
    outstandingFinanceAriaLabels: [
      'Vyberte, pokud jakoukoli částku za své vozidlo stále dlužíte',
      'Vyberte, pokud již nic za vozidlo nedlužíte'
    ],
    additionalInfoLabel:
      'Jsou pro přesné ocenění vašeho vozu zapotřebí ještě nějaké jiné informace?',
    additionalInfoTooltip:
      'Sdělte nám prosím, zda je zde ještě něco, co bychom měli o vašem voze vědět.',
    additionalInfoTooltipHover:
      'Sdělte nám prosím, zda je zde ještě něco, co bychom měli o vašem voze vědět.',
    additionalInfoPlaceholder: 'Napište to sem',
    additionalAriaLabel:
      'Zadejte jakékoli doplňující informace, které bychom měli o vašem vozu vědět',
    acceptLabel: 'Souhlasím',
    acceptArialLabel: 'Klepnutím sem odešlete žádost o nacenění vozu.',
    selectText: 'Vybrat'
  },
  pl_pl: {
    vehRegLabel: 'Data rejestracji pojazdu',
    dateLabel: 'DD',
    dateLabelReader: 'Wybierz dzień rejestracji obecnego pojazdu.',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Wybierz miesiąc rejestracji obecnego pojazdu.',
    monthPlaceholder: 'MM',
    yearLabel: 'RRRR',
    yearLabelReader: 'Wybierz rok rejestracji obecnego pojazdu.',
    yearPlaceholder: 'RRRR',
    makeLabel: 'Marka',
    makeTooltip: 'Wprowadź markę obecnego pojazdu (np. Ford).',
    makePlaceholder: 'Marka np. Ford',
    makeAriaLabel: 'Wprowadź markę obecnego pojazdu.',
    makeTooltipHover: 'Wprowadź markę obecnego pojazdu (np. Ford).',
    modelLabel: 'Model',
    modelTooltip: 'Wprowadź model obecnego pojazdu (np. Focus).',
    modelPlaceholder: 'Model np. Focus',
    modelAriaLabel: 'Wprowadź model obecnego pojazdu.',
    modelTooltipHover: 'Wprowadź model obecnego pojazdu.',
    seriesLabel: 'Seria',
    seriesTooltip: 'Wprowadź wersję obecnego pojazdu (np. ST-Line).',
    seriesPlaceholder: 'Seria np. ST-LINE',
    seriesAriaLabel: 'Wprowadź wersję obecnego pojazdu.',
    seriesTooltipHover: 'Wprowadź wersję obecnego pojazdu (np. ST-Line).',
    engineLabel: 'Silnik',
    engineTooltip: 'Wprowadź rodzaj silnika obecnego pojazdu (np. benzynowy).',
    enginePlaceholder: 'Silnik',
    engineAriaLabel:
      'Wprowadź rodzaj silnika obecnego pojazdu (np. benzynowy).',
    engineTooltipHover:
      'Wprowadź rodzaj silnika obecnego pojazdu (np. benzynowy).',
    transmissionLabel: 'Skrzynia biegów',
    transmissionTooltip:
      'Wprowadź rodzaj skrzyni biegów obecnego pojazdu (np. automatyczna).',
    transmissionPlaceholder: 'np. automatyczna',
    transmissionAriaLabel: 'Wprowadź rodzaj skrzyni biegów obecnego pojazdu.',
    transmissionTooltipHover:
      'Wprowadź rodzaj skrzyni biegów obecnego pojazdu (np. automatyczna).',
    colorLabel: 'Lakier',
    colorTooltip: 'Wprowadź kolor obecnego pojazdu (np. czarny).',
    colorPlaceholder: 'np. czarny',
    colorAriaLabel: 'Wprowadź kolor obecnego pojazdu.',
    colorTooltipHover: 'Wprowadź kolor obecnego pojazdu (np. czarny).',
    mileagePlaceholder: 'Bieżący przebieg',
    mileageLabel: 'Bieżący przebieg',
    mileageTooltip: 'Wprowadź bieżący przebieg obecnego pojazdu.',
    mileageAriaLabel: 'Wprowadź bieżący przebieg obecnego pojazdu.',
    remainingBalanceLabel: 'np. 3 000 PLN',
    remainingBalancePlaceholder: 'Pozostało do spłacenia',
    remainingBalanceAriaLabel:
      'Wprowadź kwotę pozostałą do spłaty Twojego pojazdu.',
    outstandingFinanceLabel: 'Czy nadal spłacasz swój pojazd?',
    outstandingFinanceTooltip:
      'Poinformuj nas, czy nadal musisz dokonać końcowych płatności za pojazd.',
    outstandingFinanceTooltipHover:
      'Poinformuj nas, czy nadal musisz dokonać końcowych płatności za pojazd.',
    outstandingFinanceOptions: ['Tak', 'Nie'],
    outstandingFinanceAriaLabels: [
      'Wybierz, jeśli spłacasz obecny pojazd.',
      'Wybierz, jeśli nie spłacasz obecnego pojazdu.'
    ],
    additionalInfoLabel: '',
    additionalInfoTooltip:
      'Prosimy o podanie ewentualnych dodatkowych informacji dotyczących Twojego pojazdu, które powinniśmy znać.',
    additionalInfoTooltipHover:
      'Prosimy o podanie ewentualnych dodatkowych informacji dotyczących Twojego pojazdu, które powinniśmy znać.',
    additionalInfoPlaceholder: 'Dodatkowe informacje',
    additionalAriaLabel:
      'Prosimy wprowadzić wszelkie dodatkowe informacje dotyczące Twojego pojazdu, które powinniśmy znać.',
    acceptLabel: 'Poproś o wycenę',
    acceptArialLabel:
      'Kliknij tutaj, aby przesłać prośbę o wycenę w modelu „wymień stary na nowy”',
    selectText: 'Wybierz'
  },
  pt_pt: {
    vehRegLabel: 'Data de matricula do veículo',
    dateLabel: 'DD',
    dateLabelReader: 'Selecionar o dia da matricula do veículo',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Selecionar o mês da matricula do veículo',
    monthPlaceholder: 'MM',
    yearLabel: 'AAAA',
    yearLabelReader: 'Selecionar o ano da matricula do veículo',
    yearPlaceholder: 'AAAA',
    makeLabel: 'Marca',
    makeTooltip: 'Introduzir a marca do veículo (por exemplo, Ford)',
    makePlaceholder: 'Marca',
    makeAriaLabel: 'Introduzir a marca do veículo',
    makeTooltipHover: 'Introduzir a marca do veículo (por exemplo, Ford)',
    modelLabel: 'Modelo',
    modelTooltip: 'Introduzir o modelo do veículo (por exemplo, Focus)',
    modelPlaceholder: 'Modelo',
    modelAriaLabel: 'Introduzir o modelo do veículo',
    modelTooltipHover: 'Introduzir o modelo do veículo',
    seriesLabel: 'por exemplo, ST-Line',
    seriesTooltip:
      'Introduzir a versão do veículo (por exemplo, Active, ST-Line)',
    seriesPlaceholder: 'Versão',
    seriesAriaLabel: 'Introduzir a versão do veículo',
    seriesTooltipHover:
      'Introduzir a versão do veículo (por exemplo, Active, ST-Line)',
    engineLabel: 'Por exemplo, Gasolina',
    engineTooltip:
      'Introduzir o tipo de combustivel do veículo (por exemplo, Gasolina)',
    enginePlaceholder: 'Motorização',
    engineAriaLabel: 'Syötä tiedot ajoneuvosi moottorista',
    engineTooltipHover:
      'Introduzir o tipo de combustivel do veículo (por exemplo, Gasolina)',
    transmissionLabel: 'Por exemplo, Automática',
    transmissionTooltip:
      'Introduzir a transmissão do veículo (por exemplo, Automática)',
    transmissionPlaceholder: 'Transmissão',
    transmissionAriaLabel: 'Introduzir a transmissão do veículo',
    transmissionTooltipHover:
      'Introduzir a transmissão do veículo (por exemplo, Automática)',
    colorLabel: 'por exemplo, Preto',
    colorTooltip: 'Introduzir a cor do veículo (por exemplo, Preto)',
    colorPlaceholder: 'Cor',
    colorAriaLabel: 'Introduzir a cor do seu veículo',
    colorTooltipHover: 'Introduzir a cor do veículo (por exemplo, Preto)',
    mileagePlaceholder: 'Quilometragem atual',
    mileageLabel: 'Quilometragem atual',
    mileageTooltip: 'Introduzir a quilometragem atual do veículo',
    mileageAriaLabel: 'Introduzir a quilometragem atual do veículo',
    remainingBalanceLabel: 'Por exemplo, 3000 €',
    remainingBalancePlaceholder: 'Saldo em dívida',
    remainingBalanceAriaLabel:
      'Introduza o montante que ainda se encontra em dívida no veículo',
    outstandingFinanceLabel:
      'Ainda tem algum valor em dívida referente ao veículo?',
    outstandingFinanceTooltip:
      'Indicar se ainda é necessário efetuar algum pagamento final referente ao veículo',
    outstandingFinanceTooltipHover:
      'Indicar se ainda é necessário efetuar algum pagamento final referente ao veículo',
    outstandingFinanceOptions: ['Sim', 'Não'],
    outstandingFinanceAriaLabels: [
      'Selecionar se ainda tem algum valor em dívida referente ao veículo',
      'Selecionar se já não tem valor em dívida referente ao veículo'
    ],
    additionalInfoLabel:
      'Há alguma informação adicional que seja necessária indicar sobre o veículo para podermos fornecer uma avaliação rigorosa?',
    additionalInfoTooltip:
      'Indicar se houver mais alguma informação relevante sobre o veículo',
    additionalInfoTooltipHover:
      'Please let us know if there is anything else we need to know about your vehicle',
    additionalInfoPlaceholder: 'Escrever aqui',
    additionalAriaLabel:
      'Introduzir qualquer informação adicional sobre o veículo',
    acceptLabel: 'Aceitar',
    acceptArialLabel: 'Clique aqui para enviar o pedido de avaliação de troca',
    selectText: 'Selecione'
  },
  el_gr: {
    vehRegLabel: 'Ημερομηνία πρώτης κυκλοφορίας οχήματος',
    dateLabel: 'ΗΗ',
    dateLabelReader: 'Επίλεξε την ημέρα καταχώρησης του οχήματός σου',
    datePlaceholder: 'ΗΗ',
    monthLabel: 'MM',
    monthLabelReader: 'Επίλεξε τον μήνα καταχώρησης του οχήματός σου',
    monthPlaceholder: 'MM',
    yearLabel: 'EEEE',
    yearLabelReader: 'Επίλεξε το έτος καταχώρησης του οχήματός σου',
    yearPlaceholder: 'EEEE',
    makeLabel: 'Κατασκευαστής',
    makeTooltip: 'Πληκτρολογήστε τον κατασκευαστή του οχήματός σας (π.χ. Ford)',
    makePlaceholder: 'Κατασκευαστής',
    makeAriaLabel: 'Πληκτρολόγησε την κατασκευή του οχήματός σου',
    makeTooltipHover:
      'Πληκτρολόγησε την κατασκευή του οχήματός σου (π.χ. Ford)',
    modelLabel: 'Μοντέλο',
    modelTooltip: 'Πληκτρολογήστε το μοντέλο του οχήματός σας (π.χ. Focus)',
    modelPlaceholder: 'Μοντέλο',
    modelAriaLabel: 'Πληκτρολόγησε το μοντέλο του οχήματός σου',
    modelTooltipHover: 'Πληκτρολόγησε το μοντέλο του οχήματός σου',
    seriesLabel: 'π.χ. Zetec',
    seriesTooltip: 'Πληκτρολογήστε την έκδοση του οχήματός σας (π.χ. ST-Line)',
    seriesPlaceholder: 'Έκδοση',
    seriesAriaLabel: 'Πληκτρολόγησε τη σειρά του οχήματός σου',
    seriesTooltipHover:
      'Πληκτρολογήστε την έκδοση του οχήματός σας (π.χ. ST-Line)',
    engineLabel: 'π.χ. πετρελαιοκινητήρας',
    engineTooltip:
      'Επιλέξτε από τη λίστα τον τύπο κινητήρα του οχήματός σας (π.χ. Πετρελαιοκινητήρας)',
    enginePlaceholder: 'Κινητήρας',
    engineAriaLabel: 'Πληκτρολόγησε τον κινητήρα του οχήματός σου',
    engineTooltipHover:
      'ΕΕπιλέξτε από τη λίστα τον τύπο κινητήρα του οχήματός σας (π.χ. Πετρελαιοκινητήρας)',
    transmissionLabel: 'π.χ. Αυτόματο',
    transmissionTooltip:
      'Επιλέξτε από τη λίστα το κιβώτιο ταχυτήτων του οχήματός σας (π.χ. Αυτόματο)',
    transmissionPlaceholder: 'Κιβώτιο ταχυτήτων',
    transmissionAriaLabel:
      'Πληκτρολόγησε το κιβώτιο ταχυτήτων του οχήματός σου',
    transmissionTooltipHover:
      'Επιλέξτε από τη λίστα το κιβώτιο ταχυτήτων του οχήματός σας (π.χ. Αυτόματο)',
    colorLabel: 'π.χ. Μαύρο',
    colorTooltip:
      'Επιλέξτε από τη λίστα το χρώμα του οχήματός σας (π.χ. Μαύρο)',
    colorPlaceholder: 'Χρώμα',
    colorAriaLabel: 'Πληκτρολόγησε το χρώμα του οχήματός σου',
    colorTooltipHover:
      'Επιλέξτε από τη λίστα το χρώμα του οχήματός σας (π.χ. Μαύρο)',
    mileagePlaceholder: 'Τρέχοντα χιλιόμετρα',
    mileageLabel: 'Τρέχοντα χιλιόμετρα',
    mileageTooltip: 'Πληκτρολογήστε τα τρέχοντα χιλιόμετρα του οχήματός σας',
    mileageAriaLabel: 'Πληκτρολόγησε τα τρέχοντα χιλιόμετρα του οχήματός σου',
    remainingBalanceLabel: 'π.χ. 3.000 €',
    remainingBalancePlaceholder: 'Υπόλοιπο',
    remainingBalanceAriaLabel:
      'Πληκτρολόγησε το ποσό που ακόμη οφείλεται για το όχημά σου',
    outstandingFinanceLabel: 'Εκκρεμούν οφειλές για το όχημά σας;',
    outstandingFinanceTooltip:
      'Υποδείξτε εάν εκκρεμούν οποιεσδήποτε πληρωμές για το όχημά σας',
    outstandingFinanceTooltipHover:
      'Υποδείξτε εάν εκκρεμούν οποιεσδήποτε πληρωμές για το όχημά σας',
    outstandingFinanceOptions: ['Ναι', 'Όχι'],
    outstandingFinanceAriaLabels: [
      'Επίλεξε εάν εκκρεμούν ακόμη οποιεσδήποτε τελικές πληρωμές για το όχημά σου',
      'Επίλεξε εάν δεν εκκρεμούν οποιεσδήποτε τελικές πληρωμές για το όχημά σου'
    ],
    additionalInfoLabel:
      'Υπάρχουν πρόσθετες πληροφορίες που πρέπει να γνωρίζουμε για το όχημά σας για να παρέχουμε μία ακριβή εκτίμηση;',
    additionalInfoTooltip:
      'Ενημερώστε μας εάν υπάρχει κάτι άλλο που πρέπει να γνωρίζουμε για το όχημά σας',
    additionalInfoTooltipHover:
      'Ενημερώστε μας εάν υπάρχει κάτι άλλο που πρέπει να γνωρίζουμε για το όχημά σας',
    additionalInfoPlaceholder: 'Πληκτρολογήστε εδώ',
    additionalAriaLabel:
      'Πληκτρολόγησε τυχόν πρόσθετες πληροφορίες που πρέπει να γνωρίζουμε για το όχημά σου',
    acceptLabel: 'Υποβολή',
    acceptArialLabel:
      'Κάντε κλικ εδώ για να υποβάλλετε αίτημα για εμπορική αποτίμηση',
    selectText: 'Επιλογή'
  },
  hu_hu: {
    vehRegLabel: 'A jármű nyilvántartásba vételének dátuma',
    dateLabel: 'NAP',
    dateLabelReader:
      'Kérjük, add meg a járműved nyilvántartásba vételének napját!',
    datePlaceholder: 'DD',
    monthLabel: 'HÓNAP',
    monthLabelReader:
      'Kérjük, add meg a járműved nyilvántartásba vételének hónapját!',
    monthPlaceholder: 'HH',
    yearLabel: 'ÉV',
    yearLabelReader:
      'Kérjük, add meg a járműved nyilvántartásba vételének évét!',
    yearPlaceholder: 'ÉÉÉÉ',
    makeLabel: 'Gyártmány',
    makeTooltip: 'Kérjük, adja meg a jármű gyártmányát! (pl. Ford)',
    makePlaceholder: 'Gyártmány',
    makeAriaLabel: 'Kérjük, add meg a jármű gyártmányát!',
    makeTooltipHover: 'Kérjük, adja meg a jármű gyártmányát! (pl. Ford)',
    modelLabel: 'Modell',
    modelTooltip: 'Kérjük adja meg a jármű típusát! (pl. Focus)',
    modelPlaceholder: 'Modell',
    modelAriaLabel: 'Kérjük, add meg a járműved típusát!',
    modelTooltipHover: 'Kérjük, add meg a járműved típusát!',
    seriesLabel: 'Pl. Zetec',
    seriesTooltip:
      'Kérjük, adja meg a jármű altípusát! (pl. Titanium , ST-Line)',
    seriesPlaceholder: 'Sorozat',
    seriesAriaLabel: 'Kérjük, add meg járműved sorozatát!',
    seriesTooltipHover:
      'Kérjük, adja meg a jármű altípusát! (pl. Titanium , ST-Line)',
    engineLabel: 'Pl. Benzin',
    engineTooltip: 'Kérjük, válassza ki a motor típusát!',
    enginePlaceholder: 'Motortípus',
    engineAriaLabel: 'Kérjük, add meg a járműved motortípusát!',
    engineTooltipHover: 'Kérjük, válassza ki a motor típusát!',
    transmissionLabel: '',
    transmissionTooltip:
      'Kérjük, válassza ki a jármű sebességváltójának típusát!',
    transmissionPlaceholder: 'Sebességváltó',
    transmissionAriaLabel:
      'Kérjük, add meg a járműved sebességváltójának típusát!',
    transmissionTooltipHover:
      'Kérjük, válassza ki a jármű sebességváltójának típusát!',
    colorLabel: '',
    colorTooltip: 'Kérjük, válassza ki a jármű színét!',
    colorPlaceholder: 'Szín',
    colorAriaLabel: 'Kérjük, add meg a járműved színét!',
    colorTooltipHover: 'Kérjük, válassza ki a jármű színét!',
    mileagePlaceholder: 'Aktuális futásteljesítmény',
    mileageLabel: 'Aktuális futásteljesítmény',
    mileageTooltip: 'Kérjük, adja meg a jármű aktuális futásteljesítményét! ',
    mileageAriaLabel:
      'Kérjük, add meg a járműved aktuális futásteljesítményét!',
    remainingBalanceLabel: 'Pl. 3000 HUF',
    remainingBalancePlaceholder: 'Fennmaradó hiteltartozás',
    remainingBalanceAriaLabel:
      'Kérjük, add meg a járművedre még fennálló hiteltartozásod értékét!',
    outstandingFinanceLabel: 'Van a járműhöz kapcsolódó hiteltartozása?',
    outstandingFinanceTooltip:
      'Kérjük jelezze, hogy teljesítenie kell-e még a jármű végső kifizetését?',
    outstandingFinanceTooltipHover:
      'Kérjük jelezze, hogy teljesítenie kell-e még a jármű végső kifizetését?',
    outstandingFinanceOptions: ['Igen', 'Nem '],
    outstandingFinanceAriaLabels: [
      'Válaszd ki, ha még van hiteltartozásod a járművedre!',
      'Válaszd ki, ha már nincs hiteltartozásod a járművedre!'
    ],
    additionalInfoLabel:
      'Van-e bármilyen további információ, amit tudnunk kell a járművedről a pontos értékeléshez?',
    additionalInfoTooltip:
      'Kérjük jelezze, ha van még olyan fontos információ, amit tudnunk kell járművéről!',
    additionalInfoTooltipHover:
      'Kérjük jelezze, ha van még olyan fontos információ, amit tudnunk kell járművéről!',
    additionalInfoPlaceholder: 'Egyéb tudnivaló a járműről',
    additionalAriaLabel:
      'Kérjük, adj meg minden további információt, amelyet a járműveddel kapcsolatban tudnunk kell!',
    acceptLabel: 'Elfogadás',
    acceptArialLabel:
      'Kattintson ide a beszámítási értékbecslésre vonatkozó kérelme elküldéséhez',
    selectText: 'Kiválasztás'
  },
  ro_ro: {
    vehRegLabel: 'Data înregistrării vehiculului',
    dateLabel: 'ZZ',
    dateLabelReader: 'Selectați ziua în care a fost înregistrat vehiculul dvs.',
    datePlaceholder: 'ZZ',
    monthLabel: 'LL',
    monthLabelReader:
      'Selectați luna în care a fost înregistrat vehiculul dvs.',
    monthPlaceholder: 'LL',
    yearLabel: 'AAAA',
    yearLabelReader:
      'Vă rugăm să selectați anul în care a fost înregistrat vehiculul dvs.',
    yearPlaceholder: 'AAAA',
    makeLabel: 'Marcă',
    makeTooltip:
      'Vă rugăm să introduceți marca vehiculului dvs. (de exemplu, Ford)',
    makePlaceholder: 'Marcă',
    makeAriaLabel: 'Vă rugăm să introduceți marca vehiculului dvs.',
    makeTooltipHover:
      'Vă rugăm să introduceți marca vehiculului dvs. (de exemplu, Ford)',
    modelLabel: 'Model',
    modelTooltip:
      'Vă rugăm să introduceți modelul vehiculului dvs. (de exemplu, Focus)',
    modelPlaceholder: 'Model',
    modelAriaLabel: 'Vă rugăm să introduceți modelul vehiculului dvs.',
    modelTooltipHover: 'Vă rugăm să introduceți modelul vehiculului dvs.',
    seriesLabel: 'de exemplu, Zetec',
    seriesTooltip:
      'Vă rugăm să introduceți seria vehiculului dvs. (de exemplu, Zetec, ST-Line)',
    seriesPlaceholder: 'Serie',
    seriesAriaLabel: 'Vă rugăm să introduceți seria vehiculului dvs.',
    seriesTooltipHover: 'Vă rugăm să introduceți seria vehiculului dvs.',
    engineLabel: 'de exemplu, benzină',
    engineTooltip:
      'Vă rugăm să introduceți motorul vehiculului dvs. (de exemplu, benzină)',
    enginePlaceholder: 'Motor',
    engineAriaLabel: 'Vă rugăm să introduceți motorul vehiculului dvs.',
    engineTooltipHover:
      'Vă rugăm să introduceți motorul vehiculului dvs. (de exemplu, benzină)',
    transmissionLabel: 'de exemplu, automată',
    transmissionTooltip:
      'Vă rugăm să introduceți transmisia vehiculului dvs. (de exemplu, automată)',
    transmissionPlaceholder: 'Transmisie',
    transmissionAriaLabel:
      'Vă rugăm să introduceți transmisia vehiculului dvs.',
    transmissionTooltipHover:
      'Vă rugăm să introduceți transmisia vehiculului dvs. (de exemplu, automată)',
    colorLabel: 'de exemplu, negru',
    colorTooltip:
      'Vă rugăm să introduceți culoarea vehiculului dvs. (de exemplu, negru)',
    colorPlaceholder: 'Culoare',
    colorAriaLabel: 'Vă rugăm să introduceți culoarea vehiculului dvs.',
    colorTooltipHover:
      'Vă rugăm să introduceți culoarea vehiculului dvs. (de exemplu, negru)',
    mileagePlaceholder: 'Kilometraj actual',
    mileageLabel: 'Kilometraj actual',
    mileageTooltip:
      'Vă rugăm să introduceți kilometrajul curent al vehiculului dvs.',
    mileageAriaLabel:
      'Vă rugăm să introduceți kilometrajul curent al vehiculului dvs.',
    remainingBalanceLabel: 'de exemplu 3.000 RON',
    remainingBalancePlaceholder: 'Sold rămas',
    remainingBalanceAriaLabel:
      'Vă rugăm să introduceți suma pe care încă o datorați pentru vehiculul dvs.',
    outstandingFinanceLabel:
      'Încă mai datorați vreo plată pentru vehiculul dvs.?',
    outstandingFinanceTooltip:
      'Vă rugăm să indicați dacă mai trebuie să efectuați plăți finale pentru vehiculul dvs.',
    outstandingFinanceTooltipHover:
      'Vă rugăm să indicați dacă mai trebuie să efectuați plăți finale pentru vehiculul dvs.',
    outstandingFinanceOptions: ['Da', 'Nu'],
    outstandingFinanceAriaLabels: [
      'Selectați dacă datorați în continuare o plată pentru vehiculul dvs.',
      'Selectați dacă nu mai datorați plăți pentru vehiculul dvs.'
    ],
    additionalInfoLabel:
      'Există informații suplimentare pe care trebuie să le știm despre vehiculul dvs. pentru a oferi o evaluare exactă?',
    additionalInfoTooltip:
      'Vă rugăm să ne comunicați dacă mai este ceva ce trebuie să știm despre vehiculul dvs.',
    additionalInfoTooltipHover:
      'Vă rugăm să ne comunicați dacă mai este ceva ce trebuie să știm despre vehiculul dvs.',
    additionalInfoPlaceholder: 'Tastați aici',
    additionalAriaLabel:
      'Vă rugăm să introduceți informații suplimentare pe care ar trebui să le știm despre vehiculul dvs.',
    acceptLabel: 'Acceptare',
    acceptArialLabel:
      'Fă clic aici pentru a trimite solicitarea ta de evaluare a vehiculului pentru schimb',
    selectText: 'Alege'
  },
  es_es: {
    vehRegLabel: 'Fecha de matriculación del vehículo',
    dateLabel: 'DD',
    dateLabelReader: 'Selecciona el día de matriculación del vehículo',
    datePlaceholder: 'DD',
    monthLabel: 'MM',
    monthLabelReader: 'Selecciona el mes de matriculación del vehículo',
    monthPlaceholder: 'MM',
    yearLabel: 'YYYY',
    yearLabelReader: 'Selecciona el año de matriculación del vehículo',
    yearPlaceholder: 'YYYY',
    makeLabel: 'Fabricante',
    makePlaceholder: 'Fabricante',
    makeAriaLabel: 'Introduce el fabricante de tu vehículo',
    modelLabel: 'Modelo',
    modelPlaceholder: 'Modelo',
    modelAriaLabel: 'Introduce el modelo de tu vehículo',
    engineLabel: 'Tipo de motor',
    engineAriaLabel: 'Introduce el tipo de motor de tu vehículo',
    engineTooltip:
      'Introduce el tipo de motor de tu vehículo (p. ej., gasolina)',

    mileagePlaceholder: 'Kilometraje actual',
    mileageLabel: 'Kilometraje actual',
    mileageAriaLabel: 'Introduce el kilometraje actual de tu vehículo',
    acceptLabel: 'Aceptar',
    acceptArialLabel:
      'Haz clic aquí para enviar una solicitud de valoración de tu vehículo usado',
    selectText: '​​​​​​​Seleccionar'
  },
  it_it: {
    vehRegLabel: 'Data di immatricolazione',
    dateLabel: 'GG',
    dateLabelReader: 'Seleziona il giorno di immatricolazione del veicolo',
    datePlaceholder: 'GG',
    monthLabel: 'MM',
    monthLabelReader: 'Seleziona il mese di immatricolazione del veicolo',
    monthPlaceholder: 'MM',
    yearLabel: 'AAAA',
    yearLabelReader: "Seleziona l'anno di immatricolazione del veicolo",
    yearPlaceholder: 'AAAA',
    makeLabel: 'Marca',
    makePlaceholder: 'Marca',
    makeAriaLabel: 'Inserisci la marca del tuo veicolo',

    modelLabel: 'Modello',
    modelPlaceholder: 'Modello',
    modelAriaLabel: 'Inserisci il modello del tuo veicolo',
    engineLabel: 'Motore',
    engineAriaLabel: 'Inserisci il motore del tuo veicolo',
    engineTooltip: 'Inserisci il motore del tuo veicolo (ad es. Benzina)',
    mileagePlaceholder: 'Chilometraggio attuale',
    mileageLabel: 'Chilometraggio attuale',
    mileageAriaLabel: 'Inserisci il chilometraggio attuale del tuo veicolo',
    acceptLabel: 'Accetta',
    acceptArialLabel:
      'Fare clic qui per inviare la richiesta di valutazione di permuta',
    selectText: 'Seleziona'
  },
  fr_fr: {
    vehRegLabel: "Date d'immatriculation du véhicule",
    dateLabel: 'JJ',
    dateLabelReader:
      "Veuillez sélectionner le jour d'immatriculation de votre véhicule",
    datePlaceholder: 'JJ',
    monthLabel: 'MM',
    monthLabelReader:
      "Veuillez sélectionner le mois d'immatriculation de votre véhicule",
    monthPlaceholder: 'MM',
    yearLabel: 'AAAA',
    yearLabelReader:
      "Veuillez sélectionner l'année d'immatriculation de votre véhicule",
    yearPlaceholder: 'AAAA',

    makeLabel: 'Marque',
    makePlaceholder: 'Marque',
    makeTooltipHover: 'Veuillez saisir la marque de votre véhicule',
    modelLabel: 'Modèle',
    modelPlaceholder: 'Modèle',
    modelAriaLabel: 'Veuillez saisir le modèle de votre véhicule',

    engineLabel: 'Moteur',
    engineAriaLabel: 'Veuillez saisir le type de moteur de votre véhicule',
    engineTooltip:
      'Veuillez saisir le type de moteur de votre véhicule (ex. : Essence)',

    mileagePlaceholder: 'Kilométrage actuel',
    mileageLabel: 'Kilométrage actuel',
    mileageAriaLabel: 'Veuillez saisir le kilométrage de votre véhicule',

    acceptLabel: 'Accepter',
    acceptArialLabel:
      "Cliquez ici pour envoyer votre demande d'évaluation de reprise.",
    selectText: 'Sélectionner'
  }
}

// vehicle condition

export const conditions = (lang, condition) => (
  <div className='radio-text-list'>
    <p>{conditionsCopy[lang][condition].title}</p>
    <p>{conditionsCopy[lang][condition].text}</p>
  </div>
)
