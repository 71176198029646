import { createGlobalStyle } from 'styled-components'
import LincolnProximaNovaRegOtf from '../../assets/fonts/Lincoln/Lincoln-Proxima-Nova/Lincoln-ProximaNova-Reg.otf'
import LincolnProximaNovaSemiboldOtf from '../../assets/fonts/Lincoln/Lincoln-Proxima-Nova-Semibold/Lincoln-ProximaNova-Sbold.otf'

import FordMotionReg from '../../assets/fonts/Ford/FordMotion/FordMotion-Regular.ttf'
import FordMotionBold from '../../assets/fonts/Ford/FordMotion/FordMotion-Bold.ttf'
import FordMotionMed from '../../assets/fonts/Ford/FordMotion/FordMotion-Medium.ttf'

import FordF1Reg from '../../assets/fonts/Ford/FordF1/FordF-1-Regular.otf'
import FordF1Bold from '../../assets/fonts/Ford/FordF1/FordF-1-Bold.otf'
import FordF1Med from '../../assets/fonts/Ford/FordF1/FordF-1-Medium.otf'
import FordF1Light from '../../assets/fonts/Ford/FordF1/FordF-1-Light.otf'
import FordF1SemiBold from '../../assets/fonts/Ford/FordF1/FordF-1-Semibold.otf'

const GlobalFont = createGlobalStyle`
    /* FordMotion */
    @font-face {
        font-family: "FordMotion";
        src: url(${FordMotionReg}) format('truetype');
        font-weight: normal;
    }
    @font-face {
        font-family: "FordMotion";
        src: url(${FordMotionMed}) format('truetype');
        font-weight: 500;
    }
     @font-face {
        font-family: "FordMotion";
        src: url(${FordMotionBold}) format('truetype');
        font-weight: 700;
    }
 

    /* Ford F1 */
   @font-face {
       font-family: "FordF1";
        src: url(${FordF1Reg}) format('opentype');
        font-weight: normal;
    }

     @font-face {
       font-family: "FordF1";
        src: url(${FordF1Bold}) format('opentype');
        font-weight: bold;
    }
     @font-face {
       font-family: "FordF1";
        src: url(${FordF1Light}) format('opentype');
        font-weight: lighter;
    }
    @font-face {
       font-family: "FordF1";
        src: url(${FordF1Med}) format('opentype');
        font-weight: medium;
    }

     @font-face {
       font-family: "FordF1";
        src: url(${FordF1SemiBold}) format('opentype');
        font-weight: 500;
    }

    

    @font-face {
        font-family: "LincolnProximaNova";
        src: url(${LincolnProximaNovaRegOtf}});
        src: url(${LincolnProximaNovaRegOtf}) format("opentype")
    }
     @font-face {
        font-family: "LincolnProximaNova";
        src: url(${LincolnProximaNovaSemiboldOtf}});
        src: url(${LincolnProximaNovaSemiboldOtf}) format("opentype");
        font-weight: 600;
    }
`
export default GlobalFont
