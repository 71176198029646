import React, { useEffect, useContext, useState, useReducer } from 'react'
import { navigate } from 'hookrouter'
import { AppContext, AppACTIONS } from '../../common/context/AppContext'
import { DataContext, DataACTIONS } from '../../common/context/DataContext'
import focusError from '../../../utils/focusError'
import SelectInput from '../../common/pageElements/SelectInput'
import { useInput } from '../../common/customhooks/forms'
import TradeinButton from '../../common/pageElements/TradeinButton'

import StepHeader from '../../common/pageElements/StepHeader'
import { StepHeaders, vehicleBasicsCopy } from './copy/VehicleBasicsCopy'

import api from '../../../utils/api'

import ScrollToTop from '../../common/pageElements/ScrollToTop'
import TradeinLink from '../../common/pageElements/TradeinLink'
import { dayRange, monthRange } from '../../../constants/forms'

import { StyledVehicleBasicsFR } from '../../styled-components/screens/StyledVehicleBasics'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'

const StyledVehicleBasicsESIT = styled(StyledVehicleBasicsFR)`
  .tradein-buttons-container {
    @media ${mediaQueries.overS} {
      margin-top: 100px !important;
    }
  }
  .tradein-date-inputs-container.reg {
    width: 91%;
    margin-bottom: 40px;
    .tradein-selectInput-wrap {
      margin-top: 0;
    }
    @media ${mediaQueries.underS} {
      flex-wrap: wrap;
      .tradein-selectInput-wrap {
        flex: unset;
      }
    }
  }
`

const VehicleBasicsESIT = () => {
  const { appDispatch, language, market, application } = useContext(AppContext)
  const { dataState, dataDispatch } = useContext(DataContext)
  const {
    year,
    month,
    fuel,
    body,
    gear,
    doors,
    euSavedState,
    euSavedIncorrectState,
    registrationDate,
    engine,
    trim,
    appraisalId,
    euAllSelectDataList
  } = dataState

  const [allSelectData, setSelectDataState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      month: '',
      fuel: '',
      body: '',
      engine: '',
      gear: '',
      door: '',
      trim: ''
    }
  )

  const [incorrectRelDate, setIncorrectRelDate] = useState(
    euSavedIncorrectState
  )

  const marketLowerCase = market.toLowerCase()

  const makeId = dataState[marketLowerCase + 'LookupObj'].makeId
  const modelId = dataState[marketLowerCase + 'LookupObj'].modelId

  const [currentMthYr, setCurrentMthYr] = useState({})
  const [currentRegDay, setCurrentRegDay] = useState(null)

  const [yearRange, setYearRange] = useState(monthRange)
  const [newDateRange, setNewDateRange] = useState(dayRange)
  const [disableDate, setDisableDate] = useState(
    !dataState.registrationDate || false
  )

  const marketObj = {
    header: StepHeaders[language],
    copy: vehicleBasicsCopy[language]
  }

  useEffect(() => {
    getYears()
    dataDispatch({
      type: 'SHOW_LOADING_SPINNER',
      payload: true
    })

    if (!incorrectRelDate) {
      updateEngine()
    } else {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: false
      })
    }
  }, [])

  const initialRelMthLabel = dataState.releaseDate
    ? dataState.releaseDate.substring(5, 7)
    : month

  let initialRelMth = dataState.releaseDate
    ? dataState.releaseDate.substring(5, 7)
    : ''
  if (initialRelMth !== '') {
    if (month.toString().length > 1) {
      initialRelMth = month
    } else {
      initialRelMth = '0' + month
    }
  }

  const initialRelYear = dataState.releaseDate
    ? dataState.releaseDate.substring(0, 4)
    : year

  const initialRegMonth = registrationDate
    ? registrationDate.substring(5, 7)
    : ''
  const initialRegDate = registrationDate
    ? registrationDate.substring(8, 10)
    : ''
  const initialRegYear = registrationDate
    ? registrationDate.substring(0, 4)
    : ''

  const relMonth = useInput({
    initialValue: euSavedIncorrectState ? initialRelMth : '',
    placeholder: initialRelMth || marketObj.copy.releaseDate.month.placeholder,
    selectId: 'relMonth',
    label: marketObj.copy.releaseDate.month.text,
    labelReader: marketObj.copy.releaseDate.month.ariaLabel,
    errorMessage: marketObj.copy.releaseDate.month.errorMessage
  })

  const relYear = useInput({
    initialValue: euSavedIncorrectState ? initialRelYear : '',
    placeholder: initialRelYear || marketObj.copy.releaseDate.year.placeholder,
    selectId: 'relYear',
    label: marketObj.copy.releaseDate.year.text,
    labelReader: marketObj.copy.releaseDate.year.ariaLabel,
    errorMessage: marketObj.copy.releaseDate.year.errorMessage
  })

  const regMonth = useInput({
    initialValue: euSavedState ? initialRegMonth : '',
    selectId: 'regMonth',
    placeholder:
      initialRegMonth || marketObj.copy.registrationDate.month.placeholder,
    label: marketObj.copy.registrationDate.month.text,
    labelReader: marketObj.copy.registrationDate.month.ariaLabel,
    errorMessage: marketObj.copy.registrationDate.month.errorMessage
  })

  const regDay = useInput({
    initialValue: euSavedState ? initialRegDate : '',
    selectId: 'regDay',
    placeholder:
      initialRegDate || marketObj.copy.registrationDate.date.placeholder,
    label: marketObj.copy.registrationDate.date.text,
    labelReader: marketObj.copy.registrationDate.date.ariaLabel,
    errorMessage: marketObj.copy.registrationDate.date.errorMessage
  })

  const regYear = useInput({
    initialValue: euSavedState ? initialRegYear : '',
    selectId: 'regYear',
    placeholder:
      initialRegYear || marketObj.copy.registrationDate.year.placeholder,
    label: marketObj.copy.registrationDate.year.text,
    labelReader: marketObj.copy.registrationDate.year.ariaLabel,
    errorMessage: marketObj.copy.registrationDate.year.errorMessage
  })

  const engineSelect = useInput({
    initialValue: euSavedState ? engine : '',
    placeholder: marketObj.copy.engineInputCopy.placeholder,
    label: marketObj.copy.engineInputCopy.label,
    labelReader: marketObj.copy.engineInputCopy.ariaLabel,
    errorMessage: marketObj.copy.engineInputCopy.errorMessage
  })

  const trimSelect = useInput({
    initialValue: euSavedState ? trim : '',
    label: marketObj.copy.trimInputCopy.label,
    placeholder: marketObj.copy.trimInputCopy.placeholder,
    labelReader: marketObj.copy.trimInputCopy.ariaLabel,
    errorMessage: marketObj.copy.trimInputCopy.errorMessage
  })

  const fuelSelect = useInput({
    initialValue: euSavedIncorrectState ? fuel : '',
    placeholder: marketObj.copy.fuelInputCopy.placeholder,
    label: marketObj.copy.fuelInputCopy.label,
    labelReader: marketObj.copy.fuelInputCopy.ariaLabel,
    errorMessage: marketObj.copy.fuelInputCopy.errorMessage
  })

  const bodySelect = useInput({
    initialValue: euSavedIncorrectState ? body : '',
    placeholder: marketObj.copy.bodyInputCopy.placeholder,
    label: marketObj.copy.bodyInputCopy.label,
    labelReader: marketObj.copy.bodyInputCopy.ariaLabel,
    errorMessage: marketObj.copy.bodyInputCopy.errorMessage
  })

  const gearSelect = useInput({
    initialValue: euSavedIncorrectState ? gear : '',
    placeholder: marketObj.copy.gearInputCopy.placeholder,
    label: marketObj.copy.gearInputCopy.label,
    labelReader: marketObj.copy.gearInputCopy.ariaLabel,
    errorMessage: marketObj.copy.gearInputCopy.errorMessage
  })

  const doorSelect = useInput({
    initialValue: euSavedIncorrectState ? { value: doors, label: doors } : '',
    placeholder: marketObj.copy.doorInputCopy.placeholder,
    label: marketObj.copy.doorInputCopy.label,
    labelReader: marketObj.copy.doorInputCopy.ariaLabel,
    errorMessage: marketObj.copy.doorInputCopy.errorMessage
  })

  function getYears() {
    const currentYear = new Date().getFullYear()
    const currentYearRange = []

    // 30 = max years we accept
    for (let i = currentYear - 30; i <= currentYear; i++) {
      currentYearRange.push({ value: i, label: i })
    }
    setYearRange(currentYearRange.reverse())
  }

  const trimExtraFields = {
    gearName: dataState[marketLowerCase + 'LookupObj'].gearName,
    bodyName: dataState[marketLowerCase + 'LookupObj'].bodyName,
    fuelName: dataState[marketLowerCase + 'LookupObj'].fuelName,
    appraisalId: dataState[marketLowerCase + 'LookupObj'].appraisalId
  }

  function handleIncorrectDate() {
    api[marketLowerCase + 'Year']({ makeId: makeId, modelId: modelId })
      .then((data) => {
        const vehicleYear = data.map((el) => {
          // NOTE: for API we probably need to send the code back, not the name.
          // This is just in case a code doesn't match a name. So value = code.
          // console.log(data)
          const name = el.name
          return { value: el.id, label: name }
        })
        setSelectDataState({ year: vehicleYear })

        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
    setIncorrectRelDate(true)
  }

  const initialSelectState = {
    year: year,
    month: euSavedState ? initialRelMth : '',
    makeId: makeId,
    modelId: modelId,
    fuel: euSavedState ? fuel.label : '',
    fuelId: euSavedState ? fuel.value : '',
    body: euSavedState ? body.label : '',
    bodyId: euSavedState ? body.value : '',
    engine: euSavedState ? engine.label : '',
    engineId: euSavedState ? engine.value : '',
    gear: euSavedState ? gear.label : '',
    gearId: euSavedState ? gear.value : '',
    doors: euSavedState ? doors : '',
    trim: euSavedState ? trim.label : '',
    trimId: euSavedState ? trim.value : ''
  }

  // the values of select we want to reset in order
  const selectArr = [
    'year',
    'month',
    'fuelId',
    'bodyId',
    'gearId',
    'doors',
    'trimId',
    'engineId'
  ]

  function selectReducer(state, action) {
    const { type, value, label, updateType } = action

    let updateTypeId = updateType
    if (
      updateType !== 'doors' &&
      updateType !== 'year' &&
      updateType !== 'month'
    ) {
      updateTypeId = updateType + 'Id'
    } else {
      updateTypeId = updateType
    }
    let resetObj = ''

    if (type === 'reset') {
      // take the index of the item in the array
      const arrIndex = selectArr.indexOf(updateTypeId)
      // start the loop from the index and reset the next ones
      for (let i = arrIndex + 1; i < selectArr.length; i++) {
        resetObj = state[selectArr[i]] = ''
      }
    }

    switch (type) {
      case 'updateSelect':
        return { ...state, [updateType]: label, [updateTypeId]: value }
      case 'reset':
        return {
          ...state,
          [updateType]: label,
          [updateType + 'Id']: value,
          resetObj
        }
      default:
        throw new Error()
    }
  }

  const [selectState, selectDispatch] = useReducer(
    selectReducer,
    initialSelectState
  )

  function updateEngine() {
    // const { makeId, modelId, year, month, fuelId, bodyId, gearId, doors } =
    //   selectState
    const enginePayLoad = {
      makeId,
      modelId,
      year,
      month,
      fuelId: fuel.id,
      bodyId: body.id,
      gearId: gear.id,
      doors
    }
    const handleEngine = function (data) {
      const vehicleEngine = data.map((el) => {
        // remove null values
        if (el.id && el.name) {
          return { value: el.id, label: el.name }
        }
        return null
      })
      setSelectDataState({
        engine: vehicleEngine.filter((x) => x !== undefined)
      })
    }
    handleApiCall(marketLowerCase + 'Engine', enginePayLoad, handleEngine)
  }

  const selectHandler = (type) => (e) => {
    const apiField = type === 'month' ? e.label : e.value
    const displayLabel = e.label

    selectDispatch({
      type: 'updateSelect',
      updateType: type,
      label: displayLabel,
      value: apiField
    })

    if (type !== 'trim') {
      dataDispatch({
        type: 'SHOW_LOADING_SPINNER',
        payload: true
      })
    }

    let apiPayload = selectState
    let apiCall

    const { fuelName, gearName, bodyName, appraisalId } = trimExtraFields

    switch (type) {
      case 'year':
        apiPayload = { ...apiPayload, year: apiField }
        apiCall = 'Month'
        if (selectState.month || selectState.trimId) {
          // relMonth.value.value = null
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'month':
        apiPayload = { ...apiPayload, month: apiField }
        apiCall = 'Fuel'
        if (selectState.fuelId || selectState.trimId) {
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'fuel':
        apiPayload = { ...apiPayload, fuelId: apiField }
        apiCall = 'Body'
        if (selectState.bodyId || selectState.trimId) {
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'body':
        apiPayload = { ...apiPayload, bodyId: apiField }
        apiCall = 'Gear'
        if (selectState.gearId || selectState.trimId) {
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'gear':
        apiPayload = { ...apiPayload, gearId: apiField }
        apiCall = 'Doors'
        if (selectState.doors || selectState.trimId) {
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'doors':
        apiPayload = { ...apiPayload, doors: apiField }
        apiCall = 'Engine'
        if (selectState.engineId || selectState.trimId) {
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }
        break
      case 'engine':
        if (!incorrectRelDate) {
          apiPayload = {
            fuelId: fuel.id,
            bodyId: body.id,
            gearId: gear.id,
            month,
            year,
            doors,
            engineId: apiField,
            fuelName,
            gearName,
            bodyName,
            appraisalId
          }
        } else {
          apiPayload = {
            ...apiPayload,
            engineId: apiField,
            fuelName,
            gearName,
            bodyName,
            appraisalId
          }
        }
        if (selectState.trimId) {
          selectDispatch({
            type: 'reset',
            updateType: type,
            label: displayLabel,
            value: apiField
          })
        }

        apiCall = 'Trim'
        break
      case 'trim':
        break
      default:
        break
    }
    const handleFn = function (data) {
      const selectData = data.map((el, i) => {
        if (apiCall === 'Body') {
          trimExtraFields.bodyName = el.name
        } else if (apiCall === 'Gear') {
          trimExtraFields.gearName = el.name
        } else if (apiCall === 'Fuel') {
          trimExtraFields.fuelName = el.name
        }
        if (apiCall === 'Month') {
          const monthMM = el.id.toString().length > 1 ? el.id : '0' + el.id
          return { value: el.name, label: monthMM }
        } else if (apiCall === 'Trim') {
          return { value: i + 1, label: el }
        } else {
          return { value: el.id, label: el.name }
        }
      })
      setSelectDataState({
        [apiCall.toLowerCase()]: selectData
      })
    }

    if (type !== 'trim') {
      handleApiCall(marketLowerCase + apiCall, apiPayload, handleFn)
    }
  }

  function handleSubmit() {
    // .value for initialValue, .value.value for value from the list
    const relMonthValid = relMonth.value.value || relMonth.value
    const relYearValid = relYear.value.value || relYear.value
    const fuelValid = fuelSelect.value.value
    const bodyValid = bodySelect.value.value
    const gearValid = gearSelect.value.value
    const doorsValid = doorSelect.value.value
    const engineValid = engineSelect.value.value
    const trimValid = trimSelect.value.value
    const regMonthValid = regMonth.value.value || initialRegMonth
    const regDateValid = regDay.value.value || initialRegDate
    const regYearValid = regYear.value.value || initialRegYear

    const pageValid = incorrectRelDate
      ? relMonthValid &&
        relYearValid &&
        engineValid &&
        fuelValid &&
        bodyValid &&
        gearValid &&
        doorsValid &&
        trimValid &&
        regMonthValid &&
        regDateValid &&
        regYearValid
      : engineValid &&
        trimValid &&
        regMonthValid &&
        regDateValid &&
        regYearValid

    if (pageValid) {
      const registrationDate = createDateString(
        regYearValid,
        regMonthValid,
        regDateValid
      )
      const releaseDate = createDateString(relYearValid, relMonthValid)

      if (incorrectRelDate) {
        saveValues(
          registrationDate,
          trimSelect.value,
          engineSelect.value,
          releaseDate,
          fuelSelect.value,
          bodySelect.value,
          gearSelect.value,
          doorsValid
        )
      } else {
        saveValues(registrationDate, trimSelect.value, engineSelect.value)
      }

      if (application !== 'SHOWROOM') {
        appDispatch({
          type: AppACTIONS.jumpToStepUpdated,
          payload: 'vehiclecondition_EU'
        })
        navigate('/vehiclecondition_EU')
      } else {
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: true
        })
        api
          .getAppraisalEst({
            appraisalId: appraisalId,
            trim: trimSelect.value.label
          })
          .then((data) => {
            dataDispatch({
              type: DataACTIONS.currentAppraisalQuoteUpdated,
              payload: data.appraisalEstimate
            })
            appDispatch({
              type: AppACTIONS.jumpToStepUpdated,
              payload: 'tradeinappraisal_EU'
            })
            navigate('/tradeinappraisal_EU')
            dataDispatch({
              type: 'SHOW_LOADING_SPINNER',
              payload: false
            })
          })
          .catch((error) => {
            console.log(error)
            dataDispatch({
              type: 'SHOW_LOADING_SPINNER',
              payload: false
            })
          })
      }
    } else {
      let errorArray = ''
      if (incorrectRelDate) {
        errorArray = [
          relYear,
          relMonth,
          fuelSelect,
          bodySelect,
          gearSelect,
          doorSelect,
          engineSelect,
          trimSelect,
          regYear,
          regMonth,
          regDay
        ]
      } else {
        errorArray = [engineSelect, trimSelect, regYear, regMonth, regDay]
      }
      focusError(errorArray)
    }
  }

  function saveValues(
    registrationDate,
    trimObj,
    engineObj,
    releaseDate,
    fuelObj,
    bodyObj,
    gearObj,
    doors
  ) {
    if (arguments.length > 3) {
      dataDispatch({
        type: DataACTIONS.registrationDateUpdated,
        payload: registrationDate
      })
      dataDispatch({
        type: DataACTIONS.releaseDateUpdated,
        payload: releaseDate
      })
      dataDispatch({
        type: DataACTIONS.fuelUpdated,
        payload: fuelObj
      })
      dataDispatch({
        type: DataACTIONS.bodyUpdated,
        payload: bodyObj
      })
      dataDispatch({
        type: DataACTIONS.gearUpdated,
        payload: gearObj
      })
      dataDispatch({
        type: DataACTIONS.doorsUpdated,
        payload: doors
      })
      dataDispatch({
        type: DataACTIONS.engineUpdated,
        payload: engineObj
      })
      dataDispatch({
        type: DataACTIONS.trimUpdated,
        payload: trimObj
      })
      dataDispatch({
        type: DataACTIONS.euSavedIncorrectStateUpdated,
        payload: true
      })
    } else {
      dataDispatch({
        type: DataACTIONS.registrationDateUpdated,
        payload: registrationDate
      })
      dataDispatch({
        type: DataACTIONS.engineUpdated,
        payload: engineObj
      })
      dataDispatch({
        type: DataACTIONS.trimUpdated,
        payload: trimObj
      })
    }
    // save all the current select data to display in the saved state
    dataDispatch({
      type: DataACTIONS.euAllSelectDataListUpdated,
      payload: allSelectData
    })
    dataDispatch({
      type: DataACTIONS.euSavedStateUpdated,
      payload: true
    })
  }

  function createDateString(year, month, day) {
    let dates = ''
    if (arguments.length > 2) {
      dates = [year, month, day]
    } else {
      dates = [year, month]
    }
    return dates.join('-')
  }

  const handleDateChange = (e) => {
    setCurrentRegDay(e.value)
  }

  const updateDayRange = (type) => (e) => {
    const handleDayUpdated = (year, month) => {
      const numberOfDays = new Date(year, month, 0).getDate()
      const currentDate = regDay.value.value
      const daysObj = []
      for (let i = 0; i < numberOfDays; i++) {
        const days = i + 1
        const v = days < 10 ? '0' + days : days
        daysObj.push({ value: v, label: v })
      }
      if (currentDate > numberOfDays) {
        // reset regDay's values
        setCurrentRegDay(null)
        regDay.value.value = null
        regDay.valid = false

        setNewDateRange(daysObj)
      } else {
        setDisableDate(false)
        setNewDateRange(daysObj)
      }
    }
    if (type === 'year') {
      setCurrentMthYr({
        ...currentMthYr,
        year: e.value
      })
      if (currentMthYr.month) {
        handleDayUpdated(e.value, currentMthYr.month)
      }
    } else {
      setCurrentMthYr({
        ...currentMthYr,
        month: e.value
      })
      if (currentMthYr.year) {
        handleDayUpdated(currentMthYr.year, e.value)
      }
    }
  }

  function handleApiCall(apiRoute, payload, handleResultsFn) {
    let apiPayload = payload
    if (incorrectRelDate) {
      apiPayload = {
        ...payload,
        makeId: initialSelectState.makeId,
        modelId: initialSelectState.modelId
      }
    }
    api[apiRoute](apiPayload)
      .then((data) => {
        handleResultsFn(data)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
      .catch((error) => {
        console.log(error)
        dataDispatch({
          type: 'SHOW_LOADING_SPINNER',
          payload: false
        })
      })
  }

  return (
    <StyledVehicleBasicsESIT>
      <ScrollToTop />
      <div className='tradein-vb-column'>
        <StepHeader
          mainHead={marketObj.header.main}
          subHead={marketObj.header.sub}
          maxWidth='420px'
        />

        <div className='tradein-inputs-container'>
          <label>{marketObj.copy.releaseDate.text}</label>

          {incorrectRelDate ? (
            <div>
              <div className='tradein-date-inputs-container'>
                <SelectInput
                  customOnSelect={selectHandler('year')}
                  {...relYear}
                  list={euAllSelectDataList.year || allSelectData.year}
                />
                <SelectInput
                  customOnSelect={selectHandler('month')}
                  {...relMonth}
                  list={euAllSelectDataList.month || allSelectData.month}
                  isDisabled={!euSavedIncorrectState && !selectState.year}
                  value={
                    selectState.month
                      ? {
                          value: selectState.month,
                          label: selectState.month
                        }
                      : {
                          value: null,
                          label:
                            marketObj.copy.registrationDate.month.placeholder
                        }
                  }
                  valid={selectState.month || false}
                />
              </div>
              <SelectInput
                {...fuelSelect}
                customOnSelect={selectHandler('fuel')}
                list={euAllSelectDataList.fuel || allSelectData.fuel}
                isDisabled={!euSavedIncorrectState && !selectState.month}
                value={
                  selectState.fuelId
                    ? {
                        value: selectState.fuelId,
                        label: selectState.fuel
                      }
                    : {
                        value: null,
                        label: marketObj.copy.fuelInputCopy.placeholder
                      }
                }
                valid={selectState.fuelId || false}
              />
              <SelectInput
                {...bodySelect}
                customOnSelect={selectHandler('body')}
                isDisabled={!euSavedIncorrectState && !selectState.fuelId}
                list={euAllSelectDataList.body || allSelectData.body}
                value={
                  selectState.bodyId
                    ? {
                        value: selectState.bodyId,
                        label: selectState.body
                      }
                    : {
                        value: null,
                        label: marketObj.copy.bodyInputCopy.placeholder
                      }
                }
                valid={selectState.bodyId || false}
              />
              <SelectInput
                {...gearSelect}
                customOnSelect={selectHandler('gear')}
                isDisabled={!euSavedIncorrectState && !selectState.bodyId}
                list={euAllSelectDataList.gear || allSelectData.gear}
                value={
                  selectState.gearId
                    ? {
                        value: selectState.gearId,
                        label: selectState.gear
                      }
                    : {
                        value: null,
                        label: marketObj.copy.gearInputCopy.placeholder
                      }
                }
                valid={selectState.gearId || false}
              />
              <SelectInput
                {...doorSelect}
                customOnSelect={selectHandler('doors')}
                isDisabled={!euSavedIncorrectState && !selectState.gearId}
                list={euAllSelectDataList.doors || allSelectData.doors}
                value={
                  selectState.doors
                    ? {
                        value: selectState.doors,
                        label: selectState.doors
                      }
                    : {
                        value: null,
                        label: marketObj.copy.doorInputCopy.placeholder
                      }
                }
                valid={selectState.doors || false}
              />
            </div>
          ) : (
            <div>
              <div className='tradein-initial-date'>
                <label>AAAA</label>
              </div>
              <div className='tradein-initial-date'>
                <span>{initialRelYear}</span>
              </div>
              <div className='tradein-initial-date'>
                <label>MM</label>
              </div>
              <div className='tradein-initial-date'>
                <span>{initialRelMthLabel}</span>
              </div>

              <TradeinLink
                type='primary'
                action={handleIncorrectDate}
                text={marketObj.copy.incorrectDate.text}
                ariaLabel={marketObj.copy.incorrectDate.ariaLabel}
              />
            </div>
          )}
          <SelectInput
            {...engineSelect}
            isDisabled={incorrectRelDate && !selectState.doors && !euSavedState}
            customOnSelect={selectHandler('engine')}
            value={
              selectState.engineId
                ? {
                    value: selectState.engineId,
                    label: selectState.engine
                  }
                : {
                    value: null,
                    label:
                      initialRegMonth ||
                      marketObj.copy.engineInputCopy.placeholder
                  }
            }
            valid={selectState.engineId || false}
            list={allSelectData.engine}
          />
          <SelectInput
            {...trimSelect}
            list={euAllSelectDataList.trim || allSelectData.trim}
            isDisabled={!euSavedState && !selectState.engineId}
            customOnSelect={selectHandler('trim')}
            value={
              selectState.trimId
                ? {
                    value: selectState.trimId,
                    label: selectState.trim
                  }
                : {
                    value: null,
                    label:
                      initialRegMonth ||
                      marketObj.copy.trimInputCopy.placeholder
                  }
            }
            valid={selectState.trimId || false}
          />
          <label>{marketObj.copy.registrationDate.text}</label>
          <div className='tradein-date-inputs-container reg'>
            <SelectInput
              {...regYear}
              customOnSelect={updateDayRange('year')}
              list={yearRange}
            />
            <SelectInput
              {...regMonth}
              customOnSelect={updateDayRange('month')}
              list={monthRange}
            />
            <SelectInput
              {...regDay}
              isDisabled={disableDate}
              list={newDateRange}
              value={
                currentRegDay
                  ? { value: currentRegDay, label: currentRegDay }
                  : null
              }
              valid={currentRegDay ? !currentRegDay.value : false}
              customOnSelect={handleDateChange}
            />
          </div>
        </div>
        <div className='tradein-buttons-container'>
          <TradeinButton
            ariaLabel={marketObj.copy.backButton.ariaLabel}
            type='back'
            text={marketObj.copy.backButton.text}
            target='vehiclefound_EU'
          />
          <TradeinButton
            ariaLabel={
              application !== 'SHOWROOM'
                ? marketObj.copy.forwardButton.ariaLabel
                : marketObj.copy.submitButton.ariaLabel
            }
            type='primary'
            text={
              application !== 'SHOWROOM'
                ? marketObj.copy.forwardButton.text
                : marketObj.copy.submitButton.text
            }
            iconType='nextIcon'
            action={handleSubmit}
          />
        </div>
      </div>
    </StyledVehicleBasicsESIT>
  )
}

export default VehicleBasicsESIT
