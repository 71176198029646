import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { mediaQueries } from '../../styled-components/mediaQueries'
import { currencyUSD } from '../../../utils/filters'
import Image from '../../common/icons/Image'
import { AppContext } from '../context/AppContext'
import {
  ownershipPageDisclaimer,
  EstimatedFlowPopupTip
} from 'components/custom/US/Copy'
import PopUpTip from './PopUpTip'

const StyledRadioButtonCard = styled.div`
  .tradein-radio-button-card-wrapper {
    border-radius: 8px;
    border: solid 1px var(--borderDarkGrey);
    background-color: white;
    font-family: var(--fontPrimary);

    .tradein-radio-button-card-content {
      padding: 16px;

      span.expired {
        color: #d62d0a;
      }
      span.validDays {
        font-size: 16px;
        color: #8c8c8c;
      }

      .tradein-radio-button-card-row {
        display: flex;
        font-size: var(--fz-md);
        margin-bottom: 8px;
        span {
          font-weight: 500;
          font-size: var(--fz-md);
          line-height: 20px; /* 142.857% */
        }
        .tradein-rb-card-wrapper {
          flex: 1;
          display: flex;
          align-items: center;
          input {
            margin-right: 8px;
          }
        }
      }
      position: relative;
      .tradein-radio-button-card-icon,
      .tradein-radio-button-card-placeholder {
        position: absolute;
        right: 0;
        top: 25%;
        padding-right: 16px;
      }
      .tradein-radio-button-card-placeholder svg {
        width: 120px;
        height: 50px;
        @media ${mediaQueries.underS} {
          height: 80px;
        }
      }

      .tradein-radio-button-card-img {
        position: absolute;
        right: 0;
        top: 13%;
        padding-right: 16px;
        img {
          height: 80px;
        }
      }
      .tradein-radio-button-card-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
      .tradein-radio-button-card-text {
        div {
          margin-top: 8px;
        }

        &.icon-title {
          max-width: 75%;
        }

        font-size: var(--fz-sm);
        color: var(--lightBlack);
        margin-left: 32px;
        margin-top: 8px;
      }
    }
  }
  .selected-box {
    border: 2px solid var(--highlightblue);
  }
  .tradein-radio-button-card-desc {
    div {
      margin-left: 0px;
    }
    margin: 16px 0;
  }
  .tradein-radio-button-card-disclaimer {
    div {
      margin-left: 0px;
    }
    margin-top: 16px;
    font-size: 12px;
  }
  margin-bottom: 16px;
`

const RadioButtonCard = ({
  name,
  ariaLabels,
  id,
  index,
  subtitle,
  img,
  setChecked,
  curValue,
  text,
  onChange,
  value,
  savedAppraisal,
  appraisalAmount,
  enteredAmount,
  expirationDate,
  enteredObligation,
  isHomePage,
  hasDisclaimer
}) => {
  const { appState } = useContext(AppContext)
  const { isGuaranteedFlow } = appState
  function onclickCard(e) {
    if (e.currentTarget.getElementsByTagName('input')[0]) {
      e.currentTarget.getElementsByTagName('input')[0].checked = true
      if (isHomePage) {
        setChecked(e.currentTarget.getElementsByTagName('input')[0].value)
      } else {
        onChange(e.currentTarget.getElementsByTagName('input')[0], true)
      }
    }
  }

  function renderIconOrImg(savedAppraisal, index) {
    if (savedAppraisal && index > 3) {
      if (img) {
        return (
          <div className='tradein-radio-button-card-img'>
            <img src={img} />
          </div>
        )
      } else {
        return (
          <div className='tradein-radio-button-card-placeholder'>
            <Image img={'placeholderCar'} />
          </div>
        )
      }
    } else {
      return (
        <div className='tradein-radio-button-card-icon'>
          <Image img={img} />
        </div>
      )
    }
  }

  function getSelectedClass() {
    if (isHomePage) {
      if (index == curValue) {
        return 'selected-box'
      }
    }
    if (index == value && value !== '') {
      return 'selected-box'
    } else if (value && name) {
      if (value.toUpperCase() === name.toUpperCase()) {
        return 'selected-box'
      }
    } else {
      return ''
    }
  }

  return (
    <StyledRadioButtonCard>
      {savedAppraisal && index === 4 && (
        <div className='tradein-radio-button-card-desc'>
          It appears we have one or more vehicles associated with your account
          registered in our system. You can opt to choose one of these for
          trade-in evaluation.
        </div>
      )}
      <div
        className={`tradein-radio-button-card-wrapper ${getSelectedClass()}`}
        onClick={onclickCard}
      >
        <div className='tradein-radio-button-card-content'>
          <label className={`tradein-radio-button-card-row tradein-ford-rb`}>
            <div className='tradein-rb-card-wrapper'>
              <input
                id={id}
                type='radio'
                name='Own'
                aria-label={ariaLabels}
                value={index}
                aria-describedby={'error-' + name}
                onClick={onclickCard}
                onChange={isHomePage ? onChange : ''}
                defaultChecked={
                  value && name
                    ? value.toUpperCase() === name.toUpperCase()
                    : ''
                }
              />
              <span style={{ marginRight: 10 }} htmlFor={id}>
                {name}{' '}
              </span>
              {savedAppraisal && index > 3 && expirationDate === 'expired' && (
                <span class='expired'>(Offer Expired)</span>
              )}
              {savedAppraisal && index > 3 && expirationDate !== 'expired' && (
                <span class='validDays'>
                  (Offer expires in {expirationDate} days)
                </span>
              )}
            </div>

            <div className='tradein-radio-button-card-subtitle'>{subtitle}</div>

            {renderIconOrImg(savedAppraisal, index)}
          </label>

          <div
            className={
              isHomePage
                ? 'icon-title tradein-radio-button-card-text'
                : 'tradein-radio-button-card-text'
            }
          >
            {text}

            {savedAppraisal && appraisalAmount && (
              <div>Appraisal amount: {currencyUSD(appraisalAmount)}</div>
            )}
            {savedAppraisal && enteredAmount && (
              <div>Remaining Balance: {currencyUSD(enteredAmount)}</div>
            )}
            {savedAppraisal && enteredObligation && (
              <div>Lease Obligation: {currencyUSD(enteredObligation)}</div>
            )}
            {savedAppraisal && expirationDate === 'expired' && (
              <div>
                This appraisal has expired. If you still own this vehicle you
                can quickly unlock its value by updating your appraisal.
              </div>
            )}
          </div>
        </div>
      </div>
      {index === 3 && hasDisclaimer && (
        <div className='tradein-radio-button-card-disclaimer'>
          Trade-in amounts shown are conditional estimates that are not binding.
          Actual values are dependent on many factors and the dealer is not
          obligated to accept the amount shown.
          <PopUpTip
            popuptipBody={EstimatedFlowPopupTip}
            id={id}
            className='tradein-iball'
          />
        </div>
      )}
    </StyledRadioButtonCard>
  )
}
export default RadioButtonCard
